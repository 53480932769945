import { avalancheFujiTokens } from '@donaswap/tokens'
import { FeeAmount } from '@donaswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0x3F526CF2F51c3C2Ef355fDCBb13cB80c264d11Eb',
    token0: avalancheFujiTokens.wavax,
    token1: avalancheFujiTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  {
    pid: 34,
    vaultPid: 3,
    lpSymbol: 'DONA-WAVAX LP',
    lpAddress: '0x3F526CF2F51c3C2Ef355fDCBb13cB80c264d11Eb',
    quoteToken: avalancheFujiTokens.wavax,
    token: avalancheFujiTokens.dona,
  },
  // {
  //   pid: 23,
  //   vaultPid: 3,
  //   lpSymbol: 'LEET-WETH LP',
  //   lpAddress: '0x846f5e6DDb29dC5D07f8dE0a980E30cb5aa07109',
  //   quoteToken: avalancheFujiTokens.wavax,
  //   token: avalancheFujiTokens.leet,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
