import { ChainId } from '@donaswap/sdk'
import { supportedChainIdV3 } from './supportedChainIdV3'

export type FarmSupportedChainId = (typeof supportedChainIdV3)[number]

export const masterChefV3Addresses = {
    [ChainId.FIRECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.RINIA]: '0x0000000000000000000000000000000000000000',
    [ChainId.ETHEREUM]: '0x0000000000000000000000000000000000000000',
    [ChainId.GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.SEPOLIA]: '0x0000000000000000000000000000000000000000',
    [ChainId.HOLESKY]: '0x0000000000000000000000000000000000000000',
    [ChainId.BSC]: '0x0000000000000000000000000000000000000000',
    [ChainId.BSC_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON_AMOY]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON_ZKEVM]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLYGON_ZKEVM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CRO]: '0x0000000000000000000000000000000000000000',
    [ChainId.CRO_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.AVALANCHE]: '0x0000000000000000000000000000000000000000',
    [ChainId.AVALANCHE_FUJI]: '0x0000000000000000000000000000000000000000',
    [ChainId.FANTOM_OPERA]: '0x0000000000000000000000000000000000000000',
    [ChainId.FANTOM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CELO]: '0x0000000000000000000000000000000000000000',
    [ChainId.CELO_ALFAJORES]: '0x0000000000000000000000000000000000000000',
    [ChainId.OPTIMISM]: '0x0000000000000000000000000000000000000000',
    [ChainId.OPTIMISM_GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.ARBITRUM_ONE]: '0x0000000000000000000000000000000000000000',
    [ChainId.ARBITRUM_GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.SHIBARIUM]: '0x0000000000000000000000000000000000000000',
    [ChainId.SHIBARIUM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.PULSECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.PULSECHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.XEN]: '0x0000000000000000000000000000000000000000',
    [ChainId.XEN_DEVNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.HARMONY]: '0x0000000000000000000000000000000000000000',
    [ChainId.HARMONY_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.COINEX]: '0x0000000000000000000000000000000000000000',
    [ChainId.COINEX_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.DOGECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.DOGECHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ENGRAM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ETHEREUM_CLASSIC]: '0x0000000000000000000000000000000000000000',
    [ChainId.ETHEREUM_CLASSIC_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.FUSION]: '0x0000000000000000000000000000000000000000',
    [ChainId.FUSION_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.HECO]: '0x0000000000000000000000000000000000000000',
    [ChainId.HECO_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.KCC]: '0x0000000000000000000000000000000000000000',
    [ChainId.KCC_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.KLAYTN]: '0x0000000000000000000000000000000000000000',
    [ChainId.KLAYTN_BAOBAB]: '0x0000000000000000000000000000000000000000',
    [ChainId.OKXCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.OKXCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.THETHA]: '0x0000000000000000000000000000000000000000',
    [ChainId.THETHA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ULTRON]: '0x0000000000000000000000000000000000000000',
    [ChainId.ULTRON_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MOONBEAM]: '0x0000000000000000000000000000000000000000',
    [ChainId.MOONRIVER]: '0x0000000000000000000000000000000000000000',
    [ChainId.MOONBASE_ALPHA]: '0x0000000000000000000000000000000000000000',
    [ChainId.AURORA]: '0x0000000000000000000000000000000000000000',
    [ChainId.AURORA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.BOBA]: '0x0000000000000000000000000000000000000000',
    [ChainId.BOBA_GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.GNOSIS]: '0x0000000000000000000000000000000000000000',
    [ChainId.GNOSIS_CHIADO]: '0x0000000000000000000000000000000000000000',
    [ChainId.METIS]: '0x0000000000000000000000000000000000000000',
    [ChainId.METIS_GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.TELOS]: '0x0000000000000000000000000000000000000000',
    [ChainId.TELOS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.SYSCOIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.SYSCOIN_TANENBAUM]: '0x0000000000000000000000000000000000000000',
    [ChainId.IOTEX]: '0x0000000000000000000000000000000000000000',
    [ChainId.IOTEX_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.SHIDEN]: '0x0000000000000000000000000000000000000000',
    [ChainId.ASTAR]: '0x0000000000000000000000000000000000000000',
    [ChainId.SHIBUNYA]: '0x0000000000000000000000000000000000000000',
    [ChainId.FUSE]: '0x0000000000000000000000000000000000000000',
    [ChainId.FUSE_SPARKNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.VELAS]: '0x0000000000000000000000000000000000000000',
    [ChainId.VELAS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.REI]: '0x0000000000000000000000000000000000000000',
    [ChainId.REI_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.KEKCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.KEKCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.TOMOCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.TOMOCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.THUNDERCORE]: '0x0000000000000000000000000000000000000000',
    [ChainId.THUNDERCORE_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.WANCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.WANCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.RSK]: '0x0000000000000000000000000000000000000000',
    [ChainId.RSK_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ELASTOS]: '0x0000000000000000000000000000000000000000',
    [ChainId.ELASTOS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CONFLUX]: '0x0000000000000000000000000000000000000000',
    [ChainId.CONFLUX_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.BRISECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.BRISECHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MUUCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.MUUCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CANTO]: '0x0000000000000000000000000000000000000000',
    [ChainId.CANTO_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.OASIS_EMERALD]: '0x0000000000000000000000000000000000000000',
    [ChainId.OASIS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.VISION]: '0x0000000000000000000000000000000000000000',
    [ChainId.VISION_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.STEP]: '0x0000000000000000000000000000000000000000',
    [ChainId.STEP_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.METER]: '0x0000000000000000000000000000000000000000',
    [ChainId.METER_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.GODWOKEN]: '0x0000000000000000000000000000000000000000',
    [ChainId.GODWOKEN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CALLISTO]: '0x0000000000000000000000000000000000000000',
    [ChainId.CALLISTO_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.EVMOS]: '0x0000000000000000000000000000000000000000',
    [ChainId.EVMOS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ENERGY_WEB_CHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.ENERGY_VOLTA]: '0x0000000000000000000000000000000000000000',
    [ChainId.BASE]: '0x0000000000000000000000000000000000000000',
    [ChainId.BASE_GOERLI]: '0x0000000000000000000000000000000000000000',
    [ChainId.KAVA]: '0x0000000000000000000000000000000000000000',
    [ChainId.KAVA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CLOVER]: '0x0000000000000000000000000000000000000000',
    [ChainId.CLOVER_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.DEFICHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.DEFICHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.BRONOS]: '0x0000000000000000000000000000000000000000',
    [ChainId.BRONOS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.FILECOIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.FILECOIN_CALIBRATION]: '0x0000000000000000000000000000000000000000',
    [ChainId.FLARE]: '0x0000000000000000000000000000000000000000',
    [ChainId.FLARE_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.TARAXA]: '0x0000000000000000000000000000000000000000',
    [ChainId.TARAXA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ZKSYNC]: '0x0000000000000000000000000000000000000000',
    [ChainId.ZKSYNC_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.LINEA]: '0x0000000000000000000000000000000000000000',
    [ChainId.LINEA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.BTTCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.BTTCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.BOBA_AVAX]: '0x0000000000000000000000000000000000000000',
    [ChainId.BOBA_BNB]: '0x0000000000000000000000000000000000000000',
    [ChainId.CMP]: '0x0000000000000000000000000000000000000000',
    [ChainId.CMP_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CORE]: '0x0000000000000000000000000000000000000000',
    [ChainId.CORE_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.CUBECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.CUBECHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.DARWINIA_CRAB]: '0x0000000000000000000000000000000000000000',
    [ChainId.DARWINIA_PANGOLIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.DIODE_PRETNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.DIODE_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.GOCHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.GOCHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.HAQQ]: '0x0000000000000000000000000000000000000000',
    [ChainId.HAQQ_TESTEDGE2]: '0x0000000000000000000000000000000000000000',
    [ChainId.KARDIACHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.KARDIACHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.METADIUM]: '0x0000000000000000000000000000000000000000',
    [ChainId.METADIUM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.OP_BNB]: '0x0000000000000000000000000000000000000000',
    [ChainId.OP_BNB_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.PALM]: '0x0000000000000000000000000000000000000000',
    [ChainId.PALM_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLIS]: '0x0000000000000000000000000000000000000000',
    [ChainId.POLIS_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.SHIBACHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.SMART_BCH]: '0x0000000000000000000000000000000000000000',
    [ChainId.SMART_BCH_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.SONGBIRD_CANARY]: '0x0000000000000000000000000000000000000000',
    [ChainId.THAICHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.UBIQ]: '0x0000000000000000000000000000000000000000',
    [ChainId.UBIQ_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.VECHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.VECHAIN_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.XINFIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.XINFIN_APOTHEM]: '0x0000000000000000000000000000000000000000',
    [ChainId.SCROLL]: '0x0000000000000000000000000000000000000000',
    [ChainId.SCROLL_SEPOLIA]: '0x0000000000000000000000000000000000000000',
    [ChainId.XRPL_DEVNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.ZETACHAIN]: '0x0000000000000000000000000000000000000000',
    [ChainId.ZETACHAIN_ATHENS]: '0x0000000000000000000000000000000000000000',
    [ChainId.ZYX]: '0x0000000000000000000000000000000000000000',  
  } as const satisfies Record<FarmSupportedChainId, string>