import { ChainId } from '@donaswap/sdk'

import { CAKE_FLEXIBLE_SIDE_VAULT, FLAME_VAULT } from '../constants/contracts'
import { getContractAddress } from '../utils'

export function getFlameFlexibleSideVaultAddress(chainId: ChainId) {
  return getContractAddress(CAKE_FLEXIBLE_SIDE_VAULT, chainId)
}

export function getFlameVaultAddress(chainId: ChainId) {
  return getContractAddress(FLAME_VAULT, chainId)
}
