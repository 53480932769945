import { ChainId } from '@donaswap/sdk'

export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const BLOCKS_CLIENT_FIRE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ETH = 'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks'
export const BLOCKS_CLIENT_MATIC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ZKEVM_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CRO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_AVAX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_FTM = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CELO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_OP_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ARBI_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BONE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_PLS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_XN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ONE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_DOGE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ETC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_FSN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_HECO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_KCS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_KLAY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_OKT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_TFUEL = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ULX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_GLMR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_MOVR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_AURORA_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BOBA_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_XDAI = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_METIS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_TLOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SYS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_IOTX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SDN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ASTR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SBY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_FUSE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_VLX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_REI = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_KEK = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_TOMO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_TT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_WAN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_RBTC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ELA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CFX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BRISE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_MUU = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CANTO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ROSE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_VS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_STEP = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_MTR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_PCKB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CLO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_EVMOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_EWT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BASE_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_KAVA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CLOVER = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_DEFICHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BRONOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_FILECOIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_FLARE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_TARAXA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ZKSYNC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_LINEA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BTTCHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BOBA_AVAX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_BOBA_BNB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CMP = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CORE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_CUBECHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_DARWINIA_CRAB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_DIODE_PRETNET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_GOCHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_HAQQ = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_KARDIACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_METADIUM = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_PALM = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_POLIS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SHIBACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SMART_BCH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SONGBIRD_CANARY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_THAICHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_UBIQ = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_VECHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_XINFIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_SCROLL = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ZETACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_XRPL = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const BLOCKS_CLIENT_ZYX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'

export const BLOCKS_CLIENT_WITH_CHAIN = {
    [ChainId.FIRECHAIN]: BLOCKS_CLIENT_FIRE,
    [ChainId.BSC]: BLOCKS_CLIENT,
    [ChainId.ETHEREUM]: BLOCKS_CLIENT_ETH,
    [ChainId.POLYGON]: BLOCKS_CLIENT_MATIC,
    [ChainId.POLYGON_ZKEVM]: BLOCKS_CLIENT_ZKEVM_ETH,
    [ChainId.LINEA]: BLOCKS_CLIENT_LINEA,
    [ChainId.ARBITRUM_ONE]: BLOCKS_CLIENT_ARBI_ETH,
    [ChainId.CRO]: BLOCKS_CLIENT_CRO,
    [ChainId.AVALANCHE]: BLOCKS_CLIENT_AVAX,
    [ChainId.FANTOM_OPERA]: BLOCKS_CLIENT_FTM,
    [ChainId.CELO]: BLOCKS_CLIENT_CELO,
    [ChainId.OPTIMISM]: BLOCKS_CLIENT_OP_ETH,
    [ChainId.SHIBARIUM]: BLOCKS_CLIENT_BONE,
    [ChainId.PULSECHAIN]: BLOCKS_CLIENT_PLS,
    [ChainId.XEN]: BLOCKS_CLIENT_XN,
    [ChainId.HARMONY]: BLOCKS_CLIENT_ONE,
    [ChainId.COINEX]: BLOCKS_CLIENT_CET,
    [ChainId.DOGECHAIN]: BLOCKS_CLIENT_DOGE,
    [ChainId.ETHEREUM_CLASSIC]: BLOCKS_CLIENT_ETC,
    [ChainId.FUSION]: BLOCKS_CLIENT_FSN,
    [ChainId.HECO]: BLOCKS_CLIENT_HECO,
    [ChainId.KCC]: BLOCKS_CLIENT_KCS,
    [ChainId.KLAYTN]: BLOCKS_CLIENT_KLAY,
    [ChainId.OKXCHAIN]: BLOCKS_CLIENT_OKT,
    [ChainId.THETHA]: BLOCKS_CLIENT_TFUEL,
    [ChainId.ULTRON]: BLOCKS_CLIENT_ULX,
    [ChainId.MOONBEAM]: BLOCKS_CLIENT_GLMR,
    [ChainId.MOONRIVER]: BLOCKS_CLIENT_MOVR,
    [ChainId.AURORA]: BLOCKS_CLIENT_AURORA_ETH,
    [ChainId.BOBA]: BLOCKS_CLIENT_BOBA_ETH,
    [ChainId.GNOSIS]: BLOCKS_CLIENT_XDAI,
    [ChainId.METIS]: BLOCKS_CLIENT_METIS,
    [ChainId.TELOS]: BLOCKS_CLIENT_TLOS,
    [ChainId.SYSCOIN]: BLOCKS_CLIENT_SYS,
    [ChainId.IOTEX]: BLOCKS_CLIENT_IOTX,
    [ChainId.SHIDEN]: BLOCKS_CLIENT_SDN,
    [ChainId.ASTAR]: BLOCKS_CLIENT_ASTR,
    [ChainId.FUSE]: BLOCKS_CLIENT_FUSE,
    [ChainId.VELAS]: BLOCKS_CLIENT_VLX,
    [ChainId.REI]: BLOCKS_CLIENT_REI,
    [ChainId.KEKCHAIN]: BLOCKS_CLIENT_KEK,
    [ChainId.TOMOCHAIN]: BLOCKS_CLIENT_TOMO,
    [ChainId.THUNDERCORE]: BLOCKS_CLIENT_TT,
    [ChainId.WANCHAIN]: BLOCKS_CLIENT_WAN,
    [ChainId.RSK]: BLOCKS_CLIENT_RBTC,
    [ChainId.ELASTOS]: BLOCKS_CLIENT_ELA,
    [ChainId.CONFLUX]: BLOCKS_CLIENT_CFX,
    [ChainId.BRISECHAIN]: BLOCKS_CLIENT_BRISE,
    [ChainId.MUUCHAIN]: BLOCKS_CLIENT_MUU,
    [ChainId.CANTO]: BLOCKS_CLIENT_CANTO,
    [ChainId.OASIS_EMERALD]: BLOCKS_CLIENT_ROSE,
    [ChainId.VISION]: BLOCKS_CLIENT_VS,
    [ChainId.STEP]: BLOCKS_CLIENT_STEP,
    [ChainId.METER]: BLOCKS_CLIENT_MTR,
    [ChainId.GODWOKEN]: BLOCKS_CLIENT_PCKB,
    [ChainId.CALLISTO]: BLOCKS_CLIENT_CLO,
    [ChainId.EVMOS]: BLOCKS_CLIENT_EVMOS,
    [ChainId.ENERGY_WEB_CHAIN]: BLOCKS_CLIENT_EWT,
    [ChainId.BASE]: BLOCKS_CLIENT_BASE_ETH,
    [ChainId.KAVA]: BLOCKS_CLIENT_KAVA,
    [ChainId.CLOVER]: BLOCKS_CLIENT_CLOVER,
    [ChainId.DEFICHAIN]: BLOCKS_CLIENT_DEFICHAIN,
    [ChainId.BRONOS]: BLOCKS_CLIENT_BRONOS,
    [ChainId.FILECOIN]: BLOCKS_CLIENT_FILECOIN,
    [ChainId.FLARE]: BLOCKS_CLIENT_FLARE,
    [ChainId.TARAXA]: BLOCKS_CLIENT_TARAXA,
    [ChainId.ZKSYNC]: BLOCKS_CLIENT_ZKSYNC,
    [ChainId.BTTCHAIN]: BLOCKS_CLIENT_BTTCHAIN,
    [ChainId.BOBA_AVAX]: BLOCKS_CLIENT_BOBA_AVAX,
    [ChainId.BOBA_BNB]: BLOCKS_CLIENT_BOBA_BNB,
    [ChainId.CMP]: BLOCKS_CLIENT_CMP,
    [ChainId.CORE]: BLOCKS_CLIENT_CORE,
    [ChainId.CUBECHAIN]: BLOCKS_CLIENT_CUBECHAIN,
    [ChainId.DARWINIA_CRAB]: BLOCKS_CLIENT_DARWINIA_CRAB,
    [ChainId.DIODE_PRETNET]: BLOCKS_CLIENT_DIODE_PRETNET,
    [ChainId.GOCHAIN]: BLOCKS_CLIENT_GOCHAIN,
    [ChainId.HAQQ]: BLOCKS_CLIENT_HAQQ,
    [ChainId.KARDIACHAIN]: BLOCKS_CLIENT_KARDIACHAIN,
    [ChainId.METADIUM]: BLOCKS_CLIENT_METADIUM,
    [ChainId.PALM]: BLOCKS_CLIENT_PALM,
    [ChainId.POLIS]: BLOCKS_CLIENT_POLIS,
    [ChainId.SHIBACHAIN]: BLOCKS_CLIENT_SHIBACHAIN,
    [ChainId.SMART_BCH]: BLOCKS_CLIENT_SMART_BCH,
    [ChainId.SONGBIRD_CANARY]: BLOCKS_CLIENT_SONGBIRD_CANARY,
    [ChainId.THAICHAIN]: BLOCKS_CLIENT_THAICHAIN,
    [ChainId.UBIQ]: BLOCKS_CLIENT_UBIQ,
    [ChainId.VECHAIN]: BLOCKS_CLIENT_VECHAIN,
    [ChainId.XINFIN]: BLOCKS_CLIENT_XINFIN,
    [ChainId.SCROLL]: BLOCKS_CLIENT_SCROLL,
    [ChainId.ZETACHAIN]: BLOCKS_CLIENT_ZETACHAIN,
    [ChainId.XRPL_DEVNET]: BLOCKS_CLIENT_XRPL,
    [ChainId.ZYX]: BLOCKS_CLIENT_ZYX,
}