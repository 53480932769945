import { ChainId } from '@donaswap/sdk'
import memoize from 'lodash/memoize'
import {
  arbitrum,
  arbitrumGoerli,
  avalanche,
  avalancheFuji,
  goerli,
  lineaTestnet,
  mainnet,
  optimism,
  optimismGoerli,
  polygon,
  polygonZkEvm,
  polygonZkEvmTestnet,
  sepolia,
  // zkSync,
  // zkSyncTestnet,
  Chain,
  // celoAlfajores,
} from 'wagmi/chains'
import {
  // firechain,
  // rinia,
  // mainnet,
  aurora,
  holesky,
  polygonAmoy,
  auroraTestnet,
  base,
  baseGoerli,
  bsc as bsc_,
  bscTestnet,
  celo,
  celoAlfajores,
  // coinEx,
  coinExTestnet,
  // cronos,
  cronosTestnet,
  dogechain,
  dogechainTestnet,
  engramTestnet,
  fantomOpera,
  fantomTestnet,
  fusion,
  fusionTestnet,
  harmony,
  harmonyTestnet,
  klaytnCypress,
  klaytnBaobab,
  linea,
  // okxChain,
  okxChainTestnet,
  opBnb,
  opBnbTestnet,
  // scroll,
  scrollSepolia,
  shibarium,
  shibariumTestnet,
  // ultron,
  ultronTestnet,
  // xen,
  // xenDevnet,
  pulsechain,
  pulsechainTestnet,
  // ethereumClassic,
  // ethereumClassicMordor,
  heco,
  // hecoTestnet,
  kcc,
  // kccTestnet,
  // theta,
  // thetaTestnet,
  moonbeam,
  moonriver,
  moonbaseAlpha,
  // boba,
  bobaGoerli,
  // gnosis,
  gnosisChiado,
  metis,
  metisGoerli,
  telos,
  telosTestnet,
  // syscoin,
  // syscoinTanenbaum,
  // iotex,
  // iotexTestnet,
  // shiden,
  astar,
  shibunya,
  fuse,
  fuseSparknet,
  // velas,
  // velasTestnet,
  // rei,
  // reiTestnet,
  // kekchain,
  // kekchainTestnet,
  // tomochain,
  tomochainTestnet,
  thundercore,
  thundercoreTestnet,
  wanchain,
  // wanchainTestnet,
  // rsk,
  rskTestnet,
  elastos,
  // elastosTestnet,
  conflux,
  confluxTestnet,
  brisechain,
  // brisechainTestnet,
  // muuchain,
  // muuchainTestnet,
  // canto,
  // cantoTestnet,
  // oasis,
  oasisTestnet,
  // vision,
  // visionTestnet,
  // step,
  // stepTestnet,
  // meter,
  meterTestnet,
  // godwoken,
  godwokenTestnet,
  // callisto,
  // callistoTestnet,
  // evmos,
  // evmosTestnet,
  // energyWebChain,
  // energyVolta,
  kava,
  kavaTestnet,
  // clover,
  // cloverTestnet,
  // defichain,
  // defichainTestnet,
  // bronos,
  // bronosTestnet,
  // filecoin,
  // filecoinCalibration,
  flare,
  flareTestnet,
  // taraxa,
  taraxaTestnet,
  // bobaAvax,
  // bobaBnb,
  // bttchain,
  bttchainTestnet,
  cmp,
  cmpTestnet,
  core,
  coreTestnet,
  // cubechain,
  // cubechainTestnet,
  // darwiniaCrab,
  // darwiniaPangolin,
  // diodePretnet,
  // diodeTestnet,
  // gochain,
  // gochainTestnet,
  // haqq,
  // haqqTestedge,
  kardiachain,
  // kardiachainTestnet,
  // metadium,
  // metadiumTestnet,
  palm,
  palmTestnet,
  // polis,
  // polisTestnet,
  // shibachain,
  // smartBch,
  // smartBchTestnet,
  // songbirdCanary,
  // thaichain,
  // ubiq,
  // ubiqTestnet,
  // vechain,
  // vechainTestnet,
  xinfin,
  xinfinApothem,
  xrplDevnet,
  zetachain,
  zetachainAthens
  // zyx
} from '../../../../packages/wagmi/chains/addedChains'

export const CHAIN_QUERY_NAME = {
  [ChainId.FIRECHAIN]: 'fire',
  [ChainId.RINIA]: 'rinia',
  [ChainId.ETHEREUM]: 'eth',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.HOLESKY]: 'holesky',
  [ChainId.BSC]: 'bsc',
  [ChainId.BSC_TESTNET]: 'bscTestnet',
  [ChainId.ARBITRUM_ONE]: 'arb',
  [ChainId.ARBITRUM_GOERLI]: 'arbGoerli',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.POLYGON_AMOY]: 'polygonAmoy',  
  [ChainId.POLYGON_ZKEVM]: 'polygonZkEVM',
  [ChainId.POLYGON_ZKEVM_TESTNET]: 'polygonZkEVMTestnet',
  [ChainId.LINEA]: 'linea',
  [ChainId.LINEA_TESTNET]: 'lineaTestnet',
  [ChainId.CRO]: 'cronos',
  [ChainId.CRO_TESTNET]: 'cronosTestnet',
  [ChainId.AVALANCHE]: 'avalanche',
  [ChainId.AVALANCHE_FUJI]: 'avalancheFuji',
  [ChainId.FANTOM_OPERA]: 'fantomOpera',
  [ChainId.FANTOM_TESTNET]: 'fantomTestnet',
  [ChainId.CELO]: 'celo',
  [ChainId.CELO_ALFAJORES]: 'celoAlfajores',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.OPTIMISM_GOERLI]: 'optimismGoerli',
  [ChainId.SHIBARIUM]: 'shibarium',
  [ChainId.SHIBARIUM_TESTNET]: 'shibariumTestnet',
  [ChainId.PULSECHAIN]: 'pulsechain',
  [ChainId.PULSECHAIN_TESTNET]: 'pulsechainTestnet',
  [ChainId.XEN]: 'xen',
  [ChainId.XEN_DEVNET]: 'xenDevnet',
  [ChainId.HARMONY]: 'harmony',
  [ChainId.HARMONY_TESTNET]: 'harmonyTestnet',
  [ChainId.COINEX]: 'coinEx',
  [ChainId.COINEX_TESTNET]: 'coinExTestnet',
  [ChainId.DOGECHAIN]: 'dogechain',
  [ChainId.DOGECHAIN_TESTNET]: 'dogechainTestnet',
  [ChainId.ENGRAM_TESTNET]: 'engramTestnet',
  [ChainId.ETHEREUM_CLASSIC]: 'EthereumClassic',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 'EthereumClassicTestnet',
  [ChainId.FUSION]: 'fusion',
  [ChainId.FUSION_TESTNET]: 'fusionTestnet',
  [ChainId.HECO]: 'heco',
  [ChainId.HECO_TESTNET]: 'hecoTestnet',
  [ChainId.KCC]: 'kcc',
  [ChainId.KCC_TESTNET]: 'kccTestnet',
  [ChainId.KLAYTN]: 'klaytn',
  [ChainId.KLAYTN_BAOBAB]: 'klaytnBaobob',
  [ChainId.OKXCHAIN]: 'okxChain',
  [ChainId.OKXCHAIN_TESTNET]: 'okxChainTestnet',
  [ChainId.THETHA]: 'theta',
  [ChainId.THETHA_TESTNET]: 'thetaTestnet',
  [ChainId.ULTRON]: 'ultron',
  [ChainId.ULTRON_TESTNET]: 'ultronTestnet',
  [ChainId.MOONBEAM]: 'moonbeam',
  [ChainId.MOONRIVER]: 'moonriver',
  [ChainId.MOONBASE_ALPHA]: 'moonbaseAlpha',
  [ChainId.AURORA]: 'aurora',
  [ChainId.AURORA_TESTNET]: 'auroraTestnet',
  [ChainId.BOBA]: 'boba',
  [ChainId.BOBA_GOERLI]: 'bobaGoerli',
  [ChainId.GNOSIS]: 'gnosis',
  [ChainId.GNOSIS_CHIADO]: 'gnosisChiado',
  [ChainId.METIS]: 'metis',
  [ChainId.METIS_GOERLI]: 'metisGoerli',
  [ChainId.TELOS]: 'telos',
  [ChainId.TELOS_TESTNET]: 'telosTestnet',
  [ChainId.SYSCOIN]: 'syscoin',
  [ChainId.SYSCOIN_TANENBAUM]: 'syscoinTanenbaum',
  [ChainId.IOTEX]: 'iotex',
  [ChainId.IOTEX_TESTNET]: 'iotexTestnet',
  [ChainId.SHIDEN]: 'shiden',
  [ChainId.ASTAR]: 'astar',
  [ChainId.SHIBUNYA]: 'shibunya',
  [ChainId.FUSE]: 'fuse',
  [ChainId.FUSE_SPARKNET]: 'fuseSparknet',
  [ChainId.VELAS]: 'velas',
  [ChainId.VELAS_TESTNET]: 'velasTestnet',
  [ChainId.REI]: 'rei',
  [ChainId.REI_TESTNET]: 'reiTestnet',
  [ChainId.KEKCHAIN]: 'kekchain',
  [ChainId.KEKCHAIN_TESTNET]: 'kekchainTestnet',
  [ChainId.TOMOCHAIN]: 'tomochain',
  [ChainId.TOMOCHAIN_TESTNET]: 'tomochainTestnet',
  [ChainId.THUNDERCORE]: 'thundercore',
  [ChainId.THUNDERCORE_TESTNET]: 'thundercoreTestnet',
  [ChainId.WANCHAIN]: 'wanchain',
  [ChainId.WANCHAIN_TESTNET]: 'wanchainTestnet',
  [ChainId.RSK]: 'rsk',
  [ChainId.RSK_TESTNET]: 'rskTestnet',
  [ChainId.ELASTOS]: 'elastos',
  [ChainId.ELASTOS_TESTNET]: 'elastosTestnet',
  [ChainId.CONFLUX]: 'conflux',
  [ChainId.CONFLUX_TESTNET]: 'confluxTestnet',
  [ChainId.BRISECHAIN]: 'brisebriseTestnet',
  [ChainId.BRISECHAIN_TESTNET]: 'briseTestnet',
  [ChainId.MUUCHAIN]: 'muuchain',
  [ChainId.MUUCHAIN_TESTNET]: 'muuchainTestnet',
  [ChainId.CANTO]: 'canto',
  [ChainId.CANTO_TESTNET]: 'cantoTestnet',
  [ChainId.OASIS_EMERALD]: 'oasis',
  [ChainId.OASIS_TESTNET]: 'oasisTestnet',
  [ChainId.VISION]: 'vision',
  [ChainId.VISION_TESTNET]: 'visionTestnet',
  [ChainId.STEP]: 'step',
  [ChainId.STEP_TESTNET]: 'stepTestnet',
  [ChainId.METER]: 'meter',
  [ChainId.METER_TESTNET]: 'meterTestnet',
  [ChainId.GODWOKEN]: 'godwoken',
  [ChainId.GODWOKEN_TESTNET]: 'godwokenTestnet',
  [ChainId.CALLISTO]: 'callisto',
  [ChainId.CALLISTO_TESTNET]: 'callistoTestnet',
  [ChainId.EVMOS]: 'evmos',
  [ChainId.EVMOS_TESTNET]: 'evmosTestnet',
  [ChainId.ENERGY_WEB_CHAIN]: 'energywebchain',
  [ChainId.ENERGY_VOLTA]: 'energyVolta',
  [ChainId.BASE]: 'base',
  [ChainId.BASE_GOERLI]: 'baseGoerli',
  [ChainId.KAVA]: 'kava',
  [ChainId.KAVA_TESTNET]: 'kavaTestnet',
  [ChainId.CLOVER]: 'clover',
  [ChainId.CLOVER_TESTNET]: 'cloverTestnet',
  [ChainId.DEFICHAIN]: 'defichain',
  [ChainId.DEFICHAIN_TESTNET]: 'defichainTestnet',
  [ChainId.BRONOS]: 'bronos',
  [ChainId.BRONOS_TESTNET]: 'bronosTestnet',
  [ChainId.FILECOIN]: 'filecoin',
  [ChainId.FILECOIN_CALIBRATION]: 'filecoinCalibration',
  [ChainId.FLARE]: 'flare',
  [ChainId.FLARE_TESTNET]: 'flareTestnet',
  [ChainId.TARAXA]: 'taraxa',
  [ChainId.TARAXA_TESTNET]: 'taraxaTestnet',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ZKSYNC_TESTNET]: 'zksyncTestnet',
  [ChainId.BTTCHAIN]: 'bttchain',
  [ChainId.BTTCHAIN_TESTNET]: 'bttchainTestnet',
  [ChainId.BOBA_AVAX]: 'bobaAvax',
  [ChainId.BOBA_BNB]: 'bobaBnb',
  [ChainId.CMP]: 'cmp',
  [ChainId.CMP_TESTNET]: 'cmpTestnet',
  [ChainId.CORE]: 'core',
  [ChainId.CORE_TESTNET]: 'coreTestnet',
  [ChainId.CUBECHAIN]: 'cubechain',
  [ChainId.CUBECHAIN_TESTNET]: 'cubechainTestnet',
  [ChainId.DARWINIA_CRAB]: 'darwiniaCrab',
  [ChainId.DARWINIA_PANGOLIN]: 'darwiniaPangolin',
  [ChainId.DIODE_PRETNET]: 'diodePretnet',
  [ChainId.DIODE_TESTNET]: 'diodeTestnet',
  [ChainId.GOCHAIN]: 'gochain',
  [ChainId.GOCHAIN_TESTNET]: 'gochainTestnet',
  [ChainId.HAQQ]: 'haqq',
  [ChainId.HAQQ_TESTEDGE2]: 'haqqTestedge',
  [ChainId.KARDIACHAIN]: 'kardiachain',
  [ChainId.KARDIACHAIN_TESTNET]: 'kardiachainTestnet',
  [ChainId.METADIUM]: 'metadium',
  [ChainId.METADIUM_TESTNET]: 'metadiumTestnet',
  [ChainId.OP_BNB]: 'opBnb',
  [ChainId.OP_BNB_TESTNET]: 'opBnbTestnet',
  [ChainId.PALM]: 'palm',
  [ChainId.PALM_TESTNET]: 'palmTestnet',
  [ChainId.POLIS]: 'polis',
  [ChainId.POLIS_TESTNET]: 'polisTestnet',
  [ChainId.SHIBACHAIN]: 'shibachain',
  [ChainId.SMART_BCH]: 'smartBch',
  [ChainId.SMART_BCH_TESTNET]: 'smartBchTestnet',
  [ChainId.SONGBIRD_CANARY]: 'songbirdCanary',
  [ChainId.THAICHAIN]: 'thaichain',
  [ChainId.UBIQ]: 'ubiq',
  [ChainId.UBIQ_TESTNET]: 'ubiqTestnet',
  [ChainId.VECHAIN]: 'vechain',
  [ChainId.VECHAIN_TESTNET]: 'vechainTestnet',
  [ChainId.XINFIN]: 'xinfin',
  [ChainId.XINFIN_APOTHEM]: 'xinfinApothem',
  [ChainId.SCROLL]: 'scroll',
  [ChainId.SCROLL_SEPOLIA]: 'scrollSepolia',
  [ChainId.XRPL_DEVNET]: 'xrplDevnet',
  [ChainId.ZETACHAIN]: 'zetachain',
  [ChainId.ZETACHAIN_ATHENS]: 'zetachainAthens',
  [ChainId.ZYX]: 'zyx',
} as const satisfies Record<ChainId, string>

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

const bsc = {
  ...bsc_,
  rpcUrls: {
    ...bsc_.rpcUrls,
    public: {
      ...bsc_.rpcUrls.public,
      http: ['https://bsc-dataseed.binance.org/'],
    },
    default: {
      ...bsc_.rpcUrls.default,
      http: ['https://bsc-dataseed.binance.org/'],
    },
  },
} satisfies Chain

/**
 * Controls some L2 specific behavior, e.g. Slippage, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [
  ChainId.FIRECHAIN,
  ChainId.RINIA,
  ChainId.SEPOLIA,
  ChainId.HOLESKY,
  ChainId.POLYGON,
  ChainId.POLYGON_AMOY,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA,
  ChainId.LINEA_TESTNET,
  ChainId.CRO,
  ChainId.CRO_TESTNET,
  ChainId.AVALANCHE,
  ChainId.AVALANCHE_FUJI,
  ChainId.FANTOM_OPERA,
  ChainId.FANTOM_TESTNET,
  ChainId.CELO,
  ChainId.CELO_ALFAJORES,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.SHIBARIUM,
  ChainId.SHIBARIUM_TESTNET,
  ChainId.PULSECHAIN,
  ChainId.PULSECHAIN_TESTNET,
  ChainId.XEN,
  ChainId.XEN_DEVNET,
  ChainId.HARMONY,
  ChainId.HARMONY_TESTNET,
  ChainId.COINEX,
  ChainId.COINEX_TESTNET,
  ChainId.DOGECHAIN,
  ChainId.DOGECHAIN_TESTNET,
  ChainId.ENGRAM_TESTNET,
  ChainId.ETHEREUM_CLASSIC,
  ChainId.ETHEREUM_CLASSIC_TESTNET,
  ChainId.FUSION,
  ChainId.FUSION_TESTNET,
  ChainId.HECO,
  ChainId.HECO_TESTNET,
  ChainId.KCC,
  ChainId.KCC_TESTNET,
  ChainId.KLAYTN,
  ChainId.KLAYTN_BAOBAB,
  ChainId.OKXCHAIN,
  ChainId.OKXCHAIN_TESTNET,
  ChainId.THETHA,
  ChainId.THETHA_TESTNET,
  ChainId.ULTRON,
  ChainId.ULTRON_TESTNET,
  ChainId.MOONBEAM,
  ChainId.MOONRIVER,
  ChainId.MOONBASE_ALPHA,
  ChainId.AURORA,
  ChainId.AURORA_TESTNET,
  ChainId.BOBA,
  ChainId.BOBA_GOERLI,
  ChainId.GNOSIS,
  ChainId.GNOSIS_CHIADO,
  ChainId.METIS,
  ChainId.METIS_GOERLI,
  ChainId.TELOS,
  ChainId.TELOS_TESTNET,
  ChainId.SYSCOIN,
  ChainId.SYSCOIN_TANENBAUM,
  ChainId.IOTEX,
  ChainId.IOTEX_TESTNET,
  ChainId.SHIDEN,
  ChainId.ASTAR,
  ChainId.SHIBUNYA,
  ChainId.FUSE,
  ChainId.FUSE_SPARKNET,
  ChainId.VELAS,
  ChainId.VELAS_TESTNET,
  ChainId.REI,
  ChainId.REI_TESTNET,
  ChainId.KEKCHAIN,
  ChainId.KEKCHAIN_TESTNET,
  ChainId.TOMOCHAIN,
  ChainId.TOMOCHAIN_TESTNET,
  ChainId.THUNDERCORE,
  ChainId.THUNDERCORE_TESTNET,
  ChainId.WANCHAIN,
  ChainId.WANCHAIN_TESTNET,
  ChainId.RSK,
  ChainId.RSK_TESTNET,
  ChainId.ELASTOS,
  ChainId.ELASTOS_TESTNET,
  ChainId.CONFLUX,
  ChainId.CONFLUX_TESTNET,
  ChainId.BRISECHAIN,
  ChainId.BRISECHAIN_TESTNET,
  ChainId.MUUCHAIN,
  ChainId.MUUCHAIN_TESTNET,
  ChainId.CANTO,
  ChainId.CANTO_TESTNET,
  ChainId.OASIS_EMERALD,
  ChainId.OASIS_TESTNET,
  ChainId.VISION,
  ChainId.VISION_TESTNET,
  ChainId.STEP,
  ChainId.STEP_TESTNET,
  ChainId.METER,
  ChainId.METER_TESTNET,
  ChainId.GODWOKEN,
  ChainId.GODWOKEN_TESTNET,
  ChainId.CALLISTO,
  ChainId.CALLISTO_TESTNET,
  ChainId.EVMOS,
  ChainId.EVMOS_TESTNET,
  ChainId.ENERGY_WEB_CHAIN,
  ChainId.ENERGY_VOLTA,
  ChainId.BASE,
  ChainId.BASE_GOERLI,
  ChainId.KAVA,
  ChainId.KAVA_TESTNET,
  ChainId.CLOVER,
  ChainId.CLOVER_TESTNET,
  ChainId.DEFICHAIN,
  ChainId.DEFICHAIN_TESTNET,
  ChainId.BRONOS,
  ChainId.BRONOS_TESTNET,
  ChainId.FILECOIN,
  ChainId.FILECOIN_CALIBRATION,
  ChainId.FLARE,
  ChainId.FLARE_TESTNET,
  ChainId.TARAXA,
  ChainId.TARAXA_TESTNET,
  ChainId.BTTCHAIN,
  ChainId.BTTCHAIN_TESTNET,
  ChainId.BOBA_AVAX,
  ChainId.BOBA_BNB,
  ChainId.CMP,
  ChainId.CMP_TESTNET,
  ChainId.CORE,
  ChainId.CORE_TESTNET,
  ChainId.CUBECHAIN,
  ChainId.CUBECHAIN_TESTNET,
  ChainId.DARWINIA_CRAB,
  ChainId.DARWINIA_PANGOLIN,
  ChainId.DIODE_PRETNET,
  ChainId.DIODE_TESTNET,
  ChainId.GOCHAIN,
  ChainId.GOCHAIN_TESTNET,
  ChainId.HAQQ,
  ChainId.HAQQ_TESTEDGE2,
  ChainId.KARDIACHAIN,
  ChainId.KARDIACHAIN_TESTNET,
  ChainId.METADIUM,
  ChainId.METADIUM_TESTNET,
  ChainId.OP_BNB,
  ChainId.OP_BNB_TESTNET,
  ChainId.PALM,
  ChainId.PALM_TESTNET,
  ChainId.POLIS,
  ChainId.POLIS_TESTNET,
  ChainId.SHIBACHAIN,
  ChainId.SMART_BCH,
  ChainId.SMART_BCH_TESTNET,
  ChainId.SONGBIRD_CANARY,
  ChainId.THAICHAIN,
  ChainId.UBIQ,
  ChainId.UBIQ_TESTNET,
  ChainId.VECHAIN,
  ChainId.VECHAIN_TESTNET,
  ChainId.XINFIN,
  ChainId.XINFIN_APOTHEM,
  ChainId.SCROLL,
  ChainId.SCROLL_SEPOLIA,
  ChainId.XRPL_DEVNET,
  ChainId.ZETACHAIN,
  ChainId.ZETACHAIN_ATHENS,
  ChainId.ZYX
]

export const CHAINS = [
  // firechain,
  // rinia,
  mainnet,
  // goerli,
  sepolia,
  holesky,
  linea,
  lineaTestnet,
  bsc,
  bscTestnet,
  // opBnb,
  // opBnbTestnet,
  polygon,
  polygonAmoy,
  polygonZkEvm,
  polygonZkEvmTestnet,
  avalanche,
  avalancheFuji,
  fantomOpera,
  fantomTestnet,
  celo,
  // celoAlfajores,
  // cronos,
  // cronosTestnet,
  base,
  // baseGoerli,
  // shibarium,
  // shibariumTestnet,
  optimism,
  // optimismGoerli,
  arbitrum,
  // arbitrumGoerli,
  aurora,
  auroraTestnet,
  // xen,
  // xenDevnet,
  harmony,
  harmonyTestnet,
  // zkSync,
  // zkSyncTestnet,
  // fusion,
  fusionTestnet,
  dogechain,
  dogechainTestnet,
  engramTestnet,
  // coinEx,
  coinExTestnet,
  heco,
  // hecoTestnet,
  kcc,
  // kccTestnet,
  // klaytnCypress,
  // klaytnBaobab,
  // okxChain,
  okxChainTestnet,
  // theta,
  // thetaTestnet,
  // ultron,
  ultronTestnet,
  // pulsechain,
  pulsechainTestnet,
  moonriver,
  moonbeam,
  moonbaseAlpha,
  // boba,
  // bobaGoerli,
  // bobaAvax,
  // bobaBnb,
  // gnosis,
  gnosisChiado,
  metis,
  // metisGoerli,
  // telos,
  // telosTestnet,
  // syscoin,
  // syscoinTanenbaum,
  // iotex,
  // iotexTestnet,
  // shiden,
  astar,
  // shibunya,
  fuse,
  fuseSparknet,
  // velas,
  // velasTestnet,
  // rei,
  // reiTestnet,
  // kekchain,
  // kekchainTestnet,
  // tomochain,
  tomochainTestnet,
  thundercore,
  // thundercoreTestnet,
  // wanchain,
  // wanchainTestnet,
  // rsk,
  // rskTestnet,
  // elastos,
  // elastosTestnet,
  conflux,
  // confluxTestnet,
  // brisechain,
  // brisechainTestnet,
  // muuchain,
  // muuchainTestnet,
  // canto,
  // cantoTestnet,
  // oasis,
  // oasisTestnet,
  // vision,
  // visionTestnet,
  // step,
  // stepTestnet,
  // meter,
  // meterTestnet,
  // godwoken,
  godwokenTestnet,
  // callisto,
  // callistoTestnet,
  // evmos,
  // evmosTestnet,
  // energyWebChain,
  // energyVolta,
  kava,
  kavaTestnet,
  // clover,
  // cloverTestnet,
  // defichain,
  // defichainTestnet,
  // bronos,
  // bronosTestnet,
  // filecoin,
  // filecoinCalibration,
  flare,
  flareTestnet,
  // taraxa,
  // taraxaTestnet,
  // ethereumClassic,
  // ethereumClassicMordor,
  // bttchain,
  bttchainTestnet,
  cmp,
  cmpTestnet,
  core,
  coreTestnet,
  // cubechain,
  // cubechainTestnet,
  // darwiniaCrab,
  // darwiniaPangolin,
  // diodePretnet,
  // diodeTestnet,
  // gochain,
  // gochainTestnet,
  // haqq,
  // haqqTestedge,
  kardiachain,
  // kardiachainTestnet,
  // metadium,
  // metadiumTestnet,
  palm,
  // palmTestnet,
  // polis,
  // polisTestnet,
  // shibachain,
  // smartBch,
  // smartBchTestnet,
  // songbirdCanary,
  // thaichain,
  // ubiq,
  // ubiqTestnet,
  // vechain,
  // vechainTestnet,
  // xinfin,
  // xinfinApothem,
  // scroll,
  scrollSepolia,
  // xrplDevnet,
  // zetachain,
  zetachainAthens
  // zyx,
]
