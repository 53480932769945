import { ChainId } from '@donaswap/sdk'

export const GRAPH_API_PROFILE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/profile'
export const GRAPH_API_PREDICTION_BNB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/prediction-v2'
export const GRAPH_API_PREDICTION_FLAME = 'https://api.thegraph.com/subgraphs/name/0xfirechain/prediction-cake'

export const GRAPH_API_LOTTERY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/lottery'
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = 'https://profile.pancakeswap.com'
export const API_NFT = 'https://nft.pancakeswap.com/api/v1'
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const GRAPH_API_POTTERY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/pottery'
export const ONRAMP_API_BASE_URL = 'https://pcs-onramp-api.com'
export const MOONPAY_BASE_URL = 'https://api.moonpay.com'
export const MOONPAY_SIGN_URL = 'https://pcs-on-ramp-api.com'
/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/0xfirechain/prediction'

// export const V3_BSC_INFO_CLIENT = `https://open-platform.nodereal.io/${
//   process.env.NEXT_PUBLIC_NODE_REAL_API_INFO || process.env.NEXT_PUBLIC_NODE_REAL_API_ETH
// }/pancakeswap-v3/graphql`

export const V3_BSC_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-bsc`
export const V3_ETHEREUM_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-ethereum`
export const V3_POLYGON_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-polygon`
export const V3_POLYGON_ZKEVM_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-polygon-zkevm`
export const V3_AVALANCHE_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-avalanche`
export const V3_OPTIMISM_INFO_CLIENT = `https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v3-optimism`

export const STABLESWAP_SUBGRAPH_CLIENT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-stableswap'
export const GRAPH_API_NFTMARKET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/nft-market'
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'
export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/trading-competition-v3'
export const TC_MOD_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/trading-competition-v4'
export const BIT_QUERY = 'https://graphql.bitquery.io'
export const ACCESS_RISK_API = '/api/risk'
export const CELER_API = 'https://api.celerscan.com/scan'
export const ASSET_CDN = 'https://assets.donaswap.io'
export const TRADING_REWARD_API = 'https://pancake-trading-fee-rebate-api.pancakeswap.com/api/v1'
export const QUOTING_API = `${process.env.NEXT_PUBLIC_QUOTING_API}/v0/quote`
export const FARMS_API = 'https://farms-api.pancakeswap.com'
export const MERCURYO_WIDGET_ID = process.env.NEXT_PUBLIC_MERCURYO_WIDGET_ID || '95a003f2-354a-4396-828a-1126d56e4e13'
