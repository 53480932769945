import {
  BLOCKS_CLIENT_FIRE,
  BLOCKS_CLIENT,
  BLOCKS_CLIENT_ETH,
  BLOCKS_CLIENT_MATIC,
  BLOCKS_CLIENT_ZKEVM_ETH,
  BLOCKS_CLIENT_CRO,
  BLOCKS_CLIENT_AVAX,
  BLOCKS_CLIENT_FTM,
  BLOCKS_CLIENT_CELO,
  BLOCKS_CLIENT_OP_ETH,
  BLOCKS_CLIENT_ARBI_ETH,
  BLOCKS_CLIENT_BONE,
  BLOCKS_CLIENT_PLS,
  BLOCKS_CLIENT_XN,
  BLOCKS_CLIENT_ONE,
  BLOCKS_CLIENT_CET,
  BLOCKS_CLIENT_DOGE,
  BLOCKS_CLIENT_ETC,
  BLOCKS_CLIENT_FSN,
  BLOCKS_CLIENT_HECO,
  BLOCKS_CLIENT_KCS,
  BLOCKS_CLIENT_KLAY,
  BLOCKS_CLIENT_OKT,
  BLOCKS_CLIENT_TFUEL,
  BLOCKS_CLIENT_ULX,
  BLOCKS_CLIENT_GLMR,
  BLOCKS_CLIENT_MOVR,
  BLOCKS_CLIENT_AURORA_ETH,
  BLOCKS_CLIENT_BOBA_ETH,
  BLOCKS_CLIENT_XDAI,
  BLOCKS_CLIENT_METIS,
  BLOCKS_CLIENT_TLOS,
  BLOCKS_CLIENT_SYS,
  BLOCKS_CLIENT_IOTX,
  BLOCKS_CLIENT_SDN,
  BLOCKS_CLIENT_ASTR,
  BLOCKS_CLIENT_FUSE,
  BLOCKS_CLIENT_VLX,
  BLOCKS_CLIENT_REI,
  BLOCKS_CLIENT_KEK,
  BLOCKS_CLIENT_TOMO,
  BLOCKS_CLIENT_TT,
  BLOCKS_CLIENT_WAN,
  BLOCKS_CLIENT_RBTC,
  BLOCKS_CLIENT_ELA,
  BLOCKS_CLIENT_CFX,
  BLOCKS_CLIENT_BRISE,
  BLOCKS_CLIENT_MUU,
  BLOCKS_CLIENT_CANTO,
  BLOCKS_CLIENT_ROSE,
  BLOCKS_CLIENT_VS,
  BLOCKS_CLIENT_STEP,
  BLOCKS_CLIENT_MTR,
  BLOCKS_CLIENT_PCKB,
  BLOCKS_CLIENT_CLO,
  BLOCKS_CLIENT_EVMOS,
  BLOCKS_CLIENT_EWT,
  BLOCKS_CLIENT_BASE_ETH,
  BLOCKS_CLIENT_KAVA,
  BLOCKS_CLIENT_CLOVER,
  BLOCKS_CLIENT_DEFICHAIN,
  BLOCKS_CLIENT_BRONOS,
  BLOCKS_CLIENT_FILECOIN,
  BLOCKS_CLIENT_FLARE,
  BLOCKS_CLIENT_TARAXA,
  BLOCKS_CLIENT_ZKSYNC,
  BLOCKS_CLIENT_LINEA,
  BLOCKS_CLIENT_BTTCHAIN,
  BLOCKS_CLIENT_BOBA_AVAX,
  BLOCKS_CLIENT_BOBA_BNB,
  BLOCKS_CLIENT_CMP,
  BLOCKS_CLIENT_CORE,
  BLOCKS_CLIENT_CUBECHAIN,
  BLOCKS_CLIENT_DARWINIA_CRAB,
  BLOCKS_CLIENT_DIODE_PRETNET,
  BLOCKS_CLIENT_GOCHAIN,
  BLOCKS_CLIENT_HAQQ,
  BLOCKS_CLIENT_KARDIACHAIN,
  BLOCKS_CLIENT_METADIUM,
  BLOCKS_CLIENT_PALM,
  BLOCKS_CLIENT_POLIS,
  BLOCKS_CLIENT_SHIBACHAIN,
  BLOCKS_CLIENT_SMART_BCH,
  BLOCKS_CLIENT_SONGBIRD_CANARY,
  BLOCKS_CLIENT_THAICHAIN,
  BLOCKS_CLIENT_UBIQ,
  BLOCKS_CLIENT_VECHAIN,
  BLOCKS_CLIENT_XINFIN,
  BLOCKS_CLIENT_SCROLL,
  BLOCKS_CLIENT_XRPL,
  BLOCKS_CLIENT_ZETACHAIN,
  BLOCKS_CLIENT_ZYX,
} from 'config/constants/blocksClientEndpoints'
import {
  INFO_CLIENT_FIRE,
  INFO_CLIENT,
  INFO_CLIENT_ETH,
  INFO_CLIENT_ZKEVM_ETH,
  INFO_CLIENT_MATIC,
  INFO_CLIENT_CRO,
  INFO_CLIENT_AVAX,
  INFO_CLIENT_FTM,
  INFO_CLIENT_CELO,
  INFO_CLIENT_OP_ETH,
  INFO_CLIENT_ARBI_ETH,
  INFO_CLIENT_BONE,
  INFO_CLIENT_PLS,
  INFO_CLIENT_XN,
  INFO_CLIENT_ONE,
  INFO_CLIENT_CET,
  INFO_CLIENT_DOGE,
  INFO_CLIENT_ETC,
  INFO_CLIENT_FSN,
  INFO_CLIENT_HECO,
  INFO_CLIENT_KCS,
  INFO_CLIENT_KLAY,
  INFO_CLIENT_OKT,
  INFO_CLIENT_TFUEL,
  INFO_CLIENT_ULX,
  INFO_CLIENT_GLMR,
  INFO_CLIENT_MOVR,
  INFO_CLIENT_AURORA_ETH,
  INFO_CLIENT_BOBA_ETH,
  INFO_CLIENT_XDAI,
  INFO_CLIENT_METIS,
  INFO_CLIENT_TLOS,
  INFO_CLIENT_SYS,
  INFO_CLIENT_IOTX,
  INFO_CLIENT_SDN,
  INFO_CLIENT_ASTR,
  INFO_CLIENT_FUSE,
  INFO_CLIENT_VLX,
  INFO_CLIENT_REI,
  INFO_CLIENT_KEK,
  INFO_CLIENT_TOMO,
  INFO_CLIENT_TT,
  INFO_CLIENT_WAN,
  INFO_CLIENT_RBTC,
  INFO_CLIENT_ELA,
  INFO_CLIENT_CFX,
  INFO_CLIENT_BRISE,
  INFO_CLIENT_MUU,
  INFO_CLIENT_CANTO,
  INFO_CLIENT_ROSE,
  INFO_CLIENT_VS,
  INFO_CLIENT_STEP,
  INFO_CLIENT_MTR,
  INFO_CLIENT_PCKB,
  INFO_CLIENT_CLO,
  INFO_CLIENT_EVMOS,
  INFO_CLIENT_EWT,
  INFO_CLIENT_BASE_ETH,
  INFO_CLIENT_KAVA,
  INFO_CLIENT_CLOVER,
  INFO_CLIENT_DEFICHAIN,
  INFO_CLIENT_BRONOS,
  INFO_CLIENT_FILECOIN,
  INFO_CLIENT_FLARE,
  INFO_CLIENT_TARAXA,
  INFO_CLIENT_ZKSYNC,
  INFO_CLIENT_LINEA,
  INFO_CLIENT_BTTCHAIN,
  INFO_CLIENT_BOBA_AVAX,
  INFO_CLIENT_BOBA_BNB,
  INFO_CLIENT_CMP,
  INFO_CLIENT_CORE,
  INFO_CLIENT_CUBECHAIN,
  INFO_CLIENT_DARWINIA_CRAB,
  INFO_CLIENT_DIODE_PRETNET,
  INFO_CLIENT_GOCHAIN,
  INFO_CLIENT_HAQQ,
  INFO_CLIENT_KARDIACHAIN,
  INFO_CLIENT_METADIUM,
  INFO_CLIENT_PALM,
  INFO_CLIENT_POLIS,
  INFO_CLIENT_SHIBACHAIN,
  INFO_CLIENT_SMART_BCH,
  INFO_CLIENT_SONGBIRD_CANARY,
  INFO_CLIENT_THAICHAIN,
  INFO_CLIENT_UBIQ,
  INFO_CLIENT_VECHAIN,
  INFO_CLIENT_XINFIN,
  INFO_CLIENT_SCROLL,
  INFO_CLIENT_XRPL_DEVNET,
  INFO_CLIENT_ZETACHAIN,
  INFO_CLIENT_ZYX,
} from 'config/constants/infoClientEndpoints'
import {
  infoClientFIRE,
  infoClientETH,
  infoClient,
  infoStableSwapClient,
  infoClientMATIC,
  infoClientZKEVM_ETH,
  infoClientCRO,
  infoClientAVAX,
  infoClientFTM,
  infoClientCELO,
  infoClientOP,
  infoClientARBI,
  infoClientBONE,
  infoClientPLS,
  infoClientXN,
  infoClientONE,
  infoClientCET,
  infoClientDOGE,
  infoClientETC,
  infoClientFSN,
  infoClientHT,
  infoClientKCS,
  infoClientKLAY,
  infoClientOKT,
  infoClientTFUEL,
  infoClientULX,
  infoClientGLMR,
  infoClientMOVR,
  infoClientAURORA,
  infoClientBOBA,
  infoClientXDAI,
  infoClientMETIS,
  infoClientTLOS,
  infoClientSYS,
  infoClientIOTX,
  infoClientSDN,
  infoClientASTR,
  infoClientFUSE,
  infoClientVLX,
  infoClientREI,
  infoClientKEK,
  infoClientTOMO,
  infoClientTT,
  infoClientWAN,
  infoClientRBTC,
  infoClientELA,
  infoClientCFX,
  infoClientBRISE,
  infoClientMUU,
  infoClientCANTO,
  infoClientROSE,
  infoClientVS,
  infoClientSTEP,
  infoClientMTR,
  infoClientPCKB,
  infoClientCLO,
  infoClientEVMOS,
  infoClientEWT,
  infoClientBASE,
  infoClientKAVA,
  infoClientCLOVER,
  infoClientDEFICHAIN,
  infoClientBRONOS,
  infoClientFILECOIN,
  infoClientFLARE,
  infoClientTARAXA,
  infoClientZKSYNC,
  infoClientLINEA,
  infoClientBTTCHAIN,
  infoClientBOBA_AVAX,
  infoClientBOBA_BNB,
  infoClientCMP,
  infoClientCORE,
  infoClientCUBECHAIN,
  infoClientDARWINIA_CRAB,
  infoClientDIODE_PRETNET,
  infoClientGOCHAIN,
  infoClientHAQQ,
  infoClientKARDIACHAIN,
  infoClientMETADIUM,
  infoClientPALM,
  infoClientPOLIS,
  infoClientSHIBACHAIN,
  infoClientSMART_BCH,
  infoClientSONGBIRD,
  infoClientTHAICHAIN,
  infoClientUBIQ,
  infoClientVECHAIN,
  infoClientXINFIN,
  infoClientSCROLL,
  infoClientXRPL_DEVNET,
  infoClientZETACHAIN,
  infoClientZYX
} from 'utils/graphql'
import { GraphQLClient } from 'graphql-request'

import { ChainId } from '@donaswap/sdk'
import { 
  DONASWAP_ETH_V2_START,
  DONASWAP_BSC_V2_START,
  DONASWAP_POLYGON_V2_START,
  DONASWAP_POLYGON_ZKEVM_V2_START,
  DONASWAP_AVALANCHE_V2_START,
  DONASWAP_OPTIMISM_V2_START,
  DONASWAP_ARBITRUM_V2_START
} from 'config/constants/info'
import { FIRECHAIN_TOKEN_WHITELIST, FIRECHAIN_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/firechain-tokenlist'
import { BSC_TOKEN_WHITELIST, BSC_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/bsc-tokenlist'
import { ETH_TOKEN_WHITELIST, ETH_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/ethereum-tokenlist'
import { POLYGON_TOKEN_WHITELIST, POLYGON_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/polygon-tokenlist'
import { POLYGON_ZKEVM_TOKEN_WHITELIST, POLYGON_ZKEVM_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/polygon-zkevm-tokenlist'
import { AVALANCHE_TOKEN_WHITELIST, AVALANCHE_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/avalanche-tokenlist'
import { ARBITRUM_TOKEN_WHITELIST, ARBITRUM_TOKEN_BLACKLIST } from 'config/constants/tokenLists/white-and-blacklists/arbitrum-tokenlist'


// export type MultiChainName = 'BSC' | 'ETH' | 'BSC_TESTNET'
export type MultiChainName =
  | 'BSC'
  | 'ETH'
  | 'BSC_TESTNET'
  | 'FIRECHAIN'
  | 'POLYGON'
  | 'CRO'
  | 'AVALANCHE'
  | 'FANTOM_OPERA'
  | 'CELO'
  | 'OPTIMISM'
  | 'POLYGON_ZKEVM'

export type MultiChainNameExtend =
  | MultiChainName
  | 'BSC_TESTNET'
// | 'GOERLI'
// | 'SEPOLIA'
// | 'POLYGON_AMOY'
// | 'POLYGON_ZKEVM_TESTNET'
// | 'CRO_TESTNET'
// | 'AVALANCHE_FUJI'
// | 'FANTOM_TESTNET'
// | 'CELO_ALFAJORES'
// | 'OPTIMISM_GOERLI'
// | 'POLYGON_AMOY'
// | 'POLYGON_AMOY'

export const multiChainName: Record<number | string, MultiChainNameExtend> = {
  [ChainId.FIRECHAIN]: 'FIRECHAIN',
  // [ChainId.RINIA]: 'RINIA',
  [ChainId.ETHEREUM]: 'ETH',
  // [ChainId.GOERLI]: 'GOERLI',
  // [ChainId.SEPOLIA]: 'SEPOLIA',
  [ChainId.BSC]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC_TESTNET',
  [ChainId.POLYGON]: 'POLYGON',
  // [ChainId.POLYGON_AMOY]: 'POLYGON_AMOY',
  [ChainId.POLYGON_ZKEVM]: 'POLYGON_ZKEVM',
  // [ChainId.POLYGON_ZKEVM_TESTNET]: 'POLYGON_ZKEVM_TESTNET',
  [ChainId.CRO]: 'CRO',
  // [ChainId.CRO_TESTNET]: 'CRO_TESTNET',
  [ChainId.AVALANCHE]: 'AVALANCHE',
  // [ChainId.AVALANCHE_FUJI]: 'AVALANCHE_FUJI',
  [ChainId.FANTOM_OPERA]: 'FANTOM_OPERA',
  // [ChainId.FANTOM_TESTNET]: 'FANTOM_TESTNET',
  [ChainId.CELO]: 'CELO',
  // [ChainId.CELO_ALFAJORES]: 'CELO_ALFAJORES',
  [ChainId.OPTIMISM]: 'OPTIMISM',
  // [ChainId.OPTIMISM_GOERLI]: 'OPTIMISM_GOERLI',
  // [ChainId.ARBITRUM]: '',
  // [ChainId.ARBITRUM_GOERLI]: '',
  // [ChainId.SHIBARIUM]: '',
  // [ChainId.SHIBARIUM_TESTNET]: '',
  // [ChainId.PULSECHAIN]: '',
  // [ChainId.PULSECHAIN_TESTNET]: '',
  // [ChainId.XEN]: '',
  // [ChainId.XEN_DEVNET]: '',
  // [ChainId.HARMONY]: '',
  // [ChainId.HARMONY_TESTNET]: '',
  // [ChainId.COINEX]: '',
  // [ChainId.COINEX_TESTNET]: '',
  // [ChainId.DOGECHAIN]: '',
  // [ChainId.DOGECHAIN_TESTNET]: '',
  // [ChainId.ETHEREUM_CLASSIC]: '',
  // [ChainId.ETHEREUM_CLASSIC_TESTNET]: '',
  // [ChainId.FUSION]: '',
  // [ChainId.FUSION_TESTNET]: '',
  // [ChainId.HECO]: '',
  // [ChainId.HECO_TESTNET]: '',
  // [ChainId.KCC]: '',
  // [ChainId.KCC_TESTNET]: '',
  // [ChainId.KLAYTN]: '',
  // [ChainId.KLAYTN_BAOBAB]: '',
  // [ChainId.OKXCHAIN]: '',
  // [ChainId.OKXCHAIN_TESTNET]: '',
  // [ChainId.THETHA]: '',
  // [ChainId.THETHA_TESTNET]: '',
  // [ChainId.ULTRON]: '',
  // [ChainId.ULTRON_TESTNET]: '',
  // [ChainId.MOONBEAM]: '',
  // [ChainId.MOONRIVER]: '',
  // [ChainId.MOONBASE_ALPHA]: '',
  // [ChainId.AURORA]: '',
  // [ChainId.AURORA_TESTNET]: '',
  // [ChainId.BOBA]: '',
  // [ChainId.BOBA_GOERLI]: '',
  // [ChainId.GNOSIS]: '',
  // [ChainId.GNOSIS_CHIADO]: '',
  // [ChainId.METIS]: '',
  // [ChainId.METIS_GOERLI]: '',
  // [ChainId.TELOS]: '',
  // [ChainId.TELOS_TESTNET]: '',
  // [ChainId.SYSCOIN]: '',
  // [ChainId.SYSCOIN_TANENBAUM]: '',
  // [ChainId.IOTEX]: '',
  // [ChainId.IOTEX_TESTNET]: '',
  // [ChainId.SHIDEN]: '',
  // [ChainId.ASTAR]: '',
  // [ChainId.SHIBUNYA]: '',
  // [ChainId.FUSE]: '',
  // [ChainId.FUSE_SPARKNET]: '',
  // [ChainId.VELAS]: '',
  // [ChainId.VELAS_TESTNET]: '',
  // [ChainId.REI]: '',
  // [ChainId.REI_TESTNET]: '',
  // [ChainId.KEKCHAIN]: '',
  // [ChainId.KEKCHAIN_TESTNET]: '',
  // [ChainId.TOMOCHAIN]: '',
  // [ChainId.TOMOCHAIN_TESTNET]: '',
  // [ChainId.THUNDERCORE]: '',
  // [ChainId.THUNDERCORE_TESTNET]: '',
  // [ChainId.WANCHAIN]: '',
  // [ChainId.WANCHAIN_TESTNET]: '',
  // [ChainId.RSK]: '',
  // [ChainId.RSK_TESTNET]: '',
  // [ChainId.ELASTOS]: '',
  // [ChainId.ELASTOS_TESTNET]: '',
  // [ChainId.CONFLUX]: '',
  // [ChainId.CONFLUX_TESTNET]: '',
  // [ChainId.BRISECHAIN]: '',
  // [ChainId.BRISECHAIN_TESTNET]: '',
  // [ChainId.MUUCHAIN]: '',
  // [ChainId.MUUCHAIN_TESTNET]: '',
  // [ChainId.CANTO]: '',
  // [ChainId.CANTO_TESTNET]: '',
  // [ChainId.OASIS_EMERALD]: '',
  // [ChainId.OASIS_TESTNET]: '',
  // [ChainId.VISION]: '',
  // [ChainId.VISION_TESTNET]: '',
  // [ChainId.STEP]: '',
  // [ChainId.STEP_TESTNET]: '',
  // [ChainId.METER]: '',
  // [ChainId.METER_TESTNET]: '',
  // [ChainId.GODWOKEN]: '',
  // [ChainId.GODWOKEN_TESTNET]: '',
  // [ChainId.CALLISTO]: '',
  // [ChainId.CALLISTO_TESTNET]: '',
  // [ChainId.EVMOS]: '',
  // [ChainId.EVMOS_TESTNET]: '',
  // [ChainId.ENERGY_WEB_CHAIN]: '',
  // [ChainId.ENERGY_VOLTA]: '',
  // [ChainId.BASE]: '',
  // [ChainId.BASE_GOERLI]: '',
  // [ChainId.KAVA]: '',
  // [ChainId.KAVA_TESTNET]: '',
  // [ChainId.CLOVER]: '',
  // [ChainId.CLOVER_TESTNET]: '',
  // [ChainId.DEFICHAIN]: '',
  // [ChainId.DEFICHAIN_TESTNET]: '',
  // [ChainId.BRONOS]: '',
  // [ChainId.BRONOS_TESTNET]: '',
  // [ChainId.FILECOIN]: '',
  // [ChainId.FILECOIN_CALIBRATION]: '',
  // [ChainId.FLARE]: '',
  // [ChainId.FLARE_TESTNET]: '',
  // [ChainId.TARAXA]: '',
  // [ChainId.TARAXA_TESTNET]: '',
  // [ChainId.ZKSYNC]: '',
  // [ChainId.ZKSYNC_TESTNET]: '',
}

export const multiChainQueryMainToken = {
  // FIRE: 'FIRE',
  BSC: 'BNB',
  ETH: 'ETH',
  POLYGON: 'MATIC',
  // CRO: 'CRO',
  AVALANCHE: 'AVAX',
  // FTM: 'FTM',
  // CELO: 'CELO',
  // BONE: 'BONE',
}

export const multiChainBlocksClient = {
  FIRE: BLOCKS_CLIENT_FIRE,
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  BSC_TESTNET: 'https://api.thegraph.com/subgraphs/name/lengocphuc99/bsc_testnet-blocks',
  POLYGON: BLOCKS_CLIENT_MATIC,
  ZKEVM_ETH: BLOCKS_CLIENT_ZKEVM_ETH,
  CRO: BLOCKS_CLIENT_CRO,
  AVALANCHE: BLOCKS_CLIENT_AVAX,
  FTM: BLOCKS_CLIENT_FTM,
  CELO: BLOCKS_CLIENT_CELO,
  OP_ETH: BLOCKS_CLIENT_OP_ETH,
  ARBI_ETH: BLOCKS_CLIENT_ARBI_ETH,
  BONE: BLOCKS_CLIENT_BONE,
  PLS: BLOCKS_CLIENT_PLS,
  XN: BLOCKS_CLIENT_XN,
  ONE: BLOCKS_CLIENT_ONE,
  CET: BLOCKS_CLIENT_CET,
  DOGE: BLOCKS_CLIENT_DOGE,
  ETC: BLOCKS_CLIENT_ETC,
  FSN: BLOCKS_CLIENT_FSN,
  HT: BLOCKS_CLIENT_HECO,
  KCS: BLOCKS_CLIENT_KCS,
  KLAY: BLOCKS_CLIENT_KLAY,
  OKT: BLOCKS_CLIENT_OKT,
  TFUEL: BLOCKS_CLIENT_TFUEL,
  ULX: BLOCKS_CLIENT_ULX,
  GLMR: BLOCKS_CLIENT_GLMR,
  MOVR: BLOCKS_CLIENT_MOVR,
  AURORA_ETH: BLOCKS_CLIENT_AURORA_ETH,
  BOBA_ETH: BLOCKS_CLIENT_BOBA_ETH,
  XDAI: BLOCKS_CLIENT_XDAI,
  METIS: BLOCKS_CLIENT_METIS,
  TLOS: BLOCKS_CLIENT_TLOS,
  SYS: BLOCKS_CLIENT_SYS,
  IOTX: BLOCKS_CLIENT_IOTX,
  SDN: BLOCKS_CLIENT_SDN,
  ASTR: BLOCKS_CLIENT_ASTR,
  FUSE: BLOCKS_CLIENT_FUSE,
  VLX: BLOCKS_CLIENT_VLX,
  REI: BLOCKS_CLIENT_REI,
  KEK: BLOCKS_CLIENT_KEK,
  TOMO: BLOCKS_CLIENT_TOMO,
  TT: BLOCKS_CLIENT_TT,
  WAN: BLOCKS_CLIENT_WAN,
  RBTC: BLOCKS_CLIENT_RBTC,
  ELA: BLOCKS_CLIENT_ELA,
  CFX: BLOCKS_CLIENT_CFX,
  BRISE: BLOCKS_CLIENT_BRISE,
  MUU: BLOCKS_CLIENT_MUU,
  CANTO: BLOCKS_CLIENT_CANTO,
  ROSE: BLOCKS_CLIENT_ROSE,
  VS: BLOCKS_CLIENT_VS,
  STEP: BLOCKS_CLIENT_STEP,
  MTR: BLOCKS_CLIENT_MTR,
  PCKB: BLOCKS_CLIENT_PCKB,
  CLO: BLOCKS_CLIENT_CLO,
  EVMOS: BLOCKS_CLIENT_EVMOS,
  EWT: BLOCKS_CLIENT_EWT,
  BASE_ETH: BLOCKS_CLIENT_BASE_ETH,
  KAVA: BLOCKS_CLIENT_KAVA,
  CLOVER: BLOCKS_CLIENT_CLOVER,
  DFI: BLOCKS_CLIENT_DEFICHAIN,
  BRO: BLOCKS_CLIENT_BRONOS,
  FILE: BLOCKS_CLIENT_FILECOIN,
  FLARE: BLOCKS_CLIENT_FLARE,
  TARA: BLOCKS_CLIENT_TARAXA,
  ZKSYNC: BLOCKS_CLIENT_ZKSYNC,
  LINEA_ETH: BLOCKS_CLIENT_LINEA,
  BTT: BLOCKS_CLIENT_BTTCHAIN,
  BOBA_AVAX: BLOCKS_CLIENT_BOBA_AVAX,
  BOBA_BNB: BLOCKS_CLIENT_BOBA_BNB,
  CMP: BLOCKS_CLIENT_CMP,
  CORE: BLOCKS_CLIENT_CORE,
  CUBE: BLOCKS_CLIENT_CUBECHAIN,
  CRAB: BLOCKS_CLIENT_DARWINIA_CRAB,
  DIODE: BLOCKS_CLIENT_DIODE_PRETNET,
  GO: BLOCKS_CLIENT_GOCHAIN,
  ISLM: BLOCKS_CLIENT_HAQQ,
  KAI: BLOCKS_CLIENT_KARDIACHAIN,
  META: BLOCKS_CLIENT_METADIUM,
  PALM: BLOCKS_CLIENT_PALM,
  POLIS: BLOCKS_CLIENT_POLIS,
  SHIB: BLOCKS_CLIENT_SHIBACHAIN,
  BCH: BLOCKS_CLIENT_SMART_BCH,
  SGB: BLOCKS_CLIENT_SONGBIRD_CANARY,
  TCH: BLOCKS_CLIENT_THAICHAIN,
  UBQ: BLOCKS_CLIENT_UBIQ,
  VET: BLOCKS_CLIENT_VECHAIN,
  XDC: BLOCKS_CLIENT_XINFIN,
  SCROLL_ETH: BLOCKS_CLIENT_SCROLL,
  ZYX: BLOCKS_CLIENT_ZYX,
  XRP: BLOCKS_CLIENT_XRPL,
  ZETA: BLOCKS_CLIENT_ZETACHAIN,
}

export const multiChainStartTime = {
  BSC: DONASWAP_BSC_V2_START,
  ETH: DONASWAP_ETH_V2_START,
  POLYGON: DONASWAP_POLYGON_V2_START,
  POLYGON_ZKEVM: DONASWAP_POLYGON_ZKEVM_V2_START,
  AVALANCHE: DONASWAP_AVALANCHE_V2_START,
  OPTIMISM: DONASWAP_OPTIMISM_V2_START,
  ARBITRUM: DONASWAP_ARBITRUM_V2_START,

  // TODO add missing networks
}

export const multiChainId = {
  FIRECHAIN: ChainId.FIRECHAIN,
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  POLYGON: ChainId.POLYGON,
  ZKEVM_ETH: ChainId.POLYGON_ZKEVM,
  CRO: ChainId.CRO,
  AVAX: ChainId.AVALANCHE,
  FTM: ChainId.FANTOM_OPERA,
  CELO: ChainId.CELO,
  OP_ETH: ChainId.OPTIMISM,
  ARBI_ETH: ChainId.ARBITRUM_ONE,
  BONE: ChainId.SHIBARIUM,
  PLS: ChainId.PULSECHAIN,
  XN: ChainId.XEN,
  ONE: ChainId.HARMONY,
  CET: ChainId.COINEX,
  DOGE: ChainId.DOGECHAIN,
  ETC: ChainId.ETHEREUM_CLASSIC,
  FSN: ChainId.FUSION,
  HT: ChainId.HECO,
  KCS: ChainId.KCC,
  KLAY: ChainId.KLAYTN,
  OKT: ChainId.OKXCHAIN,
  TFUEL: ChainId.THETHA,
  ULX: ChainId.ULTRON,
  GLMR: ChainId.MOONBEAM,
  MOVR: ChainId.MOONRIVER,
  AURORA_ETH: ChainId.AURORA,
  BOBA_ETH: ChainId.BOBA,
  XDAI: ChainId.GNOSIS,
  METIS: ChainId.METIS,
  TLOS: ChainId.TELOS,
  SYS: ChainId.SYSCOIN,
  IOTX: ChainId.IOTEX,
  SDN: ChainId.SHIDEN,
  ASTR: ChainId.ASTAR,
  FUSE: ChainId.FUSE,
  VLX: ChainId.VELAS,
  REI: ChainId.REI,
  KEK: ChainId.KEKCHAIN,
  TOMO: ChainId.TOMOCHAIN,
  TT: ChainId.THUNDERCORE,
  WAN: ChainId.WANCHAIN,
  RBTC: ChainId.RSK,
  ELA: ChainId.ELASTOS,
  CFX: ChainId.CONFLUX,
  BRISE: ChainId.BRISECHAIN,
  MUU: ChainId.MUUCHAIN,
  CANTO: ChainId.CANTO,
  ROSE: ChainId.OASIS_EMERALD,
  VS: ChainId.VISION,
  STEP: ChainId.STEP,
  MTR: ChainId.METER,
  PCKB: ChainId.GODWOKEN,
  CLO: ChainId.CALLISTO,
  EVMOS: ChainId.EVMOS,
  EWT: ChainId.ENERGY_WEB_CHAIN,
  BASE_ETH: ChainId.BASE,
  KAVA: ChainId.KAVA,
  DFI: ChainId.DEFICHAIN,
  BRO: ChainId.BRONOS,
  FIL: ChainId.FILECOIN,
  FLR: ChainId.FLARE,
  TARA: ChainId.TARAXA,
  ZKSYNC_ETH: ChainId.ZKSYNC,
  LINEA_ETH: ChainId.LINEA,
  BTT: ChainId.BTTCHAIN,
  BOBA_AVAX: ChainId.BOBA_AVAX,
  BOBA_BNB: ChainId.BOBA_BNB,
  CMP: ChainId.CMP,
  CORE: ChainId.CORE,
  CUBE: ChainId.CUBECHAIN,
  CRAB: ChainId.DARWINIA_CRAB,
  DIODE: ChainId.DIODE_PRETNET,
  GO: ChainId.GOCHAIN,
  ISLM: ChainId.HAQQ,
  KAI: ChainId.KARDIACHAIN,
  META: ChainId.METADIUM,
  PALM: ChainId.PALM,
  POLIS: ChainId.POLIS,
  SHIB: ChainId.SHIBACHAIN,
  BCH: ChainId.SMART_BCH,
  SGB: ChainId.SONGBIRD_CANARY,
  TCH: ChainId.THAICHAIN,
  UBQ: ChainId.UBIQ,
  VET: ChainId.VECHAIN,
  XDC: ChainId.XINFIN,
  SCROLL_ETH: ChainId.SCROLL,
  ZYX: ChainId.ZYX,
  // XRP: ChainId.XRPL,
  ZETA: BLOCKS_CLIENT_ZETACHAIN
}

export const multiChainPaths = {
  [ChainId.FIRECHAIN]: 'fire',
  [ChainId.RINIA]: '/rinia',
  [ChainId.BSC]: '',
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.GOERLI]: '/goerli',
  [ChainId.SEPOLIA]: '/sepolia',
  [ChainId.HOLESKY]: '/holesky',
  [ChainId.POLYGON]: '/matic',
  [ChainId.POLYGON_AMOY]: '/amoy',
  [ChainId.POLYGON_ZKEVM]: '/zkevm-eth',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '/zkevm-eth',
  [ChainId.CRO]: '/cro',
  [ChainId.CRO_TESTNET]: '/cro',
  [ChainId.AVALANCHE]: '/avax',
  [ChainId.AVALANCHE_FUJI]: '/avax',
  [ChainId.FANTOM_OPERA]: '/ftm',
  [ChainId.FANTOM_TESTNET]: '/ftm',
  [ChainId.CELO]: '/celo',
  [ChainId.CELO_ALFAJORES]: '/celo',
  [ChainId.OPTIMISM]: '/op',
  [ChainId.OPTIMISM_GOERLI]: '/op',
  [ChainId.ARBITRUM_ONE]: '/arbi-eth',
  [ChainId.ARBITRUM_GOERLI]: '/arbi-eth',
  [ChainId.HARMONY]: '/one',
  [ChainId.HARMONY_TESTNET]: '/one',
  [ChainId.SHIBARIUM]: '/shib',
  [ChainId.SHIBARIUM_TESTNET]: '/shib',
  [ChainId.PULSECHAIN]: '/pls',
  [ChainId.PULSECHAIN_TESTNET]: '/pls',
  [ChainId.XEN]: '/xn',
  [ChainId.XEN_DEVNET]: '/xn',
  [ChainId.COINEX]: '/cet',
  [ChainId.COINEX_TESTNET]: '/cet',
  [ChainId.DOGECHAIN]: '/doge',
  [ChainId.DOGECHAIN_TESTNET]: '/doge',
  [ChainId.ENGRAM_TESTNET]: '/doge',
  [ChainId.ETHEREUM_CLASSIC]: '/etc',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: '/etc',
  [ChainId.FUSION]: '/fsn',
  [ChainId.FUSION_TESTNET]: '/fsn',
  [ChainId.HECO]: '/ht',
  [ChainId.HECO_TESTNET]: '/ht',
  [ChainId.KCC]: '/kcs',
  [ChainId.KCC_TESTNET]: '/kcs',
  [ChainId.KLAYTN]: '/klay',
  [ChainId.KLAYTN_BAOBAB]: '/klay',
  [ChainId.OKXCHAIN]: '/okt',
  [ChainId.OKXCHAIN_TESTNET]: '/okt',
  [ChainId.THETHA]: '/tfuel',
  [ChainId.THETHA_TESTNET]: '/tfuel',
  [ChainId.ULTRON]: '/ulx',
  [ChainId.ULTRON_TESTNET]: '/ulx',
  [ChainId.MOONBEAM]: '/glmr',
  [ChainId.MOONRIVER]: '/movr',
  [ChainId.MOONBASE_ALPHA]: '/dev',
  [ChainId.AURORA]: '/aurora',
  [ChainId.AURORA_TESTNET]: '/aurora',
  [ChainId.BOBA]: '/boba',
  [ChainId.BOBA_GOERLI]: '/boba',
  [ChainId.GNOSIS]: '/gnosis',
  [ChainId.GNOSIS_CHIADO]: '/gnosis',
  [ChainId.METIS]: '/metis',
  [ChainId.METIS_GOERLI]: '/metis',
  [ChainId.TELOS]: '/tlos',
  [ChainId.TELOS_TESTNET]: '/tlos',
  [ChainId.SYSCOIN]: '/sys',
  [ChainId.SYSCOIN_TANENBAUM]: '/sys',
  [ChainId.IOTEX]: '/iotx',
  [ChainId.IOTEX_TESTNET]: '/iotx',
  [ChainId.SHIDEN]: '/sdn',
  [ChainId.ASTAR]: '/astr',
  [ChainId.SHIBUNYA]: '/sby',
  [ChainId.FUSE]: '/fuse',
  [ChainId.FUSE_SPARKNET]: '/fuse',
  [ChainId.VELAS]: '/vlx',
  [ChainId.VELAS_TESTNET]: '/vlx',
  [ChainId.REI]: '/rei',
  [ChainId.REI_TESTNET]: '/rei',
  [ChainId.KEKCHAIN]: '/kek',
  [ChainId.KEKCHAIN_TESTNET]: '/kek',
  [ChainId.TOMOCHAIN]: '/tomo',
  [ChainId.TOMOCHAIN_TESTNET]: '/tomo',
  [ChainId.THUNDERCORE]: '/tt',
  [ChainId.THUNDERCORE_TESTNET]: '/tt',
  [ChainId.WANCHAIN]: '/wan',
  [ChainId.WANCHAIN_TESTNET]: '/wan',
  [ChainId.RSK]: '/rbtc',
  [ChainId.RSK_TESTNET]: '/rbtc',
  [ChainId.ELASTOS]: '/ela',
  [ChainId.ELASTOS_TESTNET]: '/ela',
  [ChainId.CONFLUX]: '/cfx',
  [ChainId.CONFLUX_TESTNET]: '/cfx',
  [ChainId.BRISECHAIN]: '/brise',
  [ChainId.BRISECHAIN_TESTNET]: '/brise',
  [ChainId.MUUCHAIN]: '/muu',
  [ChainId.MUUCHAIN_TESTNET]: '/muu',
  [ChainId.CANTO]: '/canto',
  [ChainId.CANTO_TESTNET]: '/canto',
  [ChainId.OASIS_EMERALD]: '/rose',
  [ChainId.OASIS_TESTNET]: '/rose',
  [ChainId.VISION]: '/vs',
  [ChainId.VISION_TESTNET]: '/vs',
  [ChainId.STEP]: '/step',
  [ChainId.STEP_TESTNET]: '/step',
  [ChainId.METER]: '/mtr',
  [ChainId.METER_TESTNET]: '/mtr',
  [ChainId.GODWOKEN]: '/pckb',
  [ChainId.GODWOKEN_TESTNET]: '/pckb',
  [ChainId.CALLISTO]: '/clo',
  [ChainId.CALLISTO_TESTNET]: '/clo',
  [ChainId.EVMOS]: '/evmos',
  [ChainId.EVMOS_TESTNET]: '/evmos',
  [ChainId.ENERGY_WEB_CHAIN]: '/energy',
  [ChainId.ENERGY_VOLTA]: '/energy',
  [ChainId.BASE]: '/base',
  [ChainId.BASE_GOERLI]: '/base',
  [ChainId.KAVA]: 'kava',
  [ChainId.KAVA_TESTNET]: 'kavaTestnet',
  [ChainId.CLOVER]: 'clover',
  [ChainId.CLOVER_TESTNET]: 'cloverTestnet',
  [ChainId.DEFICHAIN]: 'defichain',
  [ChainId.DEFICHAIN_TESTNET]: 'defichainTestnet',
  [ChainId.BRONOS]: 'bronos',
  [ChainId.BRONOS_TESTNET]: 'bronosTestnet',
  [ChainId.FILECOIN]: 'filecoin',
  [ChainId.FILECOIN_CALIBRATION]: 'filecoinCalibration',
  [ChainId.FLARE]: 'flare',
  [ChainId.FLARE_TESTNET]: 'flareTestnet',
  [ChainId.TARAXA]: 'taraxa',
  [ChainId.TARAXA_TESTNET]: 'taraxaTestnet',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ZKSYNC_TESTNET]: 'zksyncTestnet',
  [ChainId.LINEA]: 'linea',
  [ChainId.LINEA_TESTNET]: 'lineaTestnet',
  [ChainId.BTTCHAIN]: 'bttchain',
  [ChainId.BTTCHAIN_TESTNET]: 'bttchainTestnet',
  [ChainId.BOBA_AVAX]: 'bobaAvax',
  [ChainId.BOBA_BNB]: 'bobaBnb',
  [ChainId.CMP]: 'cmp',
  [ChainId.CMP_TESTNET]: 'cmpTestnet',
  [ChainId.CORE]: 'core',
  [ChainId.CORE_TESTNET]: 'coreTestnet',
  [ChainId.CUBECHAIN]: 'cubechain',
  [ChainId.CUBECHAIN_TESTNET]: 'cubechainTestnet',
  [ChainId.DARWINIA_CRAB]: 'darwiniaCrab',
  [ChainId.DARWINIA_PANGOLIN]: 'darwiniaPangolin',
  [ChainId.DIODE_PRETNET]: 'diodePretnet',
  [ChainId.DIODE_TESTNET]: 'diodeTestnet',
  [ChainId.GOCHAIN]: 'gochain',
  [ChainId.GOCHAIN_TESTNET]: 'gochainTestnet',
  [ChainId.HAQQ]: 'haqq',
  [ChainId.HAQQ_TESTEDGE2]: 'haqqTestedge',
  [ChainId.KARDIACHAIN]: 'kardiachain',
  [ChainId.KARDIACHAIN_TESTNET]: 'kardiachainTestnet',
  [ChainId.METADIUM]: 'metadium',
  [ChainId.METADIUM_TESTNET]: 'metadiumTestnet',
  [ChainId.OP_BNB]: 'opBnb',
  [ChainId.OP_BNB_TESTNET]: 'opBnbTestnet',
  [ChainId.PALM]: 'palm',
  [ChainId.PALM_TESTNET]: 'palmTestnet',
  [ChainId.POLIS]: 'polis',
  [ChainId.POLIS_TESTNET]: 'polisTestnet',
  [ChainId.SHIBACHAIN]: 'shibachain',
  [ChainId.SMART_BCH]: 'smartBch',
  [ChainId.SMART_BCH_TESTNET]: 'smartBchTestnet',
  [ChainId.SONGBIRD_CANARY]: 'songbirdCanary',
  [ChainId.THAICHAIN]: 'thaichain',
  [ChainId.UBIQ]: 'ubiq',
  [ChainId.UBIQ_TESTNET]: 'ubiqTestnet',
  [ChainId.VECHAIN]: 'vechain',
  [ChainId.VECHAIN_TESTNET]: 'vechainTestnet',
  [ChainId.XINFIN]: 'xinfin',
  [ChainId.XINFIN_APOTHEM]: 'xinfinApothem',
  [ChainId.SCROLL]: 'scroll',
  [ChainId.SCROLL_SEPOLIA]: 'scrollSepolia',
  [ChainId.XRPL_DEVNET]: 'xrplDevnet',
  [ChainId.ZETACHAIN]: 'zetachain',
  [ChainId.ZETACHAIN_ATHENS]: 'zetachainAthens',
  [ChainId.ZYX]: 'zyx'
}

export const multiChainQueryClient = {
  FIRE: infoClientFIRE,
  BSC: infoClient,
  ETH: infoClientETH,
  POLYGON: infoClientMATIC,
  ZKEVM_ETH: infoClientZKEVM_ETH,
  CRO: infoClientCRO,
  AVALANCHE: infoClientAVAX,
  FTM: infoClientFTM,
  CELO: infoClientCELO,
  OP_ETH: infoClientOP,
  ARBI_ETH: infoClientARBI,
  BONE: infoClientBONE,
  PLS: infoClientPLS,
  XN: infoClientXN,
  ONE: infoClientONE,
  CET: infoClientCET,
  DOGE: infoClientDOGE,
  ETC: infoClientETC,
  FSN: infoClientFSN,
  HT: infoClientHT,
  KCS: infoClientKCS,
  KLAY: infoClientKLAY,
  OKT: infoClientOKT,
  TFUEL: infoClientTFUEL,
  ULX: infoClientULX,
  GLMR: infoClientGLMR,
  MOVR: infoClientMOVR,
  AURORA_ETH: infoClientAURORA,
  BOBA_ETH: infoClientBOBA,
  XDAI: infoClientXDAI,
  METIS: infoClientMETIS,
  TLOS: infoClientTLOS,
  SYS: infoClientSYS,
  IOTX: infoClientIOTX,
  SDN: infoClientSDN,
  ASTR: infoClientASTR,
  FUSE: infoClientFUSE,
  VLX: infoClientVLX,
  REI: infoClientREI,
  KEK: infoClientKEK,
  TOMO: infoClientTOMO,
  TT: infoClientTT,
  WAN: infoClientWAN,
  RBTC: infoClientRBTC,
  ELA: infoClientELA,
  CFX: infoClientCFX,
  BRISE: infoClientBRISE,
  MUU: infoClientMUU,
  CANTO: infoClientCANTO,
  ROSE: infoClientROSE,
  VS: infoClientVS,
  STEP: infoClientSTEP,
  MTR: infoClientMTR,
  PCKB: infoClientPCKB,
  CLO: infoClientCLO,
  EVMOS: infoClientEVMOS,
  EWT: infoClientEWT,
  BASE_ETH: infoClientBASE,
  KAVA: infoClientKAVA,
  CLOVER: infoClientCLOVER,
  DFI: infoClientDEFICHAIN,
  BRO: infoClientBRONOS,
  FILE: infoClientFILECOIN,
  FLARE: infoClientFLARE,
  TARA: infoClientTARAXA,
  ZKSYNC: infoClientZKSYNC,
  LINEA_ETH: infoClientLINEA,
  BTT: infoClientBTTCHAIN,
  BOBA_AVAX: infoClientBOBA_AVAX,
  BOBA_BNB: infoClientBOBA_BNB,
  CMP: infoClientCMP,
  CORE: infoClientCORE,
  CUBE: infoClientCUBECHAIN,
  CRAB: infoClientDARWINIA_CRAB,
  DIODE: infoClientDIODE_PRETNET,
  GO: infoClientGOCHAIN,
  ISLM: infoClientHAQQ,
  KAI: infoClientKARDIACHAIN,
  META: infoClientMETADIUM,
  PALM: infoClientPALM,
  POLIS: infoClientPOLIS,
  SHIB: infoClientSHIBACHAIN,
  BCH: infoClientSMART_BCH,
  SGB: infoClientSONGBIRD,
  TCH: infoClientTHAICHAIN,
  UBQ: infoClientUBIQ,
  VET: infoClientVECHAIN,
  XDC: infoClientXINFIN,
  SCROLL_ETH: infoClientSCROLL,
  XRP: infoClientXRPL_DEVNET,
  ZETA: infoClientZETACHAIN,
  ZYX: infoClientZYX,
}

export const multiChainQueryEndPoint = {
  FIRE: INFO_CLIENT_FIRE,
  BSC: INFO_CLIENT,
  ETH: INFO_CLIENT_ETH,
  POLYGON: INFO_CLIENT_MATIC,
  POLYGON_ZKEVM: INFO_CLIENT_ZKEVM_ETH,
  CRO: INFO_CLIENT_CRO,
  AVALANCHE: INFO_CLIENT_AVAX,
  FTM: INFO_CLIENT_FTM,
  CELO: INFO_CLIENT_CELO,
  OP_ETH: INFO_CLIENT_OP_ETH,
  ARBI_ETH: INFO_CLIENT_ARBI_ETH,
  BONE: INFO_CLIENT_BONE,
  PLS: INFO_CLIENT_PLS,
  XN: INFO_CLIENT_XN,
  ONE: INFO_CLIENT_ONE,
  CET: INFO_CLIENT_CET,
  DOGE: INFO_CLIENT_DOGE,
  ETC: INFO_CLIENT_ETC,
  FSN: INFO_CLIENT_FSN,
  HT: INFO_CLIENT_HECO,
  KCS: INFO_CLIENT_KCS,
  KLAY: INFO_CLIENT_KLAY,
  OKT: INFO_CLIENT_OKT,
  TFUEL: INFO_CLIENT_TFUEL,
  ULX: INFO_CLIENT_ULX,
  GLMR: INFO_CLIENT_GLMR,
  MOVR: INFO_CLIENT_MOVR,
  AURORA_ETH: INFO_CLIENT_AURORA_ETH,
  BOBA_ETH: INFO_CLIENT_BOBA_ETH,
  XDAI: INFO_CLIENT_XDAI,
  METIS: INFO_CLIENT_METIS,
  TLOS: INFO_CLIENT_TLOS,
  SYS: INFO_CLIENT_SYS,
  IOTX: INFO_CLIENT_IOTX,
  SDN: INFO_CLIENT_SDN,
  ASTR: INFO_CLIENT_ASTR,
  FUSE: INFO_CLIENT_FUSE,
  VLX: INFO_CLIENT_VLX,
  REI: INFO_CLIENT_REI,
  KEK: INFO_CLIENT_KEK,
  TOMO: INFO_CLIENT_TOMO,
  TT: INFO_CLIENT_TT,
  WAN: INFO_CLIENT_WAN,
  RBTC: INFO_CLIENT_RBTC,
  ELA: INFO_CLIENT_ELA,
  CFX: INFO_CLIENT_CFX,
  BRISE: INFO_CLIENT_BRISE,
  MUU: INFO_CLIENT_MUU,
  CANTO: INFO_CLIENT_CANTO,
  ROSE: INFO_CLIENT_ROSE,
  VS: INFO_CLIENT_VS,
  STEP: INFO_CLIENT_STEP,
  MTR: INFO_CLIENT_MTR,
  PCKB: INFO_CLIENT_PCKB,
  CLO: INFO_CLIENT_CLO,
  EVMOS: INFO_CLIENT_EVMOS,
  EWT: INFO_CLIENT_EWT,
  BASE_ETH: INFO_CLIENT_BASE_ETH,
  KAVA: INFO_CLIENT_KAVA,
  CLOVER: INFO_CLIENT_CLOVER,
  DFI: INFO_CLIENT_DEFICHAIN,
  BRO: INFO_CLIENT_BRONOS,
  FILE: INFO_CLIENT_FILECOIN,
  FLARE: INFO_CLIENT_FLARE,
  TARA: INFO_CLIENT_TARAXA,
  ZKSYNC: INFO_CLIENT_ZKSYNC,
  LINEA_ETH: INFO_CLIENT_LINEA,
  BTT: INFO_CLIENT_BTTCHAIN,
  BOBA_AVAX: INFO_CLIENT_BOBA_AVAX,
  BOBA_BNB: INFO_CLIENT_BOBA_BNB,
  CMP: INFO_CLIENT_CMP,
  CORE: INFO_CLIENT_CORE,
  CUBE: INFO_CLIENT_CUBECHAIN,
  CRAB: INFO_CLIENT_DARWINIA_CRAB,
  DIODE: INFO_CLIENT_DIODE_PRETNET,
  GO: INFO_CLIENT_GOCHAIN,
  ISLM: INFO_CLIENT_HAQQ,
  KAI: INFO_CLIENT_KARDIACHAIN,
  META: INFO_CLIENT_METADIUM,
  PALM: INFO_CLIENT_PALM,
  POLIS: INFO_CLIENT_POLIS,
  SHIB: INFO_CLIENT_SHIBACHAIN,
  BCH: INFO_CLIENT_SMART_BCH,
  SGB: INFO_CLIENT_SONGBIRD_CANARY,
  TCH: INFO_CLIENT_THAICHAIN,
  UBQ: INFO_CLIENT_UBIQ,
  VET: INFO_CLIENT_VECHAIN,
  XDC: INFO_CLIENT_XINFIN,
  SCROLL_ETH: INFO_CLIENT_SCROLL,
  XRP: INFO_CLIENT_XRPL_DEVNET,
  ZETA: INFO_CLIENT_ZETACHAIN,
  ZYX: INFO_CLIENT_ZYX,
}

export const multiChainScan = {
  FIRE: 'FireScan',
  BSC: 'BscScan',
  ETH: 'EtherScan',
  POLYGON: 'PolygonScan',
  CRO: 'CronoScan',
  AVALANCHE: 'Snowtrace',
  FTM: 'FTMScan',
  CELO: 'CeloScan',
  BONE: 'ShibariumScan',

}

export const multiChainTokenBlackList = {
  FIRE: FIRECHAIN_TOKEN_BLACKLIST,
  BSC: BSC_TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  POLYGON: POLYGON_TOKEN_BLACKLIST,
  POLYGON_ZKEVM: POLYGON_ZKEVM_TOKEN_BLACKLIST,
  AVALANCHE: AVALANCHE_TOKEN_BLACKLIST,
  ARBI_ETH: ARBITRUM_TOKEN_BLACKLIST,
}

export const multiChainTokenWhiteList = {
  FIRE: FIRECHAIN_TOKEN_WHITELIST,
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
  POLYGON: POLYGON_TOKEN_WHITELIST,
  POLYGON_ZKEVM: POLYGON_ZKEVM_TOKEN_WHITELIST,
  AVALANCHE: AVALANCHE_TOKEN_WHITELIST,
  ARBI_ETH: ARBITRUM_TOKEN_WHITELIST,

  // TODO Add other networks
  // CRO: CRO_TOKEN_WHITELIST,
  // FTM: FTM_TOKEN_WHITELIST,
  // CELO: CELO_TOKEN_WHITELIST,
  // OP_ETH: OP_ETH_TOKEN_WHITELIST,
  // BONE: BONE_TOKEN_WHITELIST,
  // PLS: PLS_TOKEN_WHITELIST,
  // XN: XN_TOKEN_WHITELIST,
  // ONE: ONE_TOKEN_WHITELIST,
  // CET: CET_TOKEN_WHITELIST,
  // DOGE: DOGE_TOKEN_WHITELIST,
  // ETC: ETC_TOKEN_WHITELIST,
  // FSN: FSN_TOKEN_WHITELIST,
  // HT: HECO_TOKEN_WHITELIST,
  // KCS: KCS_TOKEN_WHITELIST,
  // KLAY: KLAY_TOKEN_WHITELIST,
  // OKT: OKT_TOKEN_WHITELIST,
  // TFUEL: TFUEL_TOKEN_WHITELIST,
  // ULX: ULX_TOKEN_WHITELIST,
  // GLMR: GLMR_TOKEN_WHITELIST,
  // MOVR: MOVR_TOKEN_WHITELIST,
  // AURORA_ETH: AURORA_ETH_TOKEN_WHITELIST,
  // BOBA_ETH: BOBA_ETH_TOKEN_WHITELIST,
  // XDAI: XDAI_TOKEN_WHITELIST,
  // METIS: METIS_TOKEN_WHITELIST,
  // TLOS: TLOS_TOKEN_WHITELIST,
  // SYS: SYS_TOKEN_WHITELIST,
  // IOTX: IOTX_TOKEN_WHITELIST,
  // SDN: SDN_TOKEN_WHITELIST,
  // ASTR: ASTR_TOKEN_WHITELIST,
  // FUSE: FUSE_TOKEN_WHITELIST,
  // VLX: VLX_TOKEN_WHITELIST,
  // REI: REI_TOKEN_WHITELIST,
  // KEK: KEK_TOKEN_WHITELIST,
  // TOMO: TOMO_TOKEN_WHITELIST,
  // TT: TT_TOKEN_WHITELIST,
  // WAN: WAN_TOKEN_WHITELIST,
  // RBTC: RBTC_TOKEN_WHITELIST,
  // ELA: ELA_TOKEN_WHITELIST,
  // CFX: CFX_TOKEN_WHITELIST,
  // BRISE: BRISE_TOKEN_WHITELIST,
  // MUU: MUU_TOKEN_WHITELIST,
  // CANTO: CANTO_TOKEN_WHITELIST,
  // ROSE: ROSE_TOKEN_WHITELIST,
  // VS: VS_TOKEN_WHITELIST,
  // STEP: STEP_TOKEN_WHITELIST,
  // MTR: MTR_TOKEN_WHITELIST,
  // PCKB: PCKB_TOKEN_WHITELIST,
  // CLO: CLO_TOKEN_WHITELIST,
  // EVMOS: EVMOS_TOKEN_WHITELIST,
  // EWT: EWT_TOKEN_WHITELIST,
  // BASE_ETH: BASE_ETH_TOKEN_WHITELIST,
  // KAVA: KAVA_TOKEN_WHITELIST,
  // CLOVER: CLOVER_TOKEN_WHITELIST,
  // DFI: DEFICHAIN_TOKEN_WHITELIST,
  // BRO: BRONOS_TOKEN_WHITELIST,
  // FILE: FILECOIN_TOKEN_WHITELIST,
  // FLARE: FLARE_TOKEN_WHITELIST,
  // TARA: TARAXA_TOKEN_WHITELIST,
  // ZKSYNC: ZKSYNC_TOKEN_WHITELIST,
  // LINEA_ETH: LINEA_TOKEN_WHITELIST,
  // BTT: BTTCHAIN_TOKEN_WHITELIST,
  // BOBA_AVAX: BOBA_AVAX_TOKEN_WHITELIST,
  // BOBA_BNB: BOBA_BNB_TOKEN_WHITELIST,
  // CMP: CMP_TOKEN_WHITELIST,
  // CORE: CORE_TOKEN_WHITELIST,
  // CUBE: CUBECHAIN_TOKEN_WHITELIST,
  // CRAB: DARWINIA_CRAB_TOKEN_WHITELIST,
  // DIODE: DIODE_PRETNET_TOKEN_WHITELIST,
  // GO: GOCHAIN_TOKEN_WHITELIST,
  // ISLM: HAQQ_TOKEN_WHITELIST,
  // KAI: KARDIACHAIN_TOKEN_WHITELIST,
  // META: METADIUM_TOKEN_WHITELIST,
  // PALM: PALM_TOKEN_WHITELIST,
  // POLIS: POLIS_TOKEN_WHITELIST,
  // SHIB: SHIBACHAIN_TOKEN_WHITELIST,
  // BCH: SMART_BCH_TOKEN_WHITELIST,
  // SGB: SONGBIRD_CANARY_TOKEN_WHITELIST,
  // TCH: THAICHAIN_TOKEN_WHITELIST,
  // UBQ: UBIQ_TOKEN_WHITELIST,
  // VET: VECHAIN_TOKEN_WHITELIST,
  // XDC: XINFIN_TOKEN_WHITELIST,
  // SCROLL_ETH: SCROLL_TOKEN_WHITELIST,
  // ZYX: ZYX_TOKEN_WHITELIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName): GraphQLClient => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const subgraphTokenName = {
  '0x738d96caf7096659db4c1afbf1e1bdfd281f388c': 'Ankr Staked MATIC',
  '0x14016e85a25aeb13065688cafb43044c2ef86784': 'True USD Old',
}

export const subgraphTokenSymbol = {
  '0x14016e85a25aeb13065688cafb43044c2ef86784': 'TUSDOLD',
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
