export const MINIMUM_SEARCH_CHARACTERS = 2

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0025
export const LP_HOLDERS_FEE = 0.0017
export const TREASURY_FEE = 0.000225
export const BUYBACK_FEE = 0.000575

export const DONASWAP_BSC_V2_START = 1694263788 // Sep-09-2023 12:49:48 PM +UTC
export const DONASWAP_ETH_V2_START = 1694283803 // Sep-09-2023 06:23:23 PM +UTC
export const DONASWAP_POLYGON_V2_START = 1694346795 // Sep-10-2023 11:53:15 AM +UTC
export const DONASWAP_POLYGON_ZKEVM_V2_START = 1694379138 // Sep-10-2023 08:52:18 PM +UTC
export const DONASWAP_AVALANCHE_V2_START = 1694345579 // Sep-10-2023 11:32:59 AM +UTC
export const DONASWAP_OPTIMISM_V2_START = 1694373681 // Sep-10-2023 07:21:21 PM +UTC
export const DONASWAP_ARBITRUM_V2_START = 1694386369 // Sep-10-2023 10:52:49 PM +UTC
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10
