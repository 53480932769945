import { darkColors, lightColors, fireColors } from "../../theme/colors";
import { ModalTheme } from "./types";

export const light: ModalTheme = {
  background: lightColors.backgroundAlt,
};

export const dark: ModalTheme = {
  background: darkColors.backgroundAlt,
};

export const fire: ModalTheme = {
  background: fireColors.backgroundAlt,
};
