import { Chain } from 'wagmi'

export const firechain = {
  id: 529,
  name: 'Firechain',
  network: 'Firechain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fire Token',
    symbol: 'FIRE',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.rpc1.thefirechain.com/'] },
    public: { http: ['https://mainnet.rpc1.thefirechain.com/'] },
  },
  blockExplorers: {
    etherscan: { name: 'FireScan', url: 'https://rinia.firescan.io' },
    default: { name: 'FireScan', url: 'https://rinia.firescan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const rinia = {
  id: 917,
  name: 'Rinia',
  network: 'Rinia',
  nativeCurrency: {
    decimals: 18,
    name: 'Fire Token',
    symbol: 'tFIRE',
  },
  rpcUrls: {
    default: { http: ['https://rinia-rpc1.thefirechain.com/'] },
    public: { http: ['https://rinia-rpc1.thefirechain.com/'] },
  },
  blockExplorers: {
    etherscan: { name: 'FireScan', url: 'https://rinia.firescan.io' },
    default: { name: 'FireScan', url: 'https://rinia.firescan.io' },
  },
  contracts: {
    multicall3: {
      address: '0x7F27A321CA400E8E66E4CE47a663BE381b7C9Ec7',
      blockCreated: 103168,
    },
  },
  testnet: true,
} as const satisfies Chain

export const mainnet = {
  id: 1,
  name: 'Ethereum',
  network: 'Ethereum-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.infura.io/v3/'] },
    public: { http: ['https://mainnet.infura.io/v3/'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://etherscan.io' },
    default: { name: 'EtherScan', url: 'https://etherscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
} as const satisfies Chain

export const goerli = {
  id: 5,
  name: 'Goerli',
  network: 'Goerli-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://eth-goerli.public.blastapi.io'] },
    public: { http: ['https://eth-goerli.public.blastapi.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://goerli.etherscan.io/' },
    default: { name: 'EtherScan', url: 'https://goerli.etherscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const sepolia = {
  id: 11155111,
  name: 'Sepolia',
  network: 'Sepolia-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://eth-sepolia.g.alchemy.com/v2'] },
    public: { http: ['https://eth-sepolia.g.alchemy.com/v2'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://sepolia.etherscan.io' },
    default: { name: 'EtherScan', url: 'https://sepolia.etherscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const holesky = {
  id: 17000,
  name: 'Holesky',
  network: 'Holesky-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://ethereum-holesky.publicnode.com'] },
    public: { http: ['https://ethereum-holesky.publicnode.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://holesky.etherscan.io/' },
    default: { name: 'EtherScan', url: 'https://holesky.etherscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const bsc = {
  id: 56,
  name: 'BNB Smart Chain',
  network: 'BNB-Smart-Chain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: { http: ['https://bsc-dataseed.binance.org'] },
    public: { http: ['https://bsc-dataseed.binance.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BscScan', url: 'https://bscscan.com' },
    default: { name: 'BscScan', url: 'https://bscscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
} as const satisfies Chain

export const bscTestnet = {
  id: 97,
  name: 'BNB Smart Chain Testnet',
  network: 'BNB-Smart-Chain-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'tBNB',
  },
  rpcUrls: {
    default: { http: ['https://data-seed-prebsc-2-s1.binance.org:8545'] },
    public: { http: ['https://data-seed-prebsc-2-s1.binance.org:8545'] },
  },
  blockExplorers: {
    etherscan: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
    default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const opBnb = {
  id: 204,
  name: 'opBNB',
  network: 'opBNB-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB Token',
    symbol: 'BNB',
  },
  rpcUrls: {
    default: { http: ['https://opbnb-mainnet-rpc.bnbchain.org'] },
    public: { http: ['https://opbnb-mainnet-rpc.bnbchain.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'OpBnbScan', url: 'https://opbnbscan.com/' },
    default: { name: 'OpBnbScan', url: 'https://opbnbscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 15921452,
    },
  },
} as const satisfies Chain

export const opBnbTestnet = {
  id: 5611,
  name: 'opBNB Testnet',
  network: 'opBNB-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    default: { http: ['https://opbnb-testnet-rpc.bnbchain.org'] },
    public: { http: ['https://opbnb-testnet-rpc.bnbchain.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'OpBnbScan', url: 'https://testnet.opbnbscan.com/' },
    default: { name: 'OpBnbScan', url: 'https://testnet.opbnbscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const cronos = {
  id: 25,
  name: 'Cronos',
  network: 'Cronos-Mainnet',
  nativeCurrency: {
    decimals: 25,
    name: 'Cronos Token',
    symbol: 'CRO',
  },
  rpcUrls: {
    default: { http: ['https://evm.cronos.org'] },
    public: { http: ['https://evm.cronos.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'CronoScan', url: 'https://cronoscan.com' },
    default: { name: 'CronoScan', url: 'https://cronoscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const cronosTestnet = {
  id: 338,
  name: 'Cronos Testnet',
  network: 'Cronos-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Cronos Token',
    symbol: 'tCRO',
  },
  rpcUrls: {
    default: { http: ['https://evm-t3.cronos.org'] },
    public: { http: ['https://evm-t3.cronos.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'CronoScan', url: 'https://testnet.cronoscan.com' },
    default: { name: 'CronoScan', url: 'https://testnet.cronoscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const celo = {
  id: 42220,
  name: 'Celo',
  network: 'Celo-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Celo Token',
    symbol: 'CELO',
  },
  rpcUrls: {
    default: { http: ['https://forno.celo.org'] },
    public: { http: ['https://forno.celo.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'Celoscan', url: 'https://celoscan.io/' },
    default: { name: 'Celoscan', url: 'https://celoscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const celoAlfajores = {
  id: 44787,
  name: 'Celo Alfajores',
  network: 'Celo-Alfajores',
  nativeCurrency: {
    decimals: 18,
    name: 'Celo Token',
    symbol: 'tCELO',
  },
  rpcUrls: {
    default: { http: ['https://alfajores-forno.celo-testnet.org'] },
    public: { http: ['https://alfajores-forno.celo-testnet.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'Celoscan', url: 'https://alfajores.celoscan.io/' },
    default: { name: 'Celoscan', url: 'https://alfajores.celoscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const harmony = {
  id: 1666600000,
  name: 'Harmony',
  network: 'Harmony-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Harmony Token',
    symbol: 'ONE',
  },
  rpcUrls: {
    default: { http: ['https://api.harmony.one'] },
    public: { http: ['https://api.harmony.one'] },
  },
  blockExplorers: {
    etherscan: { name: 'HarmonyScan', url: 'https://explorer.harmony.one' },
    default: { name: 'HarmonyScan', url: 'https://explorer.harmony.one' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const harmonyTestnet = {
  id: 1666700000,
  name: 'Harmony Testnet',
  network: 'Harmony-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Harmony Token',
    symbol: 'tONE',
  },
  rpcUrls: {
    default: { http: ['https://api.s0.b.hmny.io'] },
    public: { http: ['https://api.s0.b.hmny.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'HarmonyScan', url: 'https://explorer.pops.one' },
    default: { name: 'HarmonyScan', url: 'https://explorer.pops.one' },
  },
  contracts: {
    multicall3: {
      address: '0x9622b9DDce346D209246cAcB935C6C1CCad783e5',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const shibarium = {
  id: 109,
  name: 'Shibarium',
  network: 'Shibarium-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bone Token',
    symbol: 'BONE',
  },
  rpcUrls: {
    default: { http: ['https://www.shibrpc.com'] },
    public: { http: ['https://www.shibrpc.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ShibariumScan', url: 'https://www.shibariumscan.io/' },
    default: { name: 'ShibariumScan', url: 'https://www.shibariumscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const shibariumTestnet = {
  id: 719,
  name: 'Shibarium Testnet',
  network: 'Shibarium-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bone Token',
    symbol: 'tBONE',
  },
  rpcUrls: {
    default: { http: ['https://puppynet.shibrpc.com'] },
    public: { http: ['https://puppynet.shibrpc.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ShibariumScan', url: 'https://puppyscan.shib.io' },
    default: { name: 'ShibariumScan', url: 'https://puppyscan.shib.io' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const pulsechain = {
  id: 369,
  name: 'Pulsechain',
  network: 'Pulsechain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Pulse Token',
    symbol: 'PLS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.pulsechain.com'] },
    public: { http: ['https://rpc.pulsechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'PulseScan', url: 'https://otter.pulsechain.com/' },
    default: { name: 'PulseScan', url: 'https://otter.pulsechain.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const pulsechainTestnet = {
  id: 943,
  name: 'Pulsechain Testnet',
  network: 'Pulsechain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Pulse Token',
    symbol: 'tPLS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.v4.testnet.pulsechain.com'] },
    public: { http: ['https://rpc.v4.testnet.pulsechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'PulseScan', url: 'https://scan.v4.testnet.pulsechain.com/' },
    default: { name: 'PulseScan', url: 'https://scan.v4.testnet.pulsechain.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const xen = {
  id: 20221200000,
  name: 'Xen',
  network: 'Xen-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Xen Token',
    symbol: 'XN',
  },
  rpcUrls: {
    default: { http: ['https://x1-devnet.xen.network'] },
    public: { http: ['https://x1-devnet.xen.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'XenScan', url: 'https://explorer.x1-devnet.xen.network' },
    default: { name: 'XenScan', url: 'https://explorer.x1-devnet.xen.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const xenDevnet = {
  id: 202212,
  name: 'Xen Devnet',
  network: 'Xen-Devnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Xen Token',
    symbol: 'tXN',
  },
  rpcUrls: {
    default: { http: ['https://x1-devnet.xen.network'] },
    public: { http: ['https://x1-devnet.xen.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'XenScan', url: 'https://explorer.x1-devnet.xen.network' },
    default: { name: 'XenScan', url: 'https://explorer.x1-devnet.xen.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const coinEx = {
  id: 52,
  name: 'CoinEx',
  network: 'CoinEx-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'CoinEx Token',
    symbol: 'CET',
  },
  rpcUrls: {
    default: { http: ['https://rpc.coinex.net'] },
    public: { http: ['https://rpc.coinex.net'] },
  },
  blockExplorers: {
    etherscan: { name: 'CoinExScan', url: 'https://www.coinex.net' },
    default: { name: 'CoinExScan', url: 'https://www.coinex.net' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const coinExTestnet = {
  id: 53,
  name: 'CoinEx Testnet',
  network: 'CoinEx-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'CoinEx Token',
    symbol: 'tCET',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.coinex.net'] },
    public: { http: ['https://testnet-rpc.coinex.net'] },
  },
  blockExplorers: {
    etherscan: { name: 'CoinExScan', url: 'https://testnet.coinex.net' },
    default: { name: 'CoinExScan', url: 'https://testnet.coinex.net' },
  },
  contracts: {
    multicall3: {
      address: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const dogechain = {
  id: 2000,
  name: 'Dogechain',
  network: 'Dogechain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Dogecoin',
    symbol: 'DOGE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.dogechain.dog'] },
    public: { http: ['https://rpc.dogechain.dog'] },
  },
  blockExplorers: {
    etherscan: { name: 'DogechainScan', url: 'https://explorer.dogechain.dog' },
    default: { name: 'DogechainScan', url: 'https://explorer.dogechain.dog' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14151696,
    },
  },
} as const satisfies Chain

export const dogechainTestnet = {
  id: 568,
  name: 'Dogechain Testnet',
  network: 'Dogechain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Dogecoin',
    symbol: 'tDOGE',
  },
  rpcUrls: {
    default: { http: ['https://rpc-testnet.dogechain.dog'] },
    public: { http: ['https://rpc-testnet.dogechain.dog'] },
  },
  blockExplorers: {
    etherscan: { name: 'DogechainScan', url: 'https://explorer-testnet.dogechain.dog' },
    default: { name: 'DogechainScan', url: 'https://explorer-testnet.dogechain.dog' },
  },
  contracts: {
    multicall3: {
      address: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const engramTestnet = {
  id: 131,
  name: 'Engram Testnet',
  network: 'Engram-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Engram Token',
    symbol: 'tGRAM',
  },
  rpcUrls: {
    default: { http: ['https://tokio-archive.engram.tech	'] },
    public: { http: ['https://tokio-archive.engram.tech	'] },
  },
  blockExplorers: {
    etherscan: { name: 'EngramScan', url: 'https://tokioscan-v2.engram.tech/' },
    default: { name: 'EngramScan', url: 'https://tokioscan-v2.engram.tech' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const ethereumClassic = {
  id: 61,
  name: 'Ethereum Classic',
  network: 'Ethereum-Classic-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ETC Token',
    symbol: 'ETC',
  },
  rpcUrls: {
    default: { http: ['https://www.ethercluster.com/etc'] },
    public: { http: ['https://www.ethercluster.com/etc'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blockscout', url: 'https://blockscout.com/etc/mainnet' },
    default: { name: 'Blockscout', url: 'https://blockscout.com/etc/mainnet' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const ethereumClassicMordor = {
  id: 63,
  name: 'Ethereum Classic Mordor',
  network: 'Ethereum-Classic-Mordor',
  nativeCurrency: {
    decimals: 18,
    name: 'ETC Token',
    symbol: 'tETC',
  },
  rpcUrls: {
    default: { http: ['https://www.ethercluster.com/mordor'] },
    public: { http: ['https://www.ethercluster.com/mordor'] },
  },
  blockExplorers: {
    etherscan: { name: 'Blockscout', url: 'https://blockscout.com/etc/mordor' },
    default: { name: 'Blockscout', url: 'https://blockscout.com/etc/mordor' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const fusion = {
  id: 32659,
  name: 'Fusion',
  network: 'Fusion-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fusion Token',
    symbol: 'FSN',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.fusionnetwork.io'] },
    public: { http: ['https://mainnet.fusionnetwork.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'fsnscan', url: 'https://fsnscan.com' },
    default: { name: 'fsnscan', url: 'https://fsnscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const fusionTestnet = {
  id: 46688,
  name: 'Fusion Testnet',
  network: 'Fusion-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fusion Token',
    symbol: 'tFSN',
  },
  rpcUrls: {
    default: { http: ['https://testnet.fusionnetwork.io'] },
    public: { http: ['https://testnet.fusionnetwork.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'FsnScan', url: 'https://testnet.fsnscan.com' },
    default: { name: 'FsnScan', url: 'https://testnet.fsnscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const heco = {
  id: 128,
  name: 'Heco',
  network: 'Heco-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Heco Token',
    symbol: 'HT',
  },
  rpcUrls: {
    default: { http: ['https://http-mainnet.hecochain.com'] },
    public: { http: ['https://http-mainnet.hecochain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'HecoInfo', url: 'https://hecoinfo.com' },
    default: { name: 'HecoInfo', url: 'https://hecoinfo.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const hecoTestnet = {
  id: 256,
  name: 'Heco Testnet',
  network: 'Heco-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Heco Token',
    symbol: 'tHT',
  },
  rpcUrls: {
    default: { http: ['https://http-testnet.hecochain.com'] },
    public: { http: ['https://http-testnet.hecochain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'HecoInfo', url: 'https://testnet.hecoinfo.com' },
    default: { name: 'HecoInfo', url: 'https://testnet.hecoinfo.com' },
  },
  contracts: {
    multicall3: {
      address: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const kcc = {
  id: 321,
  name: 'KCC',
  network: 'KCC-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'KuCoin Token',
    symbol: 'KCS',
  },
  rpcUrls: {
    default: { http: ['https://rpc-mainnet.kcc.network'] },
    public: { http: ['https://rpc-mainnet.kcc.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'KccScan', url: 'https://explorer.kcc.io/en' },
    default: { name: 'KccScan', url: 'https://explorer.kcc.io/en' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const kccTestnet = {
  id: 322,
  name: 'KCC Testnet',
  network: 'KCC-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'KuCoin Token',
    symbol: 'tKCS',
  },
  rpcUrls: {
    default: { http: ['https://http-testnet.hecochain.com'] },
    public: { http: ['https://http-testnet.hecochain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'KccScan', url: 'https://scan-testnet.kcc.network' },
    default: { name: 'KccScan', url: 'https://scan-testnet.kcc.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const klaytnCypress = {
  id: 8217,
  name: 'Klaytn Cypress',
  network: 'Klaytn-Cypress',
  nativeCurrency: {
    decimals: 18,
    name: 'Klay Token',
    symbol: 'KLAY',
  },
  rpcUrls: {
    default: { http: ['https://public-node-api.klaytnapi.com/v1/cypress'] },
    public: { http: ['https://public-node-api.klaytnapi.com/v1/cypress'] },
  },
  blockExplorers: {
    etherscan: { name: 'Klaytnscope', url: 'https://scope.klaytn.com' },
    default: { name: 'Klaytnscope', url: 'https://scope.klaytn.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const klaytnBaobab = {
  id: 1001,
  name: 'Klaytn Baobab',
  network: 'Klaytn-Baobab',
  nativeCurrency: {
    decimals: 18,
    name: 'Klay Token',
    symbol: 'tKLAY',
  },
  rpcUrls: {
    default: { http: ['https://api.baobab.klaytn.net:8651'] },
    public: { http: ['https://api.baobab.klaytn.net:8651'] },
  },
  blockExplorers: {
    etherscan: { name: 'Klaytnscope', url: 'https://baobab.scope.klaytn.com' },
    default: { name: 'Klaytnscope', url: 'https://baobab.scope.klaytn.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const okxChain = {
  id: 66,
  name: 'OKXChain',
  network: 'OKXChain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'OKXChain Token',
    symbol: 'OKT',
  },
  rpcUrls: {
    default: { http: ['https://exchainrpc.okex.org'] },
    public: { http: ['https://exchainrpc.okex.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'OKLink', url: 'https://www.oklink.com/en/okc' },
    default: { name: 'OKLink', url: 'https://www.oklink.com/en/okc' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const okxChainTestnet = {
  id: 65,
  name: 'OKXChain Testnet',
  network: 'OKXChain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'OKXChain Token',
    symbol: 'tOKT',
  },
  rpcUrls: {
    default: { http: ['https://exchaintestrpc.okex.org'] },
    public: { http: ['https://exchaintestrpc.okex.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'OKLink', url: 'https://www.oklink.com/okexchain-test' },
    default: { name: 'OKLink', url: 'https://www.oklink.com/okexchain-test' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const theta = {
  id: 361,
  name: 'Theta',
  network: 'Theta-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Theta Fuel',
    symbol: 'TFUEL',
  },
  rpcUrls: {
    default: { http: ['https://eth-rpc-api.thetatoken.org/rpc'] },
    public: { http: ['https://eth-rpc-api.thetatoken.org/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'ThetaScan', url: 'https://explorer.thetatoken.org' },
    default: { name: 'ThetaScan', url: 'https://explorer.thetatoken.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const thetaTestnet = {
  id: 365,
  name: 'Theta Testnet',
  network: 'Theta-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Theta Fuel',
    symbol: 'tTFUEL',
  },
  rpcUrls: {
    default: { http: ['https://eth-rpc-api-testnet.thetatoken.org/rpc'] },
    public: { http: ['https://eth-rpc-api-testnet.thetatoken.org/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'ThetaScan', url: 'https://testnet-explorer.thetatoken.org' },
    default: { name: 'ThetaScan', url: 'https://testnet-explorer.thetatoken.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const ultron = {
  id: 1231,
  name: 'Ultron',
  network: 'Ultron-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ultron Token',
    symbol: 'ULX',
  },
  rpcUrls: {
    default: { http: ['https://ultron-rpc.net'] },
    public: { http: ['https://ultron-rpc.net'] },
  },
  blockExplorers: {
    etherscan: { name: 'UltronScan', url: 'https://ulxscan.com' },
    default: { name: 'UltronScan', url: 'https://ulxscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const ultronTestnet = {
  id: 1230,
  name: 'Ultron Testnet',
  network: 'Ultron-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ultron Token',
    symbol: 'tULX',
  },
  rpcUrls: {
    default: { http: ['https://ultron-dev.io'] },
    public: { http: ['https://ultron-dev.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'UltronScan', url: 'https://explorer.ultron-dev.io' },
    default: { name: 'UltronScan', url: 'https://explorer.ultron-dev.io' },
  },
  contracts: {
    multicall3: {
      address: '0xdf1d724a7166261eeb015418fe8c7679bbea7fd6',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const moonbeam = {
  id: 1284,
  name: 'Moonbeam',
  network: 'Moonbeam',
  nativeCurrency: {
    decimals: 18,
    name: 'Moonbeam Token',
    symbol: 'GLMR',
  },
  rpcUrls: {
    default: { http: ['https://rpc.api.moonbeam.network'] },
    public: { http: ['https://rpc.api.moonbeam.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'MoonScan', url: 'https://moonbeam.moonscan.io' },
    default: { name: 'MoonScan', url: 'https://moonbeam.moonscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const moonriver = {
  id: 1285,
  name: 'Moonriver',
  network: 'Moonriver',
  nativeCurrency: {
    decimals: 18,
    name: 'Moonriver Token',
    symbol: 'MOVR',
  },
  rpcUrls: {
    default: { http: ['https://rpc.api.moonriver.moonbeam.network'] },
    public: { http: ['https://rpc.api.moonriver.moonbeam.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'MoonScan', url: 'https://moonriver.moonscan.io' },
    default: { name: 'MoonScan', url: 'https://moonriver.moonscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const moonbaseAlpha = {
  id: 1287,
  name: 'Moonbase Alpha',
  network: 'Moonbase-Alpha',
  nativeCurrency: {
    decimals: 18,
    name: 'Moonbase Token',
    symbol: 'DEV',
  },
  rpcUrls: {
    default: { http: ['https://rpc.api.moonbase.moonbeam.network'] },
    public: { http: ['https://rpc.api.moonbase.moonbeam.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'MoonScan', url: 'https://moonbase.moonscan.io' },
    default: { name: 'MoonScan', url: 'https://moonbase.moonscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const aurora = {
  id: 1313161554,
  name: 'Aurora',
  network: 'Aurora',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.aurora.dev'] },
    public: { http: ['https://mainnet.aurora.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'AuroraScan', url: 'https://aurorascan.dev' },
    default: { name: 'AuroraScan', url: 'https://aurorascan.dev' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const auroraTestnet = {
  id: 1313161555,
  name: 'Aurora Testnet',
  network: 'Aurora-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://testnet.aurora.dev'] },
    public: { http: ['https://testnet.aurora.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'AuroraScan', url: 'https://testnet.aurorascan.dev/' },
    default: { name: 'AuroraScan', url: 'https://testnet.aurorascan.dev/' },
  },
  contracts: {
    multicall3: {
      address: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const boba = {
  id: 288,
  name: 'Boba',
  network: 'Boba',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.boba.network'] },
    public: { http: ['https://mainnet.boba.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'BobaScan', url: 'https://bobascan.com' },
    default: { name: 'BobaScan', url: 'https://bobascan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const bobaGoerli = {
  id: 2888,
  name: 'Boba Goerli',
  network: 'Boba-Goerli',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://goerli.boba.network/'] },
    public: { http: ['https://goerli.boba.network/'] },
  },
  blockExplorers: {
    etherscan: { name: 'BobaScan', url: 'https://testnet.bobascan.com' },
    default: { name: 'BobaScan', url: 'https://testnet.bobascan.com' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const gnosis = {
  id: 100,
  name: 'Gnosis',
  network: 'Gnosis',
  nativeCurrency: {
    decimals: 18,
    name: 'xDai Token',
    symbol: 'xDai',
  },
  rpcUrls: {
    default: { http: ['https://rpc.gnosischain.com'] },
    public: { http: ['https://rpc.gnosischain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'GnosisScan', url: 'https://gnosisscan.io' },
    default: { name: 'GnosisScan', url: 'https://gnosisscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const gnosisChiado = {
  id: 10200,
  name: 'Gnosis Chiado',
  network: 'Gnosis-Chiado',
  nativeCurrency: {
    decimals: 18,
    name: 'Chiado xDai',
    symbol: 'txDai',
  },
  rpcUrls: {
    default: { http: ['https://rpc.chiadochain.net'] },
    public: { http: ['https://rpc.chiadochain.net'] },
  },
  blockExplorers: {
    etherscan: { name: 'GnosisScan', url: 'https://blockscout.chiadochain.net' },
    default: { name: 'GnosisScan', url: 'https://blockscout.chiadochain.net' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const metis = {
  id: 1088,
  name: 'Metis',
  network: 'Metis',
  nativeCurrency: {
    decimals: 18,
    name: 'Metis Token',
    symbol: 'METIS',
  },
  rpcUrls: {
    default: { http: ['https://andromeda.metis.io/?owner=1088'] },
    public: { http: ['https://andromeda.metis.io/?owner=1088'] },
  },
  blockExplorers: {
    etherscan: { name: 'MetisScan', url: 'https://andromeda-explorer.metis.io' },
    default: { name: 'MetisScan', url: 'https://andromeda-explorer.metis.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const metisGoerli = {
  id: 599,
  name: 'Metis Goerli',
  network: 'Metis-Goerli',
  nativeCurrency: {
    decimals: 18,
    name: 'Metis Token',
    symbol: 'tMETIS',
  },
  rpcUrls: {
    default: { http: ['https://goerli.gateway.metisdevops.link'] },
    public: { http: ['https://goerli.gateway.metisdevops.link'] },
  },
  blockExplorers: {
    etherscan: { name: 'MetisScan', url: 'https://goerli.explorer.metisdevops.link/' },
    default: { name: 'MetisScan', url: 'https://goerli.explorer.metisdevops.link/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const telos = {
  id: 40,
  name: 'Telos',
  network: 'Telos-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Telos Token',
    symbol: 'TLOS',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.telos.net/evm'] },
    public: { http: ['https://mainnet.telos.net/evm'] },
  },
  blockExplorers: {
    etherscan: { name: 'TelosScan', url: 'https://teloscan.io' },
    default: { name: 'TelosScan', url: 'https://teloscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const telosTestnet = {
  id: 41,
  name: 'Telos Testnet',
  network: 'Telos-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Telos Token',
    symbol: 'tTLOS',
  },
  rpcUrls: {
    default: { http: ['https://testnet.telos.net/evm'] },
    public: { http: ['https://testnet.telos.net/evm'] },
  },
  blockExplorers: {
    etherscan: { name: 'TelosScan', url: 'https://testnet.teloscan.io/' },
    default: { name: 'TelosScan', url: 'https://testnet.teloscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const syscoin = {
  id: 57,
  name: 'Syscoin',
  network: 'Syscoin-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Syscoin Token',
    symbol: 'SYS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.syscoin.org'] },
    public: { http: ['https://rpc.syscoin.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'SyscoinScan', url: 'https://teloscan.io' },
    default: { name: 'SyscoinScan', url: 'https://teloscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const syscoinTanenbaum = {
  id: 5700,
  name: 'Syscoin Tanenbaum',
  network: 'Syscoin-Tanenbaum',
  nativeCurrency: {
    decimals: 18,
    name: 'Syscoin Token',
    symbol: 'tSYS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.tanenbaum.io'] },
    public: { http: ['https://rpc.tanenbaum.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'SyscoinScan', url: 'https://tanenbaum.io' },
    default: { name: 'SyscoinScan', url: 'https://tanenbaum.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const iotex = {
  id: 4689,
  name: 'IoTeX',
  network: 'IoTeX-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'IoTeX Token',
    symbol: 'IOTX',
  },
  rpcUrls: {
    default: { http: ['https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492'] },
    public: { http: ['https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492'] },
  },
  blockExplorers: {
    etherscan: { name: 'IotexScan', url: 'https://iotexscan.io' },
    default: { name: 'IotexScan', url: 'https://iotexscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const iotexTestnet = {
  id: 4690,
  name: 'IoTeX Testnet',
  network: 'IoTeX-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'IoTeX Token',
    symbol: 'tIOTX',
  },
  rpcUrls: {
    default: { http: ['https://babel-api.testnet.iotex.io'] },
    public: { http: ['https://babel-api.testnet.iotex.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'IotexScan', url: 'https://testnet.iotexscan.io' },
    default: { name: 'IotexScan', url: 'https://testnet.iotexscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const shiden = {
  id: 336,
  name: 'Shiden',
  network: 'Shiden-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Shiden Token',
    symbol: 'SDN',
  },
  rpcUrls: {
    default: { http: ['https://shiden.public.blastapi.io'] },
    public: { http: ['https://shiden.public.blastapi.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ShidenScan', url: 'https://blockscout.com/shiden' },
    default: { name: 'ShidenScan', url: 'https://blockscout.com/shiden' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const astar = {
  id: 592,
  name: 'Astar',
  network: 'Astar-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Astar Token',
    symbol: 'ASTR',
  },
  rpcUrls: {
    default: { http: ['https://evm.astar.network'] },
    public: { http: ['https://evm.astar.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'AstarScan', url: 'https://blockscout.com/astar' },
    default: { name: 'AstarScan', url: 'https://blockscout.com/astar' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const shibunya = {
  id: 81,
  name: 'Shibunya',
  network: 'Shibunya',
  nativeCurrency: {
    decimals: 18,
    name: 'Shibunya Token',
    symbol: 'SBY',
  },
  rpcUrls: {
    default: { http: ['https://shibuya.public.blastapi.io'] },
    public: { http: ['https://shibuya.public.blastapi.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ShibunyaScan', url: 'https://blockscout.com/shibuya' },
    default: { name: 'ShibunyaScan', url: 'https://blockscout.com/shibuya' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const fuse = {
  id: 122,
  name: 'Fuse',
  network: 'Fuse-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fuse Token',
    symbol: 'FUSE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.fuse.io'] },
    public: { http: ['https://rpc.fuse.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'FuseScan', url: 'https://fuse.io/' },
    default: { name: 'FuseScan', url: 'https://fuse.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const fuseSparknet = {
  id: 123,
  name: 'Fuse Sparknet',
  network: 'Fuse-Sparknet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fuse Token',
    symbol: 'SPARK',
  },
  rpcUrls: {
    default: { http: ['https://rpc.fusespark.io'] },
    public: { http: ['https://rpc.fusespark.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'FuseScan', url: 'https://explorer.fusespark.io/' },
    default: { name: 'FuseScan', url: 'https://explorer.fusespark.io/' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const velas = {
  id: 106,
  name: 'Velas',
  network: 'Velas-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Velas Token',
    symbol: 'VLX',
  },
  rpcUrls: {
    default: { http: ['https://evmexplorer.velas.com/rpc'] },
    public: { http: ['https://evmexplorer.velas.com/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'VelasScan', url: 'https://evmexplorer.velas.com' },
    default: { name: 'VelasScan', url: 'https://evmexplorer.velas.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const velasTestnet = {
  id: 111,
  name: 'Velas Testnet',
  network: 'Velas-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Velas Token',
    symbol: 'tVLX',
  },
  rpcUrls: {
    default: { http: ['https://evmexplorer.testnet.velas.com/rpc'] },
    public: { http: ['https://evmexplorer.testnet.velas.com/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'FuseScan', url: 'https://evmexplorer.testnet.velas.com' },
    default: { name: 'FuseScan', url: 'https://evmexplorer.testnet.velas.com' },
  },
  contracts: {
    multicall3: {
      address: '0xC751d479E574647f0F20B1A3A6f5D220b6Fd7De3',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const rei = {
  id: 55555,
  name: 'Rei Chain',
  network: 'Reichain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Rei Token',
    symbol: 'REI',
  },
  rpcUrls: {
    default: { http: ['https://rei-rpc.moonrhythm.io'] },
    public: { http: ['https://rei-rpc.moonrhythm.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ReiScan', url: 'https://reiscan.com' },
    default: { name: 'ReiScan', url: 'https://reiscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const reiTestnet = {
  id: 55556,
  name: 'Rei Chain Testnet',
  network: 'Reichain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Rei Token',
    symbol: 'tREI',
  },
  rpcUrls: {
    default: { http: ['https://rei-testnet-rpc.moonrhythm.io'] },
    public: { http: ['https://rei-testnet-rpc.moonrhythm.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ReiScan', url: 'https://testnet.reiscan.com' },
    default: { name: 'ReiScan', url: 'https://testnet.reiscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const kekchain = {
  id: 420420,
  name: 'Kekchain',
  network: 'Kekchain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kekchain Token',
    symbol: 'KEK',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.kekchain.com'] },
    public: { http: ['https://mainnet.kekchain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'KekScan', url: 'https://mainnet-explorer.kekchain.com' },
    default: { name: 'KekScan', url: 'https://mainnet-explorer.kekchain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const kekchainTestnet = {
  id: 420666,
  name: 'Kekchain Testnet',
  network: 'Kekchain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kekchain Token',
    symbol: 'tKEK',
  },
  rpcUrls: {
    default: { http: ['https://testnet.kekchain.com'] },
    public: { http: ['https://testnet.kekchain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'KekScan', url: 'https://testnet-explorer.kekchain.com' },
    default: { name: 'KekScan', url: 'https://testnet-explorer.kekchain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const tomochain = {
  id: 88,
  name: 'TomoChain',
  network: 'TomoChain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'TomoChain Token',
    symbol: 'TOMO',
  },
  rpcUrls: {
    default: { http: ['https://rpc.tomochain.com'] },
    public: { http: ['https://rpc.tomochain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'TomoScan', url: 'https://tomochain.com' },
    default: { name: 'TomoScan', url: 'https://tomochain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const tomochainTestnet = {
  id: 89,
  name: 'TomoChain Testnet',
  network: 'TomoChain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'TomoChain Token',
    symbol: 'tTOMO',
  },
  rpcUrls: {
    default: { http: ['https://rpc.testnet.tomochain.com'] },
    public: { http: ['https://rpc.testnet.tomochain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'TomoScan', url: 'https://tomochain.com' },
    default: { name: 'TomoScan', url: 'https://tomochain.com' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const thundercore = {
  id: 108,
  name: 'ThunderCore',
  network: 'ThunderCore-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ThunderCore Token',
    symbol: 'TT',
  },
  rpcUrls: {
    default: { http: ['https://mainnet-rpc.thundercore.com'] },
    public: { http: ['https://mainnet-rpc.thundercore.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ThundercoreScan', url: 'https://viewblock.io/thundercore' },
    default: { name: 'ThundercoreScan', url: 'https://viewblock.io/thundercore' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const thundercoreTestnet = {
  id: 18,
  name: 'ThunderCore Testnet',
  network: 'ThunderCore-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ThunderCore Token',
    symbol: 'TST',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.thundercore.com'] },
    public: { http: ['https://testnet-rpc.thundercore.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ThundercoreScan', url: 'https://explorer-testnet.thundercore.com' },
    default: { name: 'ThundercoreScan', url: 'https://explorer-testnet.thundercore.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const wanchain = {
  id: 888,
  name: 'Wanchain',
  network: 'Wanchain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Wanchain Token',
    symbol: 'WAN',
  },
  rpcUrls: {
    default: { http: ['https://gwan-ssl.wandevs.org:56891'] },
    public: { http: ['https://gwan-ssl.wandevs.org:56891'] },
  },
  blockExplorers: {
    etherscan: { name: 'WanScan', url: 'https://www.wanscan.org' },
    default: { name: 'WanScan', url: 'https://www.wanscan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const wanchainTestnet = {
  id: 999,
  name: 'Wanchain Testnet',
  network: 'Wanchain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Wanchain Token',
    symbol: 'tWAN',
  },
  rpcUrls: {
    default: { http: ['https://gwan-ssl.wandevs.org:46891'] },
    public: { http: ['https://gwan-ssl.wandevs.org:46891'] },
  },
  blockExplorers: {
    etherscan: { name: 'WanScan', url: 'https://testnet.wanscan.org' },
    default: { name: 'WanScan', url: 'https://testnet.wanscan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const rsk = {
  id: 30,
  name: 'RSK',
  network: 'RSK-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'RSK Token',
    symbol: 'RBTC',
  },
  rpcUrls: {
    default: { http: ['https://public-node.rsk.co'] },
    public: { http: ['https://public-node.rsk.co'] },
  },
  blockExplorers: {
    etherscan: { name: 'RskScan', url: 'https://explorer.rsk.co' },
    default: { name: 'RskScan', url: 'https://explorer.rsk.co' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const rskTestnet = {
  id: 31,
  name: 'RSK Testnet',
  network: 'RSK-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'RSK Token',
    symbol: 'tRBTC',
  },
  rpcUrls: {
    default: { http: ['https://public-node.testnet.rsk.co'] },
    public: { http: ['https://public-node.testnet.rsk.co'] },
  },
  blockExplorers: {
    etherscan: { name: 'RskScan', url: 'https://explorer.testnet.rsk.co' },
    default: { name: 'RskScan', url: 'https://explorer.testnet.rsk.co' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const elastos = {
  id: 20,
  name: 'Elastos Smart Chain',
  network: 'Elastos-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Elastos Token',
    symbol: 'ELA',
  },
  rpcUrls: {
    default: { http: ['https://api.elastos.io/esc'] },
    public: { http: ['https://api.elastos.io/esc'] },
  },
  blockExplorers: {
    etherscan: { name: 'ElastosScan', url: 'https://esc.elastos.io' },
    default: { name: 'ElastosScan', url: 'https://esc.elastos.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const elastosTestnet = {
  id: 21,
  name: 'Elastos Testnet',
  network: 'Elastos-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Elastos Token',
    symbol: 'tELA',
  },
  rpcUrls: {
    default: { http: ['https://api-testnet.elastos.io/eth'] },
    public: { http: ['https://api-testnet.elastos.io/eth'] },
  },
  blockExplorers: {
    etherscan: { name: 'ElastosScan', url: 'https://esc-testnet.elastos.io' },
    default: { name: 'ElastosScan', url: 'https://esc-testnet.elastos.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const conflux = {
  id: 1030,
  name: 'Conflux eSpace',
  network: 'Conflux-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Conflux Token',
    symbol: 'CFX',
  },
  rpcUrls: {
    default: { http: ['https://evm.confluxrpc.com'] },
    public: { http: ['https://evm.confluxrpc.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ConfluxScan', url: 'https://evm.confluxscan.net' },
    default: { name: 'ConfluxScan', url: 'https://evm.confluxscan.net' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const confluxTestnet = {
  id: 71,
  name: 'Conflux Testnet',
  network: 'Conflux-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Conflux Token',
    symbol: 'tCFX',
  },
  rpcUrls: {
    default: { http: ['https://evm.confluxrpc.com'] },
    public: { http: ['https://evm.confluxrpc.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'ConfluxScan', url: 'https://evm.confluxscan.net' },
    default: { name: 'ConfluxScan', url: 'https://evm.confluxscan.net' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const brisechain = {
  id: 32520,
  name: 'Bitgert',
  network: 'Bitgert-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bitgert Native Token',
    symbol: 'BRISE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.icecreamswap.com'] },
    public: { http: ['https://rpc.icecreamswap.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'BriseScan', url: 'https://brisescan.com' },
    default: { name: 'BriseScan', url: 'https://brisescan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const brisechainTestnet = {
  id: 64668,
  name: 'Bitgert Testnet',
  network: 'Bitgert-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bitgert Native Token',
    symbol: 'tBRISE',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.brisescan.com'] },
    public: { http: ['https://testnet-rpc.brisescan.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'BriseScan', url: 'https://testnet-explorer.Brisescan.com' },
    default: { name: 'BriseScan', url: 'https://testnet-explorer.Brisescan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const muuchain = {
  id: 20402,
  name: 'Muuchain',
  network: 'Muuchain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Muuchain Token',
    symbol: 'MUU',
  },
  rpcUrls: {
    default: { http: ['https://mainnet-rpc.muuchain.com'] },
    public: { http: ['https://mainnet-rpc.muuchain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'MuuScan', url: 'https://explorer.muuchain.com' },
    default: { name: 'MuuScan', url: 'https://explorer.muuchain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const muuchainTestnet = {
  id: 42110,
  name: 'Muuchain Testnet',
  network: 'Muuchain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Muuchain Token',
    symbol: 'tMUU',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.muuchain.com'] },
    public: { http: ['https://testnet-rpc.muuchain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'MuuScan', url: 'https://testnet-explorer.muuchain.com' },
    default: { name: 'MuuScan', url: 'https://testnet-explorer.muuchain.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const canto = {
  id: 7700,
  name: 'Canto',
  network: 'Canto-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Canto Token',
    symbol: 'CANTO',
  },
  rpcUrls: {
    default: { http: ['https://canto.slingshot.finance'] },
    public: { http: ['https://canto.slingshot.finance'] },
  },
  blockExplorers: {
    etherscan: { name: 'CantoScan', url: 'https://evm.explorer.canto.io' },
    default: { name: 'CantoScan', url: 'https://evm.explorer.canto.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const cantoTestnet = {
  id: 7701,
  name: 'Canto Testnet',
  network: 'Canto-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Canto Token',
    symbol: 'tCANTO',
  },
  rpcUrls: {
    default: { http: ['https://canto-testnet.plexnode.wtf'] },
    public: { http: ['https://canto-testnet.plexnode.wtf'] },
  },
  blockExplorers: {
    etherscan: { name: 'CantoScan', url: 'https://testnet.tuber.build/' },
    default: { name: 'CantoScan', url: 'https://testnet.tuber.build/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const oasis = {
  id: 42262,
  name: 'Oasis Emerald',
  network: 'Oasis-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Oasis Token',
    symbol: 'ROSE',
  },
  rpcUrls: {
    default: { http: ['https://emerald.oasis.dev'] },
    public: { http: ['https://emerald.oasis.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'OasisScan', url: 'https://explorer.emerald.oasis.dev' },
    default: { name: 'OasisScan', url: 'https://explorer.emerald.oasis.dev' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const oasisTestnet = {
  id: 42261,
  name: 'Oasis Testnet',
  network: 'Oasis-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Oasis Token',
    symbol: 'tROSE',
  },
  rpcUrls: {
    default: { http: ['https://testnet.emerald.oasis.dev'] },
    public: { http: ['https://testnet.emerald.oasis.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'OasisScan', url: 'https://testnet.explorer.emerald.oasis.dev' },
    default: { name: 'OasisScan', url: 'https://testnet.explorer.emerald.oasis.dev' },
  },
  contracts: {
    multicall3: {
      address: '0xC751d479E574647f0F20B1A3A6f5D220b6Fd7De3',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const vision = {
  id: 888888,
  name: 'Vision',
  network: 'Vision-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Vision Token',
    symbol: 'VS',
  },
  rpcUrls: {
    default: { http: ['https://infragrid.v.network/ethereum/compatible'] },
    public: { http: ['https://infragrid.v.network/ethereum/compatible'] },
  },
  blockExplorers: {
    etherscan: { name: 'VisionScan', url: 'https://www.visionscan.org' },
    default: { name: 'VisionScan', url: 'https://www.visionscan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const visionTestnet = {
  id: 666666,
  name: 'Vision Testnet',
  network: 'Vision-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Vision Token',
    symbol: 'tVS',
  },
  rpcUrls: {
    default: { http: ['https://vpioneer.infragrid.v.network/ethereum/compatible'] },
    public: { http: ['https://vpioneer.infragrid.v.network/ethereum/compatible'] },
  },
  blockExplorers: {
    etherscan: { name: 'VisionScan', url: 'https://visionscan.org' },
    default: { name: 'VisionScan', url: 'https://visionscan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const step = {
  id: 1234,
  name: 'Step Network',
  network: 'Step-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Step Token',
    symbol: 'FITFI',
  },
  rpcUrls: {
    default: { http: ['https://rpc.step.network'] },
    public: { http: ['https://rpc.step.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'StepScan', url: 'https://stepscan.io' },
    default: { name: 'StepScan', url: 'https://stepscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const stepTestnet = {
  id: 12345,
  name: 'Step Testnet',
  network: 'Step-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Step Token',
    symbol: 'tFITFI',
  },
  rpcUrls: {
    default: { http: ['https://rpc.testnet.step.network'] },
    public: { http: ['https://rpc.testnet.step.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'StepScan', url: 'https://testnet.stepscan.io' },
    default: { name: 'StepScan', url: 'https://testnet.stepscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const meter = {
  id: 82,
  name: 'Meter',
  network: 'Meter-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Meter Token',
    symbol: 'MTR',
  },
  rpcUrls: {
    default: { http: ['https://rpc.meter.io'] },
    public: { http: ['https://rpc.meter.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'MeterScan', url: 'https://scan.meter.io' },
    default: { name: 'MeterScan', url: 'https://scan.meter.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const meterTestnet = {
  id: 83,
  name: 'Meter Testnet',
  network: 'Meter-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Meter Token',
    symbol: 'tMTR',
  },
  rpcUrls: {
    default: { http: ['https://rpctest.meter.io'] },
    public: { http: ['https://rpctest.meter.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'MeterScan', url: 'https://scan-warringstakes.meter.io' },
    default: { name: 'MeterScan', url: 'https://scan-warringstakes.meter.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const godwoken = {
  id: 71402,
  name: 'Godwoken',
  network: 'Godwoken-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Godwoken Token',
    symbol: 'pCKB',
  },
  rpcUrls: {
    default: { http: ['https://v1.mainnet.godwoken.io/rpc'] },
    public: { http: ['https://v1.mainnet.godwoken.io/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'GodwokenScan', url: 'https://v1.gwscan.com/' },
    default: { name: 'GodwokenScan', url: 'https://v1.gwscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const godwokenTestnet = {
  id: 71401,
  name: 'Godwoken Testnet',
  network: 'Godwoken-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Godwoken Token',
    symbol: 'tpCKB',
  },
  rpcUrls: {
    default: { http: ['https://godwoken-testnet-v1.ckbapp.dev'] },
    public: { http: ['https://godwoken-testnet-v1.ckbapp.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'GodwokenScan', url: 'https://v1.testnet.gwscan.com/' },
    default: { name: 'GodwokenScan', url: 'https://v1.testnet.gwscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const callisto = {
  id: 820,
  name: 'Callisto',
  network: 'Callisto-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Callisto Token',
    symbol: 'CLO',
  },
  rpcUrls: {
    default: { http: ['https://rpc.callisto.network'] },
    public: { http: ['https://rpc.callisto.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'CallistoScan', url: 'https://callisto.network' },
    default: { name: 'CallistoScan', url: 'https://callisto.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const callistoTestnet = {
  id: 20729,
  name: 'Callisto Testnet',
  network: 'Callisto-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Callisto Token',
    symbol: 'tCLO',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.callisto.network'] },
    public: { http: ['https://testnet-rpc.callisto.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'CallistoScan', url: 'https://testnet-explorer.callisto.network/' },
    default: { name: 'CallistoScan', url: 'https://testnet-explorer.callisto.network/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const evmos = {
  id: 9001,
  name: 'Evmos',
  network: 'Evmos-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Evmos Token',
    symbol: 'EVMOS',
  },
  rpcUrls: {
    default: { http: ['https://eth.bd.evmos.org:8545'] },
    public: { http: ['https://eth.bd.evmos.org:8545'] },
  },
  blockExplorers: {
    etherscan: { name: 'EScan', url: 'https://escan.live' },
    default: { name: 'EScan', url: 'https://escan.live' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const evmosTestnet = {
  id: 9000,
  name: 'Evmos Testnet',
  network: 'Evmos-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Evmos Token',
    symbol: 'tEVMOS',
  },
  rpcUrls: {
    default: { http: ['https://eth.bd.evmos.dev:8545'] },
    public: { http: ['https://eth.bd.evmos.dev:8545'] },
  },
  blockExplorers: {
    etherscan: { name: 'EScan', url: 'https://evm.evmos.dev' },
    default: { name: 'EScan', url: 'https://evm.evmos.dev' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const energyWebChain = {
  id: 246,
  name: 'Energy Web Chain',
  network: 'Energy-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Energy Token',
    symbol: 'EWT',
  },
  rpcUrls: {
    default: { http: ['https://rpc.energyweb.org'] },
    public: { http: ['https://rpc.energyweb.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'EnergyScan', url: 'https://explorer.energyweb.org' },
    default: { name: 'EnergyScan', url: 'https://explorer.energyweb.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const energyVolta = {
  id: 73799,
  name: 'Energy Volta',
  network: 'Energy-Volta',
  nativeCurrency: {
    decimals: 18,
    name: 'Energy Token',
    symbol: 'VT',
  },
  rpcUrls: {
    default: { http: ['https://volta-rpc.energyweb.org'] },
    public: { http: ['https://volta-rpc.energyweb.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'EnergyScan', url: 'https://volta-explorer.energyweb.org' },
    default: { name: 'EnergyScan', url: 'https://volta-explorer.energyweb.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const base = {
  id: 8453,
  name: 'Base',
  network: 'Base-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.base.org'] },
    public: { http: ['https://mainnet.base.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BaseScan', url: 'https://basescan.org' },
    default: { name: 'BaseScan', url: 'https://basescan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const baseGoerli = {
  id: 84531,
  name: 'Base Goerli',
  network: 'Base-Goerli',
  nativeCurrency: {
    decimals: 18,
    name: 'Ethereum Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://goerli.base.org'] },
    public: { http: ['https://goerli.base.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BaseScan', url: 'https://goerli.basescan.org' },
    default: { name: 'BaseScan', url: 'https://goerli.basescan.org' },
  },
  contracts: {
    multicall3: {
      address: '0xA46704f14Ed42D5fC539A762CF411aE963DED99e',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const kava = {
  id: 2222,
  name: 'Kava',
  network: 'Kava-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kava Token',
    symbol: 'KAVA',
  },
  rpcUrls: {
    default: { http: ['https://evm.kava.io'] },
    public: { http: ['https://evm.kava.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'KavaScan', url: 'https://explorer.kava.io' },
    default: { name: 'KavaScan', url: 'https://explorer.kava.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const kavaTestnet = {
  id: 2221,
  name: 'Kava Testnet',
  network: 'Kava-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kava Token',
    symbol: 'tKAVA',
  },
  rpcUrls: {
    default: { http: ['https://evm.testnet.kava.io'] },
    public: { http: ['https://evm.testnet.kava.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'KavaScan', url: 'https://testnet.kavascan.com' },
    default: { name: 'KavaScan', url: 'https://testnet.kavascan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const defichain = {
  id: 1131,
  name: 'Defichain',
  network: 'Defichain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Defichain Token',
    symbol: 'DFI',
  },
  rpcUrls: {
    default: { http: ['https://defichain.com/'] },
    public: { http: ['https://defichain.com/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DefiScan', url: '' },
    default: { name: 'DefiScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const defichainTestnet = {
  id: 1132,
  name: 'Defichain Testnet',
  network: 'Defichain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Defichain Token',
    symbol: 'tDFI',
  },
  rpcUrls: {
    default: { http: ['https://defichain.com/'] },
    public: { http: ['https://defichain.com/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DefiScan', url: '' },
    default: { name: 'DefiScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const clover = {
  id: 1024,
  name: 'Clover',
  network: 'Clover',
  nativeCurrency: {
    decimals: 18,
    name: 'Clover Token',
    symbol: 'CLV',
  },
  rpcUrls: {
    default: { http: ['https://api-para.clover.finance'] },
    public: { http: ['https://api-para.clover.finance'] },
  },
  blockExplorers: {
    etherscan: { name: 'CloverScan', url: 'https://clvscan.com/' },
    default: { name: 'CloverScan', url: 'https://clvscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const cloverTestnet = {
  id: 1023,
  name: 'Clover Testnet',
  network: 'Clover-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Clover Token',
    symbol: 'tCLV',
  },
  rpcUrls: {
    default: { http: ['https://rpc.clover.finance'] },
    public: { http: ['https://rpc.clover.finance'] },
  },
  blockExplorers: {
    etherscan: { name: 'CloverScan', url: '' },
    default: { name: 'CloverScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const bronos = {
  id: 1039,
  name: 'Bronos',
  network: 'Bronos-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bronos Token',
    symbol: 'BRO',
  },
  rpcUrls: {
    default: { http: ['https://evm.bronos.org'] },
    public: { http: ['https://evm.bronos.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BronoScan', url: 'https://broscan.bronos.org' },
    default: { name: 'BronoScan', url: 'https://broscan.bronos.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const bronosTestnet = {
  id: 1038,
  name: 'Bronos Testnet',
  network: 'Bronos-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bronos Token',
    symbol: 'tBRO',
  },
  rpcUrls: {
    default: { http: ['https://evm-testnet.bronos.org'] },
    public: { http: ['https://evm-testnet.bronos.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BronoScan', url: 'https://tbroscan.bronos.org' },
    default: { name: 'BronoScan', url: 'https://tbroscan.bronos.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const flare = {
  id: 14,
  name: 'Flare',
  network: 'Flare-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Flare Token',
    symbol: 'FLR',
  },
  rpcUrls: {
    default: { http: ['https://flare-api.flare.network/ext/C/rpc'] },
    public: { http: ['https://flare-api.flare.network/ext/C/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'FlareScan', url: 'https://flare-explorer.flare.network' },
    default: { name: 'FlareScan', url: 'https://flare-explorer.flare.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const flareTestnet = {
  id: 114,
  name: 'Flare Testnet',
  network: 'Flare-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Flare Token',
    symbol: 'tFLR',
  },
  rpcUrls: {
    default: { http: ['https://coston2-api.flare.network/ext/C/rpc'] },
    public: { http: ['https://coston2-api.flare.network/ext/C/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'FlareScan', url: 'https://coston2-explorer.flare.network' },
    default: { name: 'FlareScan', url: 'https://coston2-explorer.flare.network' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const filecoin = {
  id: 314,
  name: 'Filecoin',
  network: 'Filecoin-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Filecoin Token',
    symbol: 'FIL',
  },
  rpcUrls: {
    default: { http: ['https://api.node.glif.io/rpc/v1'] },
    public: { http: ['https://api.node.glif.io/rpc/v1'] },
  },
  blockExplorers: {
    etherscan: { name: 'FilScan', url: 'https://filscan.io' },
    default: { name: 'FilScan', url: 'https://filscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const filecoinCalibration = {
  id: 314159,
  name: 'Filecoin Calibration',
  network: 'Filecoin-Calibration',
  nativeCurrency: {
    decimals: 18,
    name: 'Filecoin Token',
    symbol: 'tFIL',
  },
  rpcUrls: {
    default: { http: ['https://api.calibration.node.glif.io/rpc/v1'] },
    public: { http: ['https://api.calibration.node.glif.io/rpc/v1'] },
  },
  blockExplorers: {
    etherscan: { name: 'FilScan', url: 'https://calibration.filscan.io' },
    default: { name: 'FilScan', url: 'https://calibration.filscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const taraxa = {
  id: 841,
  name: 'Taraxa',
  network: 'Taraxa-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Taraxa Token',
    symbol: 'TARA',
  },
  rpcUrls: {
    default: { http: ['https://rpc.mainnet.taraxa.io'] },
    public: { http: ['https://rpc.mainnet.taraxa.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'TaraScan', url: 'https://explorer.mainnet.taraxa.io' },
    default: { name: 'TaraScan', url: 'https://explorer.mainnet.taraxa.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const taraxaTestnet = {
  id: 842,
  name: 'Taraxa Testnet',
  network: 'Taraxa-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Taraxa Token',
    symbol: 'tTARA',
  },
  rpcUrls: {
    default: { http: ['https://rpc.testnet.taraxa.io'] },
    public: { http: ['https://rpc.testnet.taraxa.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'TaraScan', url: 'https://explorer.testnet.taraxa.io' },
    default: { name: 'TaraScan', url: 'https://explorer.testnet.taraxa.io' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803ef7763b77e4ef0082bc0bcf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const zksync = {
  id: 324,
  name: 'ZkSync',
  network: 'ZkSync-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://zksync2-mainnet.zksync.io'] },
    public: { http: ['https://zksync2-mainnet.zksync.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ZkScan', url: 'https://explorer.zksync.io' },
    default: { name: 'ZkScan', url: 'https://explorer.zksync.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const zksyncTestnet = {
  id: 280,
  name: 'ZkSync Testnet',
  network: 'ZkSync-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://zksync2-testnet.zksync.dev'] },
    public: { http: ['https://zksync2-testnet.zksync.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'ZkScan', url: 'https://goerli.explorer.zksync.io' },
    default: { name: 'ZkScan', url: 'https://goerli.explorer.zksync.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const optimism = {
  id: 10,
  name: 'Optimism',
  network: 'Optimism-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.optimism.io'] },
    public: { http: ['https://mainnet.optimism.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://optimistic.etherscan.io' },
    default: { name: 'EtherScan', url: 'https://optimistic.etherscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const optimismGoerli = {
  id: 420,
  name: 'Optimism Goerli',
  network: 'Optimism Goerli',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://goerli.optimism.io'] },
    public: { http: ['https://goerli.optimism.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'EtherScan', url: 'https://goerli-optimism.etherscan.io' },
    default: { name: 'EtherScan', url: 'https://goerli-optimism.etherscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const bobaAvax = {
  id: 43288,
  name: 'Boba Avax',
  network: 'Boba-Avax-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Boba Token',
    symbol: 'BOBA',
  },
  rpcUrls: {
    default: { http: ['https://avax.boba.network'] },
    public: { http: ['https://avax.boba.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'BobaAvaxScan', url: 'https://blockexplorer.avax.boba.network' },
    default: { name: 'BobaAvaxScan', url: 'https://blockexplorer.avax.boba.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const bobaBnb = {
  id: 56288,
  name: 'Boba BNB',
  network: 'Boba-BNB-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Boba Token',
    symbol: 'BOBA',
  },
  rpcUrls: {
    default: { http: ['https://bnb.boba.network'] },
    public: { http: ['https://bnb.boba.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'BobaBnbScan', url: 'https://blockexplorer.bnb.boba.network' },
    default: { name: 'BobaBnbScan', url: 'https://blockexplorer.bnb.boba.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const bttchain = {
  id: 199,
  name: 'BitTorrent Chain',
  network: 'BitTorrent-Chain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BitTorrent Token',
    symbol: 'BTT',
  },
  rpcUrls: {
    default: { http: ['https://rpc.bittorrentchain.io/'] },
    public: { http: ['https://rpc.bittorrentchain.io/'] },
  },
  blockExplorers: {
    etherscan: { name: 'BttScan', url: 'https://bttcscan.com' },
    default: { name: 'BttScan', url: 'https://bttcscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const bttchainTestnet = {
  id: 1029,
  name: 'BitTorrent Chain Testnet',
  network: 'BitTorrent-Chain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BitTorrent Token',
    symbol: 'tBTT',
  },
  rpcUrls: {
    default: { http: ['https://pre-rpc.bt.io/'] },
    public: { http: ['https://pre-rpc.bt.io/'] },
  },
  blockExplorers: {
    etherscan: { name: 'BttScan', url: 'https://testnet.bttcscan.com/' },
    default: { name: 'BttScan', url: 'https://testnet.bttcscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const cmp = {
  id: 256256,
  name: 'CMP',
  network: 'CMP-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Caduceus Token',
    symbol: 'CMP',
  },
  rpcUrls: {
    default: { http: ['https://mainnet.block.caduceus.foundation'] },
    public: { http: ['https://mainnet.block.caduceus.foundation'] },
  },
  blockExplorers: {
    etherscan: { name: 'MainnetScan', url: 'https://mainnet.scan.caduceus.foundation' },
    default: { name: 'MainnetScan', url: 'https://mainnet.scan.caduceus.foundation' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const cmpTestnet = {
  id: 512512,
  name: 'CMP Testnet',
  network: 'CMP-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Caduceus Token',
    symbol: 'tCMP',
  },
  rpcUrls: {
    default: { http: ['https://galaxy.block.caduceus.foundation'] },
    public: { http: ['https://galaxy.block.caduceus.foundation'] },
  },
  blockExplorers: {
    etherscan: { name: 'GalaxyScan', url: 'https://galaxy.scan.caduceus.foundation' },
    default: { name: 'GalaxyScan', url: 'https://galaxy.scan.caduceus.foundation' },
  },
  contracts: {
    multicall3: {
      address: '0x35060f7803ef7763b77e4ef0082bc0bcf2654154',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const core = {
  id: 1116,
  name: 'Core',
  network: 'Core-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Core Token',
    symbol: 'CORE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.coredao.org/'] },
    public: { http: ['https://rpc.coredao.org/'] },
  },
  blockExplorers: {
    etherscan: { name: 'CoreScan', url: 'https://scan.coredao.org' },
    default: { name: 'CoreScan', url: 'https://scan.coredao.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const coreTestnet = {
  id: 1115,
  name: 'Core Testnet',
  network: 'Core-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Core Token',
    symbol: 'tCORE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.test.btcs.network/'] },
    public: { http: ['https://rpc.test.btcs.network/'] },
  },
  blockExplorers: {
    etherscan: { name: 'CoreScan', url: 'https://scan.test.btcs.network' },
    default: { name: 'CoreScan', url: 'https://scan.test.btcs.network' },
  },
  contracts: {
    multicall3: {
      address: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const cubechain = {
  id: 1818,
  name: 'Cube Chain',
  network: 'Cube-Chain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Cube Native Token',
    symbol: 'CUBE',
  },
  rpcUrls: {
    default: { http: ['https://http-mainnet.cube.network'] },
    public: { http: ['https://http-mainnet.cube.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'CubeScan', url: 'https://cubescan.network' },
    default: { name: 'CubeScan', url: 'https://cubescan.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const cubechainTestnet = {
  id: 1819,
  name: 'Cube Chain Testnet',
  network: 'Cube-Chain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Cube Native Token',
    symbol: 'CUBE',
  },
  rpcUrls: {
    default: { http: ['https://http-testnet.cube.network'] },
    public: { http: ['https://http-testnet.cube.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'CubeScan', url: 'https://cubescan.network' },
    default: { name: 'CubeScan', url: 'https://cubescan.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const darwiniaCrab = {
  id: 44,
  name: 'Darwinia Crab Network',
  network: 'Darwinia-Crab-Network',
  nativeCurrency: {
    decimals: 18,
    name: 'Crab Native Token',
    symbol: 'CRAB',
  },
  rpcUrls: {
    default: { http: ['https://crab-rpc.darwinia.network'] },
    public: { http: ['https://crab-rpc.darwinia.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'SubScan', url: 'https://crab.subscan.io' },
    default: { name: 'SubScan', url: 'https://crab.subscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const darwiniaPangolin = {
  id: 43,
  name: 'Darwinia Pangolin Network',
  network: 'Darwinia-Pangolin-Network',
  nativeCurrency: {
    decimals: 18,
    name: 'Pangolin Native Token',
    symbol: 'PRING',
  },
  rpcUrls: {
    default: { http: ['C'] },
    public: { http: ['https://pangolin-rpc.darwinia.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'SubScan', url: 'https://pangolin.subscan.io' },
    default: { name: 'SubScan', url: 'https://pangolin.subscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const diodePretnet = {
  id: 15,
  name: 'Diode Prenet',
  network: 'Diode Prenet',
  nativeCurrency: {
    decimals: 18,
    name: 'Diode Native Token',
    symbol: 'DIODE',
  },
  rpcUrls: {
    default: { http: ['https://prenet.diode.io:8443/'] },
    public: { http: ['https://prenet.diode.io:8443/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DiodeScan', url: '' },
    default: { name: 'DiodeScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const diodeTestnet = {
  id: 13,
  name: 'Diode Testnet Staging',
  network: 'Diode-Testnet-Staging',
  nativeCurrency: {
    decimals: 18,
    name: 'Diode Native Token',
    symbol: 'sDIODE',
  },
  rpcUrls: {
    default: { http: ['https://staging.diode.io:8443/'] },
    public: { http: ['https://staging.diode.io:8443/'] },
  },
  blockExplorers: {
    etherscan: { name: 'DiodeScan', url: '' },
    default: { name: 'DiodeScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const gochain = {
  id: 60,
  name: 'GoChain',
  network: 'GoChain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'GoChain Ether',
    symbol: 'GO',
  },
  rpcUrls: {
    default: { http: ['https://rpc.gochain.io'] },
    public: { http: ['https://rpc.gochain.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'GoChain Explorer', url: 'https://explorer.gochain.io' },
    default: { name: 'GoChain Explorer', url: 'https://explorer.gochain.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const gochainTestnet = {
  id: 31337,
  name: 'Gochain Testnet',
  network: 'Gochain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Go Ether',
    symbol: 'GO',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.gochain.io'] },
    public: { http: ['https://testnet-rpc.gochain.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'GoChain Explorer', url: 'https://testnet-explorer.gochain.io' },
    default: { name: 'GoChain Explorer', url: 'https://testnet-explorer.gochain.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const haqq = {
  id: 11235,
  name: 'Haqq Network',
  network: 'Haqq',
  nativeCurrency: {
    decimals: 18,
    name: 'Islamic Coin',
    symbol: 'ISLM',
  },
  rpcUrls: {
    default: { http: ['https://rpc.eth.haqq.network'] },
    public: { http: ['https://rpc.eth.haqq.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'HAQQ Explorer', url: 'https://explorer.haqq.network' },
    default: { name: 'HAQQ Explorer', url: 'https://explorer.haqq.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const haqqTestedge = {
  id: 54211,
  name: 'Haqq Testedge',
  network: 'Haqq-Testedge',
  nativeCurrency: {
    decimals: 18,
    name: 'Islamic Coin',
    symbol: 'tISLM',
  },
  rpcUrls: {
    default: { http: ['https://rpc.eth.testedge2.haqq.network'] },
    public: { http: ['https://rpc.eth.testedge2.haqq.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'TestEdge HAQQ Explorer', url: 'https://explorer.testedge2.haqq.network' },
    default: { name: 'TestEdge HAQQ Explorer', url: 'https://explorer.testedge2.haqq.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const kardiachain = {
  id: 24,
  name: 'Kardiachain',
  network: 'Kardiachain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kardia Native Token',
    symbol: 'KAI',
  },
  rpcUrls: {
    default: { http: ['https://rpc.kardiachain.io'] },
    public: { http: ['https://rpc.kardiachain.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'KardiaScan', url: 'https://explorer.kardiachain.io/' },
    default: { name: 'KardiaScan', url: 'https://explorer.kardiachain.io/' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const kardiachainTestnet = {
  id: 242,
  name: 'Kardiachain Testnet',
  network: 'Kardiachain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Kardia Native Token',
    symbol: 'tKAI',
  },
  rpcUrls: {
    default: { http: ['https://dev.kardiachain.io/'] },
    public: { http: ['https://dev.kardiachain.io/'] },
  },
  blockExplorers: {
    etherscan: { name: 'KardiaScan', url: '' },
    default: { name: 'KardiaScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const linea = {
  id: 59144,
  name: 'Linea',
  network: 'Linea-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Linea Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://rpc.linea.build'] },
    public: { http: ['https://rpc.linea.build'] },
  },
  blockExplorers: {
    etherscan: { name: 'LineaScan', url: 'https://lineascan.build' },
    default: { name: 'LineaScan', url: 'https://lineascan.build' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const metadium = {
  id: 11,
  name: 'Metadium',
  network: 'Metadium-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Metadium Native Token',
    symbol: 'META',
  },
  rpcUrls: {
    default: { http: ['https://api.metadium.com/prod'] },
    public: { http: ['https://api.metadium.com/prod'] },
  },
  blockExplorers: {
    etherscan: { name: 'MetaScan', url: '' },
    default: { name: 'MetaScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const metadiumTestnet = {
  id: 12,
  name: 'Metadium Testnet',
  network: 'Metadium-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Metadium Native Token',
    symbol: 'KAL',
  },
  rpcUrls: {
    default: { http: ['https://api.metadium.com/dev'] },
    public: { http: ['https://api.metadium.com/dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'MetaScan', url: '' },
    default: { name: 'MetaScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const palm = {
  id: 11297108109,
  name: 'Palm',
  network: 'Palm-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Palm Native Token',
    symbol: 'PALM',
  },
  rpcUrls: {
    default: { http: ['https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b'] },
    public: { http: ['https://palm-mainnet.infura.io/v3/3a961d6501e54add9a41aa53f15de99b'] },
  },
  blockExplorers: {
    etherscan: { name: 'Palm Explorer', url: 'https://explorer.palm.io/' },
    default: { name: 'Palm Explorer', url: 'https://explorer.palm.io/' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const palmTestnet = {
  id: 11297108099,
  name: 'Palm Testnet',
  network: 'Palm-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Palm Native Token',
    symbol: 'tPALM',
  },
  rpcUrls: {
    default: { http: ['https://palm-testnet.infura.io'] },
    public: { http: ['https://palm-testnet.infura.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'Palm Explorer', url: 'https://explorer.palm-uat.xyz' },
    default: { name: 'Palm Explorer', url: 'https://explorer.palm-uat.xyz' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const polis = {
  id: 333999,
  name: 'Polis',
  network: 'Polis-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Polis Native Token',
    symbol: 'POLIS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.polis.tech'] },
    public: { http: ['https://rpc.polis.tech'] },
  },
  blockExplorers: {
    etherscan: { name: 'PolisScan', url: '' },
    default: { name: 'PolisScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const polisTestnet = {
  id: 333888,
  name: 'Polis Testnet',
  network: 'Polis-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Polis Native Token',
    symbol: 'tPOLIS',
  },
  rpcUrls: {
    default: { http: ['https://rpc.polis.tech'] },
    public: { http: ['https://rpc.polis.tech'] },
  },
  blockExplorers: {
    etherscan: { name: 'PolisScan', url: '' },
    default: { name: 'PolisScan', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const shibachain = {
  id: 27,
  name: 'ShibaChain',
  network: 'ShibaChain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'SHIBA INU COIN',
    symbol: 'SHIB',
  },
  rpcUrls: {
    default: { http: ['https://rpc.shibchain.org'] },
    public: { http: ['https://rpc.shibchain.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'Shiba Explorer', url: 'https://exp.shibchain.org' },
    default: { name: 'Shiba Explorer', url: 'https://exp.shibchain.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const smartBch = {
  id: 10000,
  name: 'Smart Bitcoin Cash',
  network: 'SmartBCH-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bitcoin Cash',
    symbol: 'BCH',
  },
  rpcUrls: {
    default: { http: ['https://rpc-mainnet.smartbch.org'] },
    public: { http: ['https://rpc-mainnet.smartbch.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'SyscoinScan', url: 'https://teloscan.io' },
    default: { name: 'SyscoinScan', url: 'https://teloscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const smartBchTestnet = {
  id: 10001,
  name: 'Smart Bitcoin Cash Testnet',
  network: 'SmartBCH-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Bitcoin Cash',
    symbol: 'tBCH',
  },
  rpcUrls: {
    default: { http: ['https://rpc-testnet.smartbch.org'] },
    public: { http: ['https://rpc-testnet.smartbch.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'BchScan', url: 'https://tanenbaum.io' },
    default: { name: 'BchScan', url: 'https://tanenbaum.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const songbirdCanary = {
  id: 19,
  name: 'Songbird',
  network: 'Songbird-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Songbird Native Token',
    symbol: 'SGB',
  },
  rpcUrls: {
    default: { http: ['https://songbird-api.flare.network/ext/C/rpc'] },
    public: { http: ['https://songbird-api.flare.network/ext/C/rpc'] },
  },
  blockExplorers: {
    etherscan: { name: 'Songbird Explorer', url: 'https://songbird-explorer.flare.network' },
    default: { name: 'Songbird Explorer', url: 'https://songbird-explorer.flare.network' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const thaichain = {
  id: 7,
  name: 'Thaichain',
  network: 'Thaichain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Thaichain Native Token',
    symbol: 'TCH',
  },
  rpcUrls: {
    default: { http: ['https://rpc.thaichain.org'] },
    public: { http: ['https://rpc.thaichain.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'Thaichain Explorer', url: 'https://exp.thaichain.org' },
    default: { name: 'Thaichain Explorer', url: 'https://exp.thaichain.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const ubiq = {
  id: 8,
  name: 'Ubiq',
  network: 'Ubiq-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ubiq Native Token',
    symbol: 'UBQ',
  },
  rpcUrls: {
    default: { http: ['https://rpc.octano.dev'] },
    public: { http: ['https://rpc.octano.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'UbiqScan', url: 'https://ubiqscan.io' },
    default: { name: 'UbiqScan', url: 'https://ubiqscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const ubiqTestnet = {
  id: 9,
  name: 'Ubiq Testnet',
  network: 'Ubiq-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: ' Token',
    symbol: 'tUBQ',
  },
  rpcUrls: {
    default: { http: ['https://rpc.octano.dev'] },
    public: { http: ['https://rpc.octano.dev'] },
  },
  blockExplorers: {
    etherscan: { name: 'UbiqScan', url: 'https://ubiqscan.io' },
    default: { name: 'UbiqScan', url: 'https://ubiqscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const vechain = {
  id: 100009,
  name: 'Vechain',
  network: 'Vechain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Vechain Native Token',
    symbol: 'VET',
  },
  rpcUrls: {
    default: { http: ['https://rpc.vechain.com'] },
    public: { http: ['https://rpc.vechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'VeChain Explorer', url: 'https://explore.vechain.org' },
    default: { name: 'VeChain Explorer', url: 'https://explore.vechain.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const vechainTestnet = {
  id: 100010,
  name: 'Vechain Testnet',
  network: 'Vechain-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Vechain Native Token',
    symbol: 'tVET',
  },
  rpcUrls: {
    default: { http: ['https://rpc.vechain.com'] },
    public: { http: ['https://rpc.vechain.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'VeChain Explorer', url: '' },
    default: { name: 'VeChain Explorer', url: '' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const xinfin = {
  id: 50,
  name: 'Xinfin',
  network: 'Xinfin-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Xinfin Token',
    symbol: 'XDC',
  },
  rpcUrls: {
    default: { http: ['https://erpc.xinfin.network'] },
    public: { http: ['https://erpc.xinfin.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'XdcScan', url: 'https://xdc.blocksscan.io' },
    default: { name: 'XdcScan', url: 'https://xdc.blocksscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const xinfinApothem = {
  id: 51,
  name: 'Xinfin Apothem',
  network: 'Xinfin-Apothem',
  nativeCurrency: {
    decimals: 18,
    name: 'Xinfin Token',
    symbol: 'tXDC',
  },
  rpcUrls: {
    default: { http: ['https://rpc.apothem.network'] },
    public: { http: ['https://rpc.apothem.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'XdcScan', url: 'https://apothem.blocksscan.io' },
    default: { name: 'XdcScan', url: 'https://apothem.blocksscan.io' },
  },
  contracts: {
    multicall3: {
      address: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const scroll = {
  id: 534352,
  name: 'Scroll',
  network: 'Scroll-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://rpc.scroll.io'] },
    public: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ScrollScan', url: 'https://blockscout.scroll.io' },
    default: { name: 'ScrollScan', url: 'https://blockscout.scroll.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const scrollSepolia = {
  id: 534351,
  name: 'Scroll Sepolia',
  network: 'Scroll-Sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether Token',
    symbol: 'tETH',
  },
  rpcUrls: {
    default: { http: ['https://scroll-sepolia.blockpi.network/v1/rpc/public'] },
    public: { http: ['https://scroll-sepolia.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    etherscan: { name: 'ScrollScan', url: 'https://sepolia-blockscout.scroll.io' },
    default: { name: 'ScrollScan', url: 'https://sepolia-blockscout.scroll.io' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const xrplDevnet = {
  id: 1440002,
  name: 'Xrpl Devnet',
  network: 'Xrpl-Devnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Xrpl Token',
    symbol: 'tXRP',
  },
  rpcUrls: {
    default: { http: ['https://rpc-evm-sidechain.xrpl.org'] },
    public: { http: ['https://rpc-evm-sidechain.xrpl.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'XprlScan', url: 'https://evm-sidechain.xrpl.org' },
    default: { name: 'XprlScan', url: 'https://evm-sidechain.xrpl.org' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const zetachain = {
  id: 7000,
  name: 'ZetaChain',
  network: 'ZetaChain-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'ZetaChain Token',
    symbol: 'ZETA',
  },
  rpcUrls: {
    default: { http: ['https://rpc.scroll.io'] },
    public: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    etherscan: { name: 'ZetaScan', url: 'https://zetachain.blockscout.com/' },
    default: { name: 'ZetaScan', url: 'https://zetachain.blockscout.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const zetachainAthens = {
  id: 7001,
  name: 'ZetaChain Athens',
  network: 'ZetaChain-Athens',
  nativeCurrency: {
    decimals: 18,
    name: 'ZetaChain Token',
    symbol: 'tZETA',
  },
  rpcUrls: {
    default: { http: ['https://zetachain-athens-evm.blockpi.network/v1/rpc/public'] },
    public: { http: ['https://zetachain-athens-evm.blockpi.network/v1/rpc/public'] },
  },
  blockExplorers: {
    etherscan: { name: 'ZetaScan', url: 'https://zetachain-athens-3.blockscout.com/' },
    default: { name: 'ZetaScan', url: 'https://zetachain-athens-3.blockscout.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 2715217,
    },
  },
  testnet: true,
} as const satisfies Chain

export const zyx = {
  id: 55,
  name: 'Zyx',
  network: 'Zyx-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Zyx Native Token',
    symbol: 'ZYX',
  },
  rpcUrls: {
    default: { http: ['https://rpc-1.zyx.network/'] },
    public: { http: ['https://rpc-1.zyx.network/'] },
  },
  blockExplorers: {
    etherscan: { name: 'ZyxScan', url: 'https://zyxscan.com' },
    default: { name: 'ZyxScan', url: 'https://zyxscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const fantomOpera = {
  id: 250,
  name: 'Fantom Opera',
  network: 'Fantom-Opera',
  nativeCurrency: {
    decimals: 18,
    name: 'Fantom Native Token',
    symbol: 'FTM',
  },
  rpcUrls: {
    default: { http: ['https://rpcapi.fantom.network/'] },
    public: { http: ['https://rpcapi.fantom.network/'] },
  },
  blockExplorers: {
    etherscan: { name: 'FtmScan', url: 'https://ftmscan.com/' },
    default: { name: 'FtmScan', url: 'https://ftmscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const fantomTestnet = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'Fantom-Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Fantom Native Token',
    symbol: 'tFTM',
  },
  rpcUrls: {
    default: { http: ['https://rpc.testnet.fantom.network/'] },
    public: { http: ['https://rpc.testnet.fantom.network/'] },
  },
  blockExplorers: {
    etherscan: { name: 'FtmScan', url: 'https://testnet.ftmscan.com/' },
    default: { name: 'FtmScan', url: 'https://testnet.ftmscan.com/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain

export const polygon = {
  id: 250,
  name: 'Polygon',
  network: 'Polygon-Mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Matic Token',
    symbol: 'MATIC',
  },
  rpcUrls: {
    default: { http: ['https://polygon-mainnet.g.alchemy.com/v2'] },
    public: { http: ['https://polygon-mainnet.g.alchemy.com/v2'] },
  },
  blockExplorers: {
    etherscan: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com' },
    default: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
} as const satisfies Chain

export const polygonAmoy = {
  id: 80002,
  name: 'Polygon Amoy',
  network: 'Polygon-Amoy',
  nativeCurrency: {
    decimals: 18,
    name: 'Matic Token',
    symbol: 'tMATIC',
  },
  rpcUrls: {
    default: { http: ['https://rpc-amoy.polygon.technology'] },
    public: { http: ['https://rpc-amoy.polygon.technology'] },
  },
  blockExplorers: {
    etherscan: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com' },
    default: { name: 'PolygonScan', url: 'https://amoy.polygonscan.com' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 17422483,
    },
  },
  testnet: true,
} as const satisfies Chain