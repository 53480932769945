import { darkColors, lightColors, fireColors } from "../../theme/colors";
import { DonaswapToggleTheme } from "./types";

export const light: DonaswapToggleTheme = {
  handleBackground: lightColors.backgroundAlt,
  handleShadow: lightColors.textDisabled,
};

export const dark: DonaswapToggleTheme = {
  handleBackground: darkColors.backgroundAlt,
  handleShadow: darkColors.textDisabled,
};

export const fire: DonaswapToggleTheme = {
  handleBackground: fireColors.backgroundAlt,
  handleShadow: fireColors.textDisabled,
};
