import { ChainId } from '@donaswap/sdk'
import { TokenAddressMap as TTokenAddressMap, WrappedTokenInfo, TokenList, TokenInfo } from '@donaswap/token-lists'
import { ListsState } from '@donaswap/token-lists/react'
import {
  DEFAULT_LIST_OF_LISTS,
  OFFICIAL_LISTS,
  UNSUPPORTED_LIST_URLS,
  WARNING_LIST_URLS,
  // FIRECHAIN_URLS,
  ETH_URLS,
  BSC_URLS,
  POLYGON_URLS
} from 'config/constants/lists'
import { atom, useAtomValue } from 'jotai'
import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import _pickBy from 'lodash/pickBy'
import { EMPTY_LIST } from '@donaswap/tokens'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'
import { useActiveChainId } from 'hooks/useActiveChainId'
import DEFAULT_TOKEN_LIST from '../../config/constants/tokenLists/donaswap-default.tokenlist.json'
import UNSUPPORTED_TOKEN_LIST from '../../config/constants/tokenLists/donaswap-unsupported.tokenlist.json'
import WARNING_TOKEN_LIST from '../../config/constants/tokenLists/donaswap-warning.tokenlist.json'
import ONRAMP_TOKEN_LIST from '../../config/constants/tokenLists/donaswap-supported-onramp-currency-list.json'
import { listsAtom } from './lists'
import { isAddress } from '../../utils'

type TokenAddressMap = TTokenAddressMap<ChainId>

// use ordering of default list of lists to assign priority
function sortByListPriority(urlA: string, urlB: string) {
  const first = DEFAULT_LIST_OF_LISTS.includes(urlA) ? DEFAULT_LIST_OF_LISTS.indexOf(urlA) : Number.MAX_SAFE_INTEGER
  const second = DEFAULT_LIST_OF_LISTS.includes(urlB) ? DEFAULT_LIST_OF_LISTS.indexOf(urlB) : Number.MAX_SAFE_INTEGER

  // need reverse order to make sure mapping includes top priority last
  if (first < second) return 1
  if (first > second) return -1
  return 0
}

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
}

// -------------------------------------
//   Selectors
// -------------------------------------
const selectorActiveUrlsAtom = atom((get) => get(listsAtom)?.activeListUrls ?? [])
export const selectorByUrlsAtom = atom((get) => get(listsAtom)?.byUrl ?? {})

const activeListUrlsAtom = atom((get) => {
  const urls = get(selectorActiveUrlsAtom)
  return urls?.filter((url) => !UNSUPPORTED_LIST_URLS.includes(url))
})

const combineTokenMapsWithDefault = (lists: ListsState['byUrl'], urls: string[]) => {
  const defaultTokenMap = listToTokenMap(DEFAULT_TOKEN_LIST as TokenList, 'address')
  if (!urls) return defaultTokenMap
  return combineMaps(combineTokenMaps(lists, urls), defaultTokenMap)
}

const combineTokenMapsWithOnRamp = () => {
  const onRampTokens = listToTokenMap(ONRAMP_TOKEN_LIST as TokenList, 'symbol')
  return onRampTokens
}

export const combinedCurrenciesMapFromActiveUrlsAtom = atom(() => {
  return combineTokenMapsWithOnRamp()
})

const combineTokenMaps = (lists: ListsState['byUrl'], urls: string[]): any => {
  if (!urls) return EMPTY_LIST
  return (
    urls
      .slice()
      // sort by priority so top priority goes last
      .sort(sortByListPriority)
      .reduce((allTokens, currentUrl) => {
        const current = lists[currentUrl]?.current
        if (!current) return allTokens
        try {
          const newTokens = Object.assign(listToTokenMap(current, 'address'))
          return combineMaps(allTokens, newTokens)
        } catch (error) {
          console.error('Could not show token list due to error', error)
          return allTokens
        }
      }, EMPTY_LIST)
  )
}

export const combinedTokenMapFromActiveUrlsAtom = atom((get) => {
  const [selectorByUrls, selectorActiveUrls] = [get(selectorByUrlsAtom), get(selectorActiveUrlsAtom)]
  return combineTokenMapsWithDefault(selectorByUrls, selectorActiveUrls)
})

const inactiveUrlAtom = atom((get) => {
  const [lists, urls] = [get(selectorByUrlsAtom), get(selectorActiveUrlsAtom)]
  return Object.keys(lists).filter((url) => !urls?.includes(url) && !UNSUPPORTED_LIST_URLS.includes(url))
})

export const combinedTokenMapFromInActiveUrlsAtom = atom((get) => {
  const [lists, inactiveUrl] = [get(selectorByUrlsAtom), get(inactiveUrlAtom)]
  return combineTokenMaps(lists, inactiveUrl)
})

export const combinedTokenMapFromOfficialsUrlsAtom = atom((get) => {
  const lists = get(selectorByUrlsAtom)
  return combineTokenMapsWithDefault(lists, OFFICIAL_LISTS)
})

export const tokenListFromOfficialsUrlsAtom = atom((get) => {
  const lists: ListsState['byUrl'] = get(selectorByUrlsAtom)

  const mergedTokenLists: TokenInfo[] = OFFICIAL_LISTS.reduce((acc, url) => {
    if (lists?.[url]?.current?.tokens) {
      acc.push(...(lists?.[url]?.current?.tokens || []))
    }
    return acc
  }, [])

  const mergedList =
    mergedTokenLists.length > 0 ? [...DEFAULT_TOKEN_LIST.tokens, ...mergedTokenLists] : DEFAULT_TOKEN_LIST.tokens
  return mapValues(
    groupBy(
      uniqBy(mergedList, (tokenInfo) => `${tokenInfo.chainId}#${tokenInfo.address}`),
      'chainId',
    ),
    (tokenInfos) => keyBy(tokenInfos, 'address'),
  )
})

export const combinedTokenMapFromUnsupportedUrlsAtom = atom((get) => {
  const lists = get(selectorByUrlsAtom)
  // get hard coded unsupported tokens
  const localUnsupportedListMap = listToTokenMap(UNSUPPORTED_TOKEN_LIST, 'address')
  // get any loaded unsupported tokens
  const loadedUnsupportedListMap = combineTokenMaps(lists, UNSUPPORTED_LIST_URLS)

  return combineMaps(localUnsupportedListMap, loadedUnsupportedListMap)
})

export const combinedTokenMapFromWarningUrlsAtom = atom((get) => {
  const lists = get(selectorByUrlsAtom)
  // get hard coded unsupported tokens
  const localUnsupportedListMap = listToTokenMap(WARNING_TOKEN_LIST as TokenList, 'address')
  // get any loaded unsupported tokens
  const loadedUnsupportedListMap = combineTokenMaps(lists, WARNING_LIST_URLS)

  return combineMaps(localUnsupportedListMap, loadedUnsupportedListMap)
})
const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

export function listToTokenMap(list: TokenList, key?: string): TokenAddressMap {
  const result = listCache?.get(list)
  if (result) return result

  const tokenMap: WrappedTokenInfo[] = uniqBy(
    list.tokens,
    (tokenInfo: TokenInfo) => `${tokenInfo.chainId}#${tokenInfo.address}`,
  )
    .map((tokenInfo) => {
      const checksummedAddress = isAddress(tokenInfo.address)
      if (checksummedAddress) {
        return new WrappedTokenInfo({ ...tokenInfo, address: checksummedAddress })
      }
      return null
    })
    .filter(Boolean)

  const groupedTokenMap: { [chainId: string]: WrappedTokenInfo[] } = groupBy(tokenMap, 'chainId')

  const tokenAddressMap = mapValues(groupedTokenMap, (tokenInfoList) =>
    mapValues(keyBy(tokenInfoList, key), (tokenInfo) => ({ token: tokenInfo, list })),
  ) as TokenAddressMap

  // add chain id item if not exist
  enumKeys(ChainId).forEach((chainId) => {
    if (!(ChainId[chainId] in tokenAddressMap)) {
      Object.defineProperty(tokenAddressMap, ChainId[chainId], {
        value: {},
      })
    }
  })

  listCache?.set(list, tokenAddressMap)
  return tokenAddressMap
}

// -------------------------------------
//   Hooks
// -------------------------------------
export function useAllLists(): {
  readonly [url: string]: {
    readonly current: TokenList | null
    readonly pendingUpdate: TokenList | null
    readonly loadingRequestId: string | null
    readonly error: string | null
  }
} {
  const { chainId } = useActiveChainId()

  const urls = useAtomValue(selectorByUrlsAtom)

  return useMemo(
    () =>
      _pickBy(
        urls,
        (_, url) =>
          // (chainId === ChainId.FIRECHAIN && FIRECHAIN_URLS.includes(url)) ||
          (chainId === ChainId.ETHEREUM && ETH_URLS.includes(url)) ||
          (chainId === ChainId.BSC && BSC_URLS.includes(url)) ||
          (chainId === ChainId.POLYGON && POLYGON_URLS.includes(url)),
          
      ),
    [chainId, urls],
  )
}

function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
  return {
    [ChainId.FIRECHAIN]: { ...map1[ChainId.FIRECHAIN], ...map2[ChainId.FIRECHAIN] },
    [ChainId.RINIA]: { ...map1[ChainId.RINIA], ...map2[ChainId.RINIA] },
    [ChainId.ETHEREUM]: { ...map1[ChainId.ETHEREUM], ...map2[ChainId.ETHEREUM] },
    [ChainId.GOERLI]: { ...map1[ChainId.GOERLI], ...map2[ChainId.GOERLI] },
    [ChainId.SEPOLIA]: { ...map1[ChainId.SEPOLIA], ...map2[ChainId.SEPOLIA] },
    [ChainId.HOLESKY]: { ...map1[ChainId.HOLESKY], ...map2[ChainId.HOLESKY] },
    [ChainId.BSC]: { ...map1[ChainId.BSC], ...map2[ChainId.BSC] },
    [ChainId.BSC_TESTNET]: { ...map1[ChainId.BSC_TESTNET], ...map2[ChainId.BSC_TESTNET] },
    [ChainId.POLYGON]: { ...map1[ChainId.POLYGON], ...map2[ChainId.POLYGON] },
    [ChainId.POLYGON_AMOY]: { ...map1[ChainId.POLYGON_AMOY], ...map2[ChainId.POLYGON_AMOY] },
    [ChainId.POLYGON_ZKEVM]: { ...map1[ChainId.POLYGON_ZKEVM], ...map2[ChainId.POLYGON_ZKEVM] },
    [ChainId.POLYGON_ZKEVM_TESTNET]: { ...map1[ChainId.POLYGON_ZKEVM_TESTNET], ...map2[ChainId.POLYGON_ZKEVM_TESTNET] },
    [ChainId.CRO]: { ...map1[ChainId.CRO], ...map2[ChainId.CRO] },
    [ChainId.CRO_TESTNET]: { ...map1[ChainId.CRO_TESTNET], ...map2[ChainId.CRO_TESTNET] },
    [ChainId.AVALANCHE]: { ...map1[ChainId.AVALANCHE], ...map2[ChainId.AVALANCHE] },
    [ChainId.AVALANCHE_FUJI]: { ...map1[ChainId.AVALANCHE_FUJI], ...map2[ChainId.AVALANCHE_FUJI] },
    [ChainId.FANTOM_OPERA]: { ...map1[ChainId.FANTOM_OPERA], ...map2[ChainId.FANTOM_OPERA] },
    [ChainId.FANTOM_TESTNET]: { ...map1[ChainId.FANTOM_TESTNET], ...map2[ChainId.FANTOM_TESTNET] },
    [ChainId.CELO]: { ...map1[ChainId.CELO], ...map2[ChainId.CELO] },
    [ChainId.CELO_ALFAJORES]: { ...map1[ChainId.CELO_ALFAJORES], ...map2[ChainId.CELO_ALFAJORES] },
    [ChainId.OPTIMISM]: { ...map1[ChainId.OPTIMISM], ...map2[ChainId.OPTIMISM] },
    [ChainId.OPTIMISM_GOERLI]: { ...map1[ChainId.OPTIMISM_GOERLI], ...map2[ChainId.OPTIMISM_GOERLI] },
    [ChainId.SHIBARIUM]: { ...map1[ChainId.SHIBARIUM], ...map2[ChainId.SHIBARIUM] },
    [ChainId.SHIBARIUM_TESTNET]: { ...map1[ChainId.SHIBARIUM_TESTNET], ...map2[ChainId.SHIBARIUM_TESTNET] },
    [ChainId.PULSECHAIN]: { ...map1[ChainId.PULSECHAIN], ...map2[ChainId.PULSECHAIN] },
    [ChainId.PULSECHAIN_TESTNET]: { ...map1[ChainId.PULSECHAIN_TESTNET], ...map2[ChainId.PULSECHAIN_TESTNET] },
    [ChainId.XEN]: { ...map1[ChainId.XEN], ...map2[ChainId.XEN] },
    [ChainId.XEN_DEVNET]: { ...map1[ChainId.XEN_DEVNET], ...map2[ChainId.XEN_DEVNET] },
    [ChainId.HARMONY]: { ...map1[ChainId.HARMONY], ...map2[ChainId.HARMONY] },
    [ChainId.HARMONY_TESTNET]: { ...map1[ChainId.HARMONY_TESTNET], ...map2[ChainId.HARMONY_TESTNET] },
    [ChainId.COINEX]: { ...map1[ChainId.COINEX], ...map2[ChainId.COINEX] },
    [ChainId.COINEX_TESTNET]: { ...map1[ChainId.COINEX_TESTNET], ...map2[ChainId.COINEX_TESTNET] },
    [ChainId.DOGECHAIN]: { ...map1[ChainId.DOGECHAIN], ...map2[ChainId.DOGECHAIN] },
    [ChainId.DOGECHAIN_TESTNET]: { ...map1[ChainId.DOGECHAIN_TESTNET], ...map2[ChainId.DOGECHAIN_TESTNET] },
    [ChainId.ENGRAM_TESTNET]: { ...map1[ChainId.ENGRAM_TESTNET], ...map2[ChainId.ENGRAM_TESTNET] },
    [ChainId.ETHEREUM_CLASSIC]: { ...map1[ChainId.ETHEREUM_CLASSIC], ...map2[ChainId.ETHEREUM_CLASSIC] },
    [ChainId.ETHEREUM_CLASSIC_TESTNET]: {
      ...map1[ChainId.ETHEREUM_CLASSIC_TESTNET],
      ...map2[ChainId.ETHEREUM_CLASSIC_TESTNET],
    },
    [ChainId.FUSION]: { ...map1[ChainId.FUSION], ...map2[ChainId.FUSION] },
    [ChainId.FUSION_TESTNET]: { ...map1[ChainId.FUSION_TESTNET], ...map2[ChainId.FUSION_TESTNET] },
    [ChainId.HECO]: { ...map1[ChainId.HECO], ...map2[ChainId.HECO] },
    [ChainId.HECO_TESTNET]: { ...map1[ChainId.HECO_TESTNET], ...map2[ChainId.HECO_TESTNET] },
    [ChainId.KCC]: { ...map1[ChainId.KCC], ...map2[ChainId.KCC] },
    [ChainId.KCC_TESTNET]: { ...map1[ChainId.KCC_TESTNET], ...map2[ChainId.KCC_TESTNET] },
    [ChainId.KLAYTN]: { ...map1[ChainId.KLAYTN], ...map2[ChainId.KLAYTN] },
    [ChainId.KLAYTN_BAOBAB]: { ...map1[ChainId.KLAYTN_BAOBAB], ...map2[ChainId.KLAYTN_BAOBAB] },
    [ChainId.OKXCHAIN]: { ...map1[ChainId.OKXCHAIN], ...map2[ChainId.OKXCHAIN] },
    [ChainId.OKXCHAIN_TESTNET]: { ...map1[ChainId.OKXCHAIN_TESTNET], ...map2[ChainId.OKXCHAIN_TESTNET] },
    [ChainId.THETHA]: { ...map1[ChainId.THETHA], ...map2[ChainId.THETHA] },
    [ChainId.THETHA_TESTNET]: { ...map1[ChainId.THETHA_TESTNET], ...map2[ChainId.THETHA_TESTNET] },
    [ChainId.ULTRON]: { ...map1[ChainId.ULTRON], ...map2[ChainId.ULTRON] },
    [ChainId.ULTRON_TESTNET]: { ...map1[ChainId.ULTRON_TESTNET], ...map2[ChainId.ULTRON_TESTNET] },
    [ChainId.MOONBEAM]: { ...map1[ChainId.MOONBEAM], ...map2[ChainId.MOONBEAM] },
    [ChainId.MOONRIVER]: { ...map1[ChainId.MOONRIVER], ...map2[ChainId.MOONRIVER] },
    [ChainId.MOONBASE_ALPHA]: { ...map1[ChainId.MOONBASE_ALPHA], ...map2[ChainId.MOONBASE_ALPHA] },
    [ChainId.AURORA]: { ...map1[ChainId.AURORA], ...map2[ChainId.AURORA] },
    [ChainId.AURORA_TESTNET]: { ...map1[ChainId.AURORA_TESTNET], ...map2[ChainId.AURORA_TESTNET] },
    [ChainId.BOBA]: { ...map1[ChainId.BOBA], ...map2[ChainId.BOBA] },
    [ChainId.BOBA_GOERLI]: { ...map1[ChainId.BOBA_GOERLI], ...map2[ChainId.BOBA_GOERLI] },
    [ChainId.GNOSIS]: { ...map1[ChainId.GNOSIS], ...map2[ChainId.GNOSIS] },
    [ChainId.GNOSIS_CHIADO]: { ...map1[ChainId.GNOSIS_CHIADO], ...map2[ChainId.GNOSIS_CHIADO] },
    [ChainId.METIS]: { ...map1[ChainId.METIS], ...map2[ChainId.METIS] },
    [ChainId.METIS_GOERLI]: { ...map1[ChainId.METIS_GOERLI], ...map2[ChainId.METIS_GOERLI] },
    [ChainId.TELOS]: { ...map1[ChainId.TELOS], ...map2[ChainId.TELOS] },
    [ChainId.TELOS_TESTNET]: { ...map1[ChainId.TELOS_TESTNET], ...map2[ChainId.TELOS_TESTNET] },
    [ChainId.SYSCOIN]: { ...map1[ChainId.SYSCOIN], ...map2[ChainId.SYSCOIN] },
    [ChainId.SYSCOIN_TANENBAUM]: { ...map1[ChainId.SYSCOIN_TANENBAUM], ...map2[ChainId.SYSCOIN_TANENBAUM] },
    [ChainId.IOTEX]: { ...map1[ChainId.IOTEX], ...map2[ChainId.IOTEX] },
    [ChainId.IOTEX_TESTNET]: { ...map1[ChainId.IOTEX_TESTNET], ...map2[ChainId.IOTEX_TESTNET] },
    [ChainId.SHIDEN]: { ...map1[ChainId.SHIDEN], ...map2[ChainId.SHIDEN] },
    [ChainId.ASTAR]: { ...map1[ChainId.ASTAR], ...map2[ChainId.ASTAR] },
    [ChainId.SHIBUNYA]: { ...map1[ChainId.SHIBUNYA], ...map2[ChainId.SHIBUNYA] },
    [ChainId.FUSE]: { ...map1[ChainId.FUSE], ...map2[ChainId.FUSE] },
    [ChainId.FUSE_SPARKNET]: { ...map1[ChainId.FUSE_SPARKNET], ...map2[ChainId.FUSE_SPARKNET] },
    [ChainId.VELAS]: { ...map1[ChainId.VELAS], ...map2[ChainId.VELAS] },
    [ChainId.VELAS_TESTNET]: { ...map1[ChainId.VELAS_TESTNET], ...map2[ChainId.VELAS_TESTNET] },
    [ChainId.REI]: { ...map1[ChainId.REI], ...map2[ChainId.REI] },
    [ChainId.REI_TESTNET]: { ...map1[ChainId.REI_TESTNET], ...map2[ChainId.REI_TESTNET] },
    [ChainId.KEKCHAIN]: { ...map1[ChainId.KEKCHAIN], ...map2[ChainId.KEKCHAIN] },
    [ChainId.KEKCHAIN_TESTNET]: { ...map1[ChainId.KEKCHAIN_TESTNET], ...map2[ChainId.KEKCHAIN_TESTNET] },
    [ChainId.TOMOCHAIN]: { ...map1[ChainId.TOMOCHAIN], ...map2[ChainId.TOMOCHAIN] },
    [ChainId.TOMOCHAIN_TESTNET]: { ...map1[ChainId.TOMOCHAIN_TESTNET], ...map2[ChainId.TOMOCHAIN_TESTNET] },
    [ChainId.THUNDERCORE]: { ...map1[ChainId.THUNDERCORE], ...map2[ChainId.THUNDERCORE] },
    [ChainId.THUNDERCORE_TESTNET]: { ...map1[ChainId.THUNDERCORE_TESTNET], ...map2[ChainId.THUNDERCORE_TESTNET] },
    [ChainId.WANCHAIN]: { ...map1[ChainId.WANCHAIN], ...map2[ChainId.WANCHAIN] },
    [ChainId.WANCHAIN_TESTNET]: { ...map1[ChainId.WANCHAIN_TESTNET], ...map2[ChainId.WANCHAIN_TESTNET] },
    [ChainId.RSK]: { ...map1[ChainId.RSK], ...map2[ChainId.RSK] },
    [ChainId.RSK_TESTNET]: { ...map1[ChainId.RSK_TESTNET], ...map2[ChainId.RSK_TESTNET] },
    [ChainId.ELASTOS]: { ...map1[ChainId.ELASTOS], ...map2[ChainId.ELASTOS] },
    [ChainId.ELASTOS_TESTNET]: { ...map1[ChainId.ELASTOS_TESTNET], ...map2[ChainId.ELASTOS_TESTNET] },
    [ChainId.CONFLUX]: { ...map1[ChainId.CONFLUX], ...map2[ChainId.CONFLUX] },
    [ChainId.CONFLUX_TESTNET]: { ...map1[ChainId.CONFLUX_TESTNET], ...map2[ChainId.CONFLUX_TESTNET] },
    [ChainId.BRISECHAIN]: { ...map1[ChainId.BRISECHAIN], ...map2[ChainId.BRISECHAIN] },
    [ChainId.BRISECHAIN_TESTNET]: { ...map1[ChainId.BRISECHAIN_TESTNET], ...map2[ChainId.BRISECHAIN_TESTNET] },
    [ChainId.MUUCHAIN]: { ...map1[ChainId.MUUCHAIN], ...map2[ChainId.MUUCHAIN] },
    [ChainId.MUUCHAIN_TESTNET]: { ...map1[ChainId.MUUCHAIN_TESTNET], ...map2[ChainId.MUUCHAIN_TESTNET] },
    [ChainId.CANTO]: { ...map1[ChainId.CANTO], ...map2[ChainId.CANTO] },
    [ChainId.CANTO_TESTNET]: { ...map1[ChainId.CANTO_TESTNET], ...map2[ChainId.CANTO_TESTNET] },
    [ChainId.OASIS_EMERALD]: { ...map1[ChainId.OASIS_EMERALD], ...map2[ChainId.OASIS_EMERALD] },
    [ChainId.OASIS_TESTNET]: { ...map1[ChainId.OASIS_TESTNET], ...map2[ChainId.OASIS_TESTNET] },
    [ChainId.VISION]: { ...map1[ChainId.VISION], ...map2[ChainId.VISION] },
    [ChainId.VISION_TESTNET]: { ...map1[ChainId.VISION_TESTNET], ...map2[ChainId.VISION_TESTNET] },
    [ChainId.STEP]: { ...map1[ChainId.STEP], ...map2[ChainId.STEP] },
    [ChainId.STEP_TESTNET]: { ...map1[ChainId.STEP_TESTNET], ...map2[ChainId.STEP_TESTNET] },
    [ChainId.METER]: { ...map1[ChainId.METER], ...map2[ChainId.METER] },
    [ChainId.METER_TESTNET]: { ...map1[ChainId.METER_TESTNET], ...map2[ChainId.METER_TESTNET] },
    [ChainId.GODWOKEN]: { ...map1[ChainId.GODWOKEN], ...map2[ChainId.GODWOKEN] },
    [ChainId.GODWOKEN_TESTNET]: { ...map1[ChainId.GODWOKEN_TESTNET], ...map2[ChainId.GODWOKEN_TESTNET] },
    [ChainId.CALLISTO]: { ...map1[ChainId.CALLISTO], ...map2[ChainId.CALLISTO] },
    [ChainId.CALLISTO_TESTNET]: { ...map1[ChainId.CALLISTO_TESTNET], ...map2[ChainId.CALLISTO_TESTNET] },
    [ChainId.EVMOS]: { ...map1[ChainId.EVMOS], ...map2[ChainId.EVMOS] },
    [ChainId.EVMOS_TESTNET]: { ...map1[ChainId.EVMOS_TESTNET], ...map2[ChainId.EVMOS_TESTNET] },
    [ChainId.ENERGY_WEB_CHAIN]: { ...map1[ChainId.ENERGY_WEB_CHAIN], ...map2[ChainId.ENERGY_WEB_CHAIN] },
    [ChainId.ENERGY_VOLTA]: { ...map1[ChainId.ENERGY_VOLTA], ...map2[ChainId.ENERGY_VOLTA] },
    [ChainId.BASE]: { ...map1[ChainId.BASE], ...map2[ChainId.BASE] },
    [ChainId.BASE_GOERLI]: { ...map1[ChainId.BASE_GOERLI], ...map2[ChainId.BASE_GOERLI] },
    [ChainId.KAVA]: { ...map1[ChainId.KAVA], ...map2[ChainId.KAVA] },
    [ChainId.KAVA_TESTNET]: { ...map1[ChainId.KAVA_TESTNET], ...map2[ChainId.KAVA_TESTNET] },
    [ChainId.CLOVER]: { ...map1[ChainId.CLOVER], ...map2[ChainId.CLOVER] },
    [ChainId.CLOVER_TESTNET]: { ...map1[ChainId.CLOVER_TESTNET], ...map2[ChainId.CLOVER_TESTNET] },
    [ChainId.DEFICHAIN]: { ...map1[ChainId.DEFICHAIN], ...map2[ChainId.DEFICHAIN] },
    [ChainId.DEFICHAIN_TESTNET]: { ...map1[ChainId.DEFICHAIN_TESTNET], ...map2[ChainId.DEFICHAIN_TESTNET] },
    [ChainId.BRONOS]: { ...map1[ChainId.BRONOS], ...map2[ChainId.BRONOS] },
    [ChainId.BRONOS_TESTNET]: { ...map1[ChainId.BRONOS_TESTNET], ...map2[ChainId.BRONOS_TESTNET] },
    [ChainId.FILECOIN]: { ...map1[ChainId.FILECOIN], ...map2[ChainId.FILECOIN] },
    [ChainId.FILECOIN_CALIBRATION]: { ...map1[ChainId.FILECOIN_CALIBRATION], ...map2[ChainId.FILECOIN_CALIBRATION] },
    [ChainId.FLARE]: { ...map1[ChainId.FLARE], ...map2[ChainId.FLARE] },
    [ChainId.FLARE_TESTNET]: { ...map1[ChainId.FLARE_TESTNET], ...map2[ChainId.FLARE_TESTNET] },
    [ChainId.TARAXA]: { ...map1[ChainId.TARAXA], ...map2[ChainId.TARAXA] },
    [ChainId.TARAXA_TESTNET]: { ...map1[ChainId.TARAXA_TESTNET], ...map2[ChainId.TARAXA_TESTNET] },
    [ChainId.ARBITRUM_ONE]: { ...map1[ChainId.ARBITRUM_ONE], ...map2[ChainId.ARBITRUM_ONE] },
    [ChainId.ARBITRUM_GOERLI]: { ...map1[ChainId.ARBITRUM_GOERLI], ...map2[ChainId.ARBITRUM_GOERLI] },
    [ChainId.ZKSYNC]: { ...map1[ChainId.ZKSYNC], ...map2[ChainId.ZKSYNC] },
    [ChainId.ZKSYNC_TESTNET]: { ...map1[ChainId.ZKSYNC_TESTNET], ...map2[ChainId.ZKSYNC_TESTNET] },
    [ChainId.LINEA]: { ...map1[ChainId.LINEA], ...map2[ChainId.LINEA] },
    [ChainId.LINEA_TESTNET]: { ...map1[ChainId.LINEA_TESTNET], ...map2[ChainId.LINEA_TESTNET] },
    [ChainId.BTTCHAIN]: { ...map1[ChainId.BTTCHAIN], ...map2[ChainId.BTTCHAIN] },
    [ChainId.BTTCHAIN_TESTNET]: { ...map1[ChainId.BTTCHAIN_TESTNET], ...map2[ChainId.BTTCHAIN_TESTNET] },
    [ChainId.BOBA_AVAX]: { ...map1[ChainId.BOBA_AVAX], ...map2[ChainId.BOBA_AVAX] },
    [ChainId.BOBA_BNB]: { ...map1[ChainId.BOBA_BNB], ...map2[ChainId.BOBA_BNB] },
    [ChainId.CMP]: { ...map1[ChainId.CMP], ...map2[ChainId.CMP] },
    [ChainId.CMP_TESTNET]: { ...map1[ChainId.CMP_TESTNET], ...map2[ChainId.CMP_TESTNET] },
    [ChainId.CORE]: { ...map1[ChainId.CORE], ...map2[ChainId.CORE] },
    [ChainId.CORE_TESTNET]: { ...map1[ChainId.CORE_TESTNET], ...map2[ChainId.CORE_TESTNET] },
    [ChainId.CUBECHAIN]: { ...map1[ChainId.CUBECHAIN], ...map2[ChainId.CUBECHAIN] },
    [ChainId.CUBECHAIN_TESTNET]: { ...map1[ChainId.CUBECHAIN_TESTNET], ...map2[ChainId.CUBECHAIN_TESTNET] },
    [ChainId.DARWINIA_CRAB]: { ...map1[ChainId.DARWINIA_CRAB], ...map2[ChainId.DARWINIA_CRAB] },
    [ChainId.DARWINIA_PANGOLIN]: { ...map1[ChainId.DARWINIA_PANGOLIN], ...map2[ChainId.DARWINIA_PANGOLIN] },
    [ChainId.DIODE_PRETNET]: { ...map1[ChainId.DIODE_PRETNET], ...map2[ChainId.DIODE_PRETNET] },
    [ChainId.DIODE_TESTNET]: { ...map1[ChainId.DIODE_TESTNET], ...map2[ChainId.DIODE_TESTNET] },
    [ChainId.GOCHAIN]: { ...map1[ChainId.GOCHAIN], ...map2[ChainId.GOCHAIN] },
    [ChainId.GOCHAIN_TESTNET]: { ...map1[ChainId.GOCHAIN_TESTNET], ...map2[ChainId.GOCHAIN_TESTNET] },
    [ChainId.HAQQ]: { ...map1[ChainId.HAQQ], ...map2[ChainId.HAQQ] },
    [ChainId.HAQQ_TESTEDGE2]: { ...map1[ChainId.HAQQ_TESTEDGE2], ...map2[ChainId.HAQQ_TESTEDGE2] },
    [ChainId.KARDIACHAIN]: { ...map1[ChainId.KARDIACHAIN], ...map2[ChainId.KARDIACHAIN] },
    [ChainId.KARDIACHAIN_TESTNET]: { ...map1[ChainId.KARDIACHAIN_TESTNET], ...map2[ChainId.KARDIACHAIN_TESTNET] },
    [ChainId.METADIUM]: { ...map1[ChainId.METADIUM], ...map2[ChainId.METADIUM] },
    [ChainId.METADIUM_TESTNET]: { ...map1[ChainId.METADIUM_TESTNET], ...map2[ChainId.METADIUM_TESTNET] },
    [ChainId.OP_BNB]: { ...map1[ChainId.OP_BNB], ...map2[ChainId.OP_BNB] },
    [ChainId.OP_BNB_TESTNET]: { ...map1[ChainId.OP_BNB_TESTNET], ...map2[ChainId.OP_BNB_TESTNET] },
    [ChainId.PALM]: { ...map1[ChainId.PALM], ...map2[ChainId.PALM] },
    [ChainId.PALM_TESTNET]: { ...map1[ChainId.PALM_TESTNET], ...map2[ChainId.PALM_TESTNET] },
    [ChainId.POLIS]: { ...map1[ChainId.POLIS], ...map2[ChainId.POLIS] },
    [ChainId.POLIS_TESTNET]: { ...map1[ChainId.POLIS_TESTNET], ...map2[ChainId.POLIS_TESTNET] },
    [ChainId.SHIBACHAIN]: { ...map1[ChainId.SHIBACHAIN], ...map2[ChainId.SHIBACHAIN] },
    [ChainId.SMART_BCH]: { ...map1[ChainId.SMART_BCH], ...map2[ChainId.SMART_BCH] },
    [ChainId.SMART_BCH_TESTNET]: { ...map1[ChainId.SMART_BCH_TESTNET], ...map2[ChainId.SMART_BCH_TESTNET] },
    [ChainId.SONGBIRD_CANARY]: { ...map1[ChainId.SONGBIRD_CANARY], ...map2[ChainId.SONGBIRD_CANARY] },
    [ChainId.THAICHAIN]: { ...map1[ChainId.THAICHAIN], ...map2[ChainId.THAICHAIN] },
    [ChainId.UBIQ]: { ...map1[ChainId.UBIQ], ...map2[ChainId.UBIQ] },
    [ChainId.UBIQ_TESTNET]: { ...map1[ChainId.UBIQ_TESTNET], ...map2[ChainId.UBIQ_TESTNET] },
    [ChainId.VECHAIN]: { ...map1[ChainId.VECHAIN], ...map2[ChainId.VECHAIN] },
    [ChainId.VECHAIN_TESTNET]: { ...map1[ChainId.VECHAIN_TESTNET], ...map2[ChainId.VECHAIN_TESTNET] },
    [ChainId.XINFIN]: { ...map1[ChainId.XINFIN], ...map2[ChainId.XINFIN] },
    [ChainId.XINFIN_APOTHEM]: { ...map1[ChainId.XINFIN_APOTHEM], ...map2[ChainId.XINFIN_APOTHEM] },
    [ChainId.SCROLL]: { ...map1[ChainId.SCROLL], ...map2[ChainId.SCROLL] },
    [ChainId.SCROLL_SEPOLIA]: { ...map1[ChainId.SCROLL_SEPOLIA], ...map2[ChainId.SCROLL_SEPOLIA] },
    [ChainId.XRPL_DEVNET]: { ...map1[ChainId.XRPL_DEVNET], ...map2[ChainId.XRPL_DEVNET] },
    [ChainId.ZETACHAIN]: { ...map1[ChainId.ZETACHAIN], ...map2[ChainId.ZETACHAIN] },
    [ChainId.ZETACHAIN_ATHENS]: { ...map1[ChainId.ZETACHAIN_ATHENS], ...map2[ChainId.ZETACHAIN_ATHENS] },
    [ChainId.ZYX]: { ...map1[ChainId.ZYX], ...map2[ChainId.ZYX] },
  }
}

// filter out unsupported lists
export function useActiveListUrls(): string[] | undefined {
  const { chainId } = useActiveChainId()
  const urls = useAtomValue(activeListUrlsAtom)

  return useMemo(
    () =>
      urls.filter(
        (url) =>
          (chainId === ChainId.ETHEREUM && ETH_URLS.includes(url)) ||
          (chainId === ChainId.BSC && BSC_URLS.includes(url)) ||
          (chainId === ChainId.POLYGON && POLYGON_URLS.includes(url)),
      ),
    [urls, chainId],
  )
}

export function useInactiveListUrls() {
  return useAtomValue(inactiveUrlAtom)
}

// get all the tokens from active lists, combine with local default tokens
export function useCombinedActiveList(): TokenAddressMap {
  const activeTokens = useAtomValue(combinedTokenMapFromActiveUrlsAtom)
  return activeTokens
}

// all tokens from inactive lists
export function useCombinedInactiveList(): TokenAddressMap {
  return useAtomValue(combinedTokenMapFromInActiveUrlsAtom)
}

// list of tokens not supported on interface, used to show warnings and prevent swaps and adds
export function useUnsupportedTokenList(): TokenAddressMap {
  return useAtomValue(combinedTokenMapFromUnsupportedUrlsAtom)
}

// list of warning tokens on interface, used to show warnings and prevent adds
export function useWarningTokenList(): TokenAddressMap {
  return useAtomValue(combinedTokenMapFromWarningUrlsAtom)
}

export function useIsListActive(url: string): boolean {
  const activeListUrls = useActiveListUrls()
  return useMemo(() => Boolean(activeListUrls?.includes(url)), [activeListUrls, url])
}
