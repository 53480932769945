import { useTheme } from "next-themes";
import React from "react";

const Logo: React.FC<React.PropsWithChildren<any>> = () => {
  const { resolvedTheme } = useTheme();
  const isDark = resolvedTheme === "dark";
  const isLight = resolvedTheme === "light";


  return isDark || isLight ? (
    <img src="/images/nav-title-light.png" alt="logo-light" height={50} width={160} />
  ) : (
    <img src="/images/nav-title-dark.png" alt="logo-dark" height={50} width={160} />
  );
};

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark);
