import { useAccount } from 'wagmi'
import { ChainId } from '@donaswap/sdk'
import useSWRImmutable from 'swr/immutable'
import { useFlameVaultContract } from 'hooks/useContract'
import { useActiveChainId } from './useActiveChainId'

export const useUserFlameLockStatus = () => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()
  const flameVaultContract = useFlameVaultContract()

  const { data: userFlameLockStatus = null } = useSWRImmutable(
    account && chainId === ChainId.BSC ? ['userFlameLockStatus', account] : null,
    async () => {
      const [, , , , , lockEndTime, , locked] = await flameVaultContract.read.userInfo([account])
      const lockEndTimeStr = lockEndTime.toString()
      return locked && (lockEndTimeStr === '0' || Date.now() > parseInt(lockEndTimeStr) * 1000)
    },
  )
  return userFlameLockStatus
}
