import { useTheme } from "next-themes";
import React from "react";

const Icon: React.FC<React.PropsWithChildren<any>> = () => {
  const { resolvedTheme } = useTheme();
  const isDark = resolvedTheme === "dark";
  const isLight = resolvedTheme === "light";


  return isDark || isLight ? (
    <img src="/images/addedImages/donaswap.png" alt="logo-light" height={50} width={50} />
  ) : (
    <img src="/images/addedImages/fireswap.png" alt="logo-dark" height={50} width={50} />
  );
};

export default React.memo(Icon, (prev, next) => prev.isDark === next.isDark);
