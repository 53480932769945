import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Donaswap',
  defaultTitle: 'Donaswap',
  description:
    'Cheaper and faster than all other swaps? Discover Donaswap, the hottest cross-chain DEX with the best farms in DeFi and a lottery for FLAME.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Donaswap',
    site: '@Donaswap',
  },
  openGraph: {
    title: '🍩 Donaswap- A next evolution cross-chain DeFi exchange',
    description:
      'The hottest cross-chain AMM! Earn FLAME through yield farming or win it in the Lottery, then stake it in FLAME Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Donaswap), NFTs, and more, on a platform you can trust.',
    images: [{ url: 'https://www.donaswap.io/hero.png' }],
  },
}
