import { masterChefV3Addresses } from '@donaswap/farms'
import { V3_DEPLOYER_ADDRESS_MAP } from '@donaswap/v3-sdk'
import { V3_QUOTER_ADDRESSES } from '@donaswap/smart-router/evm'

export default {
  masterChef: {
    529: '0x0000000000000000000000000000000000000000',
    917: '0x0000000000000000000000000000000000000000',
    1: '0x0000000000000000000000000000000000000000',
    4: '0x0000000000000000000000000000000000000000',
    5: '0x0000000000000000000000000000000000000000',
    11155111: '0x0000000000000000000000000000000000000000',
    17000: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
    56: '0x0000000000000000000000000000000000000000',
    137: '0x0000000000000000000000000000000000000000',
    80002: '0x0000000000000000000000000000000000000000',
    1101: '0x0000000000000000000000000000000000000000',
    1442: '0x0000000000000000000000000000000000000000',
    25: '0x0000000000000000000000000000000000000000',
    338: '0x0000000000000000000000000000000000000000',
    43114: '0x0000000000000000000000000000000000000000',
    43113: '0x0000000000000000000000000000000000000000',
    250: '0x0000000000000000000000000000000000000000',
    4002: '0x0000000000000000000000000000000000000000',
    42220: '0x0000000000000000000000000000000000000000',
    44787: '0x0000000000000000000000000000000000000000',
    10: '0x0000000000000000000000000000000000000000',
    420: '0x0000000000000000000000000000000000000000',
    42161: '0x0000000000000000000000000000000000000000',
    421613: '0x0000000000000000000000000000000000000000',
    109: '0x0000000000000000000000000000000000000000',
    719: '0x0000000000000000000000000000000000000000',
    369: '0x0000000000000000000000000000000000000000',
    943: '0x0000000000000000000000000000000000000000',
    20221200000: '0x0000000000000000000000000000000000000000',
    202212: '0x0000000000000000000000000000000000000000',
    1666600000: '0x0000000000000000000000000000000000000000',
    1666700000: '0x0000000000000000000000000000000000000000',
    52: '0x0000000000000000000000000000000000000000',
    53: '0x0000000000000000000000000000000000000000',
    2000: '0x0000000000000000000000000000000000000000',
    568: '0x0000000000000000000000000000000000000000',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x0000000000000000000000000000000000000000',
    63: '0x0000000000000000000000000000000000000000',
    32659: '0x0000000000000000000000000000000000000000',
    46688: '0x0000000000000000000000000000000000000000',
    128: '0x0000000000000000000000000000000000000000',
    256: '0x0000000000000000000000000000000000000000',
    321: '0x0000000000000000000000000000000000000000',
    322: '0x0000000000000000000000000000000000000000',
    8217: '0x0000000000000000000000000000000000000000',
    1001: '0x0000000000000000000000000000000000000000',
    66: '0x0000000000000000000000000000000000000000',
    65: '0x0000000000000000000000000000000000000000',
    361: '0x0000000000000000000000000000000000000000',
    365: '0x0000000000000000000000000000000000000000',
    1231: '0x0000000000000000000000000000000000000000',
    1230: '0x0000000000000000000000000000000000000000',
    1284: '0x0000000000000000000000000000000000000000',
    1285: '0x0000000000000000000000000000000000000000',
    1287: '0x0000000000000000000000000000000000000000',
    1313161554: '0x0000000000000000000000000000000000000000',
    1313161555: '0x0000000000000000000000000000000000000000',
    288: '0x0000000000000000000000000000000000000000',
    2888: '0x0000000000000000000000000000000000000000',
    100: '0x0000000000000000000000000000000000000000',
    10200: '0x0000000000000000000000000000000000000000',
    1088: '0x0000000000000000000000000000000000000000',
    599: '0x0000000000000000000000000000000000000000',
    40: '0x0000000000000000000000000000000000000000',
    41: '0x0000000000000000000000000000000000000000',
    57: '0x0000000000000000000000000000000000000000',
    5700: '0x0000000000000000000000000000000000000000',
    4689: '0x0000000000000000000000000000000000000000',
    4690: '0x0000000000000000000000000000000000000000',
    336: '0x0000000000000000000000000000000000000000',
    592: '0x0000000000000000000000000000000000000000',
    81: '0x0000000000000000000000000000000000000000',
    122: '0x0000000000000000000000000000000000000000',
    123: '0x0000000000000000000000000000000000000000',
    106: '0x0000000000000000000000000000000000000000',
    111: '0x0000000000000000000000000000000000000000',
    55555: '0x0000000000000000000000000000000000000000',
    55556: '0x0000000000000000000000000000000000000000',
    420420: '0x0000000000000000000000000000000000000000',
    420666: '0x0000000000000000000000000000000000000000',
    88: '0x0000000000000000000000000000000000000000',
    89: '0x0000000000000000000000000000000000000000',
    108: '0x0000000000000000000000000000000000000000',
    18: '0x0000000000000000000000000000000000000000',
    888: '0x0000000000000000000000000000000000000000',
    999: '0x0000000000000000000000000000000000000000',
    30: '0x0000000000000000000000000000000000000000',
    31: '0x0000000000000000000000000000000000000000',
    20: '0x0000000000000000000000000000000000000000',
    21: '0x0000000000000000000000000000000000000000',
    1030: '0x0000000000000000000000000000000000000000',
    71: '0x0000000000000000000000000000000000000000',
    32520: '0x0000000000000000000000000000000000000000',
    64668: '0x0000000000000000000000000000000000000000',
    20402: '0x0000000000000000000000000000000000000000',
    42110: '0x0000000000000000000000000000000000000000',
    7700: '0x0000000000000000000000000000000000000000',
    7701: '0x0000000000000000000000000000000000000000',
    42262: '0x0000000000000000000000000000000000000000',
    42261: '0x0000000000000000000000000000000000000000',
    888888: '0x0000000000000000000000000000000000000000',
    666666: '0x0000000000000000000000000000000000000000',
    1234: '0x0000000000000000000000000000000000000000',
    12345: '0x0000000000000000000000000000000000000000',
    82: '0x0000000000000000000000000000000000000000',
    83: '0x0000000000000000000000000000000000000000',
    71402: '0x0000000000000000000000000000000000000000',
    71401: '0x0000000000000000000000000000000000000000',
    820: '0x0000000000000000000000000000000000000000',
    20729: '0x0000000000000000000000000000000000000000',
    9001: '0x0000000000000000000000000000000000000000',
    9000: '0x0000000000000000000000000000000000000000',
    246: '0x0000000000000000000000000000000000000000',
    73799: '0x0000000000000000000000000000000000000000',
    8453: '0x0000000000000000000000000000000000000000',
    84531: '0x0000000000000000000000000000000000000000',
    2222: '0x0000000000000000000000000000000000000000',
    2221: '0x0000000000000000000000000000000000000000',
    1024: '0x0000000000000000000000000000000000000000',
    1023: '0x0000000000000000000000000000000000000000',
    1131: '0x0000000000000000000000000000000000000000',
    1132: '0x0000000000000000000000000000000000000000',
    1039: '0x0000000000000000000000000000000000000000',
    1038: '0x0000000000000000000000000000000000000000',
    314: '0x0000000000000000000000000000000000000000',
    314159: '0x0000000000000000000000000000000000000000',
    14: '0x0000000000000000000000000000000000000000',
    114: '0x0000000000000000000000000000000000000000',
    841: '0x0000000000000000000000000000000000000000',
    842: '0x0000000000000000000000000000000000000000',
    324: '0x0000000000000000000000000000000000000000',
    280: '0x0000000000000000000000000000000000000000',
    59140: '0x0000000000000000000000000000000000000000',
    59144: '0x0000000000000000000000000000000000000000',
    204: '0x0000000000000000000000000000000000000000',
    5611: '0x0000000000000000000000000000000000000000',
    43288: '0x0000000000000000000000000000000000000000',
    56288: '0x0000000000000000000000000000000000000000',
    199: '0x0000000000000000000000000000000000000000',
    1029: '0x0000000000000000000000000000000000000000',
    256256: '0x0000000000000000000000000000000000000000',
    512512: '0x0000000000000000000000000000000000000000',
    1116: '0x0000000000000000000000000000000000000000',
    1115: '0x0000000000000000000000000000000000000000',
    1818: '0x0000000000000000000000000000000000000000',
    1819: '0x0000000000000000000000000000000000000000',
    44: '0x0000000000000000000000000000000000000000',
    43: '0x0000000000000000000000000000000000000000',
    15: '0x0000000000000000000000000000000000000000',
    13: '0x0000000000000000000000000000000000000000',
    60: '0x0000000000000000000000000000000000000000',
    31337: '0x0000000000000000000000000000000000000000',
    11235: '0x0000000000000000000000000000000000000000',
    54211: '0x0000000000000000000000000000000000000000',
    24: '0x0000000000000000000000000000000000000000',
    242: '0x0000000000000000000000000000000000000000',
    11: '0x0000000000000000000000000000000000000000',
    12: '0x0000000000000000000000000000000000000000',
    11297108109: '0x0000000000000000000000000000000000000000',
    11297108099: '0x0000000000000000000000000000000000000000',
    333999: '0x0000000000000000000000000000000000000000',
    333888: '0x0000000000000000000000000000000000000000',
    27: '0x0000000000000000000000000000000000000000',
    10000: '0x0000000000000000000000000000000000000000',
    10001: '0x0000000000000000000000000000000000000000',
    19: '0x0000000000000000000000000000000000000000',
    7: '0x0000000000000000000000000000000000000000',
    8: '0x0000000000000000000000000000000000000000',
    9: '0x0000000000000000000000000000000000000000',
    100009: '0x0000000000000000000000000000000000000000',
    100010: '0x0000000000000000000000000000000000000000',
    50: '0x0000000000000000000000000000000000000000',
    51: '0x0000000000000000000000000000000000000000',
    534352: '0x0000000000000000000000000000000000000000',
    534351: '0x0000000000000000000000000000000000000000',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x0000000000000000000000000000000000000000',
  },
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {
    529: '0x0000000000000000000000000000000000000000',
    917: '0x0000000000000000000000000000000000000000',
    1: '0x0000000000000000000000000000000000000000',
    4: '0x0000000000000000000000000000000000000000',
    5: '0x0000000000000000000000000000000000000000',
    11155111: '0x0000000000000000000000000000000000000000',
    17000: '0x0000000000000000000000000000000000000000',
    97: '0x0000000000000000000000000000000000000000',
    56: '0x0000000000000000000000000000000000000000',
    137: '0x0000000000000000000000000000000000000000',
    1101: '0x0000000000000000000000000000000000000000',
    1442: '0x0000000000000000000000000000000000000000',
    80002: '0x0000000000000000000000000000000000000000',
    25: '0x0000000000000000000000000000000000000000',
    338: '0x0000000000000000000000000000000000000000',
    43114: '0x0000000000000000000000000000000000000000',
    43113: '0x0000000000000000000000000000000000000000',
    250: '0x0000000000000000000000000000000000000000',
    4002: '0x0000000000000000000000000000000000000000',
    42220: '0x0000000000000000000000000000000000000000',
    44787: '0x0000000000000000000000000000000000000000',
    10: '0x0000000000000000000000000000000000000000',
    420: '0x0000000000000000000000000000000000000000',
    42161: '0x0000000000000000000000000000000000000000',
    421613: '0x0000000000000000000000000000000000000000',
    109: '0x0000000000000000000000000000000000000000',
    719: '0x0000000000000000000000000000000000000000',
    369: '0x0000000000000000000000000000000000000000',
    943: '0x0000000000000000000000000000000000000000',
    20221200000: '0x0000000000000000000000000000000000000000',
    202212: '0x0000000000000000000000000000000000000000',
    1666600000: '0x0000000000000000000000000000000000000000',
    1666700000: '0x0000000000000000000000000000000000000000',
    52: '0x0000000000000000000000000000000000000000',
    53: '0x0000000000000000000000000000000000000000',
    2000: '0x0000000000000000000000000000000000000000',
    568: '0x0000000000000000000000000000000000000000',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x0000000000000000000000000000000000000000',
    63: '0x0000000000000000000000000000000000000000',
    32659: '0x0000000000000000000000000000000000000000',
    46688: '0x0000000000000000000000000000000000000000',
    128: '0x0000000000000000000000000000000000000000',
    256: '0x0000000000000000000000000000000000000000',
    321: '0x0000000000000000000000000000000000000000',
    322: '0x0000000000000000000000000000000000000000',
    8217: '0x0000000000000000000000000000000000000000',
    1001: '0x0000000000000000000000000000000000000000',
    66: '0x0000000000000000000000000000000000000000',
    65: '0x0000000000000000000000000000000000000000',
    361: '0x0000000000000000000000000000000000000000',
    365: '0x0000000000000000000000000000000000000000',
    1231: '0x0000000000000000000000000000000000000000',
    1230: '0x0000000000000000000000000000000000000000',
    1284: '0x0000000000000000000000000000000000000000',
    1285: '0x0000000000000000000000000000000000000000',
    1287: '0x0000000000000000000000000000000000000000',
    1313161554: '0x0000000000000000000000000000000000000000',
    1313161555: '0x0000000000000000000000000000000000000000',
    288: '0x0000000000000000000000000000000000000000',
    2888: '0x0000000000000000000000000000000000000000',
    100: '0x0000000000000000000000000000000000000000',
    10200: '0x0000000000000000000000000000000000000000',
    1088: '0x0000000000000000000000000000000000000000',
    599: '0x0000000000000000000000000000000000000000',
    40: '0x0000000000000000000000000000000000000000',
    41: '0x0000000000000000000000000000000000000000',
    57: '0x0000000000000000000000000000000000000000',
    5700: '0x0000000000000000000000000000000000000000',
    4689: '0x0000000000000000000000000000000000000000',
    4690: '0x0000000000000000000000000000000000000000',
    336: '0x0000000000000000000000000000000000000000',
    592: '0x0000000000000000000000000000000000000000',
    81: '0x0000000000000000000000000000000000000000',
    122: '0x0000000000000000000000000000000000000000',
    123: '0x0000000000000000000000000000000000000000',
    106: '0x0000000000000000000000000000000000000000',
    111: '0x0000000000000000000000000000000000000000',
    55555: '0x0000000000000000000000000000000000000000',
    55556: '0x0000000000000000000000000000000000000000',
    420420: '0x0000000000000000000000000000000000000000',
    420666: '0x0000000000000000000000000000000000000000',
    88: '0x0000000000000000000000000000000000000000',
    89: '0x0000000000000000000000000000000000000000',
    108: '0x0000000000000000000000000000000000000000',
    18: '0x0000000000000000000000000000000000000000',
    888: '0x0000000000000000000000000000000000000000',
    999: '0x0000000000000000000000000000000000000000',
    30: '0x0000000000000000000000000000000000000000',
    31: '0x0000000000000000000000000000000000000000',
    20: '0x0000000000000000000000000000000000000000',
    21: '0x0000000000000000000000000000000000000000',
    1030: '0x0000000000000000000000000000000000000000',
    71: '0x0000000000000000000000000000000000000000',
    32520: '0x0000000000000000000000000000000000000000',
    64668: '0x0000000000000000000000000000000000000000',
    20402: '0x0000000000000000000000000000000000000000',
    42110: '0x0000000000000000000000000000000000000000',
    7700: '0x0000000000000000000000000000000000000000',
    7701: '0x0000000000000000000000000000000000000000',
    42262: '0x0000000000000000000000000000000000000000',
    42261: '0x0000000000000000000000000000000000000000',
    888888: '0x0000000000000000000000000000000000000000',
    666666: '0x0000000000000000000000000000000000000000',
    1234: '0x0000000000000000000000000000000000000000',
    12345: '0x0000000000000000000000000000000000000000',
    82: '0x0000000000000000000000000000000000000000',
    83: '0x0000000000000000000000000000000000000000',
    71402: '0x0000000000000000000000000000000000000000',
    71401: '0x0000000000000000000000000000000000000000',
    820: '0x0000000000000000000000000000000000000000',
    20729: '0x0000000000000000000000000000000000000000',
    9001: '0x0000000000000000000000000000000000000000',
    9000: '0x0000000000000000000000000000000000000000',
    246: '0x0000000000000000000000000000000000000000',
    73799: '0x0000000000000000000000000000000000000000',
    8453: '0x0000000000000000000000000000000000000000',
    84531: '0x0000000000000000000000000000000000000000',
    2222: '0x0000000000000000000000000000000000000000',
    2221: '0x0000000000000000000000000000000000000000',
    1024: '0x0000000000000000000000000000000000000000',
    1023: '0x0000000000000000000000000000000000000000',
    1131: '0x0000000000000000000000000000000000000000',
    1132: '0x0000000000000000000000000000000000000000',
    1039: '0x0000000000000000000000000000000000000000',
    1038: '0x0000000000000000000000000000000000000000',
    314: '0x0000000000000000000000000000000000000000',
    314159: '0x0000000000000000000000000000000000000000',
    14: '0x0000000000000000000000000000000000000000',
    114: '0x0000000000000000000000000000000000000000',
    841: '0x0000000000000000000000000000000000000000',
    842: '0x0000000000000000000000000000000000000000',
    324: '0x0000000000000000000000000000000000000000',
    280: '0x0000000000000000000000000000000000000000',
    59140: '0x0000000000000000000000000000000000000000',
    59144: '0x0000000000000000000000000000000000000000',
    204: '0x0000000000000000000000000000000000000000',
    5611: '0x0000000000000000000000000000000000000000',
    43288: '0x0000000000000000000000000000000000000000',
    56288: '0x0000000000000000000000000000000000000000',
    199: '0x0000000000000000000000000000000000000000',
    1029: '0x0000000000000000000000000000000000000000',
    256256: '0x0000000000000000000000000000000000000000',
    512512: '0x0000000000000000000000000000000000000000',
    1116: '0x0000000000000000000000000000000000000000',
    1115: '0x0000000000000000000000000000000000000000',
    1818: '0x0000000000000000000000000000000000000000',
    1819: '0x0000000000000000000000000000000000000000',
    44: '0x0000000000000000000000000000000000000000',
    43: '0x0000000000000000000000000000000000000000',
    15: '0x0000000000000000000000000000000000000000',
    13: '0x0000000000000000000000000000000000000000',
    60: '0x0000000000000000000000000000000000000000',
    31337: '0x0000000000000000000000000000000000000000',
    11235: '0x0000000000000000000000000000000000000000',
    54211: '0x0000000000000000000000000000000000000000',
    24: '0x0000000000000000000000000000000000000000',
    242: '0x0000000000000000000000000000000000000000',
    11: '0x0000000000000000000000000000000000000000',
    12: '0x0000000000000000000000000000000000000000',
    11297108109: '0x0000000000000000000000000000000000000000',
    11297108099: '0x0000000000000000000000000000000000000000',
    333999: '0x0000000000000000000000000000000000000000',
    333888: '0x0000000000000000000000000000000000000000',
    27: '0x0000000000000000000000000000000000000000',
    10000: '0x0000000000000000000000000000000000000000',
    10001: '0x0000000000000000000000000000000000000000',
    19: '0x0000000000000000000000000000000000000000',
    7: '0x0000000000000000000000000000000000000000',
    8: '0x0000000000000000000000000000000000000000',
    9: '0x0000000000000000000000000000000000000000',
    100009: '0x0000000000000000000000000000000000000000',
    100010: '0x0000000000000000000000000000000000000000',
    50: '0x0000000000000000000000000000000000000000',
    51: '0x0000000000000000000000000000000000000000',
    534352: '0x0000000000000000000000000000000000000000',
    534351: '0x0000000000000000000000000000000000000000',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x0000000000000000000000000000000000000000',
  },
  sousChef: {
    529: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    917: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    4: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    5: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    11155111: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    17000: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
    137: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    80002: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    338: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    43114: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    43113: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    250: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    4002: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42220: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    44787: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    10: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    420: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42161: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    421613: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    109: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    719: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    369: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    943: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    20221200000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    202212: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1666600000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1666700000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    52: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    53: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    2000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    568: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    63: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    32659: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    46688: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    128: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    256: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    321: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    322: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    8217: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1001: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    66: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    65: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    361: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    365: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1231: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1230: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1284: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1285: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1287: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1313161554: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1313161555: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    288: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    2888: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    100: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    10200: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1088: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    599: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    40: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    41: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    57: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    5700: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    4689: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    4690: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    336: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    592: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    81: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    122: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    123: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    106: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    111: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    55555: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    55556: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    420420: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    420666: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    88: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    89: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    108: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    18: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    888: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    999: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    30: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    31: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    20: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    21: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1030: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    71: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    32520: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    64668: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    20402: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42110: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    7700: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    7701: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42262: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    42261: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    888888: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    666666: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1234: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    12345: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    82: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    83: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    71402: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    71401: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    820: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    20729: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    9001: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    9000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    246: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    73799: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    8453: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    84531: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    2222: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    2221: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1024: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1023: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1131: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1132: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1039: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1038: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    314: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    314159: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    14: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    114: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    841: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    842: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    324: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    280: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    59140: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    59144: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    204: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    5611: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    43288: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56288: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    199: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1029: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    256256: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    512512: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1116: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1115: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1818: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    1819: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    44: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    43: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    15: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    13: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    60: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    31337: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    11235: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    54211: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    24: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    242: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    11: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    12: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    11297108109: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    11297108099: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    333999: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    333888: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    27: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    10000: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    10001: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    19: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    7: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    8: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    9: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    100009: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    100010: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    50: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    51: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  },
  lotteryV2: {
    529: '0x5790c3534F30437641541a0FA04C992799602998',
    917: '0x5790c3534F30437641541a0FA04C992799602998',
    1: '0x5790c3534F30437641541a0FA04C992799602998',
    4: '0x5790c3534F30437641541a0FA04C992799602998',
    5: '0x5790c3534F30437641541a0FA04C992799602998',
    11155111: '0x5790c3534F30437641541a0FA04C992799602998',
    17000: '0x5790c3534F30437641541a0FA04C992799602998',
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
    137: '0x5790c3534F30437641541a0FA04C992799602998',
    80002: '0x5790c3534F30437641541a0FA04C992799602998',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x5790c3534F30437641541a0FA04C992799602998',
    338: '0x5790c3534F30437641541a0FA04C992799602998',
    43114: '0x5790c3534F30437641541a0FA04C992799602998',
    43113: '0x5790c3534F30437641541a0FA04C992799602998',
    250: '0x5790c3534F30437641541a0FA04C992799602998',
    4002: '0x5790c3534F30437641541a0FA04C992799602998',
    42220: '0x5790c3534F30437641541a0FA04C992799602998',
    44787: '0x5790c3534F30437641541a0FA04C992799602998',
    10: '0x5790c3534F30437641541a0FA04C992799602998',
    420: '0x5790c3534F30437641541a0FA04C992799602998',
    42161: '0x5790c3534F30437641541a0FA04C992799602998',
    421613: '0x5790c3534F30437641541a0FA04C992799602998',
    109: '0x5790c3534F30437641541a0FA04C992799602998',
    719: '0x5790c3534F30437641541a0FA04C992799602998',
    369: '0x5790c3534F30437641541a0FA04C992799602998',
    943: '0x5790c3534F30437641541a0FA04C992799602998',
    20221200000: '0x5790c3534F30437641541a0FA04C992799602998',
    202212: '0x5790c3534F30437641541a0FA04C992799602998',
    1666600000: '0x5790c3534F30437641541a0FA04C992799602998',
    1666700000: '0x5790c3534F30437641541a0FA04C992799602998',
    52: '0x5790c3534F30437641541a0FA04C992799602998',
    53: '0x5790c3534F30437641541a0FA04C992799602998',
    2000: '0x5790c3534F30437641541a0FA04C992799602998',
    568: '0x5790c3534F30437641541a0FA04C992799602998',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x5790c3534F30437641541a0FA04C992799602998',
    63: '0x5790c3534F30437641541a0FA04C992799602998',
    32659: '0x5790c3534F30437641541a0FA04C992799602998',
    46688: '0x5790c3534F30437641541a0FA04C992799602998',
    128: '0x5790c3534F30437641541a0FA04C992799602998',
    256: '0x5790c3534F30437641541a0FA04C992799602998',
    321: '0x5790c3534F30437641541a0FA04C992799602998',
    322: '0x5790c3534F30437641541a0FA04C992799602998',
    8217: '0x5790c3534F30437641541a0FA04C992799602998',
    1001: '0x5790c3534F30437641541a0FA04C992799602998',
    66: '0x5790c3534F30437641541a0FA04C992799602998',
    65: '0x5790c3534F30437641541a0FA04C992799602998',
    361: '0x5790c3534F30437641541a0FA04C992799602998',
    365: '0x5790c3534F30437641541a0FA04C992799602998',
    1231: '0x5790c3534F30437641541a0FA04C992799602998',
    1230: '0x5790c3534F30437641541a0FA04C992799602998',
    1284: '0x5790c3534F30437641541a0FA04C992799602998',
    1285: '0x5790c3534F30437641541a0FA04C992799602998',
    1287: '0x5790c3534F30437641541a0FA04C992799602998',
    1313161554: '0x5790c3534F30437641541a0FA04C992799602998',
    1313161555: '0x5790c3534F30437641541a0FA04C992799602998',
    288: '0x5790c3534F30437641541a0FA04C992799602998',
    2888: '0x5790c3534F30437641541a0FA04C992799602998',
    100: '0x5790c3534F30437641541a0FA04C992799602998',
    10200: '0x5790c3534F30437641541a0FA04C992799602998',
    1088: '0x5790c3534F30437641541a0FA04C992799602998',
    599: '0x5790c3534F30437641541a0FA04C992799602998',
    40: '0x5790c3534F30437641541a0FA04C992799602998',
    41: '0x5790c3534F30437641541a0FA04C992799602998',
    57: '0x5790c3534F30437641541a0FA04C992799602998',
    5700: '0x5790c3534F30437641541a0FA04C992799602998',
    4689: '0x5790c3534F30437641541a0FA04C992799602998',
    4690: '0x5790c3534F30437641541a0FA04C992799602998',
    336: '0x5790c3534F30437641541a0FA04C992799602998',
    592: '0x5790c3534F30437641541a0FA04C992799602998',
    81: '0x5790c3534F30437641541a0FA04C992799602998',
    122: '0x5790c3534F30437641541a0FA04C992799602998',
    123: '0x5790c3534F30437641541a0FA04C992799602998',
    106: '0x5790c3534F30437641541a0FA04C992799602998',
    111: '0x5790c3534F30437641541a0FA04C992799602998',
    55555: '0x5790c3534F30437641541a0FA04C992799602998',
    55556: '0x5790c3534F30437641541a0FA04C992799602998',
    420420: '0x5790c3534F30437641541a0FA04C992799602998',
    420666: '0x5790c3534F30437641541a0FA04C992799602998',
    88: '0x5790c3534F30437641541a0FA04C992799602998',
    89: '0x5790c3534F30437641541a0FA04C992799602998',
    108: '0x5790c3534F30437641541a0FA04C992799602998',
    18: '0x5790c3534F30437641541a0FA04C992799602998',
    888: '0x5790c3534F30437641541a0FA04C992799602998',
    999: '0x5790c3534F30437641541a0FA04C992799602998',
    30: '0x5790c3534F30437641541a0FA04C992799602998',
    31: '0x5790c3534F30437641541a0FA04C992799602998',
    20: '0x5790c3534F30437641541a0FA04C992799602998',
    21: '0x5790c3534F30437641541a0FA04C992799602998',
    1030: '0x5790c3534F30437641541a0FA04C992799602998',
    71: '0x5790c3534F30437641541a0FA04C992799602998',
    32520: '0x5790c3534F30437641541a0FA04C992799602998',
    64668: '0x5790c3534F30437641541a0FA04C992799602998',
    20402: '0x5790c3534F30437641541a0FA04C992799602998',
    42110: '0x5790c3534F30437641541a0FA04C992799602998',
    7700: '0x5790c3534F30437641541a0FA04C992799602998',
    7701: '0x5790c3534F30437641541a0FA04C992799602998',
    42262: '0x5790c3534F30437641541a0FA04C992799602998',
    42261: '0x5790c3534F30437641541a0FA04C992799602998',
    888888: '0x5790c3534F30437641541a0FA04C992799602998',
    666666: '0x5790c3534F30437641541a0FA04C992799602998',
    1234: '0x5790c3534F30437641541a0FA04C992799602998',
    12345: '0x5790c3534F30437641541a0FA04C992799602998',
    82: '0x5790c3534F30437641541a0FA04C992799602998',
    83: '0x5790c3534F30437641541a0FA04C992799602998',
    71402: '0x5790c3534F30437641541a0FA04C992799602998',
    71401: '0x5790c3534F30437641541a0FA04C992799602998',
    820: '0x5790c3534F30437641541a0FA04C992799602998',
    20729: '0x5790c3534F30437641541a0FA04C992799602998',
    9001: '0x5790c3534F30437641541a0FA04C992799602998',
    9000: '0x5790c3534F30437641541a0FA04C992799602998',
    246: '0x5790c3534F30437641541a0FA04C992799602998',
    73799: '0x5790c3534F30437641541a0FA04C992799602998',
    8453: '0x5790c3534F30437641541a0FA04C992799602998',
    84531: '0x5790c3534F30437641541a0FA04C992799602998',
    2222: '0x5790c3534F30437641541a0FA04C992799602998',
    2221: '0x5790c3534F30437641541a0FA04C992799602998',
    1024: '0x5790c3534F30437641541a0FA04C992799602998',
    1023: '0x5790c3534F30437641541a0FA04C992799602998',
    1131: '0x5790c3534F30437641541a0FA04C992799602998',
    1132: '0x5790c3534F30437641541a0FA04C992799602998',
    1039: '0x5790c3534F30437641541a0FA04C992799602998',
    1038: '0x5790c3534F30437641541a0FA04C992799602998',
    314: '0x5790c3534F30437641541a0FA04C992799602998',
    314159: '0x5790c3534F30437641541a0FA04C992799602998',
    14: '0x5790c3534F30437641541a0FA04C992799602998',
    114: '0x5790c3534F30437641541a0FA04C992799602998',
    841: '0x5790c3534F30437641541a0FA04C992799602998',
    842: '0x5790c3534F30437641541a0FA04C992799602998',
    324: '0x5790c3534F30437641541a0FA04C992799602998',
    280: '0x5790c3534F30437641541a0FA04C992799602998',
    59140: '0x5790c3534F30437641541a0FA04C992799602998',
    59144: '0x5790c3534F30437641541a0FA04C992799602998',
    204: '0x5790c3534F30437641541a0FA04C992799602998',
    5611: '0x5790c3534F30437641541a0FA04C992799602998',
    43288: '0x5790c3534F30437641541a0FA04C992799602998',
    56288: '0x5790c3534F30437641541a0FA04C992799602998',
    199: '0x5790c3534F30437641541a0FA04C992799602998',
    1029: '0x5790c3534F30437641541a0FA04C992799602998',
    256256: '0x5790c3534F30437641541a0FA04C992799602998',
    512512: '0x5790c3534F30437641541a0FA04C992799602998',
    1116: '0x5790c3534F30437641541a0FA04C992799602998',
    1115: '0x5790c3534F30437641541a0FA04C992799602998',
    1818: '0x5790c3534F30437641541a0FA04C992799602998',
    1819: '0x5790c3534F30437641541a0FA04C992799602998',
    44: '0x5790c3534F30437641541a0FA04C992799602998',
    43: '0x5790c3534F30437641541a0FA04C992799602998',
    15: '0x5790c3534F30437641541a0FA04C992799602998',
    13: '0x5790c3534F30437641541a0FA04C992799602998',
    60: '0x5790c3534F30437641541a0FA04C992799602998',
    31337: '0x5790c3534F30437641541a0FA04C992799602998',
    11235: '0x5790c3534F30437641541a0FA04C992799602998',
    54211: '0x5790c3534F30437641541a0FA04C992799602998',
    24: '0x5790c3534F30437641541a0FA04C992799602998',
    242: '0x5790c3534F30437641541a0FA04C992799602998',
    11: '0x5790c3534F30437641541a0FA04C992799602998',
    12: '0x5790c3534F30437641541a0FA04C992799602998',
    11297108109: '0x5790c3534F30437641541a0FA04C992799602998',
    11297108099: '0x5790c3534F30437641541a0FA04C992799602998',
    333999: '0x5790c3534F30437641541a0FA04C992799602998',
    333888: '0x5790c3534F30437641541a0FA04C992799602998',
    27: '0x5790c3534F30437641541a0FA04C992799602998',
    10000: '0x5790c3534F30437641541a0FA04C992799602998',
    10001: '0x5790c3534F30437641541a0FA04C992799602998',
    19: '0x5790c3534F30437641541a0FA04C992799602998',
    7: '0x5790c3534F30437641541a0FA04C992799602998',
    8: '0x5790c3534F30437641541a0FA04C992799602998',
    9: '0x5790c3534F30437641541a0FA04C992799602998',
    100009: '0x5790c3534F30437641541a0FA04C992799602998',
    100010: '0x5790c3534F30437641541a0FA04C992799602998',
    50: '0x5790c3534F30437641541a0FA04C992799602998',
    51: '0x5790c3534F30437641541a0FA04C992799602998',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x5790c3534F30437641541a0FA04C992799602998',
  },
  multiCall: {
    529: '0xcA11bde05977b3631167028862bE2a173976CA11',
    917: '0x7F27A321CA400E8E66E4CE47a663BE381b7C9Ec7',
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    11155111: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    17000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
    137: '0xcA11bde05977b3631167028862bE2a173976CA11',
    80_001: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1101: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1442: '0xcA11bde05977b3631167028862bE2a173976CA11',
    25: '0xcA11bde05977b3631167028862bE2a173976CA11',
    338: '0xcA11bde05977b3631167028862bE2a173976CA11',
    43_114: '0xcA11bde05977b3631167028862bE2a173976CA11',
    43_113: '0xcA11bde05977b3631167028862bE2a173976CA11',
    250: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4002: '0xD3461635A9BEC370F71fd9c3eF6b4b79185AfDfD',
    42220: '0xcA11bde05977b3631167028862bE2a173976CA11',
    44787: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10: '0xcA11bde05977b3631167028862bE2a173976CA11',
    420: '0xcA11bde05977b3631167028862bE2a173976CA11',
    42_161: '0xcA11bde05977b3631167028862bE2a173976CA11',
    421_613: '0xcA11bde05977b3631167028862bE2a173976CA11',
    109: '0xcA11bde05977b3631167028862bE2a173976CA11',
    719: '0xC751d479E574647f0F20B1A3A6f5D220b6Fd7De3',
    369: '0xcA11bde05977b3631167028862bE2a173976CA11',
    943: '0xcA11bde05977b3631167028862bE2a173976CA11',
    20221200000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    202212: '0xD81ad778fb32EA7Bc4C660e3236100c70762CCfB',
    1666600000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1666700000: '0x9622b9DDce346D209246cAcB935C6C1CCad783e5',
    52: '0xcA11bde05977b3631167028862bE2a173976CA11',
    53: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
    2000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    568: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
    131: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    61: '0xcA11bde05977b3631167028862bE2a173976CA11',
    63: '0xcA11bde05977b3631167028862bE2a173976CA11',
    32659: '0xcA11bde05977b3631167028862bE2a173976CA11',
    46688: '0xcA11bde05977b3631167028862bE2a173976CA11',
    128: '0xcA11bde05977b3631167028862bE2a173976CA11',
    256: '0xb44DFD33a02DD87a5A16544F39391deC8DC235a0',
    321: '0xcA11bde05977b3631167028862bE2a173976CA11',
    322: '0xcA11bde05977b3631167028862bE2a173976CA11',
    8217: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1001: '0xD81ad778fb32EA7Bc4C660e3236100c70762CCfB',
    66: '0xcA11bde05977b3631167028862bE2a173976CA11',
    65: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    361: '0xcA11bde05977b3631167028862bE2a173976CA11',
    365: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1231: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1230: '0xdf1d724a7166261eeb015418fe8c7679bbea7fd6',
    1284: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1285: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1287: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1313161554: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1313161555: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
    288: '0xcA11bde05977b3631167028862bE2a173976CA11',
    2888: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    100: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10200: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1088: '0xcA11bde05977b3631167028862bE2a173976CA11',
    599: '0xcA11bde05977b3631167028862bE2a173976CA11',
    40: '0xcA11bde05977b3631167028862bE2a173976CA11',
    41: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
    57: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5700: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4689: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4690: '0xcA11bde05977b3631167028862bE2a173976CA11',
    336: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    592: '0xcA11bde05977b3631167028862bE2a173976CA11',
    81: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    122: '0xcA11bde05977b3631167028862bE2a173976CA11',
    123: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
    106: '0xcA11bde05977b3631167028862bE2a173976CA11',
    111: '0xcA11bde05977b3631167028862bE2a173976CA11',
    55555: '0xcA11bde05977b3631167028862bE2a173976CA11',
    55556: '0xcA11bde05977b3631167028862bE2a173976CA11',
    420420: '0xcA11bde05977b3631167028862bE2a173976CA11',
    420666: '0xcA11bde05977b3631167028862bE2a173976CA11',
    88: '0xcA11bde05977b3631167028862bE2a173976CA11',
    89: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
    108: '0xcA11bde05977b3631167028862bE2a173976CA11',
    18: '0xcA11bde05977b3631167028862bE2a173976CA11',
    888: '0xcA11bde05977b3631167028862bE2a173976CA11',
    999: '0xcA11bde05977b3631167028862bE2a173976CA11',
    30: '0xcA11bde05977b3631167028862bE2a173976CA11',
    31: '0xcA11bde05977b3631167028862bE2a173976CA11',
    20: '0xcA11bde05977b3631167028862bE2a173976CA11',
    21: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1030: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
    71: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
    32520: '0xcA11bde05977b3631167028862bE2a173976CA11',
    64668: '0xcA11bde05977b3631167028862bE2a173976CA11',
    20402: '0xcA11bde05977b3631167028862bE2a173976CA11',
    42110: '0xcA11bde05977b3631167028862bE2a173976CA11',
    7700: '0xcA11bde05977b3631167028862bE2a173976CA11',
    7701: '0xcA11bde05977b3631167028862bE2a173976CA11',
    42262: '0xcA11bde05977b3631167028862bE2a173976CA11',
    42261: '0xcA11bde05977b3631167028862bE2a173976CA11',
    888888: '0xcA11bde05977b3631167028862bE2a173976CA11',
    666666: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1234: '0xcA11bde05977b3631167028862bE2a173976CA11',
    12345: '0xcA11bde05977b3631167028862bE2a173976CA11',
    82: '0xcA11bde05977b3631167028862bE2a173976CA11',
    83: '0xcA11bde05977b3631167028862bE2a173976CA11',
    71402: '0xcA11bde05977b3631167028862bE2a173976CA11',
    71401: '0xcA11bde05977b3631167028862bE2a173976CA11',
    820: '0xcA11bde05977b3631167028862bE2a173976CA11',
    20729: '0xcA11bde05977b3631167028862bE2a173976CA11',
    9001: '0xcA11bde05977b3631167028862bE2a173976CA11',
    9000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    246: '0xcA11bde05977b3631167028862bE2a173976CA11',
    73799: '0xcA11bde05977b3631167028862bE2a173976CA11',
    8453: '0xcA11bde05977b3631167028862bE2a173976CA11',
    84531: '0xA46704f14Ed42D5fC539A762CF411aE963DED99e',
    2222: '0xcA11bde05977b3631167028862bE2a173976CA11',
    2221: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
    9992: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1023: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1131: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1132: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1039: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1038: '0xcA11bde05977b3631167028862bE2a173976CA11',
    314: '0xcA11bde05977b3631167028862bE2a173976CA11',
    314_159: '0xcA11bde05977b3631167028862bE2a173976CA11',
    14: '0xcA11bde05977b3631167028862bE2a173976CA11',
    114: '0xcA11bde05977b3631167028862bE2a173976CA11',
    841: '0xcA11bde05977b3631167028862bE2a173976CA11',
    842: '0xcA11bde05977b3631167028862bE2a173976CA11',
    324: '0xcA11bde05977b3631167028862bE2a173976CA11',
    280: '0xcA11bde05977b3631167028862bE2a173976CA11',
    59140: '0xcA11bde05977b3631167028862bE2a173976CA11',
    59144: '0xcA11bde05977b3631167028862bE2a173976CA11',
    204: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5611: '0xcA11bde05977b3631167028862bE2a173976CA11',
    43288: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56288: '0xcA11bde05977b3631167028862bE2a173976CA11',
    199: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1029: '0x35060f7803eF7763b77E4EF0082bc0bCf2654154',
    256256: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
    512512: '0x35060f7803ef7763b77e4ef0082bc0bcf2654154',
    1116: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    1115: '0xDf1D724A7166261eEB015418fe8c7679BBEa7fd6',
    1818: '0xcA11bde05977b3631167028862bE2a173976CA11',
    1819: '0xcA11bde05977b3631167028862bE2a173976CA11',
    44: '0xcA11bde05977b3631167028862bE2a173976CA11',
    43: '0xcA11bde05977b3631167028862bE2a173976CA11',
    15: '0xcA11bde05977b3631167028862bE2a173976CA11',
    13: '0xcA11bde05977b3631167028862bE2a173976CA11',
    60: '0xcA11bde05977b3631167028862bE2a173976CA11',
    31337: '0xcA11bde05977b3631167028862bE2a173976CA11',
    11235: '0xcA11bde05977b3631167028862bE2a173976CA11',
    54211: '0xcA11bde05977b3631167028862bE2a173976CA11',
    24: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    242: '0xcA11bde05977b3631167028862bE2a173976CA11',
    11: '0xcA11bde05977b3631167028862bE2a173976CA11',
    12: '0xcA11bde05977b3631167028862bE2a173976CA11',
    11297108109: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    11297108099: '0x0216a640C4d53F2a6603042d4E14A2B890efcD45',
    333999: '0xcA11bde05977b3631167028862bE2a173976CA11',
    333888: '0xcA11bde05977b3631167028862bE2a173976CA11',
    27: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    10001: '0xcA11bde05977b3631167028862bE2a173976CA11',
    19: '0xcA11bde05977b3631167028862bE2a173976CA11',
    7: '0xcA11bde05977b3631167028862bE2a173976CA11',
    8: '0xcA11bde05977b3631167028862bE2a173976CA11',
    9: '0xcA11bde05977b3631167028862bE2a173976CA11',
    100009: '0xcA11bde05977b3631167028862bE2a173976CA11',
    100010: '0xcA11bde05977b3631167028862bE2a173976CA11',
    50: '0xcA11bde05977b3631167028862bE2a173976CA11',
    51: '0x0216a640c4d53f2a6603042d4e14a2b890efcd45',
    534352: '0xcA11bde05977b3631167028862bE2a173976CA11',
    534351: '0xcA11bde05977b3631167028862bE2a173976CA11',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0xcA11bde05977b3631167028862bE2a173976CA11',
    55: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  donaswapProfile: {
    529: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    917: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4: '0x',
    1: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    5: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11155111: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    17000: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    137: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    80002: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    338: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43114: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43113: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    250: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4002: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42220: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    44787: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42161: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    421613: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    109: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    719: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    369: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    943: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20221200000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    202212: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1666600000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1666700000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    52: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    53: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    568: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    63: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    32659: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    46688: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    128: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    256: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    321: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    322: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8217: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    66: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    65: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    361: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    365: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1231: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1230: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1284: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1285: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1287: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1313161554: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1313161555: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10200: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1088: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    599: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    40: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    41: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    57: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    5700: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4689: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4690: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    336: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    592: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    81: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    122: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    123: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    106: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    111: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    55555: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    55556: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420420: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420666: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    88: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    89: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    108: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    18: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    999: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    30: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    31: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    21: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1030: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    32520: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    64668: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20402: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42110: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7700: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7701: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42262: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42261: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    888888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    666666: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1234: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    12345: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    82: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    83: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71402: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71401: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    820: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20729: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    246: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    73799: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8453: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    84531: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2222: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2221: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1024: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1023: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1131: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1132: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1039: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1038: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    314: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    314159: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    14: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    114: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    841: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    842: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    324: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    280: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    59140: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    59144: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    204: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    5611: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    56288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    199: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1029: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    256256: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    512512: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1116: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1115: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1818: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1819: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    44: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    15: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    13: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    60: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    31337: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11235: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    54211: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    24: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    242: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    12: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11297108109: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11297108099: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    333999: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    333888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    27: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    19: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100009: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100010: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    50: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    51: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  donaswapFlammies: {
    529: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    917: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    17000: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    137: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    80002: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    338: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43114: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43113: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    250: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4002: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42220: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    44787: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42161: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    421613: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    109: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    719: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    369: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    943: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20221200000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    202212: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1666600000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1666700000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    52: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    53: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    568: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    63: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    32659: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    46688: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    128: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    256: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    321: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    322: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8217: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    66: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    65: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    361: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    365: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1231: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1230: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1284: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1285: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1287: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1313161554: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1313161555: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10200: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1088: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    599: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    40: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    41: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    57: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    5700: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4689: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    4690: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    336: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    592: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    81: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    122: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    123: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    106: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    111: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    55555: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    55556: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420420: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    420666: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    88: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    89: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    108: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    18: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    999: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    30: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    31: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    21: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1030: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    32520: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    64668: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20402: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42110: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7700: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7701: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42262: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    42261: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    888888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    666666: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1234: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    12345: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    82: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    83: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71402: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    71401: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    820: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    20729: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    246: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    73799: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8453: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    84531: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2222: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    2221: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1024: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1023: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1131: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1132: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1039: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1038: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    314: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    314159: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    14: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    114: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    841: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    842: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    324: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    280: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    59140: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    59144: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    204: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    5611: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    56288: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    199: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1029: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    256256: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    512512: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1116: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1115: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1818: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    1819: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    44: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    43: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    15: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    13: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    60: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    31337: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11235: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    54211: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    24: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    242: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    12: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11297108109: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    11297108099: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    333999: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    333888: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    27: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10000: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    10001: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    19: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    7: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    8: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    9: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100009: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    100010: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    50: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    51: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  bunnyFactory: {
    529: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    917: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    17000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    137: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    80002: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    338: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    43114: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    43113: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    250: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    4002: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    42220: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    44787: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    10: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    420: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    42161: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    421613: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    109: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    719: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    369: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    943: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    20221200000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    202212: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1666600000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1666700000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    52: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    53: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    2000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    568: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    63: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    32659: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    46688: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    128: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    256: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    321: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    322: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    8217: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1001: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    66: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    65: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    361: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    365: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1231: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1230: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1284: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1285: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1287: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1313161554: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1313161555: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    288: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    2888: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    100: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    10200: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1088: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    599: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    40: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    41: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    57: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5700: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    4689: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    4690: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    336: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    592: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    81: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    122: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    123: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    106: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    111: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    55555: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    55556: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    420420: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    420666: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    88: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    89: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    108: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    18: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    888: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    999: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    30: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    31: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    20: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    21: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1030: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    71: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    32520: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    64668: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    20402: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    42110: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    7700: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    7701: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    42262: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    42261: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    888888: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    666666: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1234: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    12345: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    82: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    83: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    71402: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    71401: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    820: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    20729: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    9001: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    9000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    246: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    73799: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    8453: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    84531: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    2222: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    2221: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1024: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1023: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1131: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1132: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1039: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1038: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    314: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    314159: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    14: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    114: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    841: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    842: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    324: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    280: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    59140: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    59144: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    43288: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    56288: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    199: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1029: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    256256: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    512512: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1116: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1115: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1818: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    1819: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    44: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    43: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    15: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    13: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    60: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    31337: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    11235: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    54211: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    24: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    242: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    11: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    12: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    11297108109: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    11297108099: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    333999: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    333888: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    27: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    10000: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    10001: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    19: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    7: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    8: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    9: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    100009: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    100010: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    50: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    51: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    529: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    917: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    17000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '0x',
    137: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    80002: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    338: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    43114: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    43113: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    250: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    4002: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    42220: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    44787: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    10: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    420: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    42161: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    421613: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    109: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    719: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    369: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    943: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    20221200000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    202212: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1666600000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1666700000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    52: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    53: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    2000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    568: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    63: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    32659: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    46688: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    128: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    256: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    321: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    322: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    8217: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1001: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    66: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    65: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    361: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    365: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1231: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1230: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1284: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1285: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1287: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1313161554: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1313161555: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    288: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    2888: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    100: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    10200: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1088: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    599: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    40: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    41: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    57: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    5700: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    4689: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    4690: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    336: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    592: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    81: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    122: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    123: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    106: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    111: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    55555: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    55556: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    420420: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    420666: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    88: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    89: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    108: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    18: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    888: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    999: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    30: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    31: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    20: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    21: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1030: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    71: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    32520: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    64668: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    20402: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    42110: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    7700: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    7701: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    42262: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    42261: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    888888: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    666666: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1234: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    12345: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    82: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    83: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    71402: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    71401: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    820: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    20729: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    9001: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    9000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    246: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    73799: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    8453: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    84531: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    2222: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    2221: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1024: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1023: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1131: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1132: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1039: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1038: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    314: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    314159: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    14: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    114: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    841: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    842: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    324: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    280: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    59140: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    59144: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    43288: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    56288: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    199: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1029: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    256256: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    512512: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1116: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1115: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1818: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    1819: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    44: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    43: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    15: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    13: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    60: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    31337: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    11235: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    54211: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    24: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    242: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    11: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    12: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    11297108109: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    11297108099: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    333999: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    333888: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    27: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    10000: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    10001: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    19: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    7: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    8: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    9: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    100009: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    100010: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    50: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    51: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
  },
  pointCenterIfo: {
    529: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    917: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    17000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    137: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    80002: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    338: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    43114: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    43113: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    250: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    4002: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    42220: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    44787: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    10: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    420: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    42161: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    421613: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    109: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    719: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    369: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    943: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    20221200000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    202212: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1666600000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1666700000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    52: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    53: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    2000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    568: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    63: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    32659: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    46688: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    128: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    256: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    321: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    322: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    8217: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1001: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    66: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    65: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    361: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    365: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1231: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1230: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1284: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1285: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1287: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1313161554: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1313161555: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    288: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    2888: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    100: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    10200: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1088: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    599: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    40: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    41: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    57: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    5700: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    4689: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    4690: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    336: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    592: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    81: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    122: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    123: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    106: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    111: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    55555: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    55556: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    420420: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    420666: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    88: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    89: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    108: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    18: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    888: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    999: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    30: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    31: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    20: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    21: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1030: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    71: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    32520: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    64668: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    20402: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    42110: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    7700: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    7701: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    42262: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    42261: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    888888: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    666666: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1234: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    12345: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    82: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    83: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    71402: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    71401: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    820: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    20729: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    9001: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    9000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    246: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    73799: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    8453: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    84531: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    2222: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    2221: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1024: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1023: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1131: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1132: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1039: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1038: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    314: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    314159: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    14: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    114: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    841: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    842: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    324: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    280: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    59140: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    59144: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    43288: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    56288: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    199: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1029: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    256256: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    512512: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1116: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1115: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1818: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    1819: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    44: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    43: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    15: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    13: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    60: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    31337: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    11235: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    54211: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    24: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    242: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    11: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    12: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    11297108109: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    11297108099: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    333999: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    333888: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    27: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    10000: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    10001: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    19: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    7: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    8: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    9: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    100009: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    100010: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    50: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    51: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    529: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    917: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    17000: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    137: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    80002: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    338: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43113: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    250: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    4002: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    42220: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44787: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    420: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    42161: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    421613: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    719: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    369: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    943: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    20221200000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    202212: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1666600000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1666700000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    52: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    53: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    2000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    568: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    63: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    32659: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    46688: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    128: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    321: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    322: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8217: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    66: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    65: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    361: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    365: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1231: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1230: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1284: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1285: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1287: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1313161554: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1313161555: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    2888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10200: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1088: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    599: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    40: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    41: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    57: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    5700: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    4689: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    4690: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    336: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    592: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    81: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    122: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    123: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    106: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    111: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    55555: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    55556: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    420420: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    420666: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    88: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    89: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    108: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    18: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    30: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    20: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    21: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1030: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    71: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    32520: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    64668: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    20402: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    42110: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7700: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7701: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    42262: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    42261: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    888888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    666666: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1234: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12345: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    82: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    83: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    71402: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    71401: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    820: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    20729: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    246: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    73799: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8453: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    84531: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    2222: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    2221: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1024: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1023: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1131: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1132: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1039: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1038: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    314: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    314159: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    14: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    114: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    841: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    842: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    324: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    280: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    529: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    917: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    17000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    137: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    80002: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    338: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    43114: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    43113: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    250: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    4002: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    42220: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    44787: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    10: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    420: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    42161: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    421613: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    109: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    719: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    369: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    943: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    20221200000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    202212: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1666600000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1666700000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    52: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    53: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    2000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    568: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    63: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    32659: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    46688: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    128: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    256: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    321: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    322: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    8217: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1001: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    66: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    65: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    361: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    365: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1231: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1230: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1284: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1285: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1287: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1313161554: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1313161555: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    288: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    2888: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    100: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    10200: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1088: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    599: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    40: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    41: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    57: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    5700: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    4689: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    4690: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    336: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    592: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    81: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    122: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    123: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    106: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    111: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    55555: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    55556: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    420420: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    420666: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    88: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    89: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    108: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    18: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    888: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    999: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    30: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    31: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    20: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    21: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1030: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    71: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    32520: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    64668: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    20402: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    42110: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    7700: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    7701: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    42262: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    42261: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    888888: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    666666: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1234: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    12345: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    82: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    83: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    71402: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    71401: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    820: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    20729: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    9001: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    9000: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    246: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    73799: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    8453: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    84531: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    2222: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    2221: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1024: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1023: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1131: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1132: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1039: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    1038: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    314: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    314159: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    14: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    114: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    841: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    842: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    324: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    280: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionFanToken: {
    529: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    917: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    17000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '0x',
    137: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    80002: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    338: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    43114: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    43113: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    250: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    4002: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    42220: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    44787: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    10: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    420: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    42161: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    421613: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    109: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    719: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    369: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    943: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    20221200000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    202212: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1666600000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1666700000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    52: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    53: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    2000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    568: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    63: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    32659: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    46688: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    128: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    256: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    321: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    322: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    8217: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1001: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    66: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    65: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    361: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    365: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1231: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1230: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1284: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1285: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1287: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1313161554: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1313161555: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    288: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    2888: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    100: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    10200: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1088: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    599: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    40: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    41: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    57: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    5700: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    4689: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    4690: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    336: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    592: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    81: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    122: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    123: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    106: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    111: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    55555: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    55556: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    420420: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    420666: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    88: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    89: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    108: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    18: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    888: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    999: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    30: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    31: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    20: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    21: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1030: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    71: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    32520: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    64668: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    20402: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    42110: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    7700: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    7701: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    42262: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    42261: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    888888: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    666666: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1234: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    12345: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    82: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    83: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    71402: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    71401: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    820: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    20729: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    9001: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    9000: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    246: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    73799: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    8453: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    84531: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    2222: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    2221: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1024: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1023: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1131: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1132: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1039: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    1038: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    314: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    314159: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    14: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    114: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    841: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    842: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    324: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    280: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionMobox: {
    529: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    917: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    17000: '0x',
    97: '0x',
    137: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    80002: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    338: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    43114: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    43113: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    250: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    4002: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    42220: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    44787: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    10: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    420: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    42161: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    421613: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    109: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    719: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    369: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    943: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    20221200000: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    202212: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1666600000: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1666700000: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    52: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    53: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    2000: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    568: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    63: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    32659: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    46688: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    128: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    256: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    321: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    322: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    8217: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1001: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    66: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    65: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    361: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    365: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1231: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1230: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1284: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1285: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1287: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1313161554: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1313161555: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    288: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    2888: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    100: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    10200: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1088: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    599: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    40: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    41: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    57: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    5700: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    4689: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    4690: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    336: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    592: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    81: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    122: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    123: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    106: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    111: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    55555: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    55556: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    420420: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    420666: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    88: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    89: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    108: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    18: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    888: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    999: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    30: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    31: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    20: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    21: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1030: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    71: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    32520: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    64668: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    20402: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    42110: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    7700: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    7701: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    42262: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    42261: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    888888: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    666666: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1234: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    12345: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    82: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    83: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    71402: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    71401: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    820: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    20729: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    9001: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    9000: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    246: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    73799: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    8453: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    84531: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    2222: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    2221: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1024: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1023: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1131: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1132: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1039: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    1038: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    314: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    314159: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    14: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    114: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    841: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    842: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    324: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    280: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionMoD: {
    529: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    917: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    17000: '0x',
    97: '0x',
    137: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    80002: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    338: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    43114: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    43113: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    250: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    4002: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    42220: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    44787: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    10: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    420: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    42161: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    421613: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    109: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    719: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    369: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    943: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    20221200000: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    202212: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1666600000: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1666700000: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    52: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    53: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    2000: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    568: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    63: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    32659: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    46688: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    128: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    256: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    321: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    322: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    8217: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1001: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    66: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    65: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    361: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    365: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1231: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1230: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1284: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1285: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1287: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1313161554: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1313161555: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    288: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    2888: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    100: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    10200: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1088: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    599: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    40: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    41: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    57: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    5700: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    4689: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    4690: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    336: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    592: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    81: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    122: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    123: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    106: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    111: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    55555: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    55556: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    420420: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    420666: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    88: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    89: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    108: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    18: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    888: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    999: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    30: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    31: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    20: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    21: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1030: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    71: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    32520: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    64668: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    20402: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    42110: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    7700: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    7701: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    42262: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    42261: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    888888: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    666666: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1234: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    12345: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    82: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    83: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    71402: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    71401: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    820: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    20729: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    9001: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    9000: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    246: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    73799: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    8453: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    84531: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    2222: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    2221: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1024: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1023: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1131: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1132: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1039: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    1038: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    314: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    314159: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    14: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    114: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    841: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    842: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    324: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    280: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  easterNft: {
    529: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    917: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    17000: '0x',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    137: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    80002: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    338: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    43114: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    43113: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    250: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    4002: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    42220: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    44787: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    10: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    420: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    42161: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    421613: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    109: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    719: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    369: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    943: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    20221200000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    202212: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1666600000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1666700000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    52: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    53: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    2000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    568: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    63: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    32659: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    46688: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    128: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    256: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    321: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    322: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    8217: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1001: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    66: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    65: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    361: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    365: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1231: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1230: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1284: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1285: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1287: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1313161554: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1313161555: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    288: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    2888: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    100: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    10200: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1088: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    599: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    40: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    41: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    57: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    5700: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    4689: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    4690: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    336: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    592: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    81: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    122: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    123: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    106: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    111: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    55555: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    55556: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    420420: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    420666: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    88: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    89: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    108: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    18: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    888: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    999: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    30: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    31: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    20: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    21: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1030: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    71: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    32520: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    64668: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    20402: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    42110: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    7700: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    7701: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    42262: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    42261: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    888888: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    666666: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1234: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    12345: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    82: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    83: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    71402: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    71401: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    820: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    20729: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    9001: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    9000: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    246: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    73799: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    8453: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    84531: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    2222: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    2221: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1024: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1023: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1131: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1132: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1039: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    1038: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    314: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    314159: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    14: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    114: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    841: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    842: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    324: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    280: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  flameVault: {
    529: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    917: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    17000: '0x',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    137: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    80002: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    338: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    43114: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    43113: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    250: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    4002: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    42220: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    44787: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    10: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    420: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    42161: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    421613: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    109: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    719: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    369: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    943: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    20221200000: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    202212: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1666600000: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1666700000: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    52: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    53: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    2000: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    568: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    63: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    32659: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    46688: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    128: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    256: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    321: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    322: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    8217: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1001: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    66: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    65: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    361: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    365: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1231: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1230: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1284: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1285: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1287: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1313161554: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1313161555: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    288: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    2888: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    100: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    10200: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1088: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    599: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    40: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    41: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    57: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    5700: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    4689: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    4690: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    336: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    592: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    81: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    122: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    123: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    106: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    111: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    55555: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    55556: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    420420: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    420666: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    88: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    89: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    108: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    18: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    888: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    999: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    30: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    31: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    20: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    21: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1030: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    71: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    32520: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    64668: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    20402: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    42110: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    7700: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    7701: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    42262: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    42261: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    888888: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    666666: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1234: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    12345: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    82: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    83: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    71402: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    71401: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    820: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    20729: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    9001: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    9000: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    246: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    73799: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    8453: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    84531: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    2222: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    2221: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1024: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1023: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1131: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1132: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1039: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    1038: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    314: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    314159: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    14: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    114: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    841: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    842: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    324: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    280: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  flameFlexibleSideVault: {
    529: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    917: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    17000: '0x',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    137: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    80002: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    338: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    43114: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    43113: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    250: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    4002: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    42220: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    44787: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    10: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    420: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    42161: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    421613: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    109: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    719: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    369: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    943: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    20221200000: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    202212: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1666600000: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1666700000: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    52: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    53: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    2000: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    568: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    63: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    32659: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    46688: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    128: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    256: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    321: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    322: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    8217: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1001: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    66: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    65: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    361: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    365: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1231: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1230: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1284: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1285: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1287: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1313161554: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1313161555: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    288: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    2888: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    100: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    10200: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1088: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    599: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    40: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    41: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    57: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    5700: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    4689: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    4690: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    336: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    592: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    81: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    122: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    123: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    106: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    111: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    55555: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    55556: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    420420: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    420666: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    88: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    89: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    108: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    18: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    888: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    999: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    30: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    31: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    20: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    21: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1030: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    71: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    32520: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    64668: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    20402: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    42110: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    7700: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    7701: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    42262: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    42261: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    888888: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    666666: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1234: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    12345: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    82: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    83: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    71402: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    71401: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    820: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    20729: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    9001: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    9000: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    246: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    73799: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    8453: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    84531: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    2222: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    2221: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1024: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1023: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1131: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1132: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1039: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    1038: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    314: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    314159: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    14: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    114: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    841: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    842: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    324: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    280: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  predictionsBNB: {
    529: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    917: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    17000: '0x',
    97: '0x',
    137: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    80002: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    338: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    43114: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    43113: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    250: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    4002: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    42220: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    44787: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    10: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    420: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    42161: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    421613: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    109: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    719: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    369: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    943: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    20221200000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    202212: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1666600000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1666700000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    52: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    53: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    2000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    568: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    63: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    32659: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    46688: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    128: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    256: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    321: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    322: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    8217: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1001: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    66: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    65: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    361: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    365: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1231: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1230: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1284: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1285: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1287: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1313161554: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1313161555: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    288: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    2888: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    100: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    10200: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1088: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    599: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    40: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    41: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    57: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    5700: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    4689: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    4690: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    336: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    592: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    81: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    122: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    123: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    106: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    111: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    55555: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    55556: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    420420: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    420666: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    88: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    89: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    108: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    18: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    888: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    999: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    30: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    31: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    20: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    21: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1030: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    71: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    32520: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    64668: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    20402: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    42110: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    7700: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    7701: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    42262: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    42261: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    888888: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    666666: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1234: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    12345: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    82: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    83: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    71402: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    71401: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    820: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    20729: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    9001: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    9000: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    246: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    73799: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    8453: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    84531: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    2222: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    2221: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1024: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1023: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1131: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1132: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1039: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    1038: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    314: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    314159: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    14: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    114: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    841: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    842: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    324: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    280: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  predictionsFLAME: {
    529: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    917: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    17000: '0x',
    97: '0x',
    137: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    80002: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    338: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    43114: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    43113: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    250: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    4002: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    42220: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    44787: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    10: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    420: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    42161: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    421613: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    109: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    719: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    369: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    943: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    20221200000: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    202212: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1666600000: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1666700000: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    52: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    53: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    2000: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    568: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    63: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    32659: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    46688: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    128: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    256: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    321: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    322: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    8217: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1001: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    66: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    65: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    361: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    365: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1231: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1230: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1284: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1285: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1287: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1313161554: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1313161555: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    288: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    2888: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    100: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    10200: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1088: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    599: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    40: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    41: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    57: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    5700: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    4689: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    4690: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    336: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    592: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    81: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    122: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    123: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    106: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    111: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    55555: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    55556: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    420420: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    420666: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    88: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    89: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    108: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    18: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    888: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    999: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    30: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    31: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    20: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    21: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1030: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    71: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    32520: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    64668: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    20402: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    42110: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    7700: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    7701: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    42262: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    42261: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    888888: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    666666: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1234: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    12345: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    82: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    83: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    71402: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    71401: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    820: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    20729: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    9001: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    9000: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    246: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    73799: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    8453: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    84531: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    2222: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    2221: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1024: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1023: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1131: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1132: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1039: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    1038: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    314: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    314159: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    14: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    114: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    841: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    842: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    324: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    280: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  chainlinkOracleBNB: {
    529: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    917: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    17000: '0x',
    97: '0x',
    137: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    80002: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    338: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    43114: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    43113: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    250: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    4002: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    42220: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    44787: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    10: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    420: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    42161: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    421613: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    109: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    719: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    369: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    943: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    20221200000: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    202212: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1666600000: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1666700000: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    52: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    53: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    2000: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    568: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    63: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    32659: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    46688: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    128: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    256: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    321: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    322: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    8217: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1001: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    66: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    65: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    361: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    365: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1231: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1230: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1284: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1285: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1287: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1313161554: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1313161555: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    288: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    2888: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    100: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    10200: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1088: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    599: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    40: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    41: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    57: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    5700: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    4689: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    4690: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    336: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    592: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    81: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    122: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    123: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    106: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    111: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    55555: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    55556: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    420420: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    420666: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    88: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    89: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    108: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    18: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    888: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    999: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    30: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    31: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    20: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    21: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1030: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    71: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    32520: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    64668: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    20402: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    42110: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    7700: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    7701: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    42262: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    42261: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    888888: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    666666: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1234: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    12345: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    82: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    83: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    71402: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    71401: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    820: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    20729: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    9001: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    9000: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    246: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    73799: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    8453: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    84531: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    2222: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    2221: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1024: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1023: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1131: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1132: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1039: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    1038: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    314: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    314159: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    14: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    114: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    841: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    842: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    324: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    280: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  chainlinkOracleFLAME: {
    529: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    917: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    17000: '0x',
    97: '0x',
    137: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    80002: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    338: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    43114: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    43113: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    250: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    4002: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    42220: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    44787: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    10: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    420: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    42161: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    421613: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    109: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    719: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    369: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    943: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    20221200000: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    202212: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1666600000: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1666700000: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    52: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    53: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    2000: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    568: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    63: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    32659: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    46688: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    128: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    256: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    321: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    322: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    8217: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1001: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    66: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    65: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    361: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    365: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1231: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1230: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1284: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1285: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1287: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1313161554: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1313161555: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    288: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    2888: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    100: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    10200: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1088: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    599: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    40: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    41: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    57: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    5700: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    4689: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    4690: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    336: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    592: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    81: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    122: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    123: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    106: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    111: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    55555: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    55556: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    420420: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    420666: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    88: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    89: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    108: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    18: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    888: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    999: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    30: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    31: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    20: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    21: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1030: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    71: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    32520: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    64668: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    20402: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    42110: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    7700: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    7701: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    42262: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    42261: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    888888: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    666666: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1234: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    12345: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    82: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    83: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    71402: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    71401: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    820: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    20729: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    9001: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    9000: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    246: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    73799: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    8453: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    84531: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    2222: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    2221: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1024: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1023: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1131: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1132: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1039: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    1038: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    314: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    314159: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    14: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    114: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    841: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    842: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    324: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    280: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  predictionsV1: {
    529: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    917: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    17000: '0x',
    97: '0x',
    137: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    80002: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    338: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    43114: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    43113: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    250: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    4002: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    42220: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    44787: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    10: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    420: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    42161: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    421613: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    109: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    719: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    369: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    943: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    20221200000: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    202212: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1666600000: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1666700000: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    52: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    53: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    2000: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    568: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    63: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    32659: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    46688: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    128: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    256: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    321: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    322: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    8217: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1001: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    66: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    65: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    361: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    365: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1231: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1230: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1284: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1285: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1287: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1313161554: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1313161555: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    288: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    2888: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    100: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    10200: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1088: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    599: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    40: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    41: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    57: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    5700: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    4689: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    4690: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    336: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    592: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    81: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    122: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    123: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    106: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    111: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    55555: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    55556: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    420420: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    420666: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    88: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    89: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    108: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    18: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    888: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    999: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    30: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    31: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    20: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    21: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1030: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    71: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    32520: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    64668: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    20402: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    42110: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    7700: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    7701: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    42262: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    42261: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    888888: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    666666: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1234: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    12345: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    82: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    83: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    71402: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    71401: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    820: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    20729: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    9001: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    9000: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    246: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    73799: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    8453: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    84531: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    2222: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    2221: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1024: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1023: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1131: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1132: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1039: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    1038: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    314: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    314159: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    14: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    114: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    841: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    842: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    324: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    280: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecialFlameVault: {
    529: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    917: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    17000: '0x',
    97: '0x',
    137: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    80002: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    338: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    43114: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    43113: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    250: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    4002: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    42220: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    44787: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    10: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    420: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    42161: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    421613: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    109: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    719: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    369: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    943: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    20221200000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    202212: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1666600000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1666700000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    52: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    53: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    2000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    568: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    63: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    32659: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    46688: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    128: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    256: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    321: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    322: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    8217: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1001: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    66: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    65: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    361: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    365: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1231: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1230: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1284: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1285: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1287: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1313161554: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1313161555: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    288: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    2888: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    100: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    10200: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1088: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    599: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    40: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    41: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    57: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    5700: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    4689: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    4690: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    336: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    592: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    81: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    122: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    123: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    106: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    111: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    55555: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    55556: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    420420: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    420666: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    88: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    89: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    108: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    18: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    888: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    999: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    30: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    31: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    20: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    21: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1030: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    71: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    32520: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    64668: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    20402: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    42110: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    7700: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    7701: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    42262: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    42261: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    888888: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    666666: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1234: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    12345: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    82: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    83: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    71402: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    71401: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    820: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    20729: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    9001: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    9000: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    246: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    73799: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    8453: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    84531: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    2222: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    2221: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1024: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1023: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1131: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1132: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1039: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    1038: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    314: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    314159: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    14: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    114: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    841: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    842: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    324: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    280: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecialPrediction: {
    529: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    917: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    17000: '0x',
    97: '0x',
    137: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    80002: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    338: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    43114: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    43113: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    250: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    4002: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    42220: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    44787: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    10: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    420: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    42161: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    421613: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    109: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    719: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    369: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    943: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    20221200000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    202212: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1666600000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1666700000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    52: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    53: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    2000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    568: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    63: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    32659: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    46688: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    128: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    256: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    321: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    322: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    8217: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1001: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    66: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    65: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    361: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    365: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1231: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1230: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1284: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1285: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1287: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1313161554: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1313161555: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    288: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    2888: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    100: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    10200: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1088: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    599: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    40: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    41: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    57: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    5700: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    4689: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    4690: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    336: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    592: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    81: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    122: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    123: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    106: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    111: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    55555: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    55556: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    420420: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    420666: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    88: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    89: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    108: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    18: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    888: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    999: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    30: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    31: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    20: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    21: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1030: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    71: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    32520: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    64668: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    20402: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    42110: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    7700: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    7701: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    42262: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    42261: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    888888: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    666666: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1234: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    12345: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    82: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    83: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    71402: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    71401: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    820: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    20729: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    9001: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    9000: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    246: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    73799: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    8453: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    84531: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    2222: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    2221: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1024: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1023: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1131: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1132: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1039: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    1038: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    314: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    314159: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    14: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    114: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    841: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    842: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    324: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    280: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecialLottery: {
    529: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    917: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    17000: '0x',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    137: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    80002: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    338: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    43114: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    43113: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    250: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    4002: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    42220: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    44787: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    10: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    420: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    42161: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    421613: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    109: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    719: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    369: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    943: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    20221200000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    202212: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1666600000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1666700000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    52: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    53: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    2000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    568: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    63: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    32659: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    46688: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    128: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    256: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    321: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    322: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    8217: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1001: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    66: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    65: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    361: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    365: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1231: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1230: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1284: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1285: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1287: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1313161554: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1313161555: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    288: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    2888: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    100: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    10200: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1088: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    599: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    40: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    41: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    57: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    5700: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    4689: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    4690: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    336: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    592: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    81: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    122: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    123: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    106: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    111: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    55555: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    55556: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    420420: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    420666: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    88: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    89: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    108: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    18: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    888: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    999: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    30: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    31: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    20: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    21: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1030: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    71: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    32520: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    64668: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    20402: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    42110: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    7700: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    7701: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    42262: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    42261: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    888888: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    666666: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1234: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    12345: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    82: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    83: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    71402: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    71401: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    820: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    20729: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    9001: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    9000: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    246: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    73799: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    8453: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    84531: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    2222: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    2221: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1024: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1023: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1131: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1132: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1039: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    1038: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    314: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    314159: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    14: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    114: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    841: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    842: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    324: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    280: '0x382cB497110F398F0f152cae82821476AE51c9cF',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bunnySpecialXmas: {
    529: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    917: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    17000: '0x',
    97: '0x',
    137: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    80002: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    338: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    43114: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    43113: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    250: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    4002: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    42220: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    44787: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    10: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    420: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    42161: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    421613: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    109: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    719: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    369: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    943: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    20221200000: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    202212: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1666600000: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1666700000: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    52: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    53: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    2000: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    568: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    63: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    32659: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    46688: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    128: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    256: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    321: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    322: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    8217: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1001: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    66: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    65: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    361: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    365: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1231: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1230: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1284: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1285: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1287: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1313161554: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1313161555: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    288: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    2888: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    100: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    10200: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1088: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    599: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    40: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    41: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    57: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    5700: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    4689: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    4690: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    336: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    592: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    81: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    122: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    123: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    106: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    111: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    55555: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    55556: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    420420: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    420666: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    88: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    89: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    108: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    18: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    888: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    999: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    30: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    31: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    20: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    21: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1030: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    71: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    32520: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    64668: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    20402: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    42110: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    7700: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    7701: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    42262: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    42261: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    888888: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    666666: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1234: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    12345: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    82: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    83: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    71402: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    71401: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    820: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    20729: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    9001: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    9000: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    246: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    73799: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    8453: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    84531: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    2222: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    2221: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1024: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1023: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1131: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1132: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1039: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    1038: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    314: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    314159: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    14: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    114: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    841: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    842: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    324: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    280: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  farmAuction: {
    529: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    917: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    17000: '0x',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    137: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    80002: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    338: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    43114: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    43113: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    250: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    4002: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    42220: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    44787: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    10: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    420: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    42161: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    421613: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    109: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    719: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    369: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    943: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    20221200000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    202212: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1666600000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1666700000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    52: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    53: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    2000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    568: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    63: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    32659: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    46688: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    128: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    256: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    321: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    322: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    8217: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1001: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    66: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    65: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    361: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    365: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1231: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1230: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1284: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1285: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1287: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1313161554: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1313161555: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    288: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    2888: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    100: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    10200: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1088: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    599: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    40: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    41: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    57: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    5700: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    4689: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    4690: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    336: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    592: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    81: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    122: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    123: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    106: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    111: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    55555: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    55556: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    420420: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    420666: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    88: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    89: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    108: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    18: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    888: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    999: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    30: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    31: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    20: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    21: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1030: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    71: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    32520: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    64668: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    20402: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    42110: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    7700: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    7701: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    42262: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    42261: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    888888: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    666666: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1234: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    12345: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    82: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    83: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    71402: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    71401: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    820: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    20729: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    9001: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    9000: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    246: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    73799: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    8453: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    84531: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    2222: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    2221: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1024: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1023: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1131: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1132: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1039: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    1038: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    314: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    314159: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    14: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    114: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    841: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    842: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    324: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    280: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  AnniversaryAchievement: {
    529: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    917: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
    17000: '0x',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    137: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    80002: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    338: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    43114: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    43113: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    250: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    4002: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    42220: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    44787: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    10: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    420: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    42161: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    421613: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    109: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    719: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    369: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    943: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    20221200000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    202212: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1666600000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1666700000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    52: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    53: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    2000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    568: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    63: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    32659: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    46688: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    128: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    256: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    321: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    322: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    8217: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1001: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    66: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    65: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    361: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    365: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1231: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1230: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1284: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1285: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1287: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1313161554: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1313161555: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    288: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    2888: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    100: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    10200: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1088: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    599: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    40: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    41: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    57: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    5700: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    4689: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    4690: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    336: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    592: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    81: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    122: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    123: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    106: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    111: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    55555: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    55556: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    420420: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    420666: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    88: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    89: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    108: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    18: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    888: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    999: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    30: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    31: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    20: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    21: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1030: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    71: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    32520: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    64668: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    20402: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    42110: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    7700: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    7701: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    42262: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    42261: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    888888: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    666666: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1234: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    12345: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    82: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    83: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    71402: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    71401: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    820: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    20729: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    9001: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    9000: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    246: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    73799: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    8453: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    84531: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    2222: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    2221: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1024: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1023: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1131: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1132: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1039: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    1038: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    314: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    314159: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    14: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    114: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    841: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    842: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    324: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    280: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  nftMarket: {
    529: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    917: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    17000: '0x',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    137: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    80002: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    338: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    43114: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    43113: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    250: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    4002: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    42220: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    44787: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    10: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    420: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    42161: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    421613: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    109: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    719: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    369: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    943: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    20221200000: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    202212: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1666600000: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1666700000: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    52: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    53: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    2000: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    568: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    63: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    32659: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    46688: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    128: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    256: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    321: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    322: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    8217: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1001: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    66: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    65: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    361: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    365: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1231: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1230: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1284: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1285: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1287: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1313161554: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1313161555: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    288: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    2888: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    100: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    10200: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1088: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    599: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    40: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    41: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    57: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    5700: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    4689: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    4690: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    336: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    592: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    81: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    122: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    123: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    106: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    111: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    55555: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    55556: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    420420: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    420666: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    88: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    89: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    108: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    18: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    888: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    999: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    30: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    31: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    20: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    21: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1030: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    71: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    32520: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    64668: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    20402: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    42110: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    7700: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    7701: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    42262: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    42261: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    888888: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    666666: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1234: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    12345: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    82: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    83: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    71402: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    71401: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    820: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    20729: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    9001: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    9000: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    246: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    73799: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    8453: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    84531: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    2222: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    2221: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1024: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1023: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1131: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1132: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1039: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    1038: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    314: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    314159: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    14: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    114: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    841: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    842: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    324: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    280: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  nftSale: {
    529: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    917: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    17000: '0x',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    137: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    80002: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    338: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    43114: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    43113: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    250: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    4002: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    42220: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    44787: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    10: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    420: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    42161: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    421613: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    109: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    719: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    369: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    943: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    20221200000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    202212: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1666600000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1666700000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    52: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    53: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    2000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    568: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    63: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    32659: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    46688: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    128: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    256: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    321: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    322: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    8217: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1001: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    66: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    65: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    361: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    365: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1231: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1230: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1284: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1285: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1287: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1313161554: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1313161555: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    288: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    2888: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    100: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    10200: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1088: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    599: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    40: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    41: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    57: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    5700: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    4689: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    4690: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    336: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    592: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    81: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    122: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    123: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    106: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    111: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    55555: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    55556: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    420420: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    420666: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    88: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    89: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    108: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    18: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    888: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    999: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    30: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    31: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    20: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    21: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1030: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    71: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    32520: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    64668: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    20402: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    42110: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    7700: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    7701: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    42262: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    42261: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    888888: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    666666: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1234: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    12345: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    82: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    83: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    71402: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    71401: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    820: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    20729: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    9001: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    9000: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    246: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    73799: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    8453: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    84531: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    2222: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    2221: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1024: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1023: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1131: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1132: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1039: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    1038: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    314: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    314159: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    14: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    114: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    841: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    842: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    324: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    280: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  donaswapSquad: {
    529: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    917: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    17000: '0x',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    137: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    80002: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    338: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    43114: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    43113: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    250: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    4002: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    42220: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    44787: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    10: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    420: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    42161: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    421613: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    109: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    719: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    369: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    943: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    20221200000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    202212: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1666600000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1666700000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    52: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    53: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    2000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    568: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    63: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    32659: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    46688: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    128: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    256: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    321: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    322: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    8217: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1001: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    66: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    65: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    361: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    365: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1231: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1230: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1284: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1285: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1287: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1313161554: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1313161555: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    288: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    2888: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    100: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    10200: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1088: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    599: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    40: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    41: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    57: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    5700: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    4689: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    4690: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    336: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    592: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    81: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    122: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    123: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    106: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    111: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    55555: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    55556: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    420420: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    420666: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    88: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    89: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    108: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    18: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    888: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    999: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    30: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    31: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    20: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    21: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1030: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    71: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    32520: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    64668: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    20402: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    42110: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    7700: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    7701: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    42262: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    42261: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    888888: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    666666: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1234: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    12345: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    82: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    83: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    71402: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    71401: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    820: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    20729: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    9001: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    9000: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    246: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    73799: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    8453: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    84531: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    2222: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    2221: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1024: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1023: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1131: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1132: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1039: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    1038: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    314: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    314159: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    14: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    114: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    841: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    842: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    324: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    280: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  potteryDraw: {
    529: '0x0000000000000000000000000000000000000000',
    917: '0x0000000000000000000000000000000000000000',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x0000000000000000000000000000000000000000',
    17000: '0x',
    97: '0x0000000000000000000000000000000000000000',
    137: '0x0000000000000000000000000000000000000000',
    80002: '0x0000000000000000000000000000000000000000',
    1101: '0x0000000000000000000000000000000000000000',
    1442: '0x0000000000000000000000000000000000000000',
    25: '0x0000000000000000000000000000000000000000',
    338: '0x0000000000000000000000000000000000000000',
    43114: '0x0000000000000000000000000000000000000000',
    43113: '0x0000000000000000000000000000000000000000',
    250: '0x0000000000000000000000000000000000000000',
    4002: '0x0000000000000000000000000000000000000000',
    42220: '0x0000000000000000000000000000000000000000',
    44787: '0x0000000000000000000000000000000000000000',
    10: '0x0000000000000000000000000000000000000000',
    420: '0x0000000000000000000000000000000000000000',
    42161: '0x0000000000000000000000000000000000000000',
    421613: '0x0000000000000000000000000000000000000000',
    109: '0x0000000000000000000000000000000000000000',
    719: '0x0000000000000000000000000000000000000000',
    369: '0x0000000000000000000000000000000000000000',
    943: '0x0000000000000000000000000000000000000000',
    20221200000: '0x0000000000000000000000000000000000000000',
    202212: '0x0000000000000000000000000000000000000000',
    1666600000: '0x0000000000000000000000000000000000000000',
    1666700000: '0x0000000000000000000000000000000000000000',
    52: '0x0000000000000000000000000000000000000000',
    53: '0x0000000000000000000000000000000000000000',
    2000: '0x0000000000000000000000000000000000000000',
    568: '0x0000000000000000000000000000000000000000',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x0000000000000000000000000000000000000000',
    63: '0x0000000000000000000000000000000000000000',
    32659: '0x0000000000000000000000000000000000000000',
    46688: '0x0000000000000000000000000000000000000000',
    128: '0x0000000000000000000000000000000000000000',
    256: '0x0000000000000000000000000000000000000000',
    321: '0x0000000000000000000000000000000000000000',
    322: '0x0000000000000000000000000000000000000000',
    8217: '0x0000000000000000000000000000000000000000',
    1001: '0x0000000000000000000000000000000000000000',
    66: '0x0000000000000000000000000000000000000000',
    65: '0x0000000000000000000000000000000000000000',
    361: '0x0000000000000000000000000000000000000000',
    365: '0x0000000000000000000000000000000000000000',
    1231: '0x0000000000000000000000000000000000000000',
    1230: '0x0000000000000000000000000000000000000000',
    1284: '0x0000000000000000000000000000000000000000',
    1285: '0x0000000000000000000000000000000000000000',
    1287: '0x0000000000000000000000000000000000000000',
    1313161554: '0x0000000000000000000000000000000000000000',
    1313161555: '0x0000000000000000000000000000000000000000',
    288: '0x0000000000000000000000000000000000000000',
    2888: '0x0000000000000000000000000000000000000000',
    100: '0x0000000000000000000000000000000000000000',
    10200: '0x0000000000000000000000000000000000000000',
    1088: '0x0000000000000000000000000000000000000000',
    599: '0x0000000000000000000000000000000000000000',
    40: '0x0000000000000000000000000000000000000000',
    41: '0x0000000000000000000000000000000000000000',
    57: '0x0000000000000000000000000000000000000000',
    5700: '0x0000000000000000000000000000000000000000',
    4689: '0x0000000000000000000000000000000000000000',
    4690: '0x0000000000000000000000000000000000000000',
    336: '0x0000000000000000000000000000000000000000',
    592: '0x0000000000000000000000000000000000000000',
    81: '0x0000000000000000000000000000000000000000',
    122: '0x0000000000000000000000000000000000000000',
    123: '0x0000000000000000000000000000000000000000',
    106: '0x0000000000000000000000000000000000000000',
    111: '0x0000000000000000000000000000000000000000',
    55555: '0x0000000000000000000000000000000000000000',
    55556: '0x0000000000000000000000000000000000000000',
    420420: '0x0000000000000000000000000000000000000000',
    420666: '0x0000000000000000000000000000000000000000',
    88: '0x0000000000000000000000000000000000000000',
    89: '0x0000000000000000000000000000000000000000',
    108: '0x0000000000000000000000000000000000000000',
    18: '0x0000000000000000000000000000000000000000',
    888: '0x0000000000000000000000000000000000000000',
    999: '0x0000000000000000000000000000000000000000',
    30: '0x0000000000000000000000000000000000000000',
    31: '0x0000000000000000000000000000000000000000',
    20: '0x0000000000000000000000000000000000000000',
    21: '0x0000000000000000000000000000000000000000',
    1030: '0x0000000000000000000000000000000000000000',
    71: '0x0000000000000000000000000000000000000000',
    32520: '0x0000000000000000000000000000000000000000',
    64668: '0x0000000000000000000000000000000000000000',
    20402: '0x0000000000000000000000000000000000000000',
    42110: '0x0000000000000000000000000000000000000000',
    7700: '0x0000000000000000000000000000000000000000',
    7701: '0x0000000000000000000000000000000000000000',
    42262: '0x0000000000000000000000000000000000000000',
    42261: '0x0000000000000000000000000000000000000000',
    888888: '0x0000000000000000000000000000000000000000',
    666666: '0x0000000000000000000000000000000000000000',
    1234: '0x0000000000000000000000000000000000000000',
    12345: '0x0000000000000000000000000000000000000000',
    82: '0x0000000000000000000000000000000000000000',
    83: '0x0000000000000000000000000000000000000000',
    71402: '0x0000000000000000000000000000000000000000',
    71401: '0x0000000000000000000000000000000000000000',
    820: '0x0000000000000000000000000000000000000000',
    20729: '0x0000000000000000000000000000000000000000',
    9001: '0x0000000000000000000000000000000000000000',
    9000: '0x0000000000000000000000000000000000000000',
    246: '0x0000000000000000000000000000000000000000',
    73799: '0x0000000000000000000000000000000000000000',
    8453: '0x0000000000000000000000000000000000000000',
    84531: '0x0000000000000000000000000000000000000000',
    2222: '0x0000000000000000000000000000000000000000',
    2221: '0x0000000000000000000000000000000000000000',
    1024: '0x0000000000000000000000000000000000000000',
    1023: '0x0000000000000000000000000000000000000000',
    1131: '0x0000000000000000000000000000000000000000',
    1132: '0x0000000000000000000000000000000000000000',
    1039: '0x0000000000000000000000000000000000000000',
    1038: '0x0000000000000000000000000000000000000000',
    314: '0x0000000000000000000000000000000000000000',
    314159: '0x0000000000000000000000000000000000000000',
    14: '0x0000000000000000000000000000000000000000',
    114: '0x0000000000000000000000000000000000000000',
    841: '0x0000000000000000000000000000000000000000',
    842: '0x0000000000000000000000000000000000000000',
    324: '0x0000000000000000000000000000000000000000',
    280: '0x0000000000000000000000000000000000000000',
    59140: '0x0000000000000000000000000000000000000000',
    59144: '0x0000000000000000000000000000000000000000',
    204: '0x0000000000000000000000000000000000000000',
    5611: '0x0000000000000000000000000000000000000000',
    43288: '0x0000000000000000000000000000000000000000',
    56288: '0x0000000000000000000000000000000000000000',
    199: '0x0000000000000000000000000000000000000000',
    1029: '0x0000000000000000000000000000000000000000',
    256256: '0x0000000000000000000000000000000000000000',
    512512: '0x0000000000000000000000000000000000000000',
    1116: '0x0000000000000000000000000000000000000000',
    1115: '0x0000000000000000000000000000000000000000',
    1818: '0x0000000000000000000000000000000000000000',
    1819: '0x0000000000000000000000000000000000000000',
    44: '0x0000000000000000000000000000000000000000',
    43: '0x0000000000000000000000000000000000000000',
    15: '0x0000000000000000000000000000000000000000',
    13: '0x0000000000000000000000000000000000000000',
    60: '0x0000000000000000000000000000000000000000',
    31337: '0x0000000000000000000000000000000000000000',
    11235: '0x0000000000000000000000000000000000000000',
    54211: '0x0000000000000000000000000000000000000000',
    24: '0x0000000000000000000000000000000000000000',
    242: '0x0000000000000000000000000000000000000000',
    11: '0x0000000000000000000000000000000000000000',
    12: '0x0000000000000000000000000000000000000000',
    11297108109: '0x0000000000000000000000000000000000000000',
    11297108099: '0x0000000000000000000000000000000000000000',
    333999: '0x0000000000000000000000000000000000000000',
    333888: '0x0000000000000000000000000000000000000000',
    27: '0x0000000000000000000000000000000000000000',
    10000: '0x0000000000000000000000000000000000000000',
    10001: '0x0000000000000000000000000000000000000000',
    19: '0x0000000000000000000000000000000000000000',
    7: '0x0000000000000000000000000000000000000000',
    8: '0x0000000000000000000000000000000000000000',
    9: '0x0000000000000000000000000000000000000000',
    100009: '0x0000000000000000000000000000000000000000',
    100010: '0x0000000000000000000000000000000000000000',
    50: '0x0000000000000000000000000000000000000000',
    51: '0x0000000000000000000000000000000000000000',
    534352: '0x0000000000000000000000000000000000000000',
    534351: '0x0000000000000000000000000000000000000000',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x0000000000000000000000000000000000000000',
  },
  zap: {
    529: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    917: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    17000: '0x',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    137: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    80002: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    338: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    43114: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    43113: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    250: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    4002: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    42220: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    44787: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    10: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    420: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    42161: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    421613: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    109: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    719: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    369: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    943: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    20221200000: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    202212: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1666600000: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1666700000: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    52: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    53: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    2000: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    568: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    63: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    32659: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    46688: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    128: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    256: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    321: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    322: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    8217: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1001: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    66: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    65: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    361: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    365: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1231: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1230: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1284: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1285: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1287: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1313161554: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1313161555: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    288: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    2888: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    100: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    10200: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1088: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    599: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    40: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    41: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    57: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    5700: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    4689: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    4690: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    336: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    592: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    81: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    122: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    123: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    106: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    111: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    55555: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    55556: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    420420: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    420666: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    88: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    89: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    108: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    18: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    888: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    999: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    30: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    31: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    20: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    21: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1030: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    71: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    32520: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    64668: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    20402: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    42110: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    7700: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    7701: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    42262: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    42261: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    888888: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    666666: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1234: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    12345: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    82: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    83: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    71402: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    71401: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    820: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    20729: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    9001: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    9000: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    246: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    73799: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    8453: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    84531: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    2222: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    2221: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1024: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1023: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1131: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1132: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1039: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    1038: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    314: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    314159: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    14: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    114: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    841: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    842: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    324: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    280: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  stableSwapNativeHelper: {
    529: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    917: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x52E5D1e24A4308ef1A221C949cb2F7cbbAFEE090',
    17000: '0x',
    97: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    137: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    80002: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    338: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    43114: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    43113: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    250: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    4002: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    42220: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    44787: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    10: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    420: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    42161: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    421613: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    109: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    719: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    369: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    943: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    20221200000: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    202212: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1666600000: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1666700000: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    52: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    53: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    2000: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    568: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    63: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    32659: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    46688: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    128: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    256: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    321: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    322: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    8217: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1001: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    66: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    65: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    361: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    365: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1231: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1230: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1284: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1285: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1287: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1313161554: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1313161555: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    288: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    2888: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    100: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    10200: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1088: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    599: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    40: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    41: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    57: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    5700: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    4689: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    4690: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    336: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    592: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    81: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    122: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    123: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    106: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    111: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    55555: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    55556: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    420420: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    420666: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    88: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    89: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    108: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    18: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    888: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    999: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    30: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    31: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    20: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    21: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1030: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    71: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    32520: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    64668: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    20402: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    42110: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    7700: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    7701: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    42262: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    42261: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    888888: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    666666: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1234: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    12345: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    82: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    83: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    71402: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    71401: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    820: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    20729: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    9001: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    9000: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    246: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    73799: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    8453: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    84531: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    2222: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    2221: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1024: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1023: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1131: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1132: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1039: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    1038: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    314: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    314159: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    14: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    114: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    841: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    842: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    324: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    280: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  iFlame: {
    529: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    917: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    17000: '0x',
    97: '0x',
    137: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    80002: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    338: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    43114: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    43113: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    250: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    4002: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    42220: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    44787: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    10: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    420: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    42161: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    421613: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    109: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    719: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    369: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    943: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    20221200000: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    202212: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1666600000: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1666700000: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    52: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    53: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    2000: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    568: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    63: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    32659: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    46688: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    128: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    256: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    321: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    322: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    8217: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1001: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    66: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    65: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    361: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    365: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1231: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1230: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1284: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1285: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1287: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1313161554: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1313161555: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    288: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    2888: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    100: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    10200: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1088: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    599: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    40: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    41: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    57: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    5700: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    4689: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    4690: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    336: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    592: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    81: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    122: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    123: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    106: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    111: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    55555: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    55556: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    420420: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    420666: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    88: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    89: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    108: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    18: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    888: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    999: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    30: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    31: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    20: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    21: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1030: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    71: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    32520: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    64668: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    20402: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    42110: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    7700: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    7701: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    42262: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    42261: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    888888: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    666666: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1234: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    12345: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    82: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    83: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    71402: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    71401: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    820: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    20729: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    9001: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    9000: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    246: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    73799: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    8453: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    84531: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    2222: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    2221: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1024: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1023: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1131: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1132: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1039: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    1038: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    314: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    314159: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    14: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    114: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    841: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    842: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    324: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    280: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bFlameFarmBooster: {
    529: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    917: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    17000: '0x',
    97: '0x',
    137: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    80002: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    338: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    43114: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    43113: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    250: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    4002: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    42220: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    44787: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    10: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    420: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    42161: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    421613: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    109: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    719: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    369: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    943: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    20221200000: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    202212: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1666600000: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1666700000: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    52: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    53: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    2000: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    568: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    63: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    32659: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    46688: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    128: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    256: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    321: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    322: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    8217: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1001: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    66: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    65: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    361: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    365: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1231: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1230: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1284: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1285: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1287: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1313161554: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1313161555: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    288: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    2888: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    100: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    10200: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1088: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    599: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    40: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    41: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    57: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    5700: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    4689: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    4690: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    336: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    592: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    81: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    122: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    123: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    106: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    111: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    55555: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    55556: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    420420: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    420666: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    88: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    89: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    108: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    18: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    888: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    999: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    30: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    31: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    20: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    21: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1030: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    71: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    32520: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    64668: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    20402: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    42110: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    7700: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    7701: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    42262: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    42261: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    888888: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    666666: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1234: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    12345: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    82: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    83: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    71402: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    71401: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    820: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    20729: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    9001: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    9000: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    246: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    73799: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    8453: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    84531: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    2222: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    2221: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1024: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1023: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1131: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1132: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1039: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    1038: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    314: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    314159: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    14: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    114: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    841: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    842: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    324: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    280: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bFlameFarmBoosterProxyFactory: {
    529: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    917: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    17000: '0x',
    97: '0x',
    137: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    80002: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    338: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    43114: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    43113: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    250: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4002: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42220: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    44787: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    10: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42161: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    421613: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    109: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    719: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    369: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    943: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20221200000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    202212: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1666600000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1666700000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    52: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    53: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    568: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    63: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    32659: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    46688: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    128: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    256: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    321: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    322: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    8217: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1001: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    66: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    65: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    361: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    365: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1231: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1230: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1284: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1285: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1287: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1313161554: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1313161555: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    288: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    100: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    10200: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1088: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    599: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    40: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    41: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    57: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    5700: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4689: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4690: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    336: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    592: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    81: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    122: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    123: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    106: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    111: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    55555: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    55556: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420420: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420666: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    88: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    89: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    108: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    18: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    999: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    30: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    31: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    21: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1030: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    32520: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    64668: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20402: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42110: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    7700: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    7701: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42262: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42261: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    888888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    666666: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1234: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    12345: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    82: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    83: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71402: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71401: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    820: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20729: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    9001: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    9000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    246: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    73799: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    8453: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    84531: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2222: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2221: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1024: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1023: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1131: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1132: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1039: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1038: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    314: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    314159: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    14: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    114: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    841: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    842: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    324: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    280: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  bFlameFarmBoosterV3: {
    529: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    917: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x695170faE243147b3bEB4C43AA8DE5DcD9202752',
    17000: '0x',
    97: '0x56666300A1E25624489b661f3C6c456c159a109a',
    137: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    80002: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    338: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    43114: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    43113: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    250: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4002: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42220: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    44787: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    10: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42161: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    421613: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    109: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    719: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    369: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    943: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20221200000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    202212: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1666600000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1666700000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    52: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    53: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    568: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    63: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    32659: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    46688: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    128: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    256: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    321: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    322: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    8217: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1001: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    66: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    65: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    361: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    365: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1231: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1230: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1284: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1285: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1287: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1313161554: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1313161555: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    288: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    100: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    10200: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1088: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    599: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    40: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    41: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    57: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    5700: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4689: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    4690: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    336: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    592: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    81: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    122: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    123: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    106: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    111: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    55555: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    55556: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420420: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    420666: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    88: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    89: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    108: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    18: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    999: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    30: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    31: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    21: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1030: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    32520: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    64668: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20402: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42110: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    7700: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    7701: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42262: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    42261: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    888888: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    666666: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1234: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    12345: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    82: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    83: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71402: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    71401: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    820: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    20729: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    9001: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    9000: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    246: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    73799: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    8453: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    84531: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2222: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    2221: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1024: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1023: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1131: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1132: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1039: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    1038: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    314: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    314159: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    14: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    114: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    841: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    842: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    324: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    280: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  nonBscVault: {
    529: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    917: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    56: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    4: '0x',
    11155111: '0x',
    17000: '0x',
    137: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    80002: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    338: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    43114: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    43113: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    250: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    4002: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    42220: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    44787: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    10: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    420: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    42161: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    421613: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    109: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    719: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    369: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    943: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    20221200000: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    202212: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1666600000: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1666700000: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    52: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    53: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    2000: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    568: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    63: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    32659: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    46688: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    128: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    256: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    321: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    322: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    8217: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1001: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    66: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    65: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    361: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    365: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1231: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1230: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1284: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1285: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1287: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1313161554: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1313161555: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    288: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    2888: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    100: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    10200: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1088: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    599: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    40: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    41: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    57: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    5700: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    4689: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    4690: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    336: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    592: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    81: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    122: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    123: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    106: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    111: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    55555: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    55556: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    420420: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    420666: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    88: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    89: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    108: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    18: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    888: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    999: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    30: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    31: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    20: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    21: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1030: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    71: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    32520: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    64668: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    20402: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    42110: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    7700: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    7701: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    42262: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    42261: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    888888: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    666666: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1234: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    12345: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    82: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    83: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    71402: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    71401: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    820: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    20729: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    9001: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    9000: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    246: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    73799: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    8453: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    84531: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    2222: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    2221: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1024: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1023: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1131: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1132: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1039: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    1038: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    314: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    314159: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    14: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    114: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    841: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    842: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    324: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    280: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  crossFarmingSender: {
    529: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    917: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    56: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    4: '0x',
    11155111: '0x',
    17000: '0x',
    137: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    80002: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    338: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    43114: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    43113: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    250: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    4002: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    42220: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    44787: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    10: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    420: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    42161: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    421613: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    109: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    719: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    369: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    943: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    20221200000: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    202212: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1666600000: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1666700000: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    52: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    53: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    2000: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    568: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    63: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    32659: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    46688: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    128: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    256: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    321: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    322: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    8217: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1001: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    66: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    65: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    361: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    365: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1231: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1230: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1284: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1285: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1287: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1313161554: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1313161555: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    288: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    2888: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    100: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    10200: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1088: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    599: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    40: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    41: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    57: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    5700: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    4689: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    4690: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    336: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    592: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    81: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    122: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    123: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    106: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    111: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    55555: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    55556: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    420420: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    420666: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    88: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    89: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    108: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    18: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    888: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    999: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    30: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    31: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    20: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    21: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1030: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    71: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    32520: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    64668: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    20402: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    42110: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    7700: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    7701: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    42262: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    42261: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    888888: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    666666: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1234: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    12345: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    82: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    83: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    71402: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    71401: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    820: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    20729: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    9001: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    9000: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    246: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    73799: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    8453: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    84531: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    2222: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    2221: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1024: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1023: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1131: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1132: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1039: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    1038: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    314: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    314159: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    14: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    114: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    841: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    842: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    324: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    280: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  crossFarmingReceiver: {
    529: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    917: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    17000: '0x',
    97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    5: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4: '0x',
    11155111: '0x',
    137: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    80002: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    338: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    43114: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    43113: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    250: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4002: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42220: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    44787: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    10: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42161: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    421613: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    109: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    719: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    369: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    943: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20221200000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    202212: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1666600000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1666700000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    52: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    53: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    568: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    63: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    32659: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    46688: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    128: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    256: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    321: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    322: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    8217: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1001: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    66: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    65: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    361: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    365: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1231: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1230: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1284: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1285: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1287: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1313161554: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1313161555: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    288: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    100: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    10200: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1088: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    599: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    40: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    41: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    57: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    5700: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4689: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4690: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    336: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    592: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    81: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    122: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    123: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    106: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    111: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    55555: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    55556: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420420: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420666: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    88: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    89: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    108: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    18: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    999: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    30: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    31: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    21: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1030: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    32520: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    64668: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20402: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42110: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    7700: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    7701: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42262: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42261: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    888888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    666666: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1234: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    12345: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    82: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    83: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71402: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71401: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    820: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20729: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    9001: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    9000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    246: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    73799: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    8453: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    84531: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2222: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2221: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1024: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1023: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1131: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1132: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1039: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1038: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    314: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    314159: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    14: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    114: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    841: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    842: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    324: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    280: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  mmLinkedPool: {
    529: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    917: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1: '0x9Ca2A439810524250E543BA8fB6E88578aF242BC',
    5: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    4: '0x',
    11155111: '0x',
    56: '0xfEACb05b373f1A08E68235bA7FC92636b92ced01',
    17000: '0x',
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    137: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    80002: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    338: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    43114: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    43113: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    250: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4002: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42220: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    44787: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    10: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42161: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    421613: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    109: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    719: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    369: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    943: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20221200000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    202212: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1666600000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1666700000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    52: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    53: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    568: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    131: '0x0000000000000000000000000000000000000000',
    61: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    63: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    32659: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    46688: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    128: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    256: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    321: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    322: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    8217: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1001: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    66: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    65: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    361: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    365: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1231: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1230: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1284: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1285: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1287: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1313161554: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1313161555: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    288: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    100: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    10200: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1088: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    599: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    40: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    41: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    57: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    5700: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4689: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    4690: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    336: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    592: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    81: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    122: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    123: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    106: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    111: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    55555: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    55556: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420420: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    420666: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    88: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    89: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    108: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    18: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    999: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    30: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    31: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    21: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1030: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    32520: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    64668: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20402: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42110: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    7700: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    7701: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42262: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    42261: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    888888: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    666666: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1234: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    12345: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    82: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    83: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71402: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    71401: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    820: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    20729: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    9001: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    9000: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    246: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    73799: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    8453: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    84531: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2222: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    2221: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1024: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1023: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1131: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1132: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1039: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    1038: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    314: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    314159: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    14: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    114: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    841: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    842: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    324: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    280: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingReward: {
    529: '0x',
    917: '0x',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x', 
    56: '0xa842a4AD40FEbbd034fbeF25C7a880464a90e695',
    17000: '0x',
    97: '0x',
    137: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    80002: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    338: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    43114: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    43113: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    250: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    4002: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    42220: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    44787: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    10: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    420: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    42161: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    421613: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    109: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    719: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    369: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    943: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    20221200000: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    202212: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1666600000: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1666700000: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    52: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    53: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    2000: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    568: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    63: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    32659: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    46688: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    128: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    256: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    321: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    322: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    8217: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1001: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    66: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    65: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    361: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    365: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1231: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1230: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1284: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1285: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1287: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1313161554: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1313161555: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    288: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    2888: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    100: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    10200: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1088: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    599: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    40: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    41: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    57: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    5700: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    4689: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    4690: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    336: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    592: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    81: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    122: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    123: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    106: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    111: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    55555: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    55556: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    420420: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    420666: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    88: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    89: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    108: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    18: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    888: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    999: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    30: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    31: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    20: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    21: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1030: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    71: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    32520: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    64668: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    20402: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    42110: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    7700: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    7701: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    42262: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    42261: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    888888: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    666666: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1234: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    12345: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    82: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    83: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    71402: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    71401: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    820: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    20729: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    9001: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    9000: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    246: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    73799: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    8453: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    84531: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    2222: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    2221: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1024: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1023: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1131: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1132: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1039: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    1038: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    314: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    314159: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    14: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    114: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    841: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    842: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    324: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    280: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  nftPositionManager: {
    529: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    917: '0x6048aD8c9107994506a00D8749CA0c1F6Bdb5767',
    1: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    5: '0x93881Bb7Bf0CDff1919cC9abAcDeAfd98c43D31D',
    11155111: '0x93881Bb7Bf0CDff1919cC9abAcDeAfd98c43D31D',
    56: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    17000: '0xF821F474FFE125674e781A56FcaE8be0f7367F74',
    97: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    280: '0x1ede0FF9b7Bd6DC0625e3ba579d5FD697e6698b2',
    324: '0x',
    1101: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1442: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    42161: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    421613: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    137: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    80002: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    25: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    338: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    43114: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    43113: '0x08b0545208532a5EFB612f9adAe12eE2f4B06c88',
    250: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    4002: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    42220: '0x57a9a4eD3FC6192EACd810317DAa4e9c57449ee9',
    44787: '0x794f343435c597Df5609f8FD1C7DEFEAc625174E',
    10: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    420: '0x7569a77F0366D4408BD43973c28cc313D92B4c7c',
    109: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    719: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    369: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    943: '0x64a854D71F1BAdc3182e9302776c2Ba691fBe1B2',
    20221200000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    202212: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1666600000: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1666700000: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    52: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    53: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    2000: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    568: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    131: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    61: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    63: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    32659: '0x2c006FbD9ccaEB531124E008D3B56eBc2bF4ACCf',
    46688: '0xa88Ca50b591cad9D1F05A058860126D4b5854857',
    128: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    256: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    321: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    322: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    8217: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1001: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    66: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    65: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    361: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    365: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1231: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1230: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1284: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1285: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1287: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1313161554: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1313161555: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    288: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    2888: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    100: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    10200: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1088: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    599: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    40: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    41: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    57: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    5700: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    4689: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    4690: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    336: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    592: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    81: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    122: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    123: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    106: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    111: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    55555: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    55556: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    420420: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    420666: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    88: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    89: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    108: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    18: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    888: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    999: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    30: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    31: '0xe96845D6B1dF2549Fc303c9007534467dc36683a',
    20: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    21: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1030: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    71: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    32520: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    64668: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    20402: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42110: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    7700: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    7701: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42262: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42261: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    888888: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    666666: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1234: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    12345: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    82: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    83: '0x93C0b8BDb742604CB7b1CA5A7f42F2fb7148d105',
    71402: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    71401: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    820: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    20729: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    9001: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    9000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    246: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    73799: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    8453: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    84531: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    2222: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    2221: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1024: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1023: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1131: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1132: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1039: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1038: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    314: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    314159: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    14: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    114: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    841: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    842: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    59140: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    59144: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    256256: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    512512: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1116: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1115: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0xe96845D6B1dF2549Fc303c9007534467dc36683a',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x93881Bb7Bf0CDff1919cC9abAcDeAfd98c43D31D',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  v3PoolDeployer: V3_DEPLOYER_ADDRESS_MAP,
  v3Migrator: {
    529: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    917: '0x33a03Cb8132Ba8aA42E4482746ec1c413bf19852',
    1: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    5: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    11155111: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    56: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    17000: '0x4a2A6d761B6df2A46431Dc06AdA59778B7b48c96',
    97: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    280: '0x612662964B5bd12111504C13D694eeEF566f3916',
    324: '0x',
    1101: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1442: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    42161: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    421613: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    137: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    80002: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    25: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    338: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    43114: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    43113: '0xd96e0603c85FD6fe19b1Df1DA7d4bF1C82356c5D',
    250: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    4002: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    42220: '0x794f343435c597Df5609f8FD1C7DEFEAc625174E',
    44787: '0xc8aD8fd20281301d353cea721b9AA4Cfe40147EF',
    10: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    420: '0xe96845D6B1dF2549Fc303c9007534467dc36683a',
    109: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    719: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    369: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    943: '0xb55d567af8641f975de030cCa2a06808cB5b6D35',
    20221200000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    202212: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1666600000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1666700000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    52: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    53: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    2000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    568: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    131: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    61: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    63: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    32659: '0x90B4e42c15949Dd81562d4a97cE6DFF12c99E526',
    46688: '0x55C1506a9600693D19b2CFf773D9A8D4690313C1',
    128: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    256: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    321: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    322: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    8217: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1001: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    66: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    65: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    361: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    365: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1231: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1230: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1284: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1285: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1287: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1313161554: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1313161555: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    288: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    2888: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    100: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    10200: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1088: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    599: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    40: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    41: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    57: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    5700: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    4689: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    4690: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    336: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    592: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    81: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    122: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    123: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    106: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    111: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    55555: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    55556: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    420420: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    420666: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    88: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    89: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    108: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    18: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    888: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    999: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    30: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    31: '0x7c80233054097E95AfF3e4A181f977443133EbB6',
    20: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    21: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1030: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    71: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    32520: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    64668: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    20402: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42110: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    7700: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    7701: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42262: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    42261: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    888888: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    666666: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1234: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    12345: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    82: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    83: '0xCBf0B7d723EC8A5ab91A670B72323cc0881Af92F',
    71402: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    71401: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    820: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    20729: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    9001: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    9000: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    246: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    73799: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    8453: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    84531: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    2222: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    2221: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1024: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1023: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1131: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1132: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1039: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    1038: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    314: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    314159: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    14: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    114: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    841: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    842: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    59140: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    59144: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    256256: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    512512: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1116: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1115: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x2921Cc3377C717B600468cfaB437a83C2dE5039d',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x8fa63A7c39f62Ee6EA178A3Ab31D0d06b4b86B1C',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {
    529: '0x',
    917: '0x',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0xe934d2C5bE5db0295A4de3177762A9E8c74Ae4f4',
    17000: '0x',
    97: '0x',
    137: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    80002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    338: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43113: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    250: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42220: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    44787: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42161: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    421613: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    109: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    719: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    369: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    943: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20221200000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    202212: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666600000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666700000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    52: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    53: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    568: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    63: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32659: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    46688: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    128: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    256: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    321: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    322: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8217: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    66: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    65: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    361: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    365: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1231: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1230: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1284: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1285: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1287: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161554: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    288: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    100: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10200: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1088: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    599: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    40: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    41: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    57: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    5700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4689: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4690: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    336: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    592: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    81: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    122: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    123: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    106: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    111: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55556: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    88: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    89: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    108: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    18: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    999: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    30: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    31: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    21: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1030: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32520: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    64668: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42110: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7701: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42262: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42261: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    666666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1234: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    12345: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    82: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    83: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71401: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    820: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20729: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    246: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    73799: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8453: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    84531: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2222: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2221: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1024: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1023: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1131: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1132: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1039: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1038: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314159: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    14: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    841: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    842: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    324: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    280: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  affiliateProgram: {
    529: '0x',
    917: '0x',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x92C73D90F709DFf7e5E7307e8F2EE20e39396b12',
    17000: '0x',
    97: '0x1B8a475B5E5De05fB3Ac2D9ec3C2809fBF24e51c',
    137: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    80002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    338: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43113: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    250: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42220: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    44787: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42161: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    421613: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    109: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    719: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    369: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    943: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20221200000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    202212: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666600000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666700000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    52: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    53: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    568: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    63: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32659: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    46688: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    128: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    256: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    321: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    322: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8217: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    66: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    65: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    361: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    365: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1231: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1230: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1284: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1285: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1287: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161554: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    288: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    100: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10200: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1088: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    599: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    40: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    41: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    57: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    5700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4689: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4690: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    336: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    592: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    81: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    122: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    123: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    106: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    111: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55556: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    88: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    89: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    108: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    18: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    999: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    30: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    31: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    21: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1030: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32520: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    64668: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42110: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7701: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42262: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42261: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    666666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1234: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    12345: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    82: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    83: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71401: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    820: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20729: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    246: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    73799: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8453: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    84531: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2222: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2221: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1024: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1023: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1131: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1132: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1039: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1038: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314159: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    14: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    841: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    842: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    324: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    280: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingRewardTopTrades: {
    529: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    917: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1: '0x',
    4: '0x',
    5: '0x',
    11155111: '0x',
    56: '0x549d484F493b778A5c70638E30Fc6Dc6B2Dcc4c0',
    17000: '0x',
    97: '0x',
    137: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    80002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1101: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    1442: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    25: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    338: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    43113: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    250: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4002: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42220: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    44787: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42161: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    421613: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    109: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    719: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    369: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    943: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20221200000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    202212: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666600000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1666700000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    52: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    53: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    568: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    131: '0x0000000000000000000000000000000000000000',
    61: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    63: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32659: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    46688: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    128: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    256: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    321: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    322: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8217: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    66: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    65: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    361: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    365: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1231: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1230: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1284: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1285: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1287: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161554: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1313161555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    288: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    100: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    10200: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1088: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    599: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    40: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    41: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    57: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    5700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4689: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    4690: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    336: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    592: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    81: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    122: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    123: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    106: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    111: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55555: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    55556: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420420: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    420666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    88: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    89: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    108: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    18: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    999: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    30: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    31: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    21: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1030: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    32520: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    64668: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42110: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7700: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    7701: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42262: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    42261: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    888888: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    666666: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1234: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    12345: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    82: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    83: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71402: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    71401: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    820: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    20729: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9001: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    9000: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    246: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    73799: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    8453: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    84531: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2222: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    2221: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1024: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1023: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1131: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1132: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1039: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1038: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    314159: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    14: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    114: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    841: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    842: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    324: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    280: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    59140: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    59144: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    204: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    5611: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    56288: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    199: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1029: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    256256: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    512512: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1116: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1115: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1818: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    1819: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    44: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    43: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    15: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    13: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    60: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    31337: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11235: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    54211: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    24: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    242: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    12: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108109: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    11297108099: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333999: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    333888: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    27: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10000: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    10001: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    19: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    7: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    8: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    9: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100009: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    100010: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    50: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    51: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    534352: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    534351: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    7000: '0x0000000000000000000000000000000000000000',
    7001: '0x0000000000000000000000000000000000000000',
    55: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
} as const satisfies Record<string, Record<number, `0x${string}`>>
