import { ChainId } from '@donaswap/sdk'

export const INFO_CLIENT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v2-bsc'
export const INFO_CLIENT_FIRE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ETH = 'https://api.studio.thegraph.com/query/32587/exchange-v2-ethereum/version/latest'
export const INFO_CLIENT_MATIC = 'https://api.studio.thegraph.com/query/32587/exchange-v2-polygon/version/latest'
export const INFO_CLIENT_ZKEVM_ETH = 'https://api.studio.thegraph.com/query/32587/exchange-v2-polygon-zkevm/version/latest'
export const INFO_CLIENT_CRO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_AVAX = 'https://api.studio.thegraph.com/query/32587/exchange-v2-avalanche/version/latest'
export const INFO_CLIENT_FTM = 'https://api.studio.thegraph.com/query/32587/exchange-v2-fantom/version/latest'
export const INFO_CLIENT_CELO = 'https://api.studio.thegraph.com/query/32587/exchange-v2-celo/version/latest'
export const INFO_CLIENT_OP_ETH = 'https://api.studio.thegraph.com/query/32587/exchange-v2-optimism/version/latest'
export const INFO_CLIENT_ARBI_ETH = 'https://api.studio.thegraph.com/query/32587/exchange-v2-arbitrum-one/version/latest'
export const INFO_CLIENT_BONE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_PLS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_XN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ONE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_DOGE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ETC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_FSN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_HECO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_KCS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_KLAY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_OKT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_TFUEL = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ULX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_GLMR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_MOVR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_AURORA_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/exchange-v2-aurora'
export const INFO_CLIENT_BOBA_ETH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_XDAI = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_METIS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_TLOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SYS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_IOTX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SDN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ASTR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SBY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_FUSE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_VLX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_REI = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_KEK = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_TOMO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_TT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_WAN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_RBTC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ELA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CFX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BRISE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_MUU = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CANTO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ROSE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_VS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_STEP = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_MTR = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_PCKB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CLO = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_EVMOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_EWT = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BASE_ETH = 'https://api.studio.thegraph.com/query/32587/exchange-v2-base/version/latest'
export const INFO_CLIENT_KAVA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CLOVER = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_DEFICHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BRONOS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_FILECOIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_FLARE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_TARAXA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ZKSYNC = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_LINEA = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BTTCHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BOBA_AVAX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_BOBA_BNB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CMP = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CORE = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_CUBECHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_DARWINIA_CRAB = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_DIODE_PRETNET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_GOCHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_HAQQ = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_KARDIACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_METADIUM = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_PALM = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_POLIS = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SHIBACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SMART_BCH = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SONGBIRD_CANARY = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_THAICHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_UBIQ = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_VECHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_XINFIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_SCROLL = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_XRPL_DEVNET = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ZETACHAIN = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'
export const INFO_CLIENT_ZYX = 'https://api.thegraph.com/subgraphs/name/0xfirechain/'

export const INFO_CLIENT_WITH_CHAIN = {
    [ChainId.FIRECHAIN]: INFO_CLIENT_FIRE,
    [ChainId.BSC]: INFO_CLIENT,
    [ChainId.ETHEREUM]: INFO_CLIENT_ETH,
    // [ChainId.LINEA_TESTNET]: 'https://thegraph.goerli.zkevm.consensys.net/subgraphs/name/pancakeswap/exhange-eth/',
    [ChainId.ARBITRUM_ONE]: INFO_CLIENT_ARBI_ETH,
    [ChainId.POLYGON]: INFO_CLIENT_MATIC,
    [ChainId.POLYGON_ZKEVM]: INFO_CLIENT_ZKEVM_ETH,
    [ChainId.CRO]: INFO_CLIENT_CRO,
    [ChainId.AVALANCHE]: INFO_CLIENT_AVAX,
    [ChainId.FANTOM_OPERA]: INFO_CLIENT_FTM,
    [ChainId.CELO]: INFO_CLIENT_CELO,
    [ChainId.OPTIMISM]: INFO_CLIENT_OP_ETH,
    [ChainId.SHIBARIUM]: INFO_CLIENT_BONE,
    [ChainId.PULSECHAIN]: INFO_CLIENT_PLS,
    [ChainId.XEN]: INFO_CLIENT_XN,
    [ChainId.HARMONY]: INFO_CLIENT_ONE,
    [ChainId.COINEX]: INFO_CLIENT_CET,
    [ChainId.DOGECHAIN]: INFO_CLIENT_DOGE,
    [ChainId.ETHEREUM_CLASSIC]: INFO_CLIENT_ETC,
    [ChainId.FUSION]: INFO_CLIENT_FSN,
    [ChainId.HECO]: INFO_CLIENT_HECO,
    [ChainId.KCC]: INFO_CLIENT_KCS,
    [ChainId.KLAYTN]: INFO_CLIENT_KLAY,
    [ChainId.OKXCHAIN]: INFO_CLIENT_OKT,
    [ChainId.THETHA]: INFO_CLIENT_TFUEL,
    [ChainId.ULTRON]: INFO_CLIENT_ULX,
    [ChainId.MOONBEAM]: INFO_CLIENT_GLMR,
    [ChainId.MOONRIVER]: INFO_CLIENT_MOVR,
    [ChainId.AURORA]: INFO_CLIENT_AURORA_ETH,
    [ChainId.BOBA]: INFO_CLIENT_BOBA_ETH,
    [ChainId.GNOSIS]: INFO_CLIENT_XDAI,
    [ChainId.METIS]: INFO_CLIENT_METIS,
    [ChainId.TELOS]: INFO_CLIENT_TLOS,
    [ChainId.SYSCOIN]: INFO_CLIENT_SYS,
    [ChainId.IOTEX]: INFO_CLIENT_IOTX,
    [ChainId.SHIDEN]: INFO_CLIENT_SDN,
    [ChainId.ASTAR]: INFO_CLIENT_ASTR,
    [ChainId.FUSE]: INFO_CLIENT_FUSE,
    [ChainId.VELAS]: INFO_CLIENT_VLX,
    [ChainId.REI]: INFO_CLIENT_REI,
    [ChainId.KEKCHAIN]: INFO_CLIENT_KEK,
    [ChainId.TOMOCHAIN]: INFO_CLIENT_TOMO,
    [ChainId.THUNDERCORE]: INFO_CLIENT_TT,
    [ChainId.WANCHAIN]: INFO_CLIENT_WAN,
    [ChainId.RSK]: INFO_CLIENT_RBTC,
    [ChainId.ELASTOS]: INFO_CLIENT_ELA,
    [ChainId.CONFLUX]: INFO_CLIENT_CFX,
    [ChainId.BRISECHAIN]: INFO_CLIENT_BRISE,
    [ChainId.MUUCHAIN]: INFO_CLIENT_MUU,
    [ChainId.CANTO]: INFO_CLIENT_CANTO,
    [ChainId.OASIS_EMERALD]: INFO_CLIENT_ROSE,
    [ChainId.VISION]: INFO_CLIENT_VS,
    [ChainId.STEP]: INFO_CLIENT_STEP,
    [ChainId.METER]: INFO_CLIENT_MTR,
    [ChainId.GODWOKEN]: INFO_CLIENT_PCKB,
    [ChainId.CALLISTO]: INFO_CLIENT_CLO,
    [ChainId.EVMOS]: INFO_CLIENT_EVMOS,
    [ChainId.ENERGY_WEB_CHAIN]: INFO_CLIENT_EWT,
    [ChainId.BASE]: INFO_CLIENT_BASE_ETH,
    [ChainId.KAVA]: INFO_CLIENT_KAVA,
    [ChainId.CLOVER]: INFO_CLIENT_CLOVER,
    [ChainId.DEFICHAIN]: INFO_CLIENT_DEFICHAIN,
    [ChainId.BRONOS]: INFO_CLIENT_BRONOS,
    [ChainId.FILECOIN]: INFO_CLIENT_FILECOIN,
    [ChainId.FLARE]: INFO_CLIENT_FLARE,
    [ChainId.TARAXA]: INFO_CLIENT_TARAXA,
    [ChainId.ZKSYNC]: INFO_CLIENT_ZKSYNC,
    [ChainId.LINEA]: INFO_CLIENT_LINEA,
    [ChainId.BTTCHAIN]: INFO_CLIENT_BTTCHAIN,
    [ChainId.BOBA_AVAX]: INFO_CLIENT_BOBA_AVAX,
    [ChainId.BOBA_BNB]: INFO_CLIENT_BOBA_BNB,
    [ChainId.CMP]: INFO_CLIENT_CMP,
    [ChainId.CORE]: INFO_CLIENT_CORE,
    [ChainId.CUBECHAIN]: INFO_CLIENT_CUBECHAIN,
    [ChainId.DARWINIA_CRAB]: INFO_CLIENT_DARWINIA_CRAB,
    [ChainId.DIODE_PRETNET]: INFO_CLIENT_DIODE_PRETNET,
    [ChainId.GOCHAIN]: INFO_CLIENT_GOCHAIN,
    [ChainId.HAQQ]: INFO_CLIENT_HAQQ,
    [ChainId.KARDIACHAIN]: INFO_CLIENT_KARDIACHAIN,
    [ChainId.METADIUM]: INFO_CLIENT_METADIUM,
    [ChainId.PALM]: INFO_CLIENT_PALM,
    [ChainId.POLIS]: INFO_CLIENT_POLIS,
    [ChainId.SHIBACHAIN]: INFO_CLIENT_SHIBACHAIN,
    [ChainId.SMART_BCH]: INFO_CLIENT_SMART_BCH,
    [ChainId.SONGBIRD_CANARY]: INFO_CLIENT_SONGBIRD_CANARY,
    [ChainId.THAICHAIN]: INFO_CLIENT_THAICHAIN,
    [ChainId.UBIQ]: INFO_CLIENT_UBIQ,
    [ChainId.VECHAIN]: INFO_CLIENT_VECHAIN,
    [ChainId.XINFIN]: INFO_CLIENT_XINFIN,
    [ChainId.SCROLL]: INFO_CLIENT_SCROLL,
    [ChainId.XRPL_DEVNET]: INFO_CLIENT_XRPL_DEVNET,
    [ChainId.ZETACHAIN]: INFO_CLIENT_ZETACHAIN,
    [ChainId.ZYX]: INFO_CLIENT_ZYX,
}