import React from "react";
import styled from "styled-components";
import LogoFlame from "../Svg/Icons/LogoFlame";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Colors } from "../../theme";

export interface Props {
  color?: keyof Colors;
  flamePriceUsd?: number;
  showSkeleton?: boolean;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const FlamePrice: React.FC<React.PropsWithChildren<Props>> = ({
  flamePriceUsd,
  color = "textSubtle",
  showSkeleton = true,
}) => {
  return flamePriceUsd ? (
    <PriceLink
      href="https://docs.donaswap.io"
      target="_blank"
    >
      <LogoFlame width="24px" mr="8px" />
      {/* <Text color={color} bold>{`$${flamePriceUsd.toFixed(5)}`}</Text> */}
      <Text color={color} bold>Coming Soon</Text>
    </PriceLink>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(FlamePrice);
