import {
  Box,
  Flex,
  Message,
  Tag,
  LockIcon,
  MessageText,
  useTooltip,
  TooltipText,
  Skeleton,
  Text,
  NextLinkFromReactRouter,
  useMatchBreakpoints,
} from '@donaswap/uikit'
import { VaultPosition } from 'utils/flamePool'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from '@donaswap/localization'
import styled from 'styled-components'
import useFlameBenefits from './hooks/useFlameBenefits'

const FlameBenefitsCardWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 24px;
  padding: 1px 1px 3px 1px;
  background: linear-gradient(180deg, #53dee9, #7645d9);
  border-radius: ${({ theme }) => theme.radii.default};
`

const FlameBenefitsCardInner = styled(Box)`
  position: relative;
  z-index: 1;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: ${({ theme }) => theme.radii.default};

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: ${({ theme }) => theme.radii.default};
    background: ${({ theme }) => theme.colors.gradientBubblegum};
  }
`

interface FlameBenefitsCardProps {
  onDismiss: () => void
}

const FlameBenefitsCard: React.FC<React.PropsWithChildren<FlameBenefitsCardProps>> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { data: flameBenefits, status: flameBenefitsFetchStatus } = useFlameBenefits()
  const { isMobile } = useMatchBreakpoints()

  const {
    targetRef: flameTargetRef,
    tooltip: flameTooltip,
    tooltipVisible: flameTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`%lockedFlame% FLAME (including rewards) are locked in the FLAME Pool until %lockedEndTime%`, {
          lockedFlame: flameBenefits?.lockedFlame,
          lockedEndTime: flameBenefits?.lockedEndTime,
        })}
      </Text>
      <NextLinkFromReactRouter to="/pools" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: iFlameTargetRef,
    tooltip: iFlameTooltip,
    tooltipVisible: iFlameTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`iFLAME allows you to participate in the IFO public sales and commit up to %iFlame% amount of FLAME.`, {
          iFlame: flameBenefits?.iFlame,
        })}
      </Text>
      <NextLinkFromReactRouter to="/ifo" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: bFlameTargetRef,
    tooltip: bFlameTooltip,
    tooltipVisible: bFlameTooltipVisible,
  } = useTooltip(
    <>
      <Text>{t(`bFLAME allows you to boost your yield in Donaswap Farms by up to 2x.`)}</Text>
      <NextLinkFromReactRouter to="/farms" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  const {
    targetRef: vFlameTargetRef,
    tooltip: vFlameTooltip,
    tooltipVisible: vFlameTooltipVisible,
  } = useTooltip(
    <>
      <Text>
        {t(`vFLAME boosts your voting power to %totalScore% in the Donaswap voting governance.`, {
          totalScore: flameBenefits?.vFlame?.totalScore,
        })}
      </Text>
      <NextLinkFromReactRouter to="/voting" onClick={onDismiss}>
        <Text bold color="primary">
          {t('Learn More')}
        </Text>
      </NextLinkFromReactRouter>
    </>,
    {
      placement: 'bottom',
      ...(isMobile && { hideTimeout: 2000 }),
    },
  )

  return flameBenefitsFetchStatus === FetchStatus.Fetched ? (
    <>
      {[VaultPosition.None, VaultPosition.Flexible].includes(flameBenefits?.lockPosition) ? (
        <>
          <Flex flexDirection="row" alignItems="center">
            <Tag variant="secondary" mr="auto">
              <Flex alignItems="center">
                <Box as={LockIcon} mr="4px" />
                {t('No FLAME locked')}
              </Flex>
            </Tag>
            <Text fontSize="16px">{flameBenefits?.lockedFlame}</Text>
          </Flex>
          <Message mt="8px" mb="16px" variant="warning">
            <MessageText maxWidth="200px">
              {t(
                'Lock FLAME to enjoy the benefits of farm yield boosting, participating in IFOs, voting power boosts, and so much more!',
              )}{' '}
              <NextLinkFromReactRouter
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                to="/pools"
                onClick={onDismiss}
              >
                {t('Go to Pools')}
              </NextLinkFromReactRouter>
            </MessageText>
          </Message>
        </>
      ) : [VaultPosition.LockedEnd, VaultPosition.AfterBurning].includes(flameBenefits?.lockPosition) ? (
        <>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <Tag variant="failure" mr="auto">
              <Flex alignItems="center">
                <Box as={LockIcon} mr="4px" />
                {t('FLAME staking expired')}
              </Flex>
            </Tag>
            <Text fontSize="16px">{flameBenefits?.lockedFlame}</Text>
          </Flex>
          <Message mt="8px" mb="16px" variant="warning">
            <MessageText maxWidth="200px">
              {t(
                'Renew your staking position to continue enjoying the benefits of farm yield boosting, participating in IFOs, voting power boosts, and so much more!',
              )}{' '}
              <NextLinkFromReactRouter
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                to="/pools"
                onClick={onDismiss}
              >
                {t('Go to Pools')}
              </NextLinkFromReactRouter>
            </MessageText>
          </Message>
        </>
      ) : (
        <FlameBenefitsCardWrapper>
          <FlameBenefitsCardInner>
            <Flex flexDirection="row" alignItems="center">
              <Tag variant="secondary" mr="auto">
                <Flex alignItems="center">
                  <Box as={LockIcon} mr="4px" />
                  {t('FLAME locked')}
                </Flex>
              </Tag>
              <TooltipText ref={flameTargetRef} bold fontSize="16px">
                {flameBenefits?.lockedFlame}
              </TooltipText>
              {flameTooltipVisible && flameTooltip}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={iFlameTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                iFLAME
              </TooltipText>
              {iFlameTooltipVisible && iFlameTooltip}
              {flameBenefits?.iFlame}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={bFlameTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                bFLAME
              </TooltipText>
              {bFlameTooltipVisible && bFlameTooltip}
              {t('Up to %boostMultiplier%x', { boostMultiplier: 2 })}
            </Flex>
            <Flex mt="10px" flexDirection="row" alignItems="center">
              <TooltipText ref={vFlameTargetRef} color="textSubtle" fontSize="16px" mr="auto">
                vFLAME
              </TooltipText>
              {vFlameTooltipVisible && vFlameTooltip}
              {flameBenefits?.vFlame?.vaultScore}
            </Flex>
          </FlameBenefitsCardInner>
        </FlameBenefitsCardWrapper>
      )}
    </>
  ) : (
    <Skeleton width="100%" height={146} borderRadius="16px" marginBottom={24} />
  )
}

export default FlameBenefitsCard
