import { ChainId, Percent, Token, WNATIVE } from '@donaswap/sdk'
import {
  firechainTokens,
  riniaTokens,
  bscTokens,
  bscTestnetTokens,
  // ethereumTokens,
  // goerliTokens,
  sepoliaTokens,
  holeskyTokens,
  polygonTokens,
  polygonAmoyTokens,
  polygonZkEvmTokens,
  polygonZkEvmTestnetTokens,
  cronosTokens,
  cronosTestnetTokens,
  avalancheTokens,
  avalancheFujiTokens,
  fantomOperaTokens,
  fantomTestnetTokens,
  celoTokens,
  celoAlfajoresTokens,
  optimismTokens,
  optimismGoerliTokens,
  // arbitrumTokens,
  // arbitrumGoerliTokens,
  shibariumTokens,
  shibariumTestnetTokens,
  pulsechainTokens,
  pulsechainTestnetTokens,
  xenTokens,
  xenDevnetTokens,
  harmonyTokens,
  harmonyTestnetTokens,
  coinExTokens,
  coinExTestnetTokens,
  dogechainTokens,
  dogechainTestnetTokens,
  engramTestnetTokens,
  etcTokens,
  etcTestnetTokens,
  fusionTokens,
  fusionTestnetTokens,
  hecoTokens,
  hecoTestnetTokens,
  kccTokens,
  kccTestnetTokens,
  klaytnCypressTokens,
  klaytnBaobabTokens,
  okxChainTokens,
  okxChainTestnetTokens,
  thetaTokens,
  thetaTestnetTokens,
  ultronTokens,
  ultronTestnetTokens,
  moonbeamTokens,
  moonriverTokens,
  moonbaseAlphaTokens,
  auroraTokens,
  auroraTestnetTokens,
  bobaTokens,
  bobaGoerliTokens,
  gnosisTokens,
  gnosisChiadoTokens,
  metisTokens,
  metisGoerliTokens,
  telosTokens,
  telosTestnetTokens,
  syscoinTokens,
  syscoinTanenbaumTokens,
  iotexTokens,
  iotexTestnetTokens,
  shidenTokens,
  astarTokens,
  shibunyaTokens,
  fuseTokens,
  fuseSparknetTokens,
  velasTokens,
  velasTestnetTokens,
  reiTokens,
  reiTestnetTokens,
  kekchainTokens,
  kekchainTestnetTokens,
  tomochainTokens,
  tomochainTestnetTokens,
  thundercoreTokens,
  thundercoreTestnetTokens,
  wanchainTokens,
  wanchainTestnetTokens,
  rskTokens,
  rskTestnetTokens,
  elastosTokens,
  elastosTestnetTokens,
  confluxTokens,
  confluxTestnetTokens,
  brisechainTokens,
  brisechainTestnetTokens,
  muuchainTokens,
  muuchainTestnetTokens,
  cantoTokens,
  cantoTestnetTokens,
  oasisEmeraldTokens,
  oasisTestnetTokens,
  visionTokens,
  visionTestnetTokens,
  stepTokens,
  stepTestnetTokens,
  meterTokens,
  meterTestnetTokens,
  godwokenTokens,
  godwokenTestnetTokens,
  callistoTokens,
  callistoTestnetTokens,
  evmosTokens,
  evmosTestnetTokens,
  energyWebchainTokens,
  energyVoltaTokens,
  baseTokens,
  baseGoerliTokens,
  kavaTokens,
  kavaTestnetTokens,
  cloverTokens,
  cloverTestnetTokens,
  defichainTokens,
  defichainTestnetTokens,
  bronosTokens,
  bronosTestnetTokens,
  filecoinTokens,
  filecoinCalibrationTokens,
  flareTokens,
  flareTestnetTokens,
  taraxaTokens,
  taraxaTestnetTokens,
  arbitrumTokens,
  zksyncTokens,
  zkSyncTestnetTokens,
  lineaTestnetTokens,
  arbitrumGoerliTokens,

  bobaAvaxTokens,
  bobaBnbTokens,
  bttchainTokens,
  bttchainTestnetTokens,
  cmpTokens,
  cmpTestnetTokens,
  coreTokens,
  coreTestnetTokens,
  cubechainTokens,
  cubechainTestnetTokens,
  darwiniaCrabTokens,
  darwiniaPangolinTokens,
  diodePretnetTokens,
  diodeTestnetTokens,
  gochainTokens,
  gochainTestnetTokens,
  haqqTokens,
  haqqTestedgeTokens,
  kardiachainTokens,
  kardiachainTestnetTokens,
  lineaTokens,
  metadiumTokens,
  metadiumTestnetTokens,
  palmTokens,
  palmTestnetTokens,
  polisTokens,
  polisTestnetTokens,
  shibachainTokens,
  smartBchTokens,
  smartBchTestnetTokens,
  songbirdCanaryTokens,
  thaichainTokens,
  ubiqTokens,
  ubiqTestnetTokens,
  vechainTokens,
  vechainTestnetTokens,
  xinfinTokens,
  xinfinApothemTokens,
  scrollTokens,
  scrollSepoliaTokens,
  xrplDevnetTokens,
  zetachainTokens,
  zetachainAthensTokens,
  zyxTokens,
  opBnbTokens,
  opBnbTestnetTokens,
  USDC,
  USDT,
  BUSD,
  WBTC_ETH,
} from '@donaswap/tokens'
import { ChainTokenList } from './types'

export {
  ADDITIONAL_BASES,
  V2_ROUTER_ADDRESS,
  BASES_TO_CHECK_TRADES_AGAINST,
  CUSTOM_BASES,
} from '@donaswap/smart-router/evm'

export const CHAIN_REFRESH_TIME = {
  [ChainId.FIRECHAIN]: 6_000,
  [ChainId.RINIA]: 6_000,
  [ChainId.ETHEREUM]: 12_000,
  [ChainId.GOERLI]: 12_000,
  [ChainId.SEPOLIA]: 12_000,
  [ChainId.HOLESKY]: 12_000,
  [ChainId.BSC]: 6_000,
  [ChainId.BSC_TESTNET]: 6_000,
  [ChainId.POLYGON]: 12_000,
  [ChainId.POLYGON_AMOY]: 12_000,
  [ChainId.CRO]: 12_000,
  [ChainId.CRO_TESTNET]: 12_000,
  [ChainId.AVALANCHE]: 12_000,
  [ChainId.AVALANCHE_FUJI]: 12_000,
  [ChainId.FANTOM_OPERA]: 12_000,
  [ChainId.FANTOM_TESTNET]: 12_000,
  [ChainId.CELO]: 12_000,
  [ChainId.CELO_ALFAJORES]: 12_000,
  [ChainId.OPTIMISM]: 12_000,
  [ChainId.OPTIMISM_GOERLI]: 12_000,
  [ChainId.SHIBARIUM]: 12_000,
  [ChainId.SHIBARIUM_TESTNET]: 12_000,
  [ChainId.PULSECHAIN]: 12_000,
  [ChainId.PULSECHAIN_TESTNET]: 12_000,
  [ChainId.XEN]: 12_000,
  [ChainId.XEN_DEVNET]: 12_000,
  [ChainId.HARMONY]: 12_000,
  [ChainId.HARMONY_TESTNET]: 12_000,
  [ChainId.COINEX]: 12_000,
  [ChainId.COINEX_TESTNET]: 12_000,
  [ChainId.DOGECHAIN]: 12_000,
  [ChainId.DOGECHAIN_TESTNET]: 12_000,
  [ChainId.ENGRAM_TESTNET]: 12_000,
  [ChainId.ETHEREUM_CLASSIC]: 12_000,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: 12_000,
  [ChainId.FUSION]: 12_000,
  [ChainId.FUSION_TESTNET]: 12_000,
  [ChainId.HECO]: 12_000,
  [ChainId.HECO_TESTNET]: 12_000,
  [ChainId.KCC]: 12_000,
  [ChainId.KCC_TESTNET]: 12_000,
  [ChainId.KLAYTN]: 12_000,
  [ChainId.KLAYTN_BAOBAB]: 12_000,
  [ChainId.OKXCHAIN]: 12_000,
  [ChainId.OKXCHAIN_TESTNET]: 12_000,
  [ChainId.THETHA]: 12_000,
  [ChainId.THETHA_TESTNET]: 12_000,
  [ChainId.ULTRON]: 12_000,
  [ChainId.ULTRON_TESTNET]: 12_000,
  [ChainId.MOONBEAM]: 12_000,
  [ChainId.MOONRIVER]: 12_000,
  [ChainId.MOONBASE_ALPHA]: 12_000,
  [ChainId.AURORA]: 12_000,
  [ChainId.AURORA_TESTNET]: 12_000,
  [ChainId.BOBA]: 12_000,
  [ChainId.BOBA_GOERLI]: 12_000,
  [ChainId.GNOSIS]: 12_000,
  [ChainId.GNOSIS_CHIADO]: 12_000,
  [ChainId.METIS]: 12_000,
  [ChainId.METIS_GOERLI]: 12_000,
  [ChainId.TELOS]: 12_000,
  [ChainId.TELOS_TESTNET]: 12_000,
  [ChainId.SYSCOIN]: 12_000,
  [ChainId.SYSCOIN_TANENBAUM]: 12_000,
  [ChainId.IOTEX]: 12_000,
  [ChainId.IOTEX_TESTNET]: 12_000,
  [ChainId.SHIDEN]: 12_000,
  [ChainId.ASTAR]: 12_000,
  [ChainId.SHIBUNYA]: 12_000,
  [ChainId.FUSE]: 12_000,
  [ChainId.FUSE_SPARKNET]: 12_000,
  [ChainId.VELAS]: 12_000,
  [ChainId.VELAS_TESTNET]: 12_000,
  [ChainId.REI]: 12_000,
  [ChainId.REI_TESTNET]: 12_000,
  [ChainId.KEKCHAIN]: 12_000,
  [ChainId.KEKCHAIN_TESTNET]: 12_000,
  [ChainId.TOMOCHAIN]: 12_000,
  [ChainId.TOMOCHAIN_TESTNET]: 12_000,
  [ChainId.THUNDERCORE]: 12_000,
  [ChainId.THUNDERCORE_TESTNET]: 12_000,
  [ChainId.WANCHAIN]: 12_000,
  [ChainId.WANCHAIN_TESTNET]: 12_000,
  [ChainId.RSK]: 12_000,
  [ChainId.RSK_TESTNET]: 12_000,
  [ChainId.ELASTOS]: 12_000,
  [ChainId.ELASTOS_TESTNET]: 12_000,
  [ChainId.CONFLUX]: 12_000,
  [ChainId.CONFLUX_TESTNET]: 12_000,
  [ChainId.BRISECHAIN]: 12_000,
  [ChainId.BRISECHAIN_TESTNET]: 12_000,
  [ChainId.MUUCHAIN]: 12_000,
  [ChainId.MUUCHAIN_TESTNET]: 12_000,
  [ChainId.CANTO]: 12_000,
  [ChainId.CANTO_TESTNET]: 12_000,
  [ChainId.OASIS_EMERALD]: 12_000,
  [ChainId.OASIS_TESTNET]: 12_000,
  [ChainId.VISION]: 12_000,
  [ChainId.VISION_TESTNET]: 12_000,
  [ChainId.STEP]: 12_000,
  [ChainId.STEP_TESTNET]: 12_000,
  [ChainId.METER]: 12_000,
  [ChainId.METER_TESTNET]: 12_000,
  [ChainId.GODWOKEN]: 12_000,
  [ChainId.GODWOKEN_TESTNET]: 12_000,
  [ChainId.CALLISTO]: 12_000,
  [ChainId.CALLISTO_TESTNET]: 12_000,
  [ChainId.EVMOS]: 12_000,
  [ChainId.EVMOS_TESTNET]: 12_000,
  [ChainId.ENERGY_WEB_CHAIN]: 12_000,
  [ChainId.ENERGY_VOLTA]: 12_000,
  [ChainId.BASE]: 12_000,
  [ChainId.BASE_GOERLI]: 12_000,
  [ChainId.KAVA]: 12_000,
  [ChainId.KAVA_TESTNET]: 12_000,
  [ChainId.CLOVER]: 12_000,
  [ChainId.CLOVER_TESTNET]: 12_000,
  [ChainId.DEFICHAIN]: 12_000,
  [ChainId.DEFICHAIN_TESTNET]: 12_000,
  [ChainId.BRONOS]: 12_000,
  [ChainId.BRONOS_TESTNET]: 12_000,
  [ChainId.FILECOIN]: 12_000,
  [ChainId.FILECOIN_CALIBRATION]: 12_000,
  [ChainId.FLARE]: 12_000,
  [ChainId.FLARE_TESTNET]: 12_000,
  [ChainId.TARAXA]: 12_000,
  [ChainId.TARAXA_TESTNET]: 12_000,
  [ChainId.ARBITRUM_ONE]: 4_000,
  [ChainId.ARBITRUM_GOERLI]: 4_000,
  [ChainId.POLYGON_ZKEVM]: 7_000,
  [ChainId.POLYGON_ZKEVM_TESTNET]: 7_000,
  [ChainId.ZKSYNC]: 3_000,
  [ChainId.ZKSYNC_TESTNET]: 3_000,
  [ChainId.LINEA]: 12_000,
  [ChainId.LINEA_TESTNET]: 12_000,
  [ChainId.BTTCHAIN]: 12_000,
  [ChainId.BTTCHAIN_TESTNET]: 12_000,
  [ChainId.BOBA_AVAX]: 12_000,
  [ChainId.BOBA_BNB]: 12_000,
  [ChainId.CMP]: 12_000,
  [ChainId.CMP_TESTNET]: 12_000,
  [ChainId.CORE]: 12_000,
  [ChainId.CORE_TESTNET]: 12_000,
  [ChainId.CUBECHAIN]: 12_000,
  [ChainId.CUBECHAIN_TESTNET]: 12_000,
  [ChainId.DARWINIA_CRAB]: 12_000,
  [ChainId.DARWINIA_PANGOLIN]: 12_000,
  [ChainId.DIODE_PRETNET]: 12_000,
  [ChainId.DIODE_TESTNET]: 12_000,
  [ChainId.GOCHAIN]: 12_000,
  [ChainId.GOCHAIN_TESTNET]: 12_000,
  [ChainId.HAQQ]: 12_000,
  [ChainId.HAQQ_TESTEDGE2]: 12_000,
  [ChainId.KARDIACHAIN]: 12_000,
  [ChainId.KARDIACHAIN_TESTNET]: 12_000,
  [ChainId.METADIUM]: 12_000,
  [ChainId.METADIUM_TESTNET]: 12_000,
  [ChainId.OP_BNB]: 12_000,
  [ChainId.OP_BNB_TESTNET]: 12_000,
  [ChainId.PALM]: 12_000,
  [ChainId.PALM_TESTNET]: 12_000,
  [ChainId.POLIS]: 12_000,
  [ChainId.POLIS_TESTNET]: 12_000,
  [ChainId.SHIBACHAIN]: 12_000,
  [ChainId.SMART_BCH]: 12_000,
  [ChainId.SMART_BCH_TESTNET]: 12_000,
  [ChainId.SONGBIRD_CANARY]: 12_000,
  [ChainId.THAICHAIN]: 12_000,
  [ChainId.UBIQ]: 12_000,
  [ChainId.UBIQ_TESTNET]: 12_000,
  [ChainId.VECHAIN]: 12_000,
  [ChainId.VECHAIN_TESTNET]: 12_000,
  [ChainId.XINFIN]: 12_000,
  [ChainId.XINFIN_APOTHEM]: 12_000,
  [ChainId.SCROLL]: 12_000,
  [ChainId.SCROLL_SEPOLIA]: 12_000,
  [ChainId.XRPL_DEVNET]: 12_000,
  [ChainId.ZETACHAIN]: 12_000,
  [ChainId.ZETACHAIN_ATHENS]: 12_000,
  [ChainId.ZYX]: 12_000,
} as const satisfies Record<ChainId, number>

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.FIRECHAIN]: [firechainTokens.wfire],
  [ChainId.RINIA]: [riniaTokens.wfire, WNATIVE[ChainId.RINIA]],
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.SEPOLIA]: [sepoliaTokens.weth, sepoliaTokens.dona, sepoliaTokens.usdt],
  [ChainId.HOLESKY]: [holeskyTokens.weth, holeskyTokens.dona, holeskyTokens.usdt],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.btcb, bscTokens.dona],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.flame, bscTestnetTokens.busd],
  [ChainId.POLYGON]: [polygonTokens.wmatic, polygonTokens.usdt, polygonTokens.dona],
  [ChainId.POLYGON_AMOY]: [polygonAmoyTokens.wmatic],
  [ChainId.CRO]: [cronosTokens.wcro],
  [ChainId.CRO_TESTNET]: [cronosTestnetTokens.wcro],
  [ChainId.AVALANCHE]: [avalancheTokens.wavax],
  [ChainId.AVALANCHE_FUJI]: [avalancheFujiTokens.wavax],
  [ChainId.FANTOM_OPERA]: [fantomOperaTokens.wftm],
  [ChainId.FANTOM_TESTNET]: [fantomTestnetTokens.wftm],
  [ChainId.CELO]: [celoTokens.wcelo],
  [ChainId.CELO_ALFAJORES]: [celoAlfajoresTokens.wcelo],
  [ChainId.OPTIMISM]: [optimismTokens.weth],
  [ChainId.OPTIMISM_GOERLI]: [optimismGoerliTokens.weth],
  [ChainId.SHIBARIUM]: [shibariumTokens.wbone],
  [ChainId.SHIBARIUM_TESTNET]: [shibariumTestnetTokens.wbone],
  [ChainId.PULSECHAIN]: [pulsechainTokens.wpls],
  [ChainId.PULSECHAIN_TESTNET]: [pulsechainTestnetTokens.wpls],
  [ChainId.XEN]: [xenTokens.wxn],
  [ChainId.XEN_DEVNET]: [xenDevnetTokens.wxn],
  [ChainId.HARMONY]: [harmonyTokens.wone],
  [ChainId.HARMONY_TESTNET]: [harmonyTestnetTokens.wone],
  [ChainId.COINEX]: [coinExTokens.wcet],
  [ChainId.COINEX_TESTNET]: [coinExTestnetTokens.wcet],
  [ChainId.DOGECHAIN]: [dogechainTokens.wdoge],
  [ChainId.DOGECHAIN_TESTNET]: [dogechainTestnetTokens.wdoge],
  [ChainId.ENGRAM_TESTNET]: [engramTestnetTokens.wgram],
  [ChainId.ETHEREUM_CLASSIC]: [etcTokens.wetc],
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: [etcTestnetTokens.wetc],
  [ChainId.FUSION]: [fusionTokens.wfsn],
  [ChainId.FUSION_TESTNET]: [fusionTestnetTokens.wfsn],
  [ChainId.HECO]: [hecoTokens.wht],
  [ChainId.HECO_TESTNET]: [hecoTestnetTokens.wht],
  [ChainId.KCC]: [kccTokens.wkcs],
  [ChainId.KCC_TESTNET]: [kccTestnetTokens.wkcs],
  [ChainId.KLAYTN]: [klaytnCypressTokens.wklay],
  [ChainId.KLAYTN_BAOBAB]: [klaytnBaobabTokens.wklay],
  [ChainId.OKXCHAIN]: [okxChainTokens.wokt],
  [ChainId.OKXCHAIN_TESTNET]: [okxChainTestnetTokens.wokt],
  [ChainId.THETHA]: [thetaTokens.wtfuel],
  [ChainId.THETHA_TESTNET]: [thetaTestnetTokens.wtfuel],
  [ChainId.ULTRON]: [ultronTokens.wulx],
  [ChainId.ULTRON_TESTNET]: [ultronTestnetTokens.wulx],
  [ChainId.MOONBEAM]: [moonbeamTokens.wglmr],
  [ChainId.MOONRIVER]: [moonriverTokens.wmovr],
  [ChainId.MOONBASE_ALPHA]: [moonbaseAlphaTokens.wdev],
  [ChainId.AURORA]: [auroraTokens.weth],
  [ChainId.AURORA_TESTNET]: [auroraTestnetTokens.weth],
  [ChainId.BOBA]: [bobaTokens.weth],
  [ChainId.BOBA_GOERLI]: [bobaGoerliTokens.weth],
  [ChainId.GNOSIS]: [gnosisTokens.wxdai],
  [ChainId.GNOSIS_CHIADO]: [gnosisChiadoTokens.wxdai],
  [ChainId.METIS]: [metisTokens.wmetis],
  [ChainId.METIS_GOERLI]: [metisGoerliTokens.wmetis],
  [ChainId.TELOS]: [telosTokens.wtlos],
  [ChainId.TELOS_TESTNET]: [telosTestnetTokens.wtlos],
  [ChainId.SYSCOIN]: [syscoinTokens.wsys],
  [ChainId.SYSCOIN_TANENBAUM]: [syscoinTanenbaumTokens.wsys],
  [ChainId.IOTEX]: [iotexTokens.wiotx],
  [ChainId.IOTEX_TESTNET]: [iotexTestnetTokens.wiotx],
  [ChainId.SHIDEN]: [shidenTokens.wsdn],
  [ChainId.ASTAR]: [astarTokens.wastr],
  [ChainId.SHIBUNYA]: [shibunyaTokens.wsby],
  [ChainId.FUSE]: [fuseTokens.wfuse],
  [ChainId.FUSE_SPARKNET]: [fuseSparknetTokens.wfuse],
  [ChainId.VELAS]: [velasTokens.wvlx],
  [ChainId.VELAS_TESTNET]: [velasTestnetTokens.wvlx],
  [ChainId.REI]: [reiTokens.wrei],
  [ChainId.REI_TESTNET]: [reiTestnetTokens.wrei],
  [ChainId.KEKCHAIN]: [kekchainTokens.wkek],
  [ChainId.KEKCHAIN_TESTNET]: [kekchainTestnetTokens.wkek],
  [ChainId.TOMOCHAIN]: [tomochainTokens.wtomo],
  [ChainId.TOMOCHAIN_TESTNET]: [tomochainTestnetTokens.wtomo],
  [ChainId.THUNDERCORE]: [thundercoreTokens.wtt],
  [ChainId.THUNDERCORE_TESTNET]: [thundercoreTestnetTokens.wtt],
  [ChainId.WANCHAIN]: [wanchainTokens.wwan],
  [ChainId.WANCHAIN_TESTNET]: [wanchainTestnetTokens.wwan],
  [ChainId.RSK]: [rskTokens.wrbtc],
  [ChainId.RSK_TESTNET]: [rskTestnetTokens.wrbtc],
  [ChainId.ELASTOS]: [elastosTokens.wela],
  [ChainId.ELASTOS_TESTNET]: [elastosTestnetTokens.wela],
  [ChainId.CONFLUX]: [confluxTokens.wcfx],
  [ChainId.CONFLUX_TESTNET]: [confluxTestnetTokens.wcfx],
  [ChainId.BRISECHAIN]: [brisechainTokens.wbrise],
  [ChainId.BRISECHAIN_TESTNET]: [brisechainTestnetTokens.wbrise],
  [ChainId.MUUCHAIN]: [muuchainTokens.wmuu],
  [ChainId.MUUCHAIN_TESTNET]: [muuchainTestnetTokens.wmuu],
  [ChainId.CANTO]: [cantoTokens.wcanto],
  [ChainId.CANTO_TESTNET]: [cantoTestnetTokens.wcanto],
  [ChainId.OASIS_EMERALD]: [oasisEmeraldTokens.wrose],
  [ChainId.OASIS_TESTNET]: [oasisTestnetTokens.wrose],
  [ChainId.VISION]: [visionTokens.wvs],
  [ChainId.VISION_TESTNET]: [visionTestnetTokens.wvs],
  [ChainId.STEP]: [stepTokens.wfitfi],
  [ChainId.STEP_TESTNET]: [stepTestnetTokens.wfitfi],
  [ChainId.METER]: [meterTokens.wmtr],
  [ChainId.METER_TESTNET]: [meterTestnetTokens.wmtr],
  [ChainId.GODWOKEN]: [godwokenTokens.wpckb],
  [ChainId.GODWOKEN_TESTNET]: [godwokenTestnetTokens.wpckb],
  [ChainId.CALLISTO]: [callistoTokens.wclo],
  [ChainId.CALLISTO_TESTNET]: [callistoTestnetTokens.wclo],
  [ChainId.EVMOS]: [evmosTokens.wevmos],
  [ChainId.EVMOS_TESTNET]: [evmosTestnetTokens.wevmos],
  [ChainId.ENERGY_WEB_CHAIN]: [energyWebchainTokens.wewt],
  [ChainId.ENERGY_VOLTA]: [energyVoltaTokens.wewt],
  [ChainId.BASE]: [baseTokens.weth],
  [ChainId.BASE_GOERLI]: [baseGoerliTokens.weth],
  [ChainId.KAVA]: [kavaTokens.wkava],
  [ChainId.KAVA_TESTNET]: [kavaTestnetTokens.wkava],
  [ChainId.CLOVER]: [cloverTokens.wclv],
  [ChainId.CLOVER_TESTNET]: [cloverTestnetTokens.wclv],
  [ChainId.DEFICHAIN]: [defichainTokens.wdfi],
  [ChainId.DEFICHAIN_TESTNET]: [defichainTestnetTokens.wdfi],
  [ChainId.BRONOS]: [bronosTokens.wbro],
  [ChainId.BRONOS_TESTNET]: [bronosTestnetTokens.wbro],
  [ChainId.FILECOIN]: [filecoinTokens.wfil],
  [ChainId.FILECOIN_CALIBRATION]: [filecoinCalibrationTokens.wfil],
  [ChainId.FLARE]: [flareTokens.wflr],
  [ChainId.FLARE_TESTNET]: [flareTestnetTokens.wflr],
  [ChainId.TARAXA]: [taraxaTokens.wtara],
  [ChainId.TARAXA_TESTNET]: [taraxaTestnetTokens.wtara],
  [ChainId.ARBITRUM_ONE]: [arbitrumTokens.weth, arbitrumTokens.usdt, arbitrumTokens.usdc],
  [ChainId.ARBITRUM_GOERLI]: [arbitrumGoerliTokens.weth, arbitrumGoerliTokens.usdc],
  [ChainId.POLYGON_ZKEVM]: [polygonZkEvmTokens.weth, polygonZkEvmTokens.usdt, polygonZkEvmTokens.usdc],
  [ChainId.POLYGON_ZKEVM_TESTNET]: [polygonZkEvmTestnetTokens.weth, polygonZkEvmTestnetTokens.usdt],
  [ChainId.ZKSYNC]: [zksyncTokens.usdc, zksyncTokens.weth],
  [ChainId.ZKSYNC_TESTNET]: [zkSyncTestnetTokens.usdc, zkSyncTestnetTokens.weth],
  [ChainId.LINEA]: [lineaTokens.usdc, lineaTokens.weth],
  [ChainId.LINEA_TESTNET]: [lineaTestnetTokens.usdc, lineaTestnetTokens.weth],
  [ChainId.BTTCHAIN]: [bttchainTokens.wbtt],
  [ChainId.BTTCHAIN_TESTNET]: [bttchainTestnetTokens.wbtt],
  [ChainId.BOBA_AVAX]: [bobaAvaxTokens.wboba],
  [ChainId.BOBA_BNB]: [bobaBnbTokens.wboba],
  [ChainId.CMP]: [cmpTokens.wcmp],
  [ChainId.CMP_TESTNET]: [cmpTestnetTokens.wcmp],
  [ChainId.CORE]: [coreTokens.wcore],
  [ChainId.CORE_TESTNET]: [coreTestnetTokens.wcore],
  [ChainId.CUBECHAIN]: [cubechainTokens.wcube],
  [ChainId.CUBECHAIN_TESTNET]: [cubechainTestnetTokens.wcube],
  [ChainId.DARWINIA_CRAB]: [darwiniaCrabTokens.wcrab],
  [ChainId.DARWINIA_PANGOLIN]: [darwiniaPangolinTokens.wpring],
  [ChainId.DIODE_PRETNET]: [diodePretnetTokens.wdiode],
  [ChainId.DIODE_TESTNET]: [diodeTestnetTokens.wdiode],
  [ChainId.GOCHAIN]: [gochainTokens.wgo],
  [ChainId.GOCHAIN_TESTNET]: [gochainTestnetTokens.wgo],
  [ChainId.HAQQ]: [haqqTokens.wislm],
  [ChainId.HAQQ_TESTEDGE2]: [haqqTestedgeTokens.wislm],
  [ChainId.KARDIACHAIN]: [kardiachainTokens.wkai],
  [ChainId.KARDIACHAIN_TESTNET]: [kardiachainTestnetTokens.wkai],
  [ChainId.METADIUM]: [metadiumTokens.wmeta],
  [ChainId.METADIUM_TESTNET]: [metadiumTestnetTokens.wkal],
  [ChainId.OP_BNB]: [opBnbTokens.wbnb],
  [ChainId.OP_BNB_TESTNET]: [opBnbTestnetTokens.wbnb],
  [ChainId.PALM]: [palmTokens.wpalm],
  [ChainId.PALM_TESTNET]: [palmTestnetTokens.wpalm],
  [ChainId.POLIS]: [polisTokens.wpolis],
  [ChainId.POLIS_TESTNET]: [polisTestnetTokens.wpolis],
  [ChainId.SHIBACHAIN]: [shibachainTokens.wshib],
  [ChainId.SMART_BCH]: [smartBchTokens.wbch],
  [ChainId.SMART_BCH_TESTNET]: [smartBchTestnetTokens.wbch],
  [ChainId.SONGBIRD_CANARY]: [songbirdCanaryTokens.wsgb],
  [ChainId.THAICHAIN]: [thaichainTokens.wtch],
  [ChainId.UBIQ]: [ubiqTokens.wubq],
  [ChainId.UBIQ_TESTNET]: [ubiqTestnetTokens.wubq],
  [ChainId.VECHAIN]: [vechainTokens.wvet],
  [ChainId.VECHAIN_TESTNET]: [vechainTestnetTokens.wvet],
  [ChainId.XINFIN]: [xinfinTokens.wxdc],
  [ChainId.XINFIN_APOTHEM]: [xinfinApothemTokens.wxdc],
  [ChainId.SCROLL]: [scrollTokens.weth],
  [ChainId.SCROLL_SEPOLIA]: [scrollSepoliaTokens.weth],
  [ChainId.XRPL_DEVNET]: [xrplDevnetTokens.wxrp],
  [ChainId.ZETACHAIN]: [zetachainTokens.wzeta],
  [ChainId.ZETACHAIN_ATHENS]: [zetachainAthensTokens.wzeta],
  [ChainId.ZYX]: [zyxTokens.wzyx],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.FIRECHAIN]: [firechainTokens.wfire],
  [ChainId.RINIA]: [riniaTokens.wfire],
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.SEPOLIA]: [sepoliaTokens.weth, sepoliaTokens.dona],
  [ChainId.HOLESKY]: [holeskyTokens.weth, holeskyTokens.dona],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt, bscTokens.dona],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.flame, bscTestnetTokens.busd],
  [ChainId.POLYGON]: [polygonTokens.wmatic],
  [ChainId.POLYGON_AMOY]: [polygonAmoyTokens.wmatic, polygonAmoyTokens.flame],
  [ChainId.CRO]: [cronosTokens.wcro],
  [ChainId.CRO_TESTNET]: [cronosTestnetTokens.wcro],
  [ChainId.AVALANCHE]: [avalancheTokens.wavax],
  [ChainId.AVALANCHE_FUJI]: [avalancheFujiTokens.wavax],
  [ChainId.FANTOM_OPERA]: [fantomOperaTokens.wftm],
  [ChainId.FANTOM_TESTNET]: [fantomTestnetTokens.wftm],
  [ChainId.CELO]: [celoTokens.wcelo],
  [ChainId.CELO_ALFAJORES]: [celoAlfajoresTokens.wcelo],
  [ChainId.OPTIMISM]: [optimismTokens.weth],
  [ChainId.OPTIMISM_GOERLI]: [optimismGoerliTokens.weth],
  [ChainId.SHIBARIUM]: [shibariumTokens.wbone],
  [ChainId.SHIBARIUM_TESTNET]: [shibariumTestnetTokens.wbone],
  [ChainId.PULSECHAIN]: [pulsechainTokens.wpls],
  [ChainId.PULSECHAIN_TESTNET]: [pulsechainTestnetTokens.wpls],
  [ChainId.XEN]: [xenTokens.wxn],
  [ChainId.XEN_DEVNET]: [xenDevnetTokens.wxn],
  [ChainId.HARMONY]: [harmonyTokens.wone],
  [ChainId.HARMONY_TESTNET]: [harmonyTestnetTokens.wone],
  [ChainId.COINEX]: [coinExTokens.wcet],
  [ChainId.COINEX_TESTNET]: [coinExTestnetTokens.wcet],
  [ChainId.DOGECHAIN]: [dogechainTokens.wdoge],
  [ChainId.DOGECHAIN_TESTNET]: [dogechainTestnetTokens.wdoge],
  [ChainId.ENGRAM_TESTNET]: [engramTestnetTokens.wgram],
  [ChainId.ETHEREUM_CLASSIC]: [etcTokens.wetc],
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: [etcTestnetTokens.wetc],
  [ChainId.FUSION]: [fusionTokens.wfsn],
  [ChainId.FUSION_TESTNET]: [fusionTestnetTokens.wfsn],
  [ChainId.HECO]: [hecoTokens.wht],
  [ChainId.HECO_TESTNET]: [hecoTestnetTokens.wht],
  [ChainId.KCC]: [kccTokens.wkcs],
  [ChainId.KCC_TESTNET]: [kccTestnetTokens.wkcs],
  [ChainId.KLAYTN]: [klaytnCypressTokens.wklay],
  [ChainId.KLAYTN_BAOBAB]: [klaytnBaobabTokens.wklay],
  [ChainId.OKXCHAIN]: [okxChainTokens.wokt],
  [ChainId.OKXCHAIN_TESTNET]: [okxChainTestnetTokens.wokt],
  [ChainId.THETHA]: [thetaTokens.wtfuel],
  [ChainId.THETHA_TESTNET]: [thetaTestnetTokens.wtfuel],
  [ChainId.ULTRON]: [ultronTokens.wulx],
  [ChainId.ULTRON_TESTNET]: [ultronTestnetTokens.wulx],
  [ChainId.MOONBEAM]: [moonbeamTokens.wglmr],
  [ChainId.MOONRIVER]: [moonriverTokens.wmovr],
  [ChainId.MOONBASE_ALPHA]: [moonbaseAlphaTokens.wdev],
  [ChainId.AURORA]: [auroraTokens.weth],
  [ChainId.AURORA_TESTNET]: [auroraTestnetTokens.weth],
  [ChainId.BOBA]: [bobaTokens.weth],
  [ChainId.BOBA_GOERLI]: [bobaGoerliTokens.weth],
  [ChainId.GNOSIS]: [gnosisTokens.wxdai],
  [ChainId.GNOSIS_CHIADO]: [gnosisChiadoTokens.wxdai],
  [ChainId.METIS]: [metisTokens.wmetis],
  [ChainId.METIS_GOERLI]: [metisGoerliTokens.wmetis],
  [ChainId.TELOS]: [telosTokens.wtlos],
  [ChainId.TELOS_TESTNET]: [telosTestnetTokens.wtlos],
  [ChainId.SYSCOIN]: [syscoinTokens.wsys],
  [ChainId.SYSCOIN_TANENBAUM]: [syscoinTanenbaumTokens.wsys],
  [ChainId.IOTEX]: [iotexTokens.wiotx],
  [ChainId.IOTEX_TESTNET]: [iotexTestnetTokens.wiotx],
  [ChainId.SHIDEN]: [shidenTokens.wsdn],
  [ChainId.ASTAR]: [astarTokens.wastr],
  [ChainId.SHIBUNYA]: [shibunyaTokens.wsby],
  [ChainId.FUSE]: [fuseTokens.wfuse],
  [ChainId.FUSE_SPARKNET]: [fuseSparknetTokens.wfuse],
  [ChainId.VELAS]: [velasTokens.wvlx],
  [ChainId.VELAS_TESTNET]: [velasTestnetTokens.wvlx],
  [ChainId.REI]: [reiTokens.wrei],
  [ChainId.REI_TESTNET]: [reiTestnetTokens.wrei],
  [ChainId.KEKCHAIN]: [kekchainTokens.wkek],
  [ChainId.KEKCHAIN_TESTNET]: [kekchainTestnetTokens.wkek],
  [ChainId.TOMOCHAIN]: [tomochainTokens.wtomo],
  [ChainId.TOMOCHAIN_TESTNET]: [tomochainTestnetTokens.wtomo],
  [ChainId.THUNDERCORE]: [thundercoreTokens.wtt],
  [ChainId.THUNDERCORE_TESTNET]: [thundercoreTestnetTokens.wtt],
  [ChainId.WANCHAIN]: [wanchainTokens.wwan],
  [ChainId.WANCHAIN_TESTNET]: [wanchainTestnetTokens.wwan],
  [ChainId.RSK]: [rskTokens.wrbtc],
  [ChainId.RSK_TESTNET]: [rskTestnetTokens.wrbtc],
  [ChainId.ELASTOS]: [elastosTokens.wela],
  [ChainId.ELASTOS_TESTNET]: [elastosTestnetTokens.wela],
  [ChainId.CONFLUX]: [confluxTokens.wcfx],
  [ChainId.CONFLUX_TESTNET]: [confluxTestnetTokens.wcfx],
  [ChainId.BRISECHAIN]: [brisechainTokens.wbrise],
  [ChainId.BRISECHAIN_TESTNET]: [brisechainTestnetTokens.wbrise],
  [ChainId.MUUCHAIN]: [muuchainTokens.wmuu],
  [ChainId.MUUCHAIN_TESTNET]: [muuchainTestnetTokens.wmuu],
  [ChainId.CANTO]: [cantoTokens.wcanto],
  [ChainId.CANTO_TESTNET]: [cantoTestnetTokens.wcanto],
  [ChainId.OASIS_EMERALD]: [oasisEmeraldTokens.wrose],
  [ChainId.OASIS_TESTNET]: [oasisTestnetTokens.wrose],
  [ChainId.VISION]: [visionTokens.wvs],
  [ChainId.VISION_TESTNET]: [visionTestnetTokens.wvs],
  [ChainId.STEP]: [stepTokens.wfitfi],
  [ChainId.STEP_TESTNET]: [stepTestnetTokens.wfitfi],
  [ChainId.METER]: [meterTokens.wmtr],
  [ChainId.METER_TESTNET]: [meterTestnetTokens.wmtr],
  [ChainId.GODWOKEN]: [godwokenTokens.wpckb],
  [ChainId.GODWOKEN_TESTNET]: [godwokenTestnetTokens.wpckb],
  [ChainId.CALLISTO]: [callistoTokens.wclo],
  [ChainId.CALLISTO_TESTNET]: [callistoTestnetTokens.wclo],
  [ChainId.EVMOS]: [evmosTokens.wevmos],
  [ChainId.EVMOS_TESTNET]: [evmosTestnetTokens.wevmos],
  [ChainId.ENERGY_WEB_CHAIN]: [energyWebchainTokens.wewt],
  [ChainId.ENERGY_VOLTA]: [energyVoltaTokens.wewt],
  [ChainId.BASE]: [baseTokens.weth],
  [ChainId.BASE_GOERLI]: [baseGoerliTokens.weth],
  [ChainId.KAVA]: [kavaTokens.wkava],
  [ChainId.KAVA_TESTNET]: [kavaTestnetTokens.wkava],
  [ChainId.CLOVER]: [cloverTokens.wclv],
  [ChainId.CLOVER_TESTNET]: [cloverTestnetTokens.wclv],
  [ChainId.DEFICHAIN]: [defichainTokens.wdfi],
  [ChainId.DEFICHAIN_TESTNET]: [defichainTestnetTokens.wdfi],
  [ChainId.BRONOS]: [bronosTokens.wbro],
  [ChainId.BRONOS_TESTNET]: [bronosTestnetTokens.wbro],
  [ChainId.FILECOIN]: [filecoinTokens.wfil],
  [ChainId.FILECOIN_CALIBRATION]: [filecoinCalibrationTokens.wfil],
  [ChainId.FLARE]: [flareTokens.wflr],
  [ChainId.FLARE_TESTNET]: [flareTestnetTokens.wflr],
  [ChainId.TARAXA]: [taraxaTokens.wtara],
  [ChainId.TARAXA_TESTNET]: [taraxaTestnetTokens.wtara],
  [ChainId.ARBITRUM_ONE]: [arbitrumTokens.weth, arbitrumTokens.usdt, arbitrumTokens.usdc],
  [ChainId.ARBITRUM_GOERLI]: [arbitrumGoerliTokens.weth, arbitrumGoerliTokens.usdc],
  [ChainId.POLYGON_ZKEVM]: [polygonZkEvmTokens.weth, polygonZkEvmTokens.usdt, polygonZkEvmTokens.usdc],
  [ChainId.POLYGON_ZKEVM_TESTNET]: [polygonZkEvmTestnetTokens.weth, polygonZkEvmTestnetTokens.usdt],
  [ChainId.ZKSYNC]: [zksyncTokens.usdc, zksyncTokens.weth],
  [ChainId.ZKSYNC_TESTNET]: [zkSyncTestnetTokens.usdc, zkSyncTestnetTokens.weth],
  [ChainId.LINEA]: [lineaTokens.usdc, lineaTokens.weth],
  [ChainId.LINEA_TESTNET]: [lineaTestnetTokens.usdc, lineaTestnetTokens.weth],
  [ChainId.BTTCHAIN]: [bttchainTokens.wbtt],
  [ChainId.BTTCHAIN_TESTNET]: [bttchainTestnetTokens.wbtt],
  [ChainId.BOBA_AVAX]: [bobaAvaxTokens.wboba],
  [ChainId.BOBA_BNB]: [bobaBnbTokens.wboba],
  [ChainId.CMP]: [cmpTokens.wcmp],
  [ChainId.CMP_TESTNET]: [cmpTestnetTokens.wcmp],
  [ChainId.CORE]: [coreTokens.wcore],
  [ChainId.CORE_TESTNET]: [coreTestnetTokens.wcore],
  [ChainId.CUBECHAIN]: [cubechainTokens.wcube],
  [ChainId.CUBECHAIN_TESTNET]: [cubechainTestnetTokens.wcube],
  [ChainId.DARWINIA_CRAB]: [darwiniaCrabTokens.wcrab],
  [ChainId.DARWINIA_PANGOLIN]: [darwiniaPangolinTokens.wpring],
  [ChainId.DIODE_PRETNET]: [diodePretnetTokens.wdiode],
  [ChainId.DIODE_TESTNET]: [diodeTestnetTokens.wdiode],
  [ChainId.GOCHAIN]: [gochainTokens.wgo],
  [ChainId.GOCHAIN_TESTNET]: [gochainTestnetTokens.wgo],
  [ChainId.HAQQ]: [haqqTokens.wislm],
  [ChainId.HAQQ_TESTEDGE2]: [haqqTestedgeTokens.wislm],
  [ChainId.KARDIACHAIN]: [kardiachainTokens.wkai],
  [ChainId.KARDIACHAIN_TESTNET]: [kardiachainTestnetTokens.wkai],
  [ChainId.METADIUM]: [metadiumTokens.wmeta],
  [ChainId.METADIUM_TESTNET]: [metadiumTestnetTokens.wkal],
  [ChainId.OP_BNB]: [opBnbTokens.wbnb],
  [ChainId.OP_BNB_TESTNET]: [opBnbTestnetTokens.wbnb],
  [ChainId.PALM]: [palmTokens.wpalm],
  [ChainId.PALM_TESTNET]: [palmTestnetTokens.wpalm],
  [ChainId.POLIS]: [polisTokens.wpolis],
  [ChainId.POLIS_TESTNET]: [polisTestnetTokens.wpolis],
  [ChainId.SHIBACHAIN]: [shibachainTokens.wshib],
  [ChainId.SMART_BCH]: [smartBchTokens.wbch],
  [ChainId.SMART_BCH_TESTNET]: [smartBchTestnetTokens.wbch],
  [ChainId.SONGBIRD_CANARY]: [songbirdCanaryTokens.wsgb],
  [ChainId.THAICHAIN]: [thaichainTokens.wtch],
  [ChainId.UBIQ]: [ubiqTokens.wubq],
  [ChainId.UBIQ_TESTNET]: [ubiqTestnetTokens.wubq],
  [ChainId.VECHAIN]: [vechainTokens.wvet],
  [ChainId.VECHAIN_TESTNET]: [vechainTestnetTokens.wvet],
  [ChainId.XINFIN]: [xinfinTokens.wxdc],
  [ChainId.XINFIN_APOTHEM]: [xinfinApothemTokens.wxdc],
  [ChainId.SCROLL]: [scrollTokens.weth],
  [ChainId.SCROLL_SEPOLIA]: [scrollSepoliaTokens.weth],
  [ChainId.XRPL_DEVNET]: [xrplDevnetTokens.wxrp],
  [ChainId.ZETACHAIN]: [zetachainTokens.wzeta],
  [ChainId.ZETACHAIN_ATHENS]: [zetachainAthensTokens.wzeta],
  [ChainId.ZYX]: [zyxTokens.wzyx],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBTC_ETH, WNATIVE[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.dona, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt],
  ],
  [ChainId.ARBITRUM_ONE]: [
    [arbitrumTokens.weth, arbitrumTokens.usdt],
    [arbitrumTokens.weth, arbitrumTokens.usdc],
  ],
  [ChainId.ARBITRUM_GOERLI]: [[arbitrumGoerliTokens.weth, arbitrumGoerliTokens.usdc]],
  [ChainId.POLYGON_ZKEVM]: [[polygonZkEvmTokens.weth, polygonZkEvmTokens.usdt]],
  [ChainId.ZKSYNC]: [[zksyncTokens.usdc, zksyncTokens.weth]],
  [ChainId.ZKSYNC_TESTNET]: [[zkSyncTestnetTokens.usdc, zkSyncTestnetTokens.weth]],
  [ChainId.LINEA_TESTNET]: [[lineaTestnetTokens.usdc, lineaTestnetTokens.weth]],

  // ##TODO
  [ChainId.POLYGON]: [
    [polygonTokens.wmatic, polygonTokens.flame]
  ],
  [ChainId.POLYGON_AMOY]: [
    [polygonAmoyTokens.wmatic, polygonAmoyTokens.flame]
  ],
  // [ChainId.CRO]: [cronosTokens.wcro],
  // [ChainId.CRO_TESTNET]: [cronosTestnetTokens.wcro],
  // [ChainId.AVALANCHE]: [avalancheTokens.wavax],
  // [ChainId.AVALANCHE_FUJI]: [avalancheFujiTokens.wavax],
  // [ChainId.FANTOM_OPERA]: [fantomOperaTokens.wftm],
  // [ChainId.FANTOM_TESTNET]: [fantomTestnetTokens.wftm],
  // [ChainId.CELO]: [celoTokens.wcelo],
  // [ChainId.CELO_ALFAJORES]: [celoAlfajoresTokens.wcelo],
  // [ChainId.OPTIMISM]: [optimismTokens.weth],
  // [ChainId.OPTIMISM_GOERLI]: [optimismGoerliTokens.weth],
  // [ChainId.SHIBARIUM]: [shibariumTokens.wbone],
  // [ChainId.SHIBARIUM_TESTNET]: [shibariumTestnetTokens.wbone],
  // [ChainId.PULSECHAIN]: [pulsechainTokens.wpls],
  // [ChainId.PULSECHAIN_TESTNET]: [pulsechainTestnetTokens.wpls],
  // [ChainId.XEN]: [xenTokens.wxn],
  // [ChainId.XEN_DEVNET]: [xenDevnetTokens.wxn],
  // [ChainId.HARMONY]: [harmonyTokens.wone],
  // [ChainId.HARMONY_TESTNET]: [harmonyTestnetTokens.wone],
  // [ChainId.COINEX]: [coinExTokens.wcet],
  // [ChainId.COINEX_TESTNET]: [coinExTestnetTokens.wcet],
  // [ChainId.DOGECHAIN]: [dogechainTokens.wdoge],
  // [ChainId.DOGECHAIN_TESTNET]: [dogechainTestnetTokens.wdoge],
  // [ChainId.ETHEREUM_CLASSIC]: [etcTokens.wetc],
  // [ChainId.ETHEREUM_CLASSIC_TESTNET]: [etcTestnetTokens.wetc],
  // [ChainId.FUSION]: [fusionTokens.wfsn],
  // [ChainId.FUSION_TESTNET]: [fusionTestnetTokens.wfsn],
  // [ChainId.HECO]: [hecoTokens.wht],
  // [ChainId.HECO_TESTNET]: [hecoTestnetTokens.wht],
  // [ChainId.KCC]: [kccTokens.wkcs],
  // [ChainId.KCC_TESTNET]: [kccTestnetTokens.wkcs],
  // [ChainId.KLAYTN]: [klaytnCypressTokens.wklay],
  // [ChainId.KLAYTN_BAOBAB]: [klaytnBaobabTokens.wklay],
  // [ChainId.OKXCHAIN]: [okxChainTokens.wokt],
  // [ChainId.OKXCHAIN_TESTNET]: [okxChainTestnetTokens.wokt],
  // [ChainId.THETHA]: [thetaTokens.wtfuel],
  // [ChainId.THETHA_TESTNET]: [thetaTestnetTokens.wtfuel],
  // [ChainId.ULTRON]: [ultronTokens.wulx],
  // [ChainId.ULTRON_TESTNET]: [ultronTestnetTokens.wulx],
  // [ChainId.MOONBEAM]: [moonbeamTokens.wglmr],
  // [ChainId.MOONRIVER]: [moonriverTokens.wmovr],
  // [ChainId.MOONBASE_ALPHA]: [moonbaseAlphaTokens.wdev],
  // [ChainId.AURORA]: [auroraTokens.weth],
  // [ChainId.AURORA_TESTNET]: [auroraTestnetTokens.weth],
  // [ChainId.BOBA]: [bobaTokens.weth],
  // [ChainId.BOBA_GOERLI]: [bobaGoerliTokens.weth],
  // [ChainId.GNOSIS]: [gnosisTokens.wxdai],
  // [ChainId.GNOSIS_CHIADO]: [gnosisChiadoTokens.wxdai],
  // [ChainId.METIS]: [metisTokens.wmetis],
  // [ChainId.METIS_GOERLI]: [metisGoerliTokens.wmetis],
  // [ChainId.TELOS]: [telosTokens.wtlos],
  // [ChainId.TELOS_TESTNET]: [telosTestnetTokens.wtlos],
  // [ChainId.SYSCOIN]: [syscoinTokens.wsys],
  // [ChainId.SYSCOIN_TANENBAUM]: [syscoinTanenbaumTokens.wsys],
  // [ChainId.IOTEX]: [iotexTokens.wiotx],
  // [ChainId.IOTEX_TESTNET]: [iotexTestnetTokens.wiotx],
  // [ChainId.SHIDEN]: [shidenTokens.wsdn],
  // [ChainId.ASTAR]: [astarTokens.wastr],
  // [ChainId.SHIBUNYA]: [shibunyaTokens.wsby],
  // [ChainId.FUSE]: [fuseTokens.wfuse],
  // [ChainId.FUSE_SPARKNET]: [fuseSparknetTokens.wfuse],
  // [ChainId.VELAS]: [velasTokens.wvlx],
  // [ChainId.VELAS_TESTNET]: [velasTestnetTokens.wvlx],
  // [ChainId.REI]: [reiTokens.wrei],
  // [ChainId.REI_TESTNET]: [reiTestnetTokens.wrei],
  // [ChainId.KEKCHAIN]: [kekchainTokens.wkek],
  // [ChainId.KEKCHAIN_TESTNET]: [kekchainTestnetTokens.wkek],
  // [ChainId.TOMOCHAIN]: [tomochainTokens.wtomo],
  // [ChainId.TOMOCHAIN_TESTNET]: [tomochainTestnetTokens.wtomo],
  // [ChainId.THUNDERCORE]: [thundercoreTokens.wtt],
  // [ChainId.THUNDERCORE_TESTNET]: [thundercoreTestnetTokens.wtt],
  // [ChainId.WANCHAIN]: [wanchainTokens.wwan],
  // [ChainId.WANCHAIN_TESTNET]: [wanchainTestnetTokens.wwan],
  // [ChainId.RSK]: [rskTokens.wrbtc],
  // [ChainId.RSK_TESTNET]: [rskTestnetTokens.wrbtc],
  // [ChainId.ELASTOS]: [elastosTokens.wela],
  // [ChainId.ELASTOS_TESTNET]: [elastosTestnetTokens.wela],
  // [ChainId.CONFLUX]: [confluxTokens.wcfx],
  // [ChainId.CONFLUX_TESTNET]: [confluxTestnetTokens.wcfx],
  // [ChainId.BRISECHAIN]: [brisechainTokens.wbrise],
  // [ChainId.BRISECHAIN_TESTNET]: [brisechainTestnetTokens.wbrise],
  // [ChainId.MUUCHAIN]: [muuchainTokens.wmuu],
  // [ChainId.MUUCHAIN_TESTNET]: [muuchainTestnetTokens.wmuu],
  // [ChainId.CANTO]: [cantoTokens.wcanto],
  // [ChainId.CANTO_TESTNET]: [cantoTestnetTokens.wcanto],
  // [ChainId.OASIS_EMERALD]: [oasisEmeraldTokens.wrose],
  // [ChainId.OASIS_TESTNET]: [oasisTestnetTokens.wrose],
  // [ChainId.VISION]: [visionTokens.wvs],
  // [ChainId.VISION_TESTNET]: [visionTestnetTokens.wvs],
  // [ChainId.STEP]: [stepTokens.wfitfi],
  // [ChainId.STEP_TESTNET]: [stepTestnetTokens.wfitfi],
  // [ChainId.METER]: [meterTokens.wmtr],
  // [ChainId.METER_TESTNET]: [meterTestnetTokens.wmtr],
  // [ChainId.GODWOKEN]: [godwokenTokens.wpckb],
  // [ChainId.GODWOKEN_TESTNET]: [godwokenTestnetTokens.wpckb],
  // [ChainId.CALLISTO]: [callistoTokens.wclo],
  // [ChainId.CALLISTO_TESTNET]: [callistoTestnetTokens.wclo],
  // [ChainId.EVMOS]: [evmosTokens.wevmos],
  // [ChainId.EVMOS_TESTNET]: [evmosTestnetTokens.wevmos],
  // [ChainId.ENERGY_WEB_CHAIN]: [energyWebchainTokens.wewt],
  // [ChainId.ENERGY_VOLTA]: [energyVoltaTokens.wewt],
  // [ChainId.BASE]: [baseTokens.weth],
  // [ChainId.BASE_GOERLI]: [baseGoerliTokens.weth],
  // [ChainId.KAVA]: [kavaTokens.wkava],
  // [ChainId.KAVA_TESTNET]: [kavaTestnetTokens.wkava],
  // [ChainId.CLOVER]: [cloverTokens.wclv],
  // [ChainId.CLOVER_TESTNET]: [cloverTestnetTokens.wclv],
  // [ChainId.DEFICHAIN]: [defichainTokens.wdfi],
  // [ChainId.DEFICHAIN_TESTNET]: [defichainTestnetTokens.wdfi],
  // [ChainId.BRONOS]: [bronosTokens.wbro],
  // [ChainId.BRONOS_TESTNET]: [bronosTestnetTokens.wbro],
  // [ChainId.FILECOIN]: [filecoinTokens.wfil],
  // [ChainId.FILECOIN_CALIBRATION]: [filecoinCalibrationTokens.wfil],
  // [ChainId.FLARE]: [flareTokens.wflr],
  // [ChainId.FLARE_TESTNET]: [flareTestnetTokens.wflr],
  // [ChainId.TARAXA]: [taraxaTokens.wtara],
  // [ChainId.TARAXA_TESTNET]: [taraxaTestnetTokens.wtara],
}

export const BIG_INT_ZERO = 0n
export const BIG_INT_TEN = 10n

// one basis point
export const BIPS_BASE = 10000n
export const ONE_BIPS = new Percent(1n, BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100n, BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300n, BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500n, BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(1000n, BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(1500n, BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: bigint = BIG_INT_TEN ** 15n // .001 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(50n, BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(25n, BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'BNB'
export const DEFAULT_OUTPUT_CURRENCY = '0x229C32460c6BeaC113e720ac4a7495b57F53F7Cf'

// Handler string is passed to Gelato to use Donaswap router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = 500000n

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.donaswap.io/products/donaswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
