import { useAccount } from 'wagmi'
import BigNumber from 'bignumber.js'
import useSWR from 'swr'
import { useIfoCreditAddressContract } from 'hooks/useContract'
import { ChainId } from '@donaswap/sdk'
import { getBalanceNumber } from '@donaswap/utils/formatBalance'
import { useTranslation } from '@donaswap/localization'
import { useChainCurrentBlock } from 'state/block/hooks'
import { getVaultPosition, VaultPosition } from 'utils/flamePool'
import { getFlameVaultAddress } from 'utils/addressHelpers'
import { getActivePools } from 'utils/calls'
import { flameVaultV2ABI } from '@donaswap/pools'
import { BIG_ZERO } from '@donaswap/utils/bigNumber'
import { convertSharesToFlame } from 'views/Pools/helpers'
import { getScores } from 'views/Voting/getScores'
import { DONASWAP_SPACE } from 'views/Voting/config'
import { flamePoolBalanceStrategy, createTotalStrategy } from 'views/Voting/strategies'
import { publicClient } from 'utils/wagmi'

const bscClient = publicClient({ chainId: ChainId.BSC })

const useFlameBenefits = () => {
  const { address: account } = useAccount()
  const {
    currentLanguage: { locale },
  } = useTranslation()
  const ifoCreditAddressContract = useIfoCreditAddressContract()
  const flameVaultAddress = getFlameVaultAddress()
  const currentBscBlock = useChainCurrentBlock(ChainId.BSC)

  const { data, status } = useSWR(account && currentBscBlock && ['flameBenefits', account], async () => {
    const [userInfo, currentPerformanceFee, currentOverdueFee, sharePrice] = await bscClient.multicall({
      contracts: [
        {
          address: flameVaultAddress,
          abi: flameVaultV2ABI,
          functionName: 'userInfo',
          args: [account],
        },
        {
          address: flameVaultAddress,
          abi: flameVaultV2ABI,
          functionName: 'calculatePerformanceFee',
          args: [account],
        },
        {
          address: flameVaultAddress,
          abi: flameVaultV2ABI,
          functionName: 'calculateOverdueFee',
          args: [account],
        },
        {
          address: flameVaultAddress,
          abi: flameVaultV2ABI,
          functionName: 'getPricePerFullShare',
        },
      ],
      allowFailure: false,
    })

    const userContractResponse = {
      shares: userInfo[0],
      lastDepositedTime: userInfo[1],
      flameAtLastUserAction: userInfo[2],
      lastUserActionTime: userInfo[3],
      lockStartTime: userInfo[4],
      lockEndTime: userInfo[5],
      userBoostedShare: userInfo[6],
      locked: userInfo[7],
      lockedAmount: userInfo[8],
    }

    const currentPerformanceFeeAsBigNumber = new BigNumber(currentPerformanceFee.toString())
    const currentOverdueFeeAsBigNumber = new BigNumber(currentOverdueFee.toString())
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    const userBoostedSharesAsBignumber = new BigNumber(userContractResponse.userBoostedShare.toString())
    const userSharesAsBignumber = new BigNumber(userContractResponse.shares.toString())
    const lockPosition = getVaultPosition({
      userShares: userSharesAsBignumber,
      locked: userContractResponse.locked,
      lockEndTime: userContractResponse.lockEndTime.toString(),
    })
    const lockedFlame = [VaultPosition.None, VaultPosition.Flexible].includes(lockPosition)
      ? '0.00'
      : convertSharesToFlame(
          userSharesAsBignumber,
          sharePriceAsBigNumber,
          undefined,
          undefined,
          currentOverdueFeeAsBigNumber.plus(currentPerformanceFeeAsBigNumber).plus(userBoostedSharesAsBignumber),
        ).flameAsNumberBalance.toLocaleString('en', { maximumFractionDigits: 3 })

    let iFlame = ''
    let vFlame = { vaultScore: '0', totalScore: '0' }
    if (lockPosition === VaultPosition.Locked) {
      const credit = await ifoCreditAddressContract.read.getUserCredit([account])
      iFlame = getBalanceNumber(new BigNumber(credit.toString())).toLocaleString('en', { maximumFractionDigits: 3 })

      const eligiblePools = await getActivePools(ChainId.BSC, currentBscBlock)
      const poolAddresses = eligiblePools.map(({ contractAddress }) => contractAddress)

      const [flameVaultBalance, total] = await getScores(
        DONASWAP_SPACE,
        [flamePoolBalanceStrategy('v1'), createTotalStrategy(poolAddresses, 'v1')],
        ChainId.BSC.toString(),
        [account],
        Number(currentBscBlock),
      )
      vFlame = {
        vaultScore: flameVaultBalance[account]
          ? flameVaultBalance[account].toLocaleString('en', { maximumFractionDigits: 3 })
          : '0',
        totalScore: total[account] ? total[account].toLocaleString('en', { maximumFractionDigits: 3 }) : '0',
      }
    }

    return {
      lockedFlame,
      lockPosition,
      lockedEndTime: new Date(parseInt(userContractResponse.lockEndTime.toString()) * 1000).toLocaleString(locale, {
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      }),
      iFlame,
      vFlame,
    }
  })

  return { data, status }
}

export default useFlameBenefits
