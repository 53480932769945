import { ChainId } from '@donaswap/sdk'
import {
  arbitrum,
  polygonZkEvm,
  zkSync,
  zkSyncTestnet,
  polygonZkEvmTestnet,
  arbitrumGoerli,
  polygon,
  avalanche,
  avalancheFuji,
  optimism,
  optimismGoerli,
  sepolia,
  // celoAlfajores,
} from 'wagmi/chains'

import { getNodeRealUrlV2 } from 'utils/nodeReal'
import {
  firechain,
  rinia,
  holesky,
  polygonAmoy,
  celo,
  celoAlfajores,
  pulsechain,
  pulsechainTestnet,
  shibarium,
  shibariumTestnet,
  fantomOpera,
  fantomTestnet,
  xen,
  xenDevnet,
  harmony,
  harmonyTestnet,
  coinEx,
  coinExTestnet,
  dogechain,
  dogechainTestnet,
  engramTestnet,
  ethereumClassic,
  ethereumClassicMordor,
  fusion,
  fusionTestnet,
  heco,
  hecoTestnet,
  kcc,
  kccTestnet,
  klaytnCypress,
  klaytnBaobab,
  okxChain,
  okxChainTestnet,
  theta,
  thetaTestnet,
  ultron,
  ultronTestnet,
  moonbeam,
  moonriver,
  moonbaseAlpha,
  aurora,
  auroraTestnet,
  boba,
  bobaGoerli,
  gnosis,
  gnosisChiado,
  metis,
  metisGoerli,
  telos,
  telosTestnet,
  syscoin,
  syscoinTanenbaum,
  iotex,
  iotexTestnet,
  shiden,
  astar,
  shibunya,
  fuse,
  fuseSparknet,
  velas,
  velasTestnet,
  rei,
  reiTestnet,
  kekchain,
  kekchainTestnet,
  tomochain,
  tomochainTestnet,
  thundercore,
  thundercoreTestnet,
  wanchain,
  wanchainTestnet,
  rsk,
  rskTestnet,
  elastos,
  elastosTestnet,
  conflux,
  confluxTestnet,
  brisechain,
  brisechainTestnet,
  muuchain,
  muuchainTestnet,
  canto,
  cantoTestnet,
  oasis,
  oasisTestnet,
  vision,
  visionTestnet,
  step,
  stepTestnet,
  meter,
  meterTestnet,
  godwoken,
  godwokenTestnet,
  callisto,
  callistoTestnet,
  evmos,
  evmosTestnet,
  energyWebChain,
  energyVolta,
  base,
  baseGoerli,
  // sepolia,
  kava,
  kavaTestnet,
  clover,
  cloverTestnet,
  defichain,
  defichainTestnet,
  bronos,
  bronosTestnet,
  filecoin,
  filecoinCalibration,
  flare,
  flareTestnet,
  taraxa,
  taraxaTestnet,
  cronos,
  cronosTestnet,
  bobaAvax,
  bobaBnb,
  bttchain,
  bttchainTestnet,
  cmp,
  cmpTestnet,
  core,
  coreTestnet,
  cubechain,
  cubechainTestnet,
  darwiniaCrab,
  darwiniaPangolin,
  diodePretnet,
  diodeTestnet,
  gochain,
  gochainTestnet,
  haqq,
  haqqTestedge,
  kardiachain,
  kardiachainTestnet,
  linea,
  metadium,
  metadiumTestnet,
  palm,
  palmTestnet,
  polis,
  polisTestnet,
  shibachain,
  smartBch,
  smartBchTestnet,
  songbirdCanary,
  thaichain,
  ubiq,
  ubiqTestnet,
  vechain,
  vechainTestnet,
  xinfin,
  xinfinApothem,
  scroll,
  scrollSepolia,
  zyx,
  opBnb,
  opBnbTestnet,
  xrplDevnet,
  zetachain,
  zetachainAthens,
} from '../../../../packages/wagmi/chains/addedChains'

export const SERVER_NODES = {
  [ChainId.FIRECHAIN]: firechain.rpcUrls.public.http,
  [ChainId.RINIA]: rinia.rpcUrls.public.http,
  [ChainId.ETHEREUM]: [
    getNodeRealUrlV2(ChainId.ETHEREUM, process.env.SERVER_NODE_REAL_API_ETH),
    'https://eth.llamarpc.com',
    'https://cloudflare-eth.com',
    'https://mainnet.infura.io/v3',
  ],
  [ChainId.GOERLI]: [
    getNodeRealUrlV2(ChainId.GOERLI, process.env.SERVER_NODE_REAL_API_GOERLI),
    'https://eth-goerli.public.blastapi.io',
  ].filter(Boolean),
  [ChainId.SEPOLIA]: sepolia.rpcUrls.public.http,
  [ChainId.HOLESKY]: holesky.rpcUrls.public.http,
  [ChainId.BSC]: [
    process.env.NEXT_PUBLIC_NODE_PRODUCTION,
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.binance.org',
  ].filter(Boolean),
  [ChainId.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [ChainId.ARBITRUM_ONE]: arbitrum.rpcUrls.public.http,
  [ChainId.ARBITRUM_GOERLI]: arbitrumGoerli.rpcUrls.public.http,
  [ChainId.POLYGON]: polygon.rpcUrls.public.http,
  [ChainId.POLYGON_AMOY]: polygonAmoy.rpcUrls.public.http,
  [ChainId.POLYGON_ZKEVM]: polygonZkEvm.rpcUrls.public.http,
  [ChainId.POLYGON_ZKEVM_TESTNET]: [
    'https://polygon-zkevm-testnet.rpc.thirdweb.com',
    ...polygonZkEvmTestnet.rpcUrls.public.http,
  ],
  [ChainId.ZKSYNC]: zkSync.rpcUrls.public.http,
  [ChainId.ZKSYNC_TESTNET]: zkSyncTestnet.rpcUrls.public.http,
  [ChainId.LINEA]: linea.rpcUrls.public.http,
  [ChainId.LINEA_TESTNET]: [
    'https://rpc.goerli.linea.build',
    'https://linea-testnet.rpc.thirdweb.com',
    'https://consensys-zkevm-goerli-prealpha.infura.io/v3/93e8a17747e34ec0ac9a554c1b403965',
  ],
  [ChainId.CRO]: cronos.rpcUrls.public.http,
  [ChainId.CRO_TESTNET]: cronosTestnet.rpcUrls.public.http,
  [ChainId.AVALANCHE]: avalanche.rpcUrls.public.http,
  [ChainId.AVALANCHE_FUJI]: avalancheFuji.rpcUrls.public.http,
  [ChainId.FANTOM_OPERA]: fantomOpera.rpcUrls.public.http,
  [ChainId.FANTOM_TESTNET]: fantomTestnet.rpcUrls.public.http,
  [ChainId.CELO]: celo.rpcUrls.public.http,
  [ChainId.CELO_ALFAJORES]: celoAlfajores.rpcUrls.public.http,
  [ChainId.OPTIMISM]: optimism.rpcUrls.public.http,
  [ChainId.OPTIMISM_GOERLI]: optimismGoerli.rpcUrls.public.http,
  [ChainId.SHIBARIUM]: shibarium.rpcUrls.public.http,
  [ChainId.SHIBARIUM_TESTNET]: shibariumTestnet.rpcUrls.public.http,
  [ChainId.PULSECHAIN]: pulsechain.rpcUrls.public.http,
  [ChainId.PULSECHAIN_TESTNET]: pulsechainTestnet.rpcUrls.public.http,
  [ChainId.XEN]: xen.rpcUrls.public.http,
  [ChainId.XEN_DEVNET]: xenDevnet.rpcUrls.public.http,
  [ChainId.HARMONY]: harmony.rpcUrls.public.http,
  [ChainId.HARMONY_TESTNET]: harmonyTestnet.rpcUrls.public.http,
  [ChainId.COINEX]: coinEx.rpcUrls.public.http,
  [ChainId.COINEX_TESTNET]: coinExTestnet.rpcUrls.public.http,
  [ChainId.DOGECHAIN]: dogechain.rpcUrls.public.http,
  [ChainId.DOGECHAIN_TESTNET]: dogechainTestnet.rpcUrls.public.http,
  [ChainId.ENGRAM_TESTNET]: engramTestnet.rpcUrls.public.http,
  [ChainId.ETHEREUM_CLASSIC]: ethereumClassic.rpcUrls.public.http,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: ethereumClassicMordor.rpcUrls.public.http,
  [ChainId.FUSION]: fusion.rpcUrls.public.http,
  [ChainId.FUSION_TESTNET]: fusionTestnet.rpcUrls.public.http,
  [ChainId.HECO]: heco.rpcUrls.public.http,
  [ChainId.HECO_TESTNET]: hecoTestnet.rpcUrls.public.http,
  [ChainId.KCC]: kcc.rpcUrls.public.http,
  [ChainId.KCC_TESTNET]: kccTestnet.rpcUrls.public.http,
  [ChainId.KLAYTN]: klaytnCypress.rpcUrls.public.http,
  [ChainId.KLAYTN_BAOBAB]: klaytnBaobab.rpcUrls.public.http,
  [ChainId.OKXCHAIN]: okxChain.rpcUrls.public.http,
  [ChainId.OKXCHAIN_TESTNET]: okxChainTestnet.rpcUrls.public.http,
  [ChainId.THETHA]: theta.rpcUrls.public.http,
  [ChainId.THETHA_TESTNET]: thetaTestnet.rpcUrls.public.http,
  [ChainId.ULTRON]: ultron.rpcUrls.public.http,
  [ChainId.ULTRON_TESTNET]: ultronTestnet.rpcUrls.public.http,
  [ChainId.MOONBEAM]: moonbeam.rpcUrls.public.http,
  [ChainId.MOONRIVER]: moonriver.rpcUrls.public.http,
  [ChainId.MOONBASE_ALPHA]: moonbaseAlpha.rpcUrls.public.http,
  [ChainId.AURORA]: aurora.rpcUrls.public.http,
  [ChainId.AURORA_TESTNET]: auroraTestnet.rpcUrls.public.http,
  [ChainId.BOBA]: boba.rpcUrls.public.http,
  [ChainId.BOBA_GOERLI]: bobaGoerli.rpcUrls.public.http,
  [ChainId.GNOSIS]: gnosis.rpcUrls.public.http,
  [ChainId.GNOSIS_CHIADO]: gnosisChiado.rpcUrls.public.http,
  [ChainId.METIS]: metis.rpcUrls.public.http,
  [ChainId.METIS_GOERLI]: metisGoerli.rpcUrls.public.http,
  [ChainId.TELOS]: telos.rpcUrls.public.http,
  [ChainId.TELOS_TESTNET]: telosTestnet.rpcUrls.public.http,
  [ChainId.SYSCOIN]: syscoin.rpcUrls.public.http,
  [ChainId.SYSCOIN_TANENBAUM]: syscoinTanenbaum.rpcUrls.public.http,
  [ChainId.IOTEX]: iotex.rpcUrls.public.http,
  [ChainId.IOTEX_TESTNET]: iotexTestnet.rpcUrls.public.http,
  [ChainId.SHIDEN]: shiden.rpcUrls.public.http,
  [ChainId.ASTAR]: astar.rpcUrls.public.http,
  [ChainId.SHIBUNYA]: shibunya.rpcUrls.public.http,
  [ChainId.FUSE]: fuse.rpcUrls.public.http,
  [ChainId.FUSE_SPARKNET]: fuseSparknet.rpcUrls.public.http,
  [ChainId.VELAS]: velas.rpcUrls.public.http,
  [ChainId.VELAS_TESTNET]: velasTestnet.rpcUrls.public.http,
  [ChainId.REI]: rei.rpcUrls.public.http,
  [ChainId.REI_TESTNET]: reiTestnet.rpcUrls.public.http,
  [ChainId.KEKCHAIN]: kekchain.rpcUrls.public.http,
  [ChainId.KEKCHAIN_TESTNET]: kekchainTestnet.rpcUrls.public.http,
  [ChainId.TOMOCHAIN]: tomochain.rpcUrls.public.http,
  [ChainId.TOMOCHAIN_TESTNET]: tomochainTestnet.rpcUrls.public.http,
  [ChainId.THUNDERCORE]: thundercore.rpcUrls.public.http,
  [ChainId.THUNDERCORE_TESTNET]: thundercoreTestnet.rpcUrls.public.http,
  [ChainId.WANCHAIN]: wanchain.rpcUrls.public.http,
  [ChainId.WANCHAIN_TESTNET]: wanchainTestnet.rpcUrls.public.http,
  [ChainId.RSK]: rsk.rpcUrls.public.http,
  [ChainId.RSK_TESTNET]: rskTestnet.rpcUrls.public.http,
  [ChainId.ELASTOS]: elastos.rpcUrls.public.http,
  [ChainId.ELASTOS_TESTNET]: elastosTestnet.rpcUrls.public.http,
  [ChainId.CONFLUX]: conflux.rpcUrls.public.http,
  [ChainId.CONFLUX_TESTNET]: confluxTestnet.rpcUrls.public.http,
  [ChainId.BRISECHAIN]: brisechain.rpcUrls.public.http,
  [ChainId.BRISECHAIN_TESTNET]: brisechainTestnet.rpcUrls.public.http,
  [ChainId.MUUCHAIN]: muuchain.rpcUrls.public.http,
  [ChainId.MUUCHAIN_TESTNET]: muuchainTestnet.rpcUrls.public.http,
  [ChainId.CANTO]: canto.rpcUrls.public.http,
  [ChainId.CANTO_TESTNET]: cantoTestnet.rpcUrls.public.http,
  [ChainId.OASIS_EMERALD]: oasis.rpcUrls.public.http,
  [ChainId.OASIS_TESTNET]: oasisTestnet.rpcUrls.public.http,
  [ChainId.VISION]: vision.rpcUrls.public.http,
  [ChainId.VISION_TESTNET]: visionTestnet.rpcUrls.public.http,
  [ChainId.STEP]: step.rpcUrls.public.http,
  [ChainId.STEP_TESTNET]: stepTestnet.rpcUrls.public.http,
  [ChainId.METER]: meter.rpcUrls.public.http,
  [ChainId.METER_TESTNET]: meterTestnet.rpcUrls.public.http,
  [ChainId.GODWOKEN]: godwoken.rpcUrls.public.http,
  [ChainId.GODWOKEN_TESTNET]: godwokenTestnet.rpcUrls.public.http,
  [ChainId.CALLISTO]: callisto.rpcUrls.public.http,
  [ChainId.CALLISTO_TESTNET]: callistoTestnet.rpcUrls.public.http,
  [ChainId.EVMOS]: evmos.rpcUrls.public.http,
  [ChainId.EVMOS_TESTNET]: evmosTestnet.rpcUrls.public.http,
  [ChainId.ENERGY_WEB_CHAIN]: energyWebChain.rpcUrls.public.http,
  [ChainId.ENERGY_VOLTA]: energyVolta.rpcUrls.public.http,
  [ChainId.BASE]: base.rpcUrls.public.http,
  [ChainId.BASE_GOERLI]: baseGoerli.rpcUrls.public.http,
  [ChainId.KAVA]: kava.rpcUrls.public.http,
  [ChainId.KAVA_TESTNET]: kavaTestnet.rpcUrls.public.http,
  [ChainId.CLOVER]: clover.rpcUrls.public.http,
  [ChainId.CLOVER_TESTNET]: cloverTestnet.rpcUrls.public.http,
  [ChainId.DEFICHAIN]: defichain.rpcUrls.public.http,
  [ChainId.DEFICHAIN_TESTNET]: defichainTestnet.rpcUrls.public.http,
  [ChainId.BRONOS]: bronos.rpcUrls.public.http,
  [ChainId.BRONOS_TESTNET]: bronosTestnet.rpcUrls.public.http,
  [ChainId.FILECOIN]: filecoin.rpcUrls.public.http,
  [ChainId.FILECOIN_CALIBRATION]: filecoinCalibration.rpcUrls.public.http,
  [ChainId.FLARE]: flare.rpcUrls.public.http,
  [ChainId.FLARE_TESTNET]: flareTestnet.rpcUrls.public.http,
  [ChainId.TARAXA]: taraxa.rpcUrls.public.http,
  [ChainId.TARAXA_TESTNET]: taraxaTestnet.rpcUrls.public.http,
  [ChainId.BTTCHAIN]: bttchain.rpcUrls.public.http,
  [ChainId.BTTCHAIN_TESTNET]: bttchainTestnet.rpcUrls.public.http,
  [ChainId.BOBA_AVAX]: bobaAvax.rpcUrls.public.http,
  [ChainId.BOBA_BNB]: bobaBnb.rpcUrls.public.http,
  [ChainId.CMP]: cmp.rpcUrls.public.http,
  [ChainId.CMP_TESTNET]: cmpTestnet.rpcUrls.public.http,
  [ChainId.CORE]: core.rpcUrls.public.http,
  [ChainId.CORE_TESTNET]: coreTestnet.rpcUrls.public.http,
  [ChainId.CUBECHAIN]: cubechain.rpcUrls.public.http,
  [ChainId.CUBECHAIN_TESTNET]: cubechainTestnet.rpcUrls.public.http,
  [ChainId.DARWINIA_CRAB]: darwiniaCrab.rpcUrls.public.http,
  [ChainId.DARWINIA_PANGOLIN]: darwiniaPangolin.rpcUrls.public.http,
  [ChainId.DIODE_PRETNET]: diodePretnet.rpcUrls.public.http,
  [ChainId.DIODE_TESTNET]: diodeTestnet.rpcUrls.public.http,
  [ChainId.GOCHAIN]: gochain.rpcUrls.public.http,
  [ChainId.GOCHAIN_TESTNET]: gochainTestnet.rpcUrls.public.http,
  [ChainId.HAQQ]: haqq.rpcUrls.public.http,
  [ChainId.HAQQ_TESTEDGE2]: haqqTestedge.rpcUrls.public.http,
  [ChainId.KARDIACHAIN]: kardiachain.rpcUrls.public.http,
  [ChainId.KARDIACHAIN_TESTNET]: kardiachainTestnet.rpcUrls.public.http,
  [ChainId.METADIUM]: metadium.rpcUrls.public.http,
  [ChainId.METADIUM_TESTNET]: metadiumTestnet.rpcUrls.public.http,
  [ChainId.OP_BNB]: opBnb.rpcUrls.public.http,
  [ChainId.OP_BNB_TESTNET]: opBnbTestnet.rpcUrls.public.http,
  [ChainId.PALM]: palm.rpcUrls.public.http,
  [ChainId.PALM_TESTNET]: palmTestnet.rpcUrls.public.http,
  [ChainId.POLIS]: polis.rpcUrls.public.http,
  [ChainId.POLIS_TESTNET]: polisTestnet.rpcUrls.public.http,
  [ChainId.SHIBACHAIN]: shibachain.rpcUrls.public.http,
  [ChainId.SMART_BCH]: smartBch.rpcUrls.public.http,
  [ChainId.SMART_BCH_TESTNET]: smartBchTestnet.rpcUrls.public.http,
  [ChainId.SONGBIRD_CANARY]: songbirdCanary.rpcUrls.public.http,
  [ChainId.THAICHAIN]: thaichain.rpcUrls.public.http,
  [ChainId.UBIQ]: ubiq.rpcUrls.public.http,
  [ChainId.UBIQ_TESTNET]: ubiqTestnet.rpcUrls.public.http,
  [ChainId.VECHAIN]: vechain.rpcUrls.public.http,
  [ChainId.VECHAIN_TESTNET]: vechainTestnet.rpcUrls.public.http,
  [ChainId.XINFIN]: xinfin.rpcUrls.public.http,
  [ChainId.XINFIN_APOTHEM]: xinfinApothem.rpcUrls.public.http,
  [ChainId.SCROLL]: scroll.rpcUrls.public.http,
  [ChainId.SCROLL_SEPOLIA]: scrollSepolia.rpcUrls.public.http,
  [ChainId.XRPL_DEVNET]: xrplDevnet.rpcUrls.public.http,
  [ChainId.ZETACHAIN]: zetachain.rpcUrls.public.http,
  [ChainId.ZETACHAIN_ATHENS]: zetachainAthens.rpcUrls.public.http,
  [ChainId.ZYX]: zyx.rpcUrls.public.http,
} satisfies Record<ChainId, readonly string[]>

export const PUBLIC_NODES = {
  [ChainId.FIRECHAIN]: firechain.rpcUrls.public.http,
  [ChainId.RINIA]: rinia.rpcUrls.public.http,
  [ChainId.ETHEREUM]: [
    getNodeRealUrlV2(ChainId.ETHEREUM, process.env.NEXT_PUBLIC_NODE_REAL_API_ETH),
    'https://eth.llamarpc.com',
    'https://cloudflare-eth.com',
    'https://mainnet.infura.io/v3',
  ].filter(Boolean),
  [ChainId.GOERLI]: [
    getNodeRealUrlV2(ChainId.GOERLI, process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI),
    'https://eth-goerli.public.blastapi.io',
  ].filter(Boolean),
  [ChainId.SEPOLIA]: sepolia.rpcUrls.public.http,
  [ChainId.HOLESKY]: holesky.rpcUrls.public.http,
  [ChainId.BSC]: [
    process.env.NEXT_PUBLIC_NODE_PRODUCTION,
    getNodeRealUrlV2(ChainId.BSC, process.env.NEXT_PUBLIC_NODE_REAL_API_ETH),
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.binance.org',
  ].filter(Boolean),
  [ChainId.BSC_TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  [ChainId.ARBITRUM_ONE]: arbitrum.rpcUrls.public.http,
  [ChainId.ARBITRUM_GOERLI]: arbitrumGoerli.rpcUrls.public.http,
  [ChainId.POLYGON]: polygon.rpcUrls.public.http,
  [ChainId.POLYGON_AMOY]: polygonAmoy.rpcUrls.public.http,
  [ChainId.POLYGON_ZKEVM]: polygonZkEvm.rpcUrls.public.http,
  [ChainId.POLYGON_ZKEVM_TESTNET]: [
    'https://polygon-zkevm-testnet.rpc.thirdweb.com',
    ...polygonZkEvmTestnet.rpcUrls.public.http,
  ],
  [ChainId.ZKSYNC]: zkSync.rpcUrls.public.http,
  [ChainId.ZKSYNC_TESTNET]: zkSyncTestnet.rpcUrls.public.http,
  [ChainId.LINEA]: linea.rpcUrls.public.http,
  [ChainId.LINEA_TESTNET]: [
    'https://rpc.goerli.linea.build',
    'https://linea-testnet.rpc.thirdweb.com',
    'https://consensys-zkevm-goerli-prealpha.infura.io/v3/93e8a17747e34ec0ac9a554c1b403965',
  ],
  [ChainId.CRO]: cronos.rpcUrls.public.http,
  [ChainId.CRO_TESTNET]: cronosTestnet.rpcUrls.public.http,
  [ChainId.AVALANCHE]: avalanche.rpcUrls.public.http,
  [ChainId.AVALANCHE_FUJI]: avalancheFuji.rpcUrls.public.http,
  [ChainId.FANTOM_OPERA]: fantomOpera.rpcUrls.public.http,
  [ChainId.FANTOM_TESTNET]: fantomTestnet.rpcUrls.public.http,
  [ChainId.CELO]: celo.rpcUrls.public.http,
  [ChainId.CELO_ALFAJORES]: celoAlfajores.rpcUrls.public.http,
  [ChainId.OPTIMISM]: optimism.rpcUrls.public.http,
  [ChainId.OPTIMISM_GOERLI]: optimismGoerli.rpcUrls.public.http,
  [ChainId.SHIBARIUM]: shibarium.rpcUrls.public.http,
  [ChainId.SHIBARIUM_TESTNET]: shibariumTestnet.rpcUrls.public.http,
  [ChainId.PULSECHAIN]: pulsechain.rpcUrls.public.http,
  [ChainId.PULSECHAIN_TESTNET]: pulsechainTestnet.rpcUrls.public.http,
  [ChainId.XEN]: xen.rpcUrls.public.http,
  [ChainId.XEN_DEVNET]: xenDevnet.rpcUrls.public.http,
  [ChainId.HARMONY]: harmony.rpcUrls.public.http,
  [ChainId.HARMONY_TESTNET]: harmonyTestnet.rpcUrls.public.http,
  [ChainId.COINEX]: coinEx.rpcUrls.public.http,
  [ChainId.COINEX_TESTNET]: coinExTestnet.rpcUrls.public.http,
  [ChainId.DOGECHAIN]: dogechain.rpcUrls.public.http,
  [ChainId.DOGECHAIN_TESTNET]: dogechainTestnet.rpcUrls.public.http,
  [ChainId.ENGRAM_TESTNET]: engramTestnet.rpcUrls.public.http,
  [ChainId.ETHEREUM_CLASSIC]: ethereumClassic.rpcUrls.public.http,
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: ethereumClassicMordor.rpcUrls.public.http,
  [ChainId.FUSION]: fusion.rpcUrls.public.http,
  [ChainId.FUSION_TESTNET]: fusionTestnet.rpcUrls.public.http,
  [ChainId.HECO]: heco.rpcUrls.public.http,
  [ChainId.HECO_TESTNET]: hecoTestnet.rpcUrls.public.http,
  [ChainId.KCC]: kcc.rpcUrls.public.http,
  [ChainId.KCC_TESTNET]: kccTestnet.rpcUrls.public.http,
  [ChainId.KLAYTN]: klaytnCypress.rpcUrls.public.http,
  [ChainId.KLAYTN_BAOBAB]: klaytnBaobab.rpcUrls.public.http,
  [ChainId.OKXCHAIN]: okxChain.rpcUrls.public.http,
  [ChainId.OKXCHAIN_TESTNET]: okxChainTestnet.rpcUrls.public.http,
  [ChainId.THETHA]: theta.rpcUrls.public.http,
  [ChainId.THETHA_TESTNET]: thetaTestnet.rpcUrls.public.http,
  [ChainId.ULTRON]: ultron.rpcUrls.public.http,
  [ChainId.ULTRON_TESTNET]: ultronTestnet.rpcUrls.public.http,
  [ChainId.MOONBEAM]: moonbeam.rpcUrls.public.http,
  [ChainId.MOONRIVER]: moonriver.rpcUrls.public.http,
  [ChainId.MOONBASE_ALPHA]: moonbaseAlpha.rpcUrls.public.http,
  [ChainId.AURORA]: aurora.rpcUrls.public.http,
  [ChainId.AURORA_TESTNET]: auroraTestnet.rpcUrls.public.http,
  [ChainId.BOBA]: boba.rpcUrls.public.http,
  [ChainId.BOBA_GOERLI]: bobaGoerli.rpcUrls.public.http,
  [ChainId.GNOSIS]: gnosis.rpcUrls.public.http,
  [ChainId.GNOSIS_CHIADO]: gnosisChiado.rpcUrls.public.http,
  [ChainId.METIS]: metis.rpcUrls.public.http,
  [ChainId.METIS_GOERLI]: metisGoerli.rpcUrls.public.http,
  [ChainId.TELOS]: telos.rpcUrls.public.http,
  [ChainId.TELOS_TESTNET]: telosTestnet.rpcUrls.public.http,
  [ChainId.SYSCOIN]: syscoin.rpcUrls.public.http,
  [ChainId.SYSCOIN_TANENBAUM]: syscoinTanenbaum.rpcUrls.public.http,
  [ChainId.IOTEX]: iotex.rpcUrls.public.http,
  [ChainId.IOTEX_TESTNET]: iotexTestnet.rpcUrls.public.http,
  [ChainId.SHIDEN]: shiden.rpcUrls.public.http,
  [ChainId.ASTAR]: astar.rpcUrls.public.http,
  [ChainId.SHIBUNYA]: shibunya.rpcUrls.public.http,
  [ChainId.FUSE]: fuse.rpcUrls.public.http,
  [ChainId.FUSE_SPARKNET]: fuseSparknet.rpcUrls.public.http,
  [ChainId.VELAS]: velas.rpcUrls.public.http,
  [ChainId.VELAS_TESTNET]: velasTestnet.rpcUrls.public.http,
  [ChainId.REI]: rei.rpcUrls.public.http,
  [ChainId.REI_TESTNET]: reiTestnet.rpcUrls.public.http,
  [ChainId.KEKCHAIN]: kekchain.rpcUrls.public.http,
  [ChainId.KEKCHAIN_TESTNET]: kekchainTestnet.rpcUrls.public.http,
  [ChainId.TOMOCHAIN]: tomochain.rpcUrls.public.http,
  [ChainId.TOMOCHAIN_TESTNET]: tomochainTestnet.rpcUrls.public.http,
  [ChainId.THUNDERCORE]: thundercore.rpcUrls.public.http,
  [ChainId.THUNDERCORE_TESTNET]: thundercoreTestnet.rpcUrls.public.http,
  [ChainId.WANCHAIN]: wanchain.rpcUrls.public.http,
  [ChainId.WANCHAIN_TESTNET]: wanchainTestnet.rpcUrls.public.http,
  [ChainId.RSK]: rsk.rpcUrls.public.http,
  [ChainId.RSK_TESTNET]: rskTestnet.rpcUrls.public.http,
  [ChainId.ELASTOS]: elastos.rpcUrls.public.http,
  [ChainId.ELASTOS_TESTNET]: elastosTestnet.rpcUrls.public.http,
  [ChainId.CONFLUX]: conflux.rpcUrls.public.http,
  [ChainId.CONFLUX_TESTNET]: confluxTestnet.rpcUrls.public.http,
  [ChainId.BRISECHAIN]: brisechain.rpcUrls.public.http,
  [ChainId.BRISECHAIN_TESTNET]: brisechainTestnet.rpcUrls.public.http,
  [ChainId.MUUCHAIN]: muuchain.rpcUrls.public.http,
  [ChainId.MUUCHAIN_TESTNET]: muuchainTestnet.rpcUrls.public.http,
  [ChainId.CANTO]: canto.rpcUrls.public.http,
  [ChainId.CANTO_TESTNET]: cantoTestnet.rpcUrls.public.http,
  [ChainId.OASIS_EMERALD]: oasis.rpcUrls.public.http,
  [ChainId.OASIS_TESTNET]: oasisTestnet.rpcUrls.public.http,
  [ChainId.VISION]: vision.rpcUrls.public.http,
  [ChainId.VISION_TESTNET]: visionTestnet.rpcUrls.public.http,
  [ChainId.STEP]: step.rpcUrls.public.http,
  [ChainId.STEP_TESTNET]: stepTestnet.rpcUrls.public.http,
  [ChainId.METER]: meter.rpcUrls.public.http,
  [ChainId.METER_TESTNET]: meterTestnet.rpcUrls.public.http,
  [ChainId.GODWOKEN]: godwoken.rpcUrls.public.http,
  [ChainId.GODWOKEN_TESTNET]: godwokenTestnet.rpcUrls.public.http,
  [ChainId.CALLISTO]: callisto.rpcUrls.public.http,
  [ChainId.CALLISTO_TESTNET]: callistoTestnet.rpcUrls.public.http,
  [ChainId.EVMOS]: evmos.rpcUrls.public.http,
  [ChainId.EVMOS_TESTNET]: evmosTestnet.rpcUrls.public.http,
  [ChainId.ENERGY_WEB_CHAIN]: energyWebChain.rpcUrls.public.http,
  [ChainId.ENERGY_VOLTA]: energyVolta.rpcUrls.public.http,
  [ChainId.BASE]: base.rpcUrls.public.http,
  [ChainId.BASE_GOERLI]: baseGoerli.rpcUrls.public.http,
  [ChainId.KAVA]: kava.rpcUrls.public.http,
  [ChainId.KAVA_TESTNET]: kavaTestnet.rpcUrls.public.http,
  [ChainId.CLOVER]: clover.rpcUrls.public.http,
  [ChainId.CLOVER_TESTNET]: cloverTestnet.rpcUrls.public.http,
  [ChainId.DEFICHAIN]: defichain.rpcUrls.public.http,
  [ChainId.DEFICHAIN_TESTNET]: defichainTestnet.rpcUrls.public.http,
  [ChainId.BRONOS]: bronos.rpcUrls.public.http,
  [ChainId.BRONOS_TESTNET]: bronosTestnet.rpcUrls.public.http,
  [ChainId.FILECOIN]: filecoin.rpcUrls.public.http,
  [ChainId.FILECOIN_CALIBRATION]: filecoinCalibration.rpcUrls.public.http,
  [ChainId.FLARE]: flare.rpcUrls.public.http,
  [ChainId.FLARE_TESTNET]: flareTestnet.rpcUrls.public.http,
  [ChainId.TARAXA]: taraxa.rpcUrls.public.http,
  [ChainId.TARAXA_TESTNET]: taraxaTestnet.rpcUrls.public.http,
  [ChainId.BTTCHAIN]: bttchain.rpcUrls.public.http,
  [ChainId.BTTCHAIN_TESTNET]: bttchainTestnet.rpcUrls.public.http,
  [ChainId.BOBA_AVAX]: bobaAvax.rpcUrls.public.http,
  [ChainId.BOBA_BNB]: bobaBnb.rpcUrls.public.http,
  [ChainId.CMP]: cmp.rpcUrls.public.http,
  [ChainId.CMP_TESTNET]: cmpTestnet.rpcUrls.public.http,
  [ChainId.CORE]: core.rpcUrls.public.http,
  [ChainId.CORE_TESTNET]: coreTestnet.rpcUrls.public.http,
  [ChainId.CUBECHAIN]: cubechain.rpcUrls.public.http,
  [ChainId.CUBECHAIN_TESTNET]: cubechainTestnet.rpcUrls.public.http,
  [ChainId.DARWINIA_CRAB]: darwiniaCrab.rpcUrls.public.http,
  [ChainId.DARWINIA_PANGOLIN]: darwiniaPangolin.rpcUrls.public.http,
  [ChainId.DIODE_PRETNET]: diodePretnet.rpcUrls.public.http,
  [ChainId.DIODE_TESTNET]: diodeTestnet.rpcUrls.public.http,
  [ChainId.GOCHAIN]: gochain.rpcUrls.public.http,
  [ChainId.GOCHAIN_TESTNET]: gochainTestnet.rpcUrls.public.http,
  [ChainId.HAQQ]: haqq.rpcUrls.public.http,
  [ChainId.HAQQ_TESTEDGE2]: haqqTestedge.rpcUrls.public.http,
  [ChainId.KARDIACHAIN]: kardiachain.rpcUrls.public.http,
  [ChainId.KARDIACHAIN_TESTNET]: kardiachainTestnet.rpcUrls.public.http,
  [ChainId.METADIUM]: metadium.rpcUrls.public.http,
  [ChainId.METADIUM_TESTNET]: metadiumTestnet.rpcUrls.public.http,
  [ChainId.OP_BNB]: opBnb.rpcUrls.public.http,
  [ChainId.OP_BNB_TESTNET]: opBnbTestnet.rpcUrls.public.http,
  [ChainId.PALM]: palm.rpcUrls.public.http,
  [ChainId.PALM_TESTNET]: palmTestnet.rpcUrls.public.http,
  [ChainId.POLIS]: polis.rpcUrls.public.http,
  [ChainId.POLIS_TESTNET]: polisTestnet.rpcUrls.public.http,
  [ChainId.SHIBACHAIN]: shibachain.rpcUrls.public.http,
  [ChainId.SMART_BCH]: smartBch.rpcUrls.public.http,
  [ChainId.SMART_BCH_TESTNET]: smartBchTestnet.rpcUrls.public.http,
  [ChainId.SONGBIRD_CANARY]: songbirdCanary.rpcUrls.public.http,
  [ChainId.THAICHAIN]: thaichain.rpcUrls.public.http,
  [ChainId.UBIQ]: ubiq.rpcUrls.public.http,
  [ChainId.UBIQ_TESTNET]: ubiqTestnet.rpcUrls.public.http,
  [ChainId.VECHAIN]: vechain.rpcUrls.public.http,
  [ChainId.VECHAIN_TESTNET]: vechainTestnet.rpcUrls.public.http,
  [ChainId.XINFIN]: xinfin.rpcUrls.public.http,
  [ChainId.XINFIN_APOTHEM]: xinfinApothem.rpcUrls.public.http,
  [ChainId.SCROLL]: scroll.rpcUrls.public.http,
  [ChainId.SCROLL_SEPOLIA]: scrollSepolia.rpcUrls.public.http,
  [ChainId.XRPL_DEVNET]: xrplDevnet.rpcUrls.public.http,
  [ChainId.ZETACHAIN]: zetachain.rpcUrls.public.http,
  [ChainId.ZETACHAIN_ATHENS]: zetachainAthens.rpcUrls.public.http,
  [ChainId.ZYX]: zyx.rpcUrls.public.http,
} satisfies Record<ChainId, readonly string[]>
