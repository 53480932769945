import { ChainId } from '@donaswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const IFLAME = {
  [ChainId.FIRECHAIN]: '0x',
  [ChainId.RINIA]: '0x',
  [ChainId.BSC]: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.GOERLI]: '0x',
  [ChainId.SEPOLIA]: '0x',
  [ChainId.HOLESKY]: '0x',
  [ChainId.POLYGON]: '0x',
  [ChainId.POLYGON_AMOY]: '0x',
  [ChainId.POLYGON_ZKEVM]: '0x',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '0x',
  [ChainId.CRO]: '0x',
  [ChainId.CRO_TESTNET]: '0x',
  [ChainId.AVALANCHE]: '0x',
  [ChainId.AVALANCHE_FUJI]: '0x',
  [ChainId.FANTOM_OPERA]: '0x',
  [ChainId.FANTOM_TESTNET]: '0x',
  [ChainId.CELO]: '0x',
  [ChainId.CELO_ALFAJORES]: '0x',
  [ChainId.OPTIMISM]: '0x',
  [ChainId.OPTIMISM_GOERLI]: '0x',
  [ChainId.SHIBARIUM]: '0x',
  [ChainId.SHIBARIUM_TESTNET]: '0x',
  [ChainId.PULSECHAIN]: '0x',
  [ChainId.PULSECHAIN_TESTNET]: '0x',
  [ChainId.XEN]: '0x',
  [ChainId.XEN_DEVNET]: '0x',
  [ChainId.HARMONY]: '0x',
  [ChainId.HARMONY_TESTNET]: '0x',
  [ChainId.COINEX_TESTNET]: '0x',
  [ChainId.COINEX]: '0x',
  [ChainId.DOGECHAIN]: '0x',
  [ChainId.DOGECHAIN_TESTNET]: '0x',
  [ChainId.ENGRAM_TESTNET]: '0x',
  [ChainId.ETHEREUM_CLASSIC]: '0x',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: '0x',
  [ChainId.FUSION]: '0x',
  [ChainId.FUSION_TESTNET]: '0x',
  [ChainId.HECO]: '0x',
  [ChainId.HECO_TESTNET]: '0x',
  [ChainId.KCC]: '0x',
  [ChainId.KCC_TESTNET]: '0x',
  [ChainId.KLAYTN]: '0x',
  [ChainId.KLAYTN_BAOBAB]: '0x',
  [ChainId.OKXCHAIN]: '0x',
  [ChainId.OKXCHAIN_TESTNET]: '0x',
  [ChainId.THETHA]: '0x',
  [ChainId.THETHA_TESTNET]: '0x',
  [ChainId.ULTRON]: '0x',
  [ChainId.ULTRON_TESTNET]: '0x',
  [ChainId.MOONBEAM]: '0x',
  [ChainId.MOONRIVER]: '0x',
  [ChainId.MOONBASE_ALPHA]: '0x',
  [ChainId.AURORA]: '0x',
  [ChainId.AURORA_TESTNET]: '0x',
  [ChainId.BOBA]: '0x',
  [ChainId.BOBA_GOERLI]: '0x',
  [ChainId.GNOSIS]: '0x',
  [ChainId.GNOSIS_CHIADO]: '0x',
  [ChainId.METIS]: '0x',
  [ChainId.METIS_GOERLI]: '0x',
  [ChainId.TELOS]: '0x',
  [ChainId.TELOS_TESTNET]: '0x',
  [ChainId.SYSCOIN]: '0x',
  [ChainId.SYSCOIN_TANENBAUM]: '0x',
  [ChainId.IOTEX]: '0x',
  [ChainId.IOTEX_TESTNET]: '0x',
  [ChainId.SHIDEN]: '0x',
  [ChainId.ASTAR]: '0x',
  [ChainId.SHIBUNYA]: '0x',
  [ChainId.FUSE]: '0x',
  [ChainId.FUSE_SPARKNET]: '0x',
  [ChainId.VELAS]: '0x',
  [ChainId.VELAS_TESTNET]: '0x',
  [ChainId.REI]: '0x',
  [ChainId.REI_TESTNET]: '0x',
  [ChainId.KEKCHAIN]: '0x',
  [ChainId.KEKCHAIN_TESTNET]: '0x',
  [ChainId.TOMOCHAIN]: '0x',
  [ChainId.TOMOCHAIN_TESTNET]: '0x',
  [ChainId.THUNDERCORE]: '0x',
  [ChainId.THUNDERCORE_TESTNET]: '0x',
  [ChainId.WANCHAIN]: '0x',
  [ChainId.WANCHAIN_TESTNET]: '0x',
  [ChainId.RSK]: '0x',
  [ChainId.RSK_TESTNET]: '0x',
  [ChainId.ELASTOS]: '0x',
  [ChainId.ELASTOS_TESTNET]: '0x',
  [ChainId.CONFLUX]: '0x',
  [ChainId.CONFLUX_TESTNET]: '0x',
  [ChainId.BRISECHAIN]: '0x',
  [ChainId.BRISECHAIN_TESTNET]: '0x',
  [ChainId.MUUCHAIN]: '0x',
  [ChainId.MUUCHAIN_TESTNET]: '0x',
  [ChainId.CANTO]: '0x',
  [ChainId.CANTO_TESTNET]: '0x',
  [ChainId.OASIS_EMERALD]: '0x',
  [ChainId.OASIS_TESTNET]: '0x',
  [ChainId.VISION]: '0x',
  [ChainId.VISION_TESTNET]: '0x',
  [ChainId.STEP]: '0x',
  [ChainId.STEP_TESTNET]: '0x',
  [ChainId.METER]: '0x',
  [ChainId.METER_TESTNET]: '0x',
  [ChainId.GODWOKEN]: '0x',
  [ChainId.GODWOKEN_TESTNET]: '0x',
  [ChainId.CALLISTO]: '0x',
  [ChainId.CALLISTO_TESTNET]: '0x',
  [ChainId.EVMOS]: '0x',
  [ChainId.EVMOS_TESTNET]: '0x',
  [ChainId.ENERGY_WEB_CHAIN]: '0x',
  [ChainId.ENERGY_VOLTA]: '0x',
  [ChainId.BASE]: '0x',
  [ChainId.BASE_GOERLI]: '0x',
  [ChainId.KAVA]: '0x',
  [ChainId.KAVA_TESTNET]: '0x',
  [ChainId.CLOVER]: '0x',
  [ChainId.CLOVER_TESTNET]: '0x',
  [ChainId.DEFICHAIN]: '0x',
  [ChainId.DEFICHAIN_TESTNET]: '0x',
  [ChainId.BRONOS]: '0x',
  [ChainId.BRONOS_TESTNET]: '0x',
  [ChainId.FILECOIN]: '0x',
  [ChainId.FILECOIN_CALIBRATION]: '0x',
  [ChainId.FLARE]: '0x',
  [ChainId.FLARE_TESTNET]: '0x',
  [ChainId.TARAXA]: '0x',
  [ChainId.TARAXA_TESTNET]: '0x',
  [ChainId.ARBITRUM_ONE]: '0x',
  [ChainId.ARBITRUM_GOERLI]: '0x',
  [ChainId.ZKSYNC]: '0x',
  [ChainId.ZKSYNC_TESTNET]: '0x',
  [ChainId.LINEA]: '0x',
  [ChainId.LINEA_TESTNET]: '0x',
  [ChainId.BTTCHAIN]: '0x',
  [ChainId.BTTCHAIN_TESTNET]: '0x',
  [ChainId.BOBA_AVAX]: '0x',
  [ChainId.BOBA_BNB]: '0x',
  [ChainId.CMP]: '0x',
  [ChainId.CMP_TESTNET]: '0x',
  [ChainId.CORE]: '0x',
  [ChainId.CORE_TESTNET]: '0x',
  [ChainId.CUBECHAIN]: '0x',
  [ChainId.CUBECHAIN_TESTNET]: '0x',
  [ChainId.DARWINIA_CRAB]: '0x',
  [ChainId.DARWINIA_PANGOLIN]: '0x',
  [ChainId.DIODE_PRETNET]: '0x',
  [ChainId.DIODE_TESTNET]: '0x',
  [ChainId.GOCHAIN]: '0x',
  [ChainId.GOCHAIN_TESTNET]: '0x',
  [ChainId.HAQQ]: '0x',
  [ChainId.HAQQ_TESTEDGE2]: '0x',
  [ChainId.KARDIACHAIN]: '0x',
  [ChainId.KARDIACHAIN_TESTNET]: '0x',
  [ChainId.METADIUM]: '0x',
  [ChainId.METADIUM_TESTNET]: '0x',
  [ChainId.OP_BNB]: '0x',
  [ChainId.OP_BNB_TESTNET]: '0x',
  [ChainId.PALM]: '0x',
  [ChainId.PALM_TESTNET]: '0x',
  [ChainId.POLIS]: '0x',
  [ChainId.POLIS_TESTNET]: '0x',
  [ChainId.SHIBACHAIN]: '0x',
  [ChainId.SMART_BCH]: '0x',
  [ChainId.SMART_BCH_TESTNET]: '0x',
  [ChainId.SONGBIRD_CANARY]: '0x',
  [ChainId.THAICHAIN]: '0x',
  [ChainId.UBIQ]: '0x',
  [ChainId.UBIQ_TESTNET]: '0x',
  [ChainId.VECHAIN]: '0x',
  [ChainId.VECHAIN_TESTNET]: '0x',
  [ChainId.XINFIN]: '0x',
  [ChainId.XINFIN_APOTHEM]: '0x',
  [ChainId.SCROLL]: '0x',
  [ChainId.SCROLL_SEPOLIA]: '0x',
  [ChainId.XRPL_DEVNET]: '0x',
  [ChainId.ZETACHAIN]: '0x',
  [ChainId.ZETACHAIN_ATHENS]: '0x',
  [ChainId.ZYX]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>

export const FLAME_VAULT = {
  [ChainId.FIRECHAIN]: '0x',
  [ChainId.RINIA]: '0x',
  [ChainId.BSC]: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
  [ChainId.BSC_TESTNET]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.GOERLI]: '0x',
  [ChainId.SEPOLIA]: '0x',
  [ChainId.HOLESKY]: '0x',
  [ChainId.POLYGON]: '0x',
  [ChainId.POLYGON_AMOY]: '0x',
  [ChainId.POLYGON_ZKEVM]: '0x',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '0x',
  [ChainId.CRO]: '0x',
  [ChainId.CRO_TESTNET]: '0x',
  [ChainId.AVALANCHE]: '0x',
  [ChainId.AVALANCHE_FUJI]: '0x',
  [ChainId.FANTOM_OPERA]: '0x',
  [ChainId.FANTOM_TESTNET]: '0x',
  [ChainId.CELO]: '0x',
  [ChainId.CELO_ALFAJORES]: '0x',
  [ChainId.OPTIMISM]: '0x',
  [ChainId.OPTIMISM_GOERLI]: '0x',
  [ChainId.SHIBARIUM]: '0x',
  [ChainId.SHIBARIUM_TESTNET]: '0x',
  [ChainId.PULSECHAIN]: '0x',
  [ChainId.PULSECHAIN_TESTNET]: '0x',
  [ChainId.XEN]: '0x',
  [ChainId.XEN_DEVNET]: '0x',
  [ChainId.HARMONY]: '0x',
  [ChainId.HARMONY_TESTNET]: '0x',
  [ChainId.COINEX_TESTNET]: '0x',
  [ChainId.COINEX]: '0x',
  [ChainId.DOGECHAIN]: '0x',
  [ChainId.DOGECHAIN_TESTNET]: '0x',
  [ChainId.ENGRAM_TESTNET]: '0x',
  [ChainId.ETHEREUM_CLASSIC]: '0x',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: '0x',
  [ChainId.FUSION]: '0x',
  [ChainId.FUSION_TESTNET]: '0x',
  [ChainId.HECO]: '0x',
  [ChainId.HECO_TESTNET]: '0x',
  [ChainId.KCC]: '0x',
  [ChainId.KCC_TESTNET]: '0x',
  [ChainId.KLAYTN]: '0x',
  [ChainId.KLAYTN_BAOBAB]: '0x',
  [ChainId.OKXCHAIN]: '0x',
  [ChainId.OKXCHAIN_TESTNET]: '0x',
  [ChainId.THETHA]: '0x',
  [ChainId.THETHA_TESTNET]: '0x',
  [ChainId.ULTRON]: '0x',
  [ChainId.ULTRON_TESTNET]: '0x',
  [ChainId.MOONBEAM]: '0x',
  [ChainId.MOONRIVER]: '0x',
  [ChainId.MOONBASE_ALPHA]: '0x',
  [ChainId.AURORA]: '0x',
  [ChainId.AURORA_TESTNET]: '0x',
  [ChainId.BOBA]: '0x',
  [ChainId.BOBA_GOERLI]: '0x',
  [ChainId.GNOSIS]: '0x',
  [ChainId.GNOSIS_CHIADO]: '0x',
  [ChainId.METIS]: '0x',
  [ChainId.METIS_GOERLI]: '0x',
  [ChainId.TELOS]: '0x',
  [ChainId.TELOS_TESTNET]: '0x',
  [ChainId.SYSCOIN]: '0x',
  [ChainId.SYSCOIN_TANENBAUM]: '0x',
  [ChainId.IOTEX]: '0x',
  [ChainId.IOTEX_TESTNET]: '0x',
  [ChainId.SHIDEN]: '0x',
  [ChainId.ASTAR]: '0x',
  [ChainId.SHIBUNYA]: '0x',
  [ChainId.FUSE]: '0x',
  [ChainId.FUSE_SPARKNET]: '0x',
  [ChainId.VELAS]: '0x',
  [ChainId.VELAS_TESTNET]: '0x',
  [ChainId.REI]: '0x',
  [ChainId.REI_TESTNET]: '0x',
  [ChainId.KEKCHAIN]: '0x',
  [ChainId.KEKCHAIN_TESTNET]: '0x',
  [ChainId.TOMOCHAIN]: '0x',
  [ChainId.TOMOCHAIN_TESTNET]: '0x',
  [ChainId.THUNDERCORE]: '0x',
  [ChainId.THUNDERCORE_TESTNET]: '0x',
  [ChainId.WANCHAIN]: '0x',
  [ChainId.WANCHAIN_TESTNET]: '0x',
  [ChainId.RSK]: '0x',
  [ChainId.RSK_TESTNET]: '0x',
  [ChainId.ELASTOS]: '0x',
  [ChainId.ELASTOS_TESTNET]: '0x',
  [ChainId.CONFLUX]: '0x',
  [ChainId.CONFLUX_TESTNET]: '0x',
  [ChainId.BRISECHAIN]: '0x',
  [ChainId.BRISECHAIN_TESTNET]: '0x',
  [ChainId.MUUCHAIN]: '0x',
  [ChainId.MUUCHAIN_TESTNET]: '0x',
  [ChainId.CANTO]: '0x',
  [ChainId.CANTO_TESTNET]: '0x',
  [ChainId.OASIS_EMERALD]: '0x',
  [ChainId.OASIS_TESTNET]: '0x',
  [ChainId.VISION]: '0x',
  [ChainId.VISION_TESTNET]: '0x',
  [ChainId.STEP]: '0x',
  [ChainId.STEP_TESTNET]: '0x',
  [ChainId.METER]: '0x',
  [ChainId.METER_TESTNET]: '0x',
  [ChainId.GODWOKEN]: '0x',
  [ChainId.GODWOKEN_TESTNET]: '0x',
  [ChainId.CALLISTO]: '0x',
  [ChainId.CALLISTO_TESTNET]: '0x',
  [ChainId.EVMOS]: '0x',
  [ChainId.EVMOS_TESTNET]: '0x',
  [ChainId.ENERGY_WEB_CHAIN]: '0x',
  [ChainId.ENERGY_VOLTA]: '0x',
  [ChainId.BASE]: '0x',
  [ChainId.BASE_GOERLI]: '0x',
  [ChainId.KAVA]: '0x',
  [ChainId.KAVA_TESTNET]: '0x',
  [ChainId.CLOVER]: '0x',
  [ChainId.CLOVER_TESTNET]: '0x',
  [ChainId.DEFICHAIN]: '0x',
  [ChainId.DEFICHAIN_TESTNET]: '0x',
  [ChainId.BRONOS]: '0x',
  [ChainId.BRONOS_TESTNET]: '0x',
  [ChainId.FILECOIN]: '0x',
  [ChainId.FILECOIN_CALIBRATION]: '0x',
  [ChainId.FLARE]: '0x',
  [ChainId.FLARE_TESTNET]: '0x',
  [ChainId.TARAXA]: '0x',
  [ChainId.TARAXA_TESTNET]: '0x',
  [ChainId.ARBITRUM_ONE]: '0x',
  [ChainId.ARBITRUM_GOERLI]: '0x',
  [ChainId.ZKSYNC]: '0x',
  [ChainId.ZKSYNC_TESTNET]: '0x',
  [ChainId.LINEA]: '0x',
  [ChainId.LINEA_TESTNET]: '0x',
  [ChainId.BTTCHAIN]: '0x',
  [ChainId.BTTCHAIN_TESTNET]: '0x',
  [ChainId.BOBA_AVAX]: '0x',
  [ChainId.BOBA_BNB]: '0x',
  [ChainId.CMP]: '0x',
  [ChainId.CMP_TESTNET]: '0x',
  [ChainId.CORE]: '0x',
  [ChainId.CORE_TESTNET]: '0x',
  [ChainId.CUBECHAIN]: '0x',
  [ChainId.CUBECHAIN_TESTNET]: '0x',
  [ChainId.DARWINIA_CRAB]: '0x',
  [ChainId.DARWINIA_PANGOLIN]: '0x',
  [ChainId.DIODE_PRETNET]: '0x',
  [ChainId.DIODE_TESTNET]: '0x',
  [ChainId.GOCHAIN]: '0x',
  [ChainId.GOCHAIN_TESTNET]: '0x',
  [ChainId.HAQQ]: '0x',
  [ChainId.HAQQ_TESTEDGE2]: '0x',
  [ChainId.KARDIACHAIN]: '0x',
  [ChainId.KARDIACHAIN_TESTNET]: '0x',
  [ChainId.METADIUM]: '0x',
  [ChainId.METADIUM_TESTNET]: '0x',
  [ChainId.OP_BNB]: '0x',
  [ChainId.OP_BNB_TESTNET]: '0x',
  [ChainId.PALM]: '0x',
  [ChainId.PALM_TESTNET]: '0x',
  [ChainId.POLIS]: '0x',
  [ChainId.POLIS_TESTNET]: '0x',
  [ChainId.SHIBACHAIN]: '0x',
  [ChainId.SMART_BCH]: '0x',
  [ChainId.SMART_BCH_TESTNET]: '0x',
  [ChainId.SONGBIRD_CANARY]: '0x',
  [ChainId.THAICHAIN]: '0x',
  [ChainId.UBIQ]: '0x',
  [ChainId.UBIQ_TESTNET]: '0x',
  [ChainId.VECHAIN]: '0x',
  [ChainId.VECHAIN_TESTNET]: '0x',
  [ChainId.XINFIN]: '0x',
  [ChainId.XINFIN_APOTHEM]: '0x',
  [ChainId.SCROLL]: '0x',
  [ChainId.SCROLL_SEPOLIA]: '0x',
  [ChainId.XRPL_DEVNET]: '0x',
  [ChainId.ZETACHAIN]: '0x',
  [ChainId.ZETACHAIN_ATHENS]: '0x',
  [ChainId.ZYX]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_FLEXIBLE_SIDE_VAULT = {
  [ChainId.FIRECHAIN]: '0x',
  [ChainId.RINIA]: '0x',
  [ChainId.BSC]: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.GOERLI]: '0x',
  [ChainId.SEPOLIA]: '0x',
  [ChainId.HOLESKY]: '0x',
  [ChainId.POLYGON]: '0x',
  [ChainId.POLYGON_AMOY]: '0x',
  [ChainId.POLYGON_ZKEVM]: '0x',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '0x',
  [ChainId.CRO]: '0x',
  [ChainId.CRO_TESTNET]: '0x',
  [ChainId.AVALANCHE]: '0x',
  [ChainId.AVALANCHE_FUJI]: '0x',
  [ChainId.FANTOM_OPERA]: '0x',
  [ChainId.FANTOM_TESTNET]: '0x',
  [ChainId.CELO]: '0x',
  [ChainId.CELO_ALFAJORES]: '0x',
  [ChainId.OPTIMISM]: '0x',
  [ChainId.OPTIMISM_GOERLI]: '0x',
  [ChainId.SHIBARIUM]: '0x',
  [ChainId.SHIBARIUM_TESTNET]: '0x',
  [ChainId.PULSECHAIN]: '0x',
  [ChainId.PULSECHAIN_TESTNET]: '0x',
  [ChainId.XEN]: '0x',
  [ChainId.XEN_DEVNET]: '0x',
  [ChainId.HARMONY]: '0x',
  [ChainId.HARMONY_TESTNET]: '0x',
  [ChainId.COINEX_TESTNET]: '0x',
  [ChainId.COINEX]: '0x',
  [ChainId.DOGECHAIN]: '0x',
  [ChainId.DOGECHAIN_TESTNET]: '0x',
  [ChainId.ENGRAM_TESTNET]: '0x',
  [ChainId.ETHEREUM_CLASSIC]: '0x',
  [ChainId.ETHEREUM_CLASSIC_TESTNET]: '0x',
  [ChainId.FUSION]: '0x',
  [ChainId.FUSION_TESTNET]: '0x',
  [ChainId.HECO]: '0x',
  [ChainId.HECO_TESTNET]: '0x',
  [ChainId.KCC]: '0x',
  [ChainId.KCC_TESTNET]: '0x',
  [ChainId.KLAYTN]: '0x',
  [ChainId.KLAYTN_BAOBAB]: '0x',
  [ChainId.OKXCHAIN]: '0x',
  [ChainId.OKXCHAIN_TESTNET]: '0x',
  [ChainId.THETHA]: '0x',
  [ChainId.THETHA_TESTNET]: '0x',
  [ChainId.ULTRON]: '0x',
  [ChainId.ULTRON_TESTNET]: '0x',
  [ChainId.MOONBEAM]: '0x',
  [ChainId.MOONRIVER]: '0x',
  [ChainId.MOONBASE_ALPHA]: '0x',
  [ChainId.AURORA]: '0x',
  [ChainId.AURORA_TESTNET]: '0x',
  [ChainId.BOBA]: '0x',
  [ChainId.BOBA_GOERLI]: '0x',
  [ChainId.GNOSIS]: '0x',
  [ChainId.GNOSIS_CHIADO]: '0x',
  [ChainId.METIS]: '0x',
  [ChainId.METIS_GOERLI]: '0x',
  [ChainId.TELOS]: '0x',
  [ChainId.TELOS_TESTNET]: '0x',
  [ChainId.SYSCOIN]: '0x',
  [ChainId.SYSCOIN_TANENBAUM]: '0x',
  [ChainId.IOTEX]: '0x',
  [ChainId.IOTEX_TESTNET]: '0x',
  [ChainId.SHIDEN]: '0x',
  [ChainId.ASTAR]: '0x',
  [ChainId.SHIBUNYA]: '0x',
  [ChainId.FUSE]: '0x',
  [ChainId.FUSE_SPARKNET]: '0x',
  [ChainId.VELAS]: '0x',
  [ChainId.VELAS_TESTNET]: '0x',
  [ChainId.REI]: '0x',
  [ChainId.REI_TESTNET]: '0x',
  [ChainId.KEKCHAIN]: '0x',
  [ChainId.KEKCHAIN_TESTNET]: '0x',
  [ChainId.TOMOCHAIN]: '0x',
  [ChainId.TOMOCHAIN_TESTNET]: '0x',
  [ChainId.THUNDERCORE]: '0x',
  [ChainId.THUNDERCORE_TESTNET]: '0x',
  [ChainId.WANCHAIN]: '0x',
  [ChainId.WANCHAIN_TESTNET]: '0x',
  [ChainId.RSK]: '0x',
  [ChainId.RSK_TESTNET]: '0x',
  [ChainId.ELASTOS]: '0x',
  [ChainId.ELASTOS_TESTNET]: '0x',
  [ChainId.CONFLUX]: '0x',
  [ChainId.CONFLUX_TESTNET]: '0x',
  [ChainId.BRISECHAIN]: '0x',
  [ChainId.BRISECHAIN_TESTNET]: '0x',
  [ChainId.MUUCHAIN]: '0x',
  [ChainId.MUUCHAIN_TESTNET]: '0x',
  [ChainId.CANTO]: '0x',
  [ChainId.CANTO_TESTNET]: '0x',
  [ChainId.OASIS_EMERALD]: '0x',
  [ChainId.OASIS_TESTNET]: '0x',
  [ChainId.VISION]: '0x',
  [ChainId.VISION_TESTNET]: '0x',
  [ChainId.STEP]: '0x',
  [ChainId.STEP_TESTNET]: '0x',
  [ChainId.METER]: '0x',
  [ChainId.METER_TESTNET]: '0x',
  [ChainId.GODWOKEN]: '0x',
  [ChainId.GODWOKEN_TESTNET]: '0x',
  [ChainId.CALLISTO]: '0x',
  [ChainId.CALLISTO_TESTNET]: '0x',
  [ChainId.EVMOS]: '0x',
  [ChainId.EVMOS_TESTNET]: '0x',
  [ChainId.ENERGY_WEB_CHAIN]: '0x',
  [ChainId.ENERGY_VOLTA]: '0x',
  [ChainId.BASE]: '0x',
  [ChainId.BASE_GOERLI]: '0x',
  [ChainId.KAVA]: '0x',
  [ChainId.KAVA_TESTNET]: '0x',
  [ChainId.CLOVER]: '0x',
  [ChainId.CLOVER_TESTNET]: '0x',
  [ChainId.DEFICHAIN]: '0x',
  [ChainId.DEFICHAIN_TESTNET]: '0x',
  [ChainId.BRONOS]: '0x',
  [ChainId.BRONOS_TESTNET]: '0x',
  [ChainId.FILECOIN]: '0x',
  [ChainId.FILECOIN_CALIBRATION]: '0x',
  [ChainId.FLARE]: '0x',
  [ChainId.FLARE_TESTNET]: '0x',
  [ChainId.TARAXA]: '0x',
  [ChainId.TARAXA_TESTNET]: '0x',
  [ChainId.ARBITRUM_ONE]: '0x',
  [ChainId.ARBITRUM_GOERLI]: '0x',
  [ChainId.ZKSYNC]: '0x',
  [ChainId.ZKSYNC_TESTNET]: '0x',
  [ChainId.LINEA]: '0x',
  [ChainId.LINEA_TESTNET]: '0x',
  [ChainId.BTTCHAIN]: '0x',
  [ChainId.BTTCHAIN_TESTNET]: '0x',
  [ChainId.BOBA_AVAX]: '0x',
  [ChainId.BOBA_BNB]: '0x',
  [ChainId.CMP]: '0x',
  [ChainId.CMP_TESTNET]: '0x',
  [ChainId.CORE]: '0x',
  [ChainId.CORE_TESTNET]: '0x',
  [ChainId.CUBECHAIN]: '0x',
  [ChainId.CUBECHAIN_TESTNET]: '0x',
  [ChainId.DARWINIA_CRAB]: '0x',
  [ChainId.DARWINIA_PANGOLIN]: '0x',
  [ChainId.DIODE_PRETNET]: '0x',
  [ChainId.DIODE_TESTNET]: '0x',
  [ChainId.GOCHAIN]: '0x',
  [ChainId.GOCHAIN_TESTNET]: '0x',
  [ChainId.HAQQ]: '0x',
  [ChainId.HAQQ_TESTEDGE2]: '0x',
  [ChainId.KARDIACHAIN]: '0x',
  [ChainId.KARDIACHAIN_TESTNET]: '0x',
  [ChainId.METADIUM]: '0x',
  [ChainId.METADIUM_TESTNET]: '0x',
  [ChainId.OP_BNB]: '0x',
  [ChainId.OP_BNB_TESTNET]: '0x',
  [ChainId.PALM]: '0x',
  [ChainId.PALM_TESTNET]: '0x',
  [ChainId.POLIS]: '0x',
  [ChainId.POLIS_TESTNET]: '0x',
  [ChainId.SHIBACHAIN]: '0x',
  [ChainId.SMART_BCH]: '0x',
  [ChainId.SMART_BCH_TESTNET]: '0x',
  [ChainId.SONGBIRD_CANARY]: '0x',
  [ChainId.THAICHAIN]: '0x',
  [ChainId.UBIQ]: '0x',
  [ChainId.UBIQ_TESTNET]: '0x',
  [ChainId.VECHAIN]: '0x',
  [ChainId.VECHAIN_TESTNET]: '0x',
  [ChainId.XINFIN]: '0x',
  [ChainId.XINFIN_APOTHEM]: '0x',
  [ChainId.SCROLL]: '0x',
  [ChainId.SCROLL_SEPOLIA]: '0x',
  [ChainId.XRPL_DEVNET]: '0x',
  [ChainId.ZETACHAIN]: '0x',
  [ChainId.ZETACHAIN_ATHENS]: '0x',
  [ChainId.ZYX]: '0x',
} as const satisfies ContractAddresses<SupportedChainId>
