import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Colors } from "../../theme";

export interface Props {
  color?: keyof Colors;
  showSkeleton?: boolean;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
`;

const DonaPrice: React.FC<React.PropsWithChildren<Props>> = ({
  color = "textSubtle",
  showSkeleton = true,
}) => {
  const [donaPrice, setDonaPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDonaPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=donaswap&vs_currencies=usd"
        );
        const data = await response.json();
        console.log("API response data:", data);  // Debugging log
        setDonaPrice(data.donaswap?.usd || 0);  // Ensure correct reference to `donaswap`
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Dona price:", error);
        setLoading(false);
      }
    };

    fetchDonaPrice();
  }, []);

  return !loading && donaPrice ? (
    <PriceLink
      href="https://donaswap.io/swap?outputCurrency=0x229C32460c6BeaC113e720ac4a7495b57F53F7Cf&chainId=56"
    >
      <LogoRound width="24px" mr="8px" />
      <Text color={color} bold>{`$${donaPrice.toFixed(7)}`}</Text>
    </PriceLink>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} />
  ) : null;
};

export default React.memo(DonaPrice);
