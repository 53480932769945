import useSWRImmutable from 'swr/immutable'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from './bigNumber'

export const useFlamePrice = () => {
  return useSWRImmutable(
    ['flame-usd-price'],
    async () => {
      const flame = await (await fetch('')).json()
      return flame.price as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}

// for migration to bignumber.js to avoid breaking changes
export const useFlamePriceAsBN = () => {
  const { data } = useSWRImmutable(
    ['flame-usd-price-bn'],
    async () => {
      const flame = await (await fetch('')).json()
      return new BigNumber(flame.price)
    },
    {
      compare: (a, b) => {
        if (!a && !b) return true
        if (!a || !b) return false
        return a.eq(b)
      },
      refreshInterval: 1_000 * 10,
    },
  )

  return data ?? BIG_ZERO
}
