import { bscTestnetTokens } from '@donaswap/tokens'
import { FeeAmount } from '@donaswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 1,
  //   lpAddress: '0x5147173E452AE4dd23dcEe7BaAaaAB7318F16F6B',
  //   token0: bscTestnetTokens.usdt,
  //   token1: bscTestnetTokens.wbnb,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'FLAME',
  //   lpAddress: '0x36e3E4fF6471559b19F66bD10985534d5e214D44',
  //   token: bscTestnetTokens.syrup,
  //   quoteToken: bscTestnetTokens.wbnb,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'BUSD-FLAME LP',
  //   lpAddress: '0xb98C30fA9f5e9cf6749B7021b4DDc0DBFe73b73e',
  //   token: bscTestnetTokens.busd,
  //   quoteToken: bscTestnetTokens.flame,
  // },
  // {
  //   pid: 9,
  //   lpSymbol: 'BUSD-USDC LP',
  //   lpAddress: '0x7CA885d338462790DD1B5416ebe6bec75ee045a1',
  //   token: bscTestnetTokens.mockBusd, // coins[0]
  //   quoteToken: bscTestnetTokens.usdc, // coins[1]
  //   stableSwapAddress: '0xd5E56CD4c8111643a94Ee084df31F44055a1EC9F',
  //   infoStableSwapAddress: '0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
