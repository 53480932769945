import { cloverTestnetTokens } from '@donaswap/tokens'
import { FeeAmount } from '@donaswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '..'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    lpAddress: '0xF8E1FA0648F87c115F26E43271B3D6e4a80A2944',
    token0: cloverTestnetTokens.wclv,
    token1: cloverTestnetTokens.usdt,
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  {
    pid: 34,
    vaultPid: 3,
    lpSymbol: 'DONA-WCLV LP',
    lpAddress: '0xF8E1FA0648F87c115F26E43271B3D6e4a80A2944',
    quoteToken: cloverTestnetTokens.wclv,
    token: cloverTestnetTokens.dona,
  },
  // {
  //   pid: 23,
  //   vaultPid: 3,
  //   lpSymbol: 'DONA-WETH LP',
  //   lpAddress: '0x846f5e6DDb29dC5D07f8dE0a980E30cb5aa07109',
  //   quoteToken: cloverTestnetTokens.wclv,
  //   token: cloverTestnetTokens.dona,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
