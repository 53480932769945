import React from "react";
// import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // #TODO Add svg paths
    <img src="images/addedImages/flame.png" alt="icon" width={24} />
  );
};

export default Icon;
