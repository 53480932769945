import { bscTokens } from '@donaswap/tokens'
import { FeeAmount, Pool } from '@donaswap/v3-sdk'
import { getAddress } from 'viem'
import { DONASWAP_BNB_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: bscTokens.flame,
    token1: bscTokens.wbnb,
    lpAddress: '0x133B3D95bAD5405d14d53473671200e9342896BF',
    feeAmount: FeeAmount.MEDIUM,
  },
  // {
  //   pid: 2,
  //   token0: bscTokens.flame,
  //   token1: bscTokens.busd,
  //   lpAddress: '0x9f6EB6903C1277c8f02d71F8814dc9998199af1D',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   v1pid: 0,
  //   lpSymbol: 'FLAME',
  //   lpAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   token: bscTokens.syrup,
  //   quoteToken: bscTokens.wbnb,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
