import { ChainId } from '@donaswap/sdk'

export const supportedChainIdV2 = [
  ChainId.FIRECHAIN,
  ChainId.RINIA,
  ChainId.BSC,
  ChainId.BSC_TESTNET,
  ChainId.ETHEREUM,
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.HOLESKY,
  ChainId.POLYGON,
  ChainId.POLYGON_AMOY,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.CRO,
  ChainId.CRO_TESTNET,
  ChainId.AVALANCHE,
  ChainId.AVALANCHE_FUJI,
  ChainId.FANTOM_OPERA,
  ChainId.FANTOM_TESTNET,
  ChainId.CELO,
  ChainId.CELO_ALFAJORES,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.SHIBARIUM,
  ChainId.SHIBARIUM_TESTNET,
  ChainId.PULSECHAIN,
  ChainId.PULSECHAIN_TESTNET,
  ChainId.XEN,
  ChainId.XEN_DEVNET,
  ChainId.HARMONY,
  ChainId.HARMONY_TESTNET,
  ChainId.COINEX,
  ChainId.COINEX_TESTNET,
  ChainId.DOGECHAIN,
  ChainId.DOGECHAIN_TESTNET,
  ChainId.ENGRAM_TESTNET,
  ChainId.ETHEREUM_CLASSIC,
  ChainId.ETHEREUM_CLASSIC_TESTNET,
  ChainId.FUSION,
  ChainId.FUSION_TESTNET,
  ChainId.HECO,
  ChainId.HECO_TESTNET,
  ChainId.KCC,
  ChainId.KCC_TESTNET,
  ChainId.KLAYTN,
  ChainId.KLAYTN_BAOBAB,
  ChainId.OKXCHAIN,
  ChainId.OKXCHAIN_TESTNET,
  ChainId.THETHA,
  ChainId.THETHA_TESTNET,
  ChainId.ULTRON,
  ChainId.ULTRON_TESTNET,
  ChainId.MOONBEAM,
  ChainId.MOONRIVER,
  ChainId.MOONBASE_ALPHA,
  ChainId.AURORA,
  ChainId.AURORA_TESTNET,
  ChainId.BOBA,
  ChainId.BOBA_GOERLI,
  ChainId.GNOSIS,
  ChainId.GNOSIS_CHIADO,
  ChainId.METIS,
  ChainId.METIS_GOERLI,
  ChainId.TELOS,
  ChainId.TELOS_TESTNET,
  ChainId.SYSCOIN,
  ChainId.SYSCOIN_TANENBAUM,
  ChainId.IOTEX,
  ChainId.IOTEX_TESTNET,
  ChainId.SHIDEN,
  ChainId.ASTAR,
  ChainId.SHIBUNYA,
  ChainId.FUSE,
  ChainId.FUSE_SPARKNET,
  ChainId.VELAS,
  ChainId.VELAS_TESTNET,
  ChainId.REI,
  ChainId.REI_TESTNET,
  ChainId.KEKCHAIN,
  ChainId.KEKCHAIN_TESTNET,
  ChainId.TOMOCHAIN,
  ChainId.TOMOCHAIN_TESTNET,
  ChainId.THUNDERCORE,
  ChainId.THUNDERCORE_TESTNET,
  ChainId.WANCHAIN,
  ChainId.WANCHAIN_TESTNET,
  ChainId.RSK,
  ChainId.RSK_TESTNET,
  ChainId.ELASTOS,
  ChainId.ELASTOS_TESTNET,
  ChainId.CONFLUX,
  ChainId.CONFLUX_TESTNET,
  ChainId.BRISECHAIN,
  ChainId.BRISECHAIN_TESTNET,
  ChainId.MUUCHAIN,
  ChainId.MUUCHAIN_TESTNET,
  ChainId.CANTO,
  ChainId.CANTO_TESTNET,
  ChainId.OASIS_EMERALD,
  ChainId.OASIS_TESTNET,
  ChainId.VISION,
  ChainId.VISION_TESTNET,
  ChainId.STEP,
  ChainId.STEP_TESTNET,
  ChainId.METER,
  ChainId.METER_TESTNET,
  ChainId.GODWOKEN,
  ChainId.GODWOKEN_TESTNET,
  ChainId.CALLISTO,
  ChainId.CALLISTO_TESTNET,
  ChainId.EVMOS,
  ChainId.EVMOS_TESTNET,
  ChainId.ENERGY_WEB_CHAIN,
  ChainId.ENERGY_VOLTA,
  ChainId.BASE,
  ChainId.BASE_GOERLI,
  ChainId.KAVA,
  ChainId.KAVA_TESTNET,
  ChainId.CLOVER,
  ChainId.CLOVER_TESTNET,
  ChainId.DEFICHAIN,
  ChainId.DEFICHAIN_TESTNET,
  ChainId.BRONOS,
  ChainId.BRONOS_TESTNET,
  ChainId.FILECOIN,
  ChainId.FILECOIN_CALIBRATION,
  ChainId.FLARE,
  ChainId.FLARE_TESTNET,
  ChainId.TARAXA,
  ChainId.TARAXA_TESTNET,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA,
  ChainId.LINEA_TESTNET,
  ChainId.BTTCHAIN,
  ChainId.BTTCHAIN_TESTNET,
  ChainId.BOBA_AVAX,
  ChainId.BOBA_BNB,
  ChainId.CMP,
  ChainId.CMP_TESTNET,
  ChainId.CORE,
  ChainId.CORE_TESTNET,
  ChainId.CUBECHAIN,
  ChainId.CUBECHAIN_TESTNET,
  ChainId.DARWINIA_CRAB,
  ChainId.DARWINIA_PANGOLIN,
  ChainId.DIODE_PRETNET,
  ChainId.DIODE_TESTNET,
  ChainId.GOCHAIN,
  ChainId.GOCHAIN_TESTNET,
  ChainId.HAQQ,
  ChainId.HAQQ_TESTEDGE2,
  ChainId.KARDIACHAIN,
  ChainId.KARDIACHAIN_TESTNET,
  ChainId.METADIUM,
  ChainId.METADIUM_TESTNET,
  ChainId.OP_BNB,
  ChainId.OP_BNB_TESTNET,
  ChainId.PALM,
  ChainId.PALM_TESTNET,
  ChainId.POLIS,
  ChainId.POLIS_TESTNET,
  ChainId.SHIBACHAIN,
  ChainId.SMART_BCH,
  ChainId.SMART_BCH_TESTNET,
  ChainId.SONGBIRD_CANARY,
  ChainId.THAICHAIN,
  ChainId.UBIQ,
  ChainId.UBIQ_TESTNET,
  ChainId.VECHAIN,
  ChainId.VECHAIN_TESTNET,
  ChainId.XINFIN,
  ChainId.XINFIN_APOTHEM,
  ChainId.SCROLL,
  ChainId.SCROLL_SEPOLIA,
  ChainId.XRPL_DEVNET,
  ChainId.ZETACHAIN,
  ChainId.ZETACHAIN_ATHENS,
  ChainId.ZYX
] as const