import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  // EarnFillIcon,
  // DonaswapProtectorIcon,
  // EarnIcon,
  // InfoIcon,
  // DocsIcon,
  ChartLogo,
  RefreshIcon,
  // RepoIcon,
  // TrophyIcon,
  // TrophyFillIcon,
  // NftIcon,
  // NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  DocsIcon,
} from '@donaswap/uikit'
import { ContextApi } from '@donaswap/localization'
// import { SUPPORTED_CHAIN_IDS as POOL_SUPPORTED_CHAINS } from '@donaswap/pools'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { getPerpetualUrl } from 'utils/getPerpetualUrl'
// import { SUPPORT_FARMS, SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
// import { SUPPORT_FARMS } from 'config/constants/supportChains'
// import { NewIconButton } from 'views/BuyCrypto/components/NewIcon'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Exchange'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        // {
        //   label: t('Perpetual'),
        //   href: getPerpetualUrl({
        //     chainId,
        //     languageCode,
        //     isDark,
        //   }),
        //   confirmModalId: 'usCitizenConfirmModal',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.donaswap.io/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: `${t('Limit')} (V2)`,
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // },
        // {
        //   label: t('Buy Crypto'),
        //   LabelIcon: NewIconButton,
        //   href: '/buy-crypto',
        //   status: { text: t('New'), color: 'success' },
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   supportChainIds: SUPPORT_FARMS,
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //       supportChainIds: SUPPORT_FARMS,
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       supportChainIds: POOL_SUPPORTED_CHAINS,
    //     },
    //     // {
    //     //   label: t('Liquid Staking'),
    //     //   href: '/liquid-staking',
    //     //   supportChainIds: POOL_SUPPORTED_CHAINS,
    //     // },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Win'),
    //   href: '/prediction',
    //   icon: TrophyIcon,
    //   fillIcon: TrophyFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   items: [
    //     {
    //       label: t('Trading Reward'),
    //       href: '/trading-reward',
    //       hideSubNav: true,
    //     },
    //     {
    //       label: t('Trading Competition'),
    //       href: '/competition',
    //       image: '/images/decorations/tc.png',
    //       hideSubNav: true,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //       image: '/images/decorations/prediction.png',
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //   ],
    // },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    // {
    //   label: t('Game'),
    //   icon: DonaswapProtectorIcon,
    //   hideSubNav: true,
    //   items: [
    //     {
    //       label: t('Donaswap Protectors'),
    //       href: 'https://protectors.donaswap.io',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //       status: { text: t('New'), color: 'success' },
    //     },
    //   ],
    // },
    {
      label: t('Info'),
      href: '/info',
      icon: ChartLogo,
      hideSubNav: true,
      items: [
        {
          label: t('Info v3'),
          href: '/info/v3',
        },
        {
          label: t('Info v2'),
          href: '/info/v2',
        },
        //     // {
        //     //   label: t('IFO'),
        //     //   href: '/ifo',
        //     //   supportChainIds: SUPPORT_ONLY_BSC,
        //     //   image: '/images/ifos/ifo-bunny.png',
        //     // },
        //     // {
        //     //   label: t('Affiliate Program'),
        //     //   href: '/affiliates-program',
        //     // },
        //     // {
        //     //   label: t('Voting'),
        //     //   href: '/voting',
        //     //   supportChainIds: SUPPORT_ONLY_BSC,
        //     //   image: '/images/voting/voting-bunny.png',
        //     // },
        //     // {
        //     //   type: DropdownMenuItemType.DIVIDER,
        //     // },
        //     // {
        //     //   label: t('Leaderboard'),
        //     //   href: '/teams',
        //     //   supportChainIds: SUPPORT_ONLY_BSC,
        //     //   image: '/images/decorations/leaderboard.png',
        //     // },
        //     // {
        //     //   type: DropdownMenuItemType.DIVIDER,
        //     // },
        //     // {
        //     //   label: t('Blog'),
        //     //   href: 'https://blog.donaswap.io',
        //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
        //     // },
        //     // {
        //     //   label: t('Docs'),
        //     //   href: 'https://docs.donaswap.io',
        //     //   type: DropdownMenuItemType.EXTERNAL_LINK,
        //     // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Migrate'),
    //   href: 'https://claim.donaswap.io',
    //   icon: RefreshIcon,
    //   hideSubNav: true,
    //   type: DropdownMenuItemType.EXTERNAL_LINK,
    // },
    {
      label: '',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Docs'),
          href: 'https://docs.donaswap.io',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
