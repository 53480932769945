import { bscTokens } from '@donaswap/tokens'
import { getAddress } from 'viem'

import { PoolCategory, SerializedPool } from '../../types'

export const livePools: SerializedPool[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.flame,
    earningToken: bscTokens.flame,
    contractAddress: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },
  {
    sousId: 347,
    stakingToken: bscTokens.flame,
    earningToken: bscTokens.tusd,
    contractAddress: '0x72cd910eE115E494485Dd32Ce7bC5dE563eceA51',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.02314',
    version: 3,
  },
].map((p) => ({
  ...p,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

// known finished pools
const finishedPools = [
  {
    sousId: 342,
    stakingToken: bscTokens.flame,
    earningToken: bscTokens.ush,
    contractAddress: '0x6Db79ba7c0A6DDC48cFDd79Df6cb757b9E8B51DD',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1851',
    version: 3,
  },
  {
    sousId: 341,
    stakingToken: bscTokens.flame,
    earningToken: bscTokens.pstake,
    contractAddress: '0x17086aF09D63852aD4646f1f837b34e171bEa99B',
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.5497',
    version: 3,
  },
].map((p) => ({
  ...p,
  isFinished: true,
  contractAddress: getAddress(p.contractAddress),
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export const pools: SerializedPool[] = [...livePools, ...finishedPools]
